<template>
  <div class="v-form-input-counter-edit" :class="mainClass">

    <v-icon-svg v-if="currentState === 'collapsed'"
                class="minus"
                @click="counterMinus()"
                name="blue-minus-rounded"
                as-img
                as-block/>

    <v-icon-svg v-if="currentState === 'collapsed'"
                class="plus"
                @click="counterPlus()"
                name="blue-plus-rounded"
                as-img
                as-block/>

    <v-icon-svg-wrapped v-if="currentState === 'expanded'"
                        class="close"
                        name="close-icon-blue2"
                        as-img
                        :inner-size="10.5"
                        :outer-size="18"
                        @click="currentState = 'collapsed'"/>

    <v-button v-if="currentState === 'expanded'"
              class="apply"
              @click="save()"
              color="green"
              :loading="applyLoading"
              text="APPLY"/>

    <input class="input"
           v-if="currentState === 'collapsed'"
           type="text"
           v-model="inputData"
           :disabled="false"
           ref="refInput"
           @focusin="inputFocusIn"/>

    <input class="input"
           v-if="currentState === 'expanded'"
           type="text"
           v-model="inputDataExpanded"
           :disabled="false"
           ref="refInputExpanded"/>

  </div>
</template>

<script setup>

import VIconSvg from "../Base/VIconSvg.vue";
import VButton from "../Base/VButton.vue";
import {computed, ref, watch} from "vue";
import VIconSvgWrapped from "../Base/VIconSvgWrapped.vue";

const props = defineProps({
  min: {type:Number, default: null},
  max: {type:Number, default: null},
  disabled: {type:Boolean, default:false},
});

const emit = defineEmits(['save']);

const refInput = ref();
const refInputExpanded = ref();

const mainClass = computed(() => {
  let response = [];
  response.push(`is-${currentState.value}`);
  if(props.disabled) response.push('is-disabled');
  return response;
})


const inputData = defineModel();
const inputDataExpanded = ref(inputData.value);

const currentState = ref('collapsed');

const counterCorrect = (value) => {
  if(value === ''){
    return value;
  }

  let v = parseInt(value?.toString()?.replace(/\D/g, ''));
  let isDiff = v != value;
  if(isDiff && !isNaN(v)){
    return v;
  }if(isNaN(v)){
    if(props.min){
      return props.min;
    }else{
      return 1;
    }
  }
  return value;
}

const counterPlus = () => {
  let v = parseInt(counterCorrect(inputData.value));
  if(props.max!==null && v >= props.max){
    return;
  }
  inputData.value = v+1;
}

const counterMinus = () => {
  let v = parseInt(counterCorrect(inputData.value));
  if (props.min !== null && v <= props.min) {
    return;
  }
  inputData.value--;
}

watch(() => inputData.value, (value) => {
  inputData.value = counterCorrect(value);
  inputDataExpanded.value = inputData.value;
});
watch(() => inputDataExpanded.value, (value) => {
  inputDataExpanded.value = counterCorrect(value);
});

function inputFocusIn(){
  if(currentState.value !== 'expanded'){
    const length = inputData.value?.toString()?.length;
    setTimeout(() => {
      refInputExpanded.value.setSelectionRange(length,length);
    },10);
  }
  currentState.value = 'expanded'
}

const applyLoading = ref(false);
function save(){
  applyLoading.value = true;
  emit('save', inputDataExpanded.value);
}

function saveWithSuccess(){
  applyLoading.value = false;
  currentState.value = 'collapsed';
}
function saveWithError(){
  applyLoading.value = false;
}

function setContentEditable(number){
  currentState.value = 'expanded';
  inputDataExpanded.value = number;
}

defineExpose({currentState,saveWithSuccess,saveWithError,setContentEditable});

</script>

<style lang="scss" scoped>
.v-form-input-counter-edit{
  @include flex(row,between,center,5px);
  border:1px solid $secondary-blue-10;
  height:40px;
  width:100%;
  max-width:103px;
  padding:0px 5px;
  flex-shrink:0;
  border-radius: 4px;

  .plus,.minus{
    cursor:pointer;
  }

  .input{
    text-align: center;
    width:100%;
    flex-grow:2;
    border:none;
    outline:0px;
    color:#151515;
    font:$font-14-semi;
    background:transparent;
    cursor:pointer;
  }

  .apply{
    width:55px;
    height:24px;
    font:$font-mini-12-regu;
    font-weight: 500;
  }

  &.is-collapsed{
    .minus{order:1;}
    .input{order:2;}
    .plus{order:3;}
  }

  &.is-disabled{
    position:relative;
    opacity:0.5;
    &:after{
      content:'';
      display:block;
      position:absolute;
      width:100%;
      height:100%;
      top:0px;
      left:0px;
    }
  }

  &.is-expanded{
    max-width:100%;
    padding:0px 7px;
    .close{order:1;}
    .input{order:2;}
    .apply{order:3;}
  }

}
</style>