import {createApp} from 'vue';
import store from './src/store';
import {createPinia} from 'pinia';
import RecsPanel from './src/components/RecsPanel.vue';
import UserNotificationsBlock from './src/components/UserNotificationsBlock.vue';
import ChecklistsWidget from './src/components/ChecklistsWidget.vue';
import ScreenshotsCarousel from './src/components/ScreenshotsCarousel.vue';
import ScProjectAdd from "./src/components/SeoClicks/ScProjectAdd.vue";
import ScProjects from "./src/components/SeoClicks/ScProjects.vue";
import ScProject from './src/components/SeoClicks/ScProject.vue';
// import BcMain from "./src/components/BuyCredits/BcMain.vue";
import LbAddProject from "./src/components/LinkBuilding/LbAddProject.vue";
import LbProject from "./src/components/LinkBuilding/LbProject.vue";
import LbProjects from "./src/components/LinkBuilding/LbProjects.vue";
import isMobilePlugin from './src/plugins/isMobile';
import currentHashPlugin from './src/plugins/currentHash';
import ScrollAnchor from "./src/directives/scrollAnchor";
import AutoTooltip from "./src/directives/autoTooltip";
import realServerTime from "./src/plugins/realServerTime";
import ScProjectsPage from "./src/components/SeoClicks/ScProjectsPage.vue";
import LbProjectsPage from "./src/components/LinkBuilding/LbProjectsPage.vue";
import * as Sentry from '@sentry/vue';

function initSentry(apps) {
    if (import.meta.env.VITE_SENTRY === 'true' && import.meta.env.VITE_SENTRY_VUE_DSN && apps.length > 0) {
        console.log('Init Sentry');
        Sentry.init({
            app: apps,
            dsn: import.meta.env.VITE_SENTRY_VUE_DSN,
            environment: import.meta.env.VITE_APP_ENV,
            integrations: [
                // Sentry.captureConsoleIntegration(),
                // Sentry.browserTracingIntegration(),
                Sentry.replayIntegration({
                    maskAllText: false,
                    maskAllInputs: true,
                    blockAllMedia: true,
                }),
                // Sentry.replayCanvasIntegration(),
                Sentry.thirdPartyErrorFilterIntegration({
                    // Specify the application keys that you specified in the Sentry bundler plugin
                    filterKeys: ['spF3g7hJfdlG2S'],
                    // Defines how to handle errors that contain third party stack frames.
                    // Possible values are:
                    // - 'drop-error-if-contains-third-party-frames'
                    // - 'drop-error-if-exclusively-contains-third-party-frames'
                    // - 'apply-tag-if-contains-third-party-frames'
                    // - 'apply-tag-if-exclusively-contains-third-party-frames'
                    behaviour: "apply-tag-if-contains-third-party-frames",
                }),
            ],
            debug: import.meta.env.VITE_APP_ENV !== 'production',
            tracesSampleRate: parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE),
            trackComponents: true,
            attachStacktrace: true,
            autoSessionTracking: false,
            tracePropagationTargets: [
                'localhost',
                /^https:\/\/app-test\.sparktraffic\.com/,
                /^https:\/\/app\.sparktraffic\.com/,
            ],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            // Vue specific
            // logErrors: import.meta.env.VITE_APP_ENV !== 'production',
            // attachProps: true,
            ignoreErrors: [
                'Weglot is not defined',
                'Can\'t find variable: Weglot',
                'grecaptcha is not defined',
                // 'TypeError: Failed to fetch',
                'TypeError: NetworkError when attempting to fetch resource.',
                'Event: Event `Event` (type=error) captured as promise rejection',
                'UnhandledRejection: Object captured as promise rejection with keys: code, message',
                'Error: Object captured as exception with keys: isDefaultPrevented, isTrigger',
                'Non-Error exception captured',
                'Non-Error promise rejection captured',
                /Object captured as exception with keys: isTrigger, jQuery/i,
                /ResizeObserver loop completed with undelivered notifications/i,
                /Cannot redefine property: googletag/i,
                /dotLottie-common/i,
                /NS_ERROR_FAILURE: No error message/i,
                /QuotaExceededError: Failed to execute 'setItem' on 'Storage'/i
            ],
            denyUrls: [
                // Cloudflare
                /rocket-loader\.min\.js/i,
                /cloudflare-static\/rocket-loader/i,
                /static.cloudflareinsights.com\/beacon\.min\.js/i,
                // Google
                /googletagmanager\.com/i,
                // Facebook flakiness
                /graph\.facebook\.com/i,
                // Facebook blocked
                /connect\.facebook\.net\/en_US\/all\.js/i,
                // Chrome extensions
                /extensions\//i,
                /^chrome:\/\//i,
                /^chrome-extension:\/\//i,
                // Mozilla extension
                /^moz-extension:\/\//i,
                // Safari extension
                /safari-extension:\/\//i,
                /webkit-masked-url:\/\/hidden/i,
                // Other plugins
                /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
                /webappstoolbarba\.texthelp\.com/i,
                /metrics\.itunes\.apple\.com\.edgesuite\.net/i,
                // Senja
                /widget\.senja\.io/i,
                /track\.senja\.workers\.dev/i,
                /static\.senja\.io/i,
                /senja-assets\.b-cdn\.net/i,
                // Calendly Widget
                /calendly-widget\.js/i,
                // Hotjar.io
                /hotjar\.io/i
            ],
            // beforeSend(event, hint) {
            /* tslint:disable:no-string-literal only-arrow-functions */
            // const isNonErrorException = (event.exception.values && event.exception.values[0].value.startsWith('Non-Error exception captured'));
            // (hint.originalException['message'] && hint.originalException['message'].startsWith('Non-Error exception captured'))
            // (event.exception.values && event.exception.values[0].value.startsWith('Failed to read the \'localStorage\' property from \'Window\': Access is denied for this document')) ||
            // (hint.originalException['message'] && hint.originalException['message'].startsWith('Failed to read the \'localStorage\' property from \'Window\': Access is denied for this document'));

            // if (isNonErrorException) {
            // return null;
            // }

            // if (hint.originalException.name === 'CustomError') {
            //     const serverData = await hint.originalException.someMethodAttachedToOurCustomError();
            //     event.extra = {
            //         ...event.extra,
            //         serverData,
            //     };
            // }

            // return event;
            // },
            beforeBreadcrumb(breadcrumb, hint) {
                if (breadcrumb.category === 'ui.click' && hint.event && hint.event.target) {
                    const target = hint.event.target;
                    if (target) {
                        let foundEl = $(target).closest('[data-ajax-action]');
                        if (foundEl.length > 0) {
                            breadcrumb.message = `User clicked on a button with 'data-ajax-action': ${foundEl.data('ajax-action')}`;
                        }
                    }
                }

                return breadcrumb;
            },
        });

        if (typeof userData !== 'undefined') {
            Sentry.setUser({
                id: userData.id,
                email: userData.login
            });
        }
    }
}

window.addEventListener('vite:preloadError', (event) => {
    if (typeof window.location.href !== 'undefined') {
        console.error('Vite: Preload error');
        event.preventDefault();
    }
});

// intercept responses before they are handled by then or catch
// axios.interceptors.response.use(undefined, (error) => {
//     if (error.response && error.response.status === 401) {
//         console.log('Error Axios request: ' + error.response.status);
//
//         if (typeof window.location.href !== 'undefined') {
//             window.location.href = '/';
//             return false;
//         }
//     }
//
//     return Promise.reject(error.response.data);
// });

let Apps = [];
const pinia = createPinia();

const compontentsForMount = {
    '#user-notifications-vue': UserNotificationsBlock,
    '#checklists-widget-vue': ChecklistsWidget,
    '#screenshots-carousel-vue': ScreenshotsCarousel,
    '#recommendation-panel-vue': RecsPanel,
    '#seo-traffic-project-add-vue': ScProjectAdd,
    // '#seo-traffic-projects-vue': ScProjects,
    '#seo-traffic-projects-vue': ScProjectsPage,
    '#seo-traffic-project-vue': ScProject,
    // '#buy-credits-vue': BcMain,
    '#link-building-project-add-vue': LbAddProject,
    '#link-building-project-vue': LbProject,
    // '#link-building-projects-vue': LbProjects,
    '#link-building-projects-vue': LbProjectsPage,
}

Object.entries(compontentsForMount).forEach(([rootContainer, component]) => {
    if(document.querySelector(rootContainer)){
        Apps[rootContainer] = createApp(component);
    }
});

initSentry(Object.values(Apps));

Object.entries(Apps).forEach(([rootContainer, app]) => {
    if(document.querySelector(rootContainer)){
        app.use(store)
        .use(pinia)
        .use(isMobilePlugin)
        .use(currentHashPlugin)
        .use(realServerTime)
        .provide('fishText', 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis')
        .directive('scroll-anchor', ScrollAnchor)
        .directive('auto-tooltip', AutoTooltip)
        .mount(rootContainer);
    }
});