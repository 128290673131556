<template>
  <div class="v-fort-by-simple vsbs" ref="wrap">
    <div class="vsbs__text">
      <span v-if="hasText">Sort by:</span>
      <v-icon-svg
          v-if="arrowSortingDirect"
          class="vsbs__arrow-sorting-direct"
          :class="`vsbs__arrow-sorting-direct--${modelSortingDirect}`"
          name="arrow-sorting-direct"
          @click="changeArrowSorting"
      />
      <v-link href="#" ref="link" @click.prevent="refTooltipSelect.toggle()">{{model.name}}</v-link>
      <div class="vsbs__icon" @click.prevent="refTooltipSelect.toggle()">
        <v-icon-svg class="vsbs__icon-svg" name="arrow-short-v2" as-block/>
      </div>
    </div>
    <v-tooltip-select
        ref="refTooltipSelect"
        v-model="model"
        :get-linked-block="() => link.$el"
        :options="options"
        :style="{maxWidth: '216px'}"
        :close-after-selection="true"
    />
  </div>
</template>

<script setup lang="ts">
import VIconSvg from "./VIconSvg.vue";
import VLink from "./VLink.vue";
import VTooltip from "./VTooltip.vue";
import VTooltipSelect from "./VTooltipSelect.vue";
import {ref} from "vue";

const props = defineProps({
  options: {type:Array, default:[]},
  arrowSortingDirect: {type:Boolean, default:false},
  hasText: {type:Boolean,default:true},
});

const model = defineModel();
const modelSortingDirect = defineModel('sortingDirect', {default:'asc'});

const wrap = ref();
const refTooltipSelect = ref();
const link = ref();

function changeArrowSorting(){
  if(modelSortingDirect.value === 'asc'){
    modelSortingDirect.value = 'desc';
  }else{
    modelSortingDirect.value = 'asc';
  }
}

</script>

<style scoped>
.v-fort-by-simple.vsbs{
  .vsbs__text{
    display: flex;
    align-items: center;
    gap:2px;
  }
  .vsbs__arrow-sorting-direct{
    cursor: pointer;
    &.vsbs__arrow-sorting-direct--desc{
      transform:rotate(180deg);
    }
  }
  .vsbs__icon{
    @include flex(row,center,center);
    cursor:pointer;
  }
}
</style>