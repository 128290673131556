import {defineStore} from "pinia";
import {UrlManipulator} from "../utils/UrlManipulator";

export const useRoutes = defineStore('routes', {
    state: () => {
        return {
            routes: {
                'seo-clicks.list' : '/seo-traffic/projects',
                'seo-clicks.add' : '/seo-traffic/projects/add',
                'seo-clicks.get' : '/seo-traffic/projects/{id}',
                'purchase-clicks.get' : '/cart#tab_seo_traffic_credits',
                'billing-balance.get' : '/billing-balance',
                'buy-credits' : '/cart',
                'backlinks-project.list' : '/link-building/projects',
                'backlinks-pay.get' : '/cart#tab_backlinks_credits',
                'backlinks-project.add' : '/link-building/projects/add',
                'backlinks-project.get' : '/link-building/projects/{uniquekey}',
                'subscriptions' : '/subscriptions',
                'subscription' : '/subscriptions/{id}',
            }
        };
    },
    actions: {
        url(name, params = {}, getParams = {}) {
            if(this.routes.hasOwnProperty(name)){
                let url = this.routes[name];
                Object.keys(params).forEach((key) => {
                    let value = params[key];
                    url = url.replace('{'+key+'}',value);
                });
                url = (new UrlManipulator(window.location.origin + url)).addGetParams(getParams).getUrl();
                return url;
            }else{
                return null;
            }
        },
        getProjectKey(){
            const url = window.location.pathname; // Получаем текущий URL из адресной строки
            const segments = url.split('/'); // Разделяем URL на сегменты
            return segments.pop();
        },
        getUrlSections(){
            const url = window.location.pathname; // Получаем текущий URL из адресной строки
            return url.split('/');
        },
        getUrlLastSection(){
            return this.getUrlSections().at(-1);
        }
    }
});