<template>
  <div class="v-lb-recommended-backlinks-list">
    <div v-for="(countCredits,typeConstant) in countLinks" class="item">

      <v-product class="product-name" :type-constant="typeConstant"/>

      <span class="count-credits">
        {{countCredits}} {{pluralize('credit', countCredits)}}
      </span>

    </div>
  </div>
</template>

<script setup>

import VProduct from "../../Base/VProduct.vue";
import {pluralize} from "../../../utils/utils";

const props = defineProps({
  /**
   * {
   *   const_da10: counts,
   *   const_da20: counts,
   *   ...
   * }
   */
  countLinks: {type: Object,default: {}},
});
</script>

<style lang="scss" scoped>
.v-lb-recommended-backlinks-list{
  @include flex(column, start, stretch, 12px);
  .item{
    @include flex(row, between, center);
  }

  .product-name{
    font-size:16px;
  }

  .count-credits{
    font-size: 13px;
    color:#384967;
  }

}
</style>