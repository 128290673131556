<template>
  <div class="v-not-found-projects vnfp">
      <span>
        {{message}}
      </span>
    <v-button v-if="link!==null" tag="a" :href="link" color="green" :is-bold="true">Add New Project</v-button>
  </div>
</template>

<script setup>
import VButton from "./VButton.vue";

const props = defineProps({
  link: {default:null},
  message: {default:'No projects yet'}
});

</script>

<style lang="scss" scoped>
.v-not-found-projects.vnfp{
  width:100%;
  padding: 30px 0px 30px 0px;
  border-radius: 4px;
  border: 1px solid #D6E3EF;
  display: flex;
  align-items: center;
  height:150px;
  justify-content: center;
  flex-direction:column;
  gap:10px;

  span{
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #596C8F;
    text-align: center;
  }
}
</style>