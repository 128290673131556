import {runIfHasSelecter} from '../../utils/panel-lib';
import route from '../index.js';

const initScripts = function() {
  const $table = $('#backlinks-homepage-buy-table');
  const $searchInput = $('.search-box input[type="search"]');
  const $loadingBlock = $('.loading-block');

  $table.dataTable({
    responsive: true,
    processing: true,
    dom: `<'row'<'col-sm-12 col-md-6 d-flex align-items-center justify-content-start'f><'col-sm-12 col-md-6 d-flex align-items-center justify-content-end'B>>
            <'row'<'col-sm-12'tr>>
            <'row'<'col-sm-12 col-md-5 d-none'i><'col-12'p>>`,
    order: [[5, 'desc']],
    buttons: [],
    columns: [
      {'width': '25%', orderable: false},
      {'width': '25%'},
      {'width': '20%', orderable: false},
      {'width': '15%', orderable: false},
      {'width': '15%', orderable: false},
      {'class': 'hidden'},
    ],
    columnDefs: [
      {
        targets: 2,
        render: function(data) {
          if (typeof data === 'undefined' || !data) {
            return '';
          }

          let category = data.
              replaceAll(',', ', ').
              replaceAll('  ', ' ');

          return `<span>${category}</span>`;
        },
      },
      {
        targets: -2,
        render: function(data, type, row) {
          if (typeof data === 'undefined' || !data) {
            return '';
          }

          let notEnoughCredits = (jsPageData.seoClicksCredits.total < parseInt(data));

          return `
<div>${data}/<span class="text_size_small">year</span></div>
<button class="button button-link mt-2" type="button"
    data-blog-id="${row[5]}"
    data-period="12"
    data-button-action="activate"
    ${notEnoughCredits ? 'disabled' : ''}
>
    <span>Activate</span>
</button>
${notEnoughCredits ? '<div class="mt-3 mb-2"><span class="color-red">Not enough credits</span></div><a href="' + route('seo_traffic.buy') + '">Buy Credits</a>' : ''}
`;
        },
      },
      {
        targets: -3,
        render: function(data, type, row) {
          if (typeof data === 'undefined' || !data) {
            return '';
          }

          let notEnoughCredits = (jsPageData.seoClicksCredits.total < parseInt(data));

          return `
<div>${data}/<span class="text_size_small">month</span></div>
<button class="button button-link mt-2" type="button"
    data-blog-id="${row[5]}"
    data-period="1" 
    data-button-action="activate"
    ${notEnoughCredits ? 'disabled' : ''}
>
    <span>Activate</span>
</button>
${notEnoughCredits ? '<div class="mt-3 mb-2"><span class="color-red">Not enough credits</span></div><a href="' + route('seo_traffic.buy') + '">Buy Credits</a>' : ''}
`;
        },
      },
    ],
  });

  // Search
  let localStorageSearch;
  try {
    localStorageSearch = window.localStorage.getItem('st-backlinks-homepage-buy-table-search');
  } catch (e) {
    console.log('Error localStorage.getItem: ' + e.responseText);
  }

  if (localStorageSearch !== null && localStorageSearch !== 'null' && localStorageSearch) {
    $searchInput.val(localStorageSearch);
  }

  $searchInput.on('keyup search', function() {
    $table.DataTable().search($(this).val()).draw();
    window.localStorage.setItem('st-backlinks-homepage-buy-table-search',
        $(this).val());
  });

  updateTable();

  function updateTable() {
    $table.DataTable().search($searchInput.val()).ajax.url(route('api.backlinks.links.get-available'));

    /** DataTables before receiving the data */
    $table.on('preXhr.dt', function() {
      $loadingBlock.addClass('processing');
    });

    /** DataTables after receiving the data */
    $table.on('xhr.dt', function() {
      $loadingBlock.removeClass('processing');
      $('body').removeClass('loading');
    }).DataTable().ajax.reload();
  }

  $(document).on('click', function (e) {
    /** Button Activate */
    if ($(e.target).closest('[data-button-action="activate"]').length > 0 || typeof $(e.target).data('button-action') !== 'undefined') {
      let $button = $(e.target);
      if ($(e.target).closest('[data-button-action="activate"]').length > 0) {
        $button = $(e.target).closest('[data-button-action="activate"]');
      }

      window.location.href = route('backlinks.homepage.activate') + '?blogId=' + $button.data('blog-id') + '&period=' + $button.data('period');
    }

    /** Button Notify Me */
    if ($(e.target).closest('[data-button-action="notify-me"]').length > 0 || typeof $(e.target).data('button-action') !== 'undefined') {
      let $button = $(e.target);
      if ($(e.target).closest('[data-button-action="notify-me"]').length > 0) {
        $button = $(e.target).closest('[data-button-action="notify-me"]');
      }

      $button.prop('disabled', true);

      axios.post(route('api.backlinks.links.notify-user')).
          then(function(response) {
            let data = (response.data ? response.data : {});

            if (data && data.success) {
              // document.location.reload();
            }
          }).
          catch(function() {
            $button.prop('disabled', false);
          });
    }
  });
};

export const initBacklinksHomepageBuyScripts = () => {
  /**
   * @property {object} jsPageData
   */

  runIfHasSelecter('[data-role="backlinks-buy-homepage"]', [
    initScripts,
  ]);
};
