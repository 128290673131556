<template>
  <div class="sc-project-balance-blackout">
    <v-button color="green" class="button" tag="a" :href="useRoutes().url('purchase-clicks.get')">Buy credits</v-button>
  </div>
</template>

<script setup>

import VButton from "../Base/VButton.vue";
import {useRoutes} from "../../stores/useRoutes";
</script>

<style lang="scss" scoped>
.sc-project-balance-blackout{
  position:absolute;
  width:100%;
  height:100%;
  top:0px;
  left:0px;
  background:#25587D80;
  z-index:9999;
  @include flex(row, center, start, 0px);
  .button{
    margin-top:100px;
    font-size: 21px;
    font-weight: 700;
    line-height: 18px;
    height:64px;
    padding-left:20px;
    padding-right:20px;
    text-transform:uppercase;
    @media(max-width:767px){
      margin-top:25px;
      font-size:16px;
      height:54px;
    }
  }
}
</style>