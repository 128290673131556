import _ from 'lodash';
import {formatNumber} from './panel-lib.js';

const saveInterfaceSettings = function(settings, callback, callbackError) {
    $.ajax({
        url: '/api/save-interface-settings',
        method: 'POST',
        data: settings,
        dataType: 'json',
        success: callback,
        error: callbackError,
    });
};

const sendInviteFriend = function(data, callback, callbackError) {
    $.ajax({
        url: 'api/user/send-invite-friend',
        method: 'POST',
        data: data,
        dataType: 'json',
        success: callback,
        error: callbackError,
    });
};

const addUserCartHistory = function(data, callback, callbackError) {
    $.ajax({
        type: 'POST',
        url: '/api/log/user-cart-history',
        data: data,
        dataType: 'json',
        success: callback,
        error: callbackError,
    });
};

const eventPageViewed = function(data, callback, callbackError) {
    if (typeof window.convert === 'undefined') {
        return;
    }

    let convertData = window.convert.currentData;

    let params = {
        data: data,
        convertData: convertData,
        currentUrl: window.location.href,
    };

    axios.post(`/api/event/page-viewed`, params).
        then(function(response) {
            // nothing
        }).
        catch(function(error) {
            console.error(error);
        });
};

export const api = {
    user: {
        saveInterfaceSettings: saveInterfaceSettings,
        sendInviteFriend: sendInviteFriend,
    },
    log: {
        addUserCartHistory: addUserCartHistory,
    },
    event: {
        eventPageViewed: eventPageViewed,
    },
};
