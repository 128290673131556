<template>
  <v-link href="#" class="v-link-plus vlp">
    <v-icon-svg name="plus-white" class="vlp__icon"/>
  </v-link>
</template>

<script setup>

import VIconSvg from "./VIconSvg.vue";
import VLink from "./VLink.vue";
</script>

<style lang="scss" scoped>
.v-link-plus.vlp{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #1C7EE0;
  display:flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.2s;
  &:hover{
    background-color:#058aff;
  }
  .vlp__icon{
    fill:#fff;
  }
}
</style>