<template>
  <div class="v-icon-realtime-pulse virp"></div>
  <!--<div class="lottie-pulsing-circle"></div>-->
</template>

<script setup>
import {onMounted} from "vue";

//onMounted(() => {
//  console.log('lottie', lottie);
//  console.log('lottie', document.querySelector('.lottie-pulsing-circle'));
//  lottie.loadAnimation({
//    container: document.querySelector('.lottie-pulsing-circle'),
//    renderer: 'svg',  // svg, canvas, html
//    loop: true,
//    autoplay: true,
//    path: '/img/lottie/pulsing-circle.json'
//  });
//});

</script>

<style scoped>
.v-icon-realtime-pulse.virp{
  width:20px;
  height:20px;
  background: #1A73E8;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #0000001a;
  animation: pulse-animation 1.2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(26, 115, 232, 0.4);
  }
  100% {
    box-shadow: 0 0 0 7px rgba(26, 115, 232, 0);
  }
}
</style>