import {runIfHasSelecter} from '../../utils/panel-lib';
import route from '../index.js';

const initScripts = function() {
  let $form = $('.seo-traffic-abtest-add-form');
  let $buttonGenerate = $('[data-button-action="generate"]');
  let $buttonCreate = $('[data-button-action="create"]');
  let $buttonAdvancedSettings = $('[data-button-action="show-advanced-settings"]');
  let $abtestVariant1 = $('.abtest-variant1');
  let $abtestVariant2 = $('.abtest-variant2');
  let $loadingBlock = $('.loading-block');
  let $errorBox = $form.find('.error-box');
  let dataCreate = {
    name: '',
    keyword: '',
    url: '',
    max_answers: 50,
    variants: [],
    alternatives: [],
    traps: [],
  };

  $buttonGenerate.on('click', function() {
    $buttonGenerate.prop('disabled', true);
    $loadingBlock.show();
    $errorBox.html('').hide();

    let params = {
      name: $form.find('[name="name"]').val(),
      keyword: $form.find('[name="keyword"]').val(),
      url: $form.find('[name="url"]').val(),
      max_answers: parseInt($form.find('[name="max_answers"]').val()),
    };

    dataCreate.url = params.url;
    dataCreate.keyword = params.keyword;
    dataCreate.name = $form.find('[name="name"]').val();
    dataCreate.max_answers = parseInt($form.find('[name="max_answers"]').val());

    axios.get(route('api.seo_traffic.ab_tests.generate'), {params: params}).
        then(function(response) {
          let responseData = (response.data ? response.data : {});

          if (responseData && responseData.success) {
            let data = responseData.data;

            if (data) {
              $errorBox.html('').hide();

              let variants = data.variants;
              if (variants) {
                dataCreate.variants[0] = {
                  id: variants[0].id,
                  title: variants[0].title,
                  description: variants[0].description,
                };

                $abtestVariant1.find('[name="title_varian1"]').val(variants[0].title);
                $abtestVariant1.find('[name="description_varian1"]').val(variants[0].description);
                $abtestVariant1.show();
                $abtestVariant2.show();
                $buttonAdvancedSettings.show();
              }

              let alternatives = (typeof data.alternatives !== 'undefined' ? data.alternatives : []);
              if (alternatives) {
                let alternativesHtml = '';
                let count = 0;

                alternatives.forEach((alternative) => {
                  count++;

                  dataCreate.alternatives.push(
                      {
                        id: alternative.hasOwnProperty('id') ? alternative.id : '',
                        title: alternative.hasOwnProperty('title') ? alternative.title : '',
                        description: alternative.hasOwnProperty('description') ? alternative.description : '',
                      },
                  );

                  if (alternative.hasOwnProperty('title')) {
                    alternativesHtml += `<div class="form-group"><label class="form-label" for="title_alt_${alternative.id}_${count}">Title ${alternative.id}</label><input type="text" class="form-control" id="title_alt_${alternative.id}_${count}" name="title_alt_${alternative.id}_${count}" value="${alternative.title}" readonly></div>`;
                  }

                  if (alternative.hasOwnProperty('description')) {
                    alternativesHtml += `<div class="form-group"><label class="form-label" for="description_alt_${alternative.id}_${count}">Description ${alternative.id}</label><input type="text" class="form-control" id="description_alt_${alternative.id}_${count}" name="description_alt_${alternative.id}_${count}" value="${alternative.description}" readonly></div>`;
                  }
                });

                $('.abtest-alternatives').append(alternativesHtml);
              }

              let traps = (typeof data.traps !== 'undefined' ? data.traps : []);
              if (traps) {
                let trapsHtml = '';
                let count = 0;

                traps.forEach((trap) => {
                  count++;

                  dataCreate.traps.push(
                      {
                        id: trap.hasOwnProperty('id') ? trap.id : '',
                        title: trap.hasOwnProperty('title') ? trap.title : '',
                        description: trap.hasOwnProperty('description') ? trap.description : '',
                      },
                  );

                  if (trap.hasOwnProperty('title')) {
                    trapsHtml += `<div class="form-group"><label class="form-label" for="title_trap_${trap.id}_${count}">Title ${trap.id}</label><input type="text" class="form-control" id="title_trap_${trap.id}_${count}" name="title_trap_${trap.id}_${count}" value="${trap.title}" readonly></div>`;
                  }

                  if (trap.hasOwnProperty('description')) {
                    trapsHtml += `<div class="form-group"><label class="form-label" for="description_trap_${trap.id}_${count}">Description ${trap.id}</label><input type="text" class="form-control" id="description_trap_${trap.id}_${count}" name="description_trap_${trap.id}_${count}" value="${trap.description}" readonly></div>`;
                  }
                });

                $('.abtest-traps').append(trapsHtml);
                $buttonGenerate.hide();
                $buttonCreate.show();
              }
            }
          } else {
            if (responseData.message) {
              $errorBox.html(responseData.message).show();
            }
          }

          $buttonGenerate.prop('disabled', false);
          $loadingBlock.hide();
        }).
        catch(function() {
          $buttonGenerate.prop('disabled', false);
          $loadingBlock.hide();
        });
  });

  $buttonCreate.on('click', function() {
    $buttonGenerate.prop('disabled', true);

    dataCreate.variants[1] = {
      id: 'v2',
      title: $form.find('[name="title_varian2"]').val(),
      description: $form.find('[name="description_varian2"]').val(),
    };

    axios.post(route('api.seo_traffic.ab_tests.create'), dataCreate).
        then(function(response) {
          let responseData = (response.data ? response.data : {});

          if (responseData && responseData.success) {
            window.location.href = route('seo_traffic.ab_tests.index');
          } else {
            if (responseData.message) {
              $errorBox.html(responseData.message).show();
            }
          }

          $buttonGenerate.prop('disabled', false);
        }).
        catch(function() {
          $buttonGenerate.prop('disabled', false);
        });
  });

  $buttonAdvancedSettings.on('click', function() {
    $('.abtest-alternatives').toggleClass('show');
    $('.abtest-traps').toggleClass('show');
  });
};

export const initSeoTrafficABTestAddScripts = () => {
  /**
   * @prop {object} jsAppData
   */

  runIfHasSelecter('[data-role="seo-traffic-abtest-add"]', [
    initScripts]);
};
