<template>
  <div class="v-form-string-input-number vfsin">
    <div class="vfsin__content-show-wrap" v-if="!editable">
      <div class="vfsin__content-show">
        <slot name="default"/>
      </div>
      <div class="vfsin__content-show-icon-edit" @click="setEditable">
        <v-icon-svg name="icon_pencil_v2" class="vfsin__content-show-icon-edid-svg"/>
      </div>
    </div>

    <div class="vfsin__content-edit" v-if="editable">
      <v-form-input-number-edit
          ref="input"
          :value="inputData"
          @save="save"
          :filter-data="(val) => {return val >= 0;}"
          type="events"
      />
    </div>
  </div>
</template>

<script setup lang="ts">

import VIconSvg from "../Base/VIconSvg.vue";
import {computed, ref, watch} from "vue";
import VFormInputNumberEdit from "./VFormInputNumberEdit.vue";

const props = defineProps({
  label: {},
  required: {type: Boolean},
  value:{}
});

const emit = defineEmits(['save']);

const inputData = ref(props.value);
watch(() => props.value, () => {
  inputData.value = props.value;
});

const editable = ref(false);
const input = ref();

const setEditable = () => {
  editable.value = true;
}

const setNonEditable = () => {
  editable.value = false;
}

watch(() => editable.value, (value) => {
  if(value){
    setTimeout(() => {
      input.value.setContentEditable();
    },20);
  }
})

const save = (value) => {
  emit('save', value);
}

const saveWithSuccess = () => {
  input.value.saveWithSuccess();
  setNonEditable();
}

const saveWithError = (message) => {
  input.value.saveWithError(message);
}

defineExpose({saveWithSuccess,saveWithError});


</script>

<style scoped>

.v-form-string-input-number.vfsin{

  .vfsin__content-show-wrap{
    display: flex;
    align-items: center;

    .vfsin__content-show{}
    .vfsin__content-show-icon-edit{
      display: flex;
      align-items: center;
      justify-content: center;
      padding:3px;
      cursor: pointer;
      transition-duration: 0.2s;
      border-radius: 3px;
      margin-left:6px;

      &:hover{
        background-color: #ebf5ff;
      }
      .vfsin__content-show-icon-edid-svg{
        width:14px;
        height:14px;
      }
    }
  }

  .vfsin__content-edit {
    width: 100%;
    max-width: 280px;

  }

}

</style>