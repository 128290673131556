<template>
  <v-project-preview class="sc-project-preview"
                     type="seotraffic"
                     :is-demo="useProject.is_demo"
                     :modal-blackout="modalBlackout">

    <template #default>
      <div class="content">

        <div class="info content__info">

          <div class="info__top">

            <div class="info__id" :class="modalBlackout ? 'info__id--hide' : ''">
              {{useProject.id}}
              <div class="info__tag-demo" v-if="useProject.is_demo" v-text="'DEMO'"/>
              <div class="info__tag-deleted" v-if="useProject.is_deleted" v-text="'DELETED'"/>
            </div>

            <div class="info__title"
                 :class="useProject.is_deleted ? 'opacity-50' : ''"
                 v-text="useProject.name"/>

            <v-link class="info__domain"
                    :href="useProject.correctUrl"
                    :class="useProject.is_deleted ? 'opacity-50' : ''"
                    is-outer
                    v-text="useProject.shortUrl"/>

          </div>

          <v-button class="button-settings info__button"
                    v-if="!isMobile"
                    tag="a"
                    :href="useProject.linkProject"
                    color="blue2-stroke"
                    :class="modalBlackout ? 'button-settings--hide' : ''"
                    text="Settings"/>

        </div>

        <div class="charts content__charts"
             v-if="!useProject.is_deleted"
             :class="useProject.is_demo ? 'charts--demo' : ''">

          <template v-if="!isMobile">
            <sc-project-preview-realtime class="charts__realtime"
                                         :project-id="projectId"
                                         :count-bars="16"/>

            <sc-project-preview-chart-clicks class="charts__clicks"
                                             :project-id="projectId"
                                             v-if="!useProject.is_demo"/>

            <sc-project-preview-countries class="charts__countries"
                                          v-if="!useProject.is_demo"
                                          :project-id="projectId"/>

            <div v-if="useProject.is_demo" class="charts__separator"/>

            <sc-project-demo-bar v-if="useProject.is_demo"
                                 class="charts__demo-info"
                                 :project-uniquekey="useProject.id"
                                 is-compact/>
          </template>

          <template v-else-if="isMobile && !useProject.is_demo">

            <Flicking ref="refChartsSliderFlicking"
                      class="charts-slider"
                      :plugins="[new Pagination({type: 'bullet' })]"
                      :options="{camera: '100%',panel: '100%'}">

              <div class="charts-slider__item" :key="0">
                <div class="charts-slider__charts">
                  <sc-project-preview-realtime class="charts-slider__realtime"
                                               :project-id="projectId"
                                               :count-bars="15"/>
                  <sc-project-preview-chart-clicks class="charts-slider__chart-clicks"
                                                   :project-id="projectId"/>
                </div>
              </div>

              <div class="charts-slider__item"
                   v-if="Object.keys(useProject.clicks_by_country).length > 0"
                   :key="1">
                <sc-project-preview-countries class="charts-slider__countries"
                                              :project-id="projectId"/>
              </div>

              <template #viewport>
                <div class="flicking-pagination charts-slider__pagination"
                     :class="{hide: Object.keys(useProject.clicks_by_country).length === 0}"/>
              </template>

            </Flicking>

          </template>

          <template v-else-if="isMobile && useProject.is_demo">
            <sc-project-preview-realtime class="charts-slider__realtime"
                                         :project-id="projectId"
                                         :count-bars="27"/>
            <sc-project-demo-bar class="charts__demo-info"
                                 :project-uniquekey="useProject.id"
                                 is-compact/>
          </template>

        </div>

        <v-button class="button-settings content__button"
                  v-if="isMobile"
                  tag="a"
                  :href="useProject.linkProject"
                  color="blue2-stroke"
                  :class="{'button-settings--hide':modalBlackout,'content__button--left': useProject.is_deleted}"
                  text="Settings"/>

      </div>
    </template>

    <template #blackout>
      <div class="content content--modal">
        <div class="info">
          <div class="info__id info__id--white" v-text="useProject.id"/>
          <v-button class="button-settings info__button"
                    v-if="!isMobile"
                    color="white-stroke-50"
                    :href="useProject.linkProject"
                    tag="a"
                    text="Settings"/>
        </div>
        <v-button class="button-settings content__button"
                  v-if="isMobile"
                  tag="a"
                  :href="useProject.linkProject"
                  color="white-stroke-50"
                  text="Settings"/>
      </div>
    </template>

    <template #content-after v-if="!useProject.is_deleted">

      <div class="modal-info" v-if="!useProject.is_active">
        <div class="modal-info__title">Paused</div>
        <v-button class="modal-info__button" color="green"
                  :click-action="[useProject.saveIsActive, true]"
                  icon="icon-play-white"
                  text="Resume"/>
      </div>

      <div class="modal-info" v-else-if="useProject.demoCompleted">
        <div class="modal-info__title">Demo Completed</div>
        <div class="modal-info__sub_title" v-if="useProject.insufficientBalance">
          <v-icon-svg name="icon_notification-warning" as-img width="20"/>
          Buy credits to start Project
        </div>
        <v-button v-if="useProject.insufficientBalance"
                  text="Buy credits"
                  icon="shopping-cart-white"
                  class="modal-info__button"
                  color="green"
                  tag="a"
                  :href="useRoutes().url('buy-credits')"/>
        <v-button v-else
                  text="Start Project"
                  icon="icon-play-white"
                  class="modal-info__button"
                  color="green"
                  :click-action="[useProject.convertDemoToReal]"/>
      </div>

      <div class="modal-info" v-else-if="useProject.insufficientBalance && !useProject.is_demo">
        <div class="modal-info__title">Not enough credits</div>
        <v-button class="modal-info__button"
                  color="green"
                  text="Buy credits"
                  icon="shopping-cart-white"
                  tag="a"
                  :href="useRoutes().url('buy-credits')"/>
      </div>

    </template>

  </v-project-preview>
</template>

<script setup>

import VLink from "../Base/VLink.vue";
import VButton from "../Base/VButton.vue";
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import VIconSvg from "../Base/VIconSvg.vue";
import ScProjectDemoBar from "./ScProjectDemoBar.vue";
import {useSeoClicksProject} from "../../stores/useSeoClicks";
import VProjectPreview from "../Base/VProjectPreview.vue";
import {useRoutes} from "../../stores/useRoutes";
import ScProjectPreviewRealtime from "./ScProjectPreviewRealtime.vue";
import ScProjectPreviewChartClicks from "./ScProjectPreviewChartClicks.vue";
import ScProjectPreviewCountries from "./ScProjectPreviewCountries.vue";
import Flicking from "@egjs/vue3-flicking";
import { Pagination } from "@egjs/flicking-plugins";

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const props = defineProps({
  projectId: {},
});

const refChartsSliderFlicking = ref();

const useProject = useSeoClicksProject(props.projectId);

function updateInfoProject(){
  if(useProject.is_demo && !useProject.demoCompleted){
    setTimeout(() => {
      useProject.download().then(() => updateInfoProject());
    },5000);
  }
}

onMounted(() => updateInfoProject());

const modalBlackout = computed(() => {
  if(useProject.is_deleted) return null;
  if(useProject.demoCompleted) return 'black';
  if(!useProject.is_active) return 'black';
  if(useProject.insufficientBalance && !useProject.is_demo) return 'red';
  return null;
});

</script>

<style lang="scss" scoped>
.sc-project-preview{

  .content{
    @include flex(row,between,stretch,24px);
    width:100%;
    padding:24px 24px 24px 20px;
    @media(max-width: 767px) {
      @include flex(column,start,stretch,16px);
      padding:16px;
    }
    &.content--modal{
      height:100%;
      @media(max-width:767px){
        @include flex(column,between,stretch);
      }
    }
    .content__info{
      width:calc(100% - 600px - 24px);
      @media(max-width: 767px){
        width:100%;
      }
    }
    .content__charts{
      flex-shrink:0;
      width:600px;
      @media(max-width: 767px){
        width:100%;
      }
    }
    .content__button{
      align-self:flex-end;
      &.content__button--left{
        align-self:flex-start;
      }
    }
  }

  .info{
    @include flex(column,between,start, 19px);

    .info__top{
      @include flex(column,start,start,6px);
      max-width:100%;
    }

    .info__id{
      @include flex(row,start,center, 6px);
      font:$font-mini-12-regu;
      color:$dark-40;
      &.info__id--white{
        color:#fff;
      }
      &.info__id--hide{
        opacity:0;
      }
      @media (max-width: 767px) {
        font:$font-mini-10-regu;
      }
    }

    .info__title{
      @include ellipsis();
      font:$font-16-semi;
      color:$dark-80;
      @media (max-width: 767px) {
        font:$font-14-semi;
      }
    }

    .info__domain{
      @include ellipsis();
    }

    .info__tag-demo{
      font:$font-mini-12-semi;
      color:$dark-40;
    }

    .info__tag-deleted{
      font:$font-mini-12-semi;
      color:$optional-red_120;
    }

  }

  .charts{
    @include flex(row,between,stretch,36px);

    &.charts--demo{
      gap:24px;
      @media(max-width: 767px){
        @include flex(column,start,stretch,16px);
      }
    }

    .charts__realtime{
      width: 170px;
      flex-shrink: 0;
    }
    .charts__clicks{
      flex-grow:2;
    }
    .charts__separator{
      width:1px;
      background:$dark-10;
      flex-shrink:0;
    }
    .charts__demo-info{
      flex-grow:2;
      align-self:flex-start;
    }
    .charts__countries{
      width:128px;
      flex-shrink: 0;
    }
  }

  .modal-info{
    @include flex(column,center,center, 12px);
    position:absolute;
    z-index:10;
    top: 50%;
    left: 50%;
    width:100%;
    transform: translate(-50%, -50%);

    &.modal-info--has-subtitle{
      gap:8px;
    }

    .modal-info__title{
      font:$font-h2-20-semi;
      color:$white-100;
      @media(max-width:767px){
        font:$font-16-semi;
      }
    }
    .modal-info__sub_title{
      @include flex(row,center,center,8px);
      color: $white-100;
    }
    .modal-info__button{
      @include flex(row,center,center,8px);
      padding-top:0;
      padding-bottom:0;
      height:40px;
    }
  }

  .button-settings{
    padding:0px 14px;
    height:32px;
    &.button-settings--hide{
      opacity:0;
    }
  }

  .charts-slider{
    @include flex(column,start,stretch,16px);
    .charts-slider__item{
      width:100%;
    }
    .charts-slider__charts{
      @include flex(row,between,stretch,12px);
    }
    .charts-slider__realtime{
      width:50%;
    }
    .charts-slider__chart-clicks{
      width:50%;
    }
    .charts-slider__countries{

    }
    .charts-slider__pagination{
      @include flex(row,center,center,6px);
      position:initial;
      &.hide{
        display:none;
      }
      &:deep(.flicking-pagination-bullet){
        width:6px;
        height:6px;
        background: $secondary-blue-10;
        border-radius: 50%;
        transition-duration: 0.2s;
        &.flicking-pagination-bullet-active{
          background:$secondary-blue-100;
        }
      }
    }

  }

}
</style>