import axios from "axios";

export const userNotificationsModule = {
    namespaced: true,
    state: {
        notifications: [],
        loaded: false,
    },
    mutations: {
        setNotifications(state, notifications) {
            state.notifications = notifications;
        },
        setLoaded(state, loaded) {
            state.loaded = loaded;
        },
    },
    actions: {
        async fetchNotifications({state, commit}) {
            try {
                let response = await axios.get('/api/user/notifications');
                let notifications = response.data.data;

                if (notifications.length > 0) {
                    commit('setNotifications', response.data.data);
                    commit('setLoaded', true);
                }
            } catch (e) {
                console.log('Error loading notifications');
                console.log(e);
            }
        },
        async setReadNotifications({state, commit}) {
            try {
                await axios.put('/api/user/notifications/set-read');
            } catch (e) {
                console.log('Error set read notifications');
                console.log(e);
            }
        },
        async setReadNotification({state, commit}, id) {
            try {
                await axios.put('/api/user/notification/' + id + '/set-read');
            } catch (e) {
                console.log('Error set read notifications');
                console.log(e);
            }
        }
    }
}
