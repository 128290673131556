<template>
  <div class="v-button-dotted vbd">
    <div class="lbab__button-icon">
      <v-icon-svg name="big-blue-plus" class="lbab__button-icon-icon" as-img/>
    </div>
    <div class="lbab__button-text">
      <slot/>
    </div>

  </div>
</template>

<script setup>

import VIconSvg from "./VIconSvg.vue";

</script>

<style lang="scss" scoped>
.v-button-dotted.vbd{
  border: 1px dashed #1C7EE080;
  background: #F7FAFF;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor:pointer;
  height:40px;
  gap:4px;
  border-radius:4px;

  .lbab__button-icon{
    width:18px;
    height:18px;
    display:flex;
    justify-content:center;
    align-items:center;
    .lbab__button-icon-icon{
      width:10.32px;
      height:10.32px;
    }
  }
  .lbab__button-text{
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #1C7EE0;
  }
}
</style>