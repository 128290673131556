<template>
  <a class="v-link" v-bind="linkBind"><slot>{{text}}</slot></a>
</template>

<script setup>

import {computed} from "vue";

const props = defineProps({
  isOuter: {type:Boolean, default:false},
  text: {default:null},
});

const linkBind = computed(() => {
  if(props.isOuter){
    return {
      rel: 'noopener noreferrer',
    }
  }else{
    return {}
  }
});

</script>

<style scoped lang="scss">
.v-link{
  color:$secondary-blue-100;
  text-decoration: none;
  cursor: pointer;
}
.v-link :deep(svg){
  fill:$secondary-blue-100;
}
.v-link:hover{
  color:#186CBF;
}
.v-link:hover :deep(svg){
  fill:#186CBF;
}
</style>