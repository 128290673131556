<template>
  <div class="v-lb-optimization-tooltip">
    <div class="title">Recommendations</div>
    <div class="list">

      <template v-for="row in rows">
        <div class="item" v-if="row.count > 0">
          <v-icon-svg class="icon" :name="row.icon" as-img/>
          <v-link :href="useProject.link" v-text="`${row.text} ${row.count}`"/>
        </div>
      </template>

      <div class="item item--small-gap">
        <v-circle-state :size="24"
                        :value="useProject.optimizationRatingPercent"
                        :color="useProject.optimizationRatingType"/>
        <span class="text">Optimisation score</span>
      </div>

    </div>
    <v-button tag="a" :href="useProject.link" color="blue2-stroke" class="button" text="View all"/>
  </div>
</template>

<script setup>

import VIconSvg from "../VIconSvg.vue";
import VLink from "../VLink.vue";
import VButton from "../VButton.vue";
import VCircleState from "../VCircleState.vue";
import {useLinkBuildProject} from "../../../stores/useLinkBuilding";
import {computed} from "vue";

const props = defineProps({
  projectUniquekey: {},
});

const useProject = useLinkBuildProject(props.projectUniquekey);

const rows = computed(() => {
  return [
    {
      count: useProject.recommendations_danger_count,
      icon: 'thumbs-down-red',
      text: 'Critical',
    },
    {
      count: useProject.recommendations_warning_count,
      icon: 'triangle-exclamation-mark-orange',
      text: 'Warning',
    },
    {
      count: useProject.recommendations_succss_count,
      icon: 'thumbs-up-green',
      text: 'Normal',
    },
  ];
})

</script>

<style lang="scss" scoped>
.v-lb-optimization-tooltip{
  @include flex(column,start,start,9px);
  width:190px;
  background:#fff;
  border-radius:4px;
  box-shadow:$box-shadow-tooltip;
  padding:14px;
  .title{
    font:$font-mini-12-regu;
    color:$dark-30;
  }

  .list{
    @include flex(column,start,start,9px);
    .item{
      @include flex(row,start,center,16px);
      &.item--small-gap{
        gap:10px;
      }
    }
    .icon{
      width:18px;
      height:18px;
    }
    .text{
      color:$dark-50;
    }
  }
  .button{
    height:32px;
    padding:0px 14px;
  }

}
</style>