<template>
  <div
      class="v-icon-question-tooltip viqt"
      data-bs-toggle="tooltip"
      data-html="true"
      :data-title="text"
      :data-tooltip-tpl="!tpl ? 'dark' : 'light'"
      :data-tooltip-body="toBody ? '1' : '0'"
  >
    <v-icon-svg name="question-white" class="viqt__icon"/>
  </div>
</template>

<script setup lang="ts">

import VIconSvg from "./VIconSvg.vue";
import VTooltipSmall from "./VTooltipSmall.vue";
import {computed} from "vue";

const props = defineProps({
  text: {},
  tpl: {},
  toBody: {}
})

</script>

<style scoped>
.v-icon-question-tooltip.viqt{
  width:15px;
  height:15px;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color: #1D94FF80;
  border-radius: 50%;
}
</style>