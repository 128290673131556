import {runIfHasSelecter} from '../../utils/panel-lib';
import route from '../index.js';

const initScripts = function() {
  const $table = $('#backlinks-homepage-table');
  const $searchInput = $('.search-box input[type="search"]');
  const $loadingBlock = $('.loading-block');

  $table.dataTable({
    responsive: true,
    processing: true,
    dom: `<'row'<'col-sm-12 col-md-6 d-flex align-items-center justify-content-start'f><'col-sm-12 col-md-6 d-flex align-items-center justify-content-end'B>>
            <'row'<'col-sm-12'tr>>
            <'row'<'col-sm-12 col-md-5 d-none'i><'col-12'p>>`,
    order: [[7, 'desc']],
    buttons: [],
    columns: [
      {'width': '5%'},
      {'width': '5%'},
      {'width': '20%', orderable: false},
      {'width': '20%'},
      {'width': '20%', orderable: false},
      {'width': '15%', orderable: false},
      {'width': '15%'},
      {'class': 'hidden'},
    ],
    columnDefs: [
      {
        targets: 4,
        render: function(data) {
          let category = data.
              replaceAll(',', ', ').
              replaceAll('  ', ' ');

          return `<span>${category}</span>`;
        },
      },
      {
        targets: -2,
        render: function(data) {
          return `<div>${data}</div>`;
        },
      },
    ],
    createdRow: function(row, data) {},
  });

  // Search
  let localStorageSearch;
  try {
    localStorageSearch = window.localStorage.getItem(
        'st-backlinks-homepage-table-search');
  } catch (e) {
    console.log('Error localStorage.getItem: ' + e.responseText);
  }

  if (localStorageSearch !== null && localStorageSearch !== 'null' &&
      localStorageSearch) {
    $searchInput.val(localStorageSearch);
  }

  $searchInput.on('keyup search', function() {
    $table.DataTable().search($(this).val()).draw();
    window.localStorage.setItem('st-backlinks-homepage-table-search',
        $(this).val());
  });

  updateTable();

  function updateTable() {
    $table.DataTable().search($searchInput.val()).ajax.url(route('api.backlinks.links.get_user_links'));

    /** DataTables before receiving the data */
    $table.on('preXhr.dt', function() {
      $loadingBlock.addClass('processing');
    });

    /** DataTables after receiving the data */
    $table.on('xhr.dt', function() {
      $loadingBlock.removeClass('processing');
      $('body').removeClass('loading');
    }).DataTable().ajax.reload();

    /** DataTables after draw */
    $table.DataTable().on('draw', function () {
      /**  Auto-renew Activation */
      $('.autorenew input[type="checkbox"]').off('change').on('change', function () {
        let $this = $(this);
        let linkId = $this.data('link-id');
        let isAutoRenew = !!$this.prop('checked');

        $this.prop('disabled', true);

        axios.patch(route('api.backlinks.links.set_autorenew', linkId), {autoRenew: isAutoRenew ? 1 : 0}).
            then(function(response) {
              let data = (response.data ? response.data : {});

              if (data && !data.success) {
                if (isAutoRenew) {
                  $this.prop('checked', false);
                } else {
                  $this.prop('checked', true);
                }
              }
            }).
            catch(function() {
              if (isAutoRenew) {
                $this.prop('checked', false);
              } else {
                $this.prop('checked', true);
              }
            }).
            finally(function() {
              $this.prop('disabled', false);
            });
      });
    }).draw();
  }
};

export const initBacklinksHomepageScripts = () => {
  runIfHasSelecter('[data-role="backlinks-homepage"]', [
    initScripts,
  ]);
};
