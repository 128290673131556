<template>
  <div class="v-lb-optimization-short-row" :class="{'is-not-available':isNotAvailable}">
    <template v-if="!isNotAvailable">
      <div class="item" v-if="useProject.recommendations_danger_count>0">
        <v-icon-svg class="item-icon" as-img name="thumbs-down-red"/>
        <span class="item-text" v-text="useProject.recommendations_danger_count"/>
      </div>
      <div class="item" v-if="useProject.recommendations_warning_count>0">
        <v-icon-svg class="item-icon" as-img name="triangle-exclamation-mark-orange"/>
        <span class="item-text" v-text="useProject.recommendations_warning_count"/>
      </div>
      <div class="item" v-if="useProject.recommendations_succss_count>0">
        <v-icon-svg class="item-icon" as-img name="thumbs-up-green"/>
        <span class="item-text" v-text="useProject.recommendations_succss_count"/>
      </div>
    </template>
    <template v-else>
      Not available
    </template>

  </div>
</template>

<script setup>

import VIconSvg from "../VIconSvg.vue";
import {useLinkBuildProject} from "../../../stores/useLinkBuilding";
import {computed} from "vue";

const props = defineProps({
  projectUniquekey: {},
});

const useProject = useLinkBuildProject(props.projectUniquekey);

const isNotAvailable = computed(() => {
  return !(
      useProject.recommendations_danger_count > 0
      || useProject.recommendations_warning_count > 0
      || useProject.recommendations_succss_count > 0
  );
})

</script>

<style lang="scss" scoped>
.v-lb-optimization-short-row{
  &.is-not-available{
    color:$dark-30;
    font:$font-14-semi;
  }
  @include flex(row,center,center,4px);
  .item{
    @include flex(row,center,center);
    .item-icon{
      width:18px;
      height:18px;
    }
    .item-text{
      color:$dark-80;
      font:$font-14-semi;
    }
  }
}
</style>