import {runIfHasSelecter} from "../utils/panel-lib";

const initAction = () => {
    let $buttonSubmit = $('button[type="submit"]');
    $buttonSubmit.on('click', function (e) {
        e.preventDefault();

        let $form = $(this).closest('form');

        $(this).prop('disabled', true);

        $form.trigger('submit');
    });
}

const initEmailNotifications = () => {
    let notificationsStatusesSwitches = $('[data-ajax-action="set-notifications-status"]');
    let unsubscribeFromAllSwitch = $('[data-ajax-action="unsubscribe-from-all"]');

    notificationsStatusesSwitches.on('change', function () {
        let $switch = $(this);
        let groupId = $switch.data('group-id');
        let status = $switch.prop('checked') ? 1 : 0;

        $switch.prop('disabled', true);

        $.ajax({
            url: '/api/user/email-notification/set-status',
            method: 'POST',
            data: {
                group_id: groupId,
                status: status,
            },
            dataType: 'json',
            success: function (response) {
                if (!response.success) {
                    if (status === 1) {
                        $switch.prop('checked', false);
                    } else {
                        $switch.prop('checked', true);
                    }
                }
            },
            error: function () {
                if (status === 1) {
                    $switch.prop('checked', false);
                } else {
                    $switch.prop('checked', true);
                }
            },
            complete: function () {
                $switch.prop('disabled', false);
            }
        });
    });

    unsubscribeFromAllSwitch.on('change', function () {
        let $switch = $(this);
        let status = $switch.prop('checked') ? 1 : 0;

        $switch.prop('disabled', true);

        $.ajax({
            url: '/api/user/email-notifications/unsubscribe-from-all',
            method: 'POST',
            data: {
                status: status,
            },
            dataType: 'json',
            success: function (response) {
                if (!response.success) {
                    if (status === 1) {
                        notificationsStatusesSwitches.prop('disabled', false);
                        $switch.prop('checked', false);
                    } else {
                        notificationsStatusesSwitches.prop('disabled', true);
                        $switch.prop('checked', true);
                    }
                } else {
                    if (status === 1) {
                        notificationsStatusesSwitches.prop('disabled', true);
                    } else {
                        notificationsStatusesSwitches.prop('disabled', false);
                    }
                }
            },
            error: function () {
                if (status === 1) {
                    notificationsStatusesSwitches.prop('disabled', false);
                    $switch.prop('checked', false);
                } else {
                    notificationsStatusesSwitches.prop('disabled', true);
                    $switch.prop('checked', true);
                }
            },
            complete: function () {
                $switch.prop('disabled', false);
            }
        });
    });
}

export const initProfileScripts = function () {
    runIfHasSelecter('[data-role="profile"]', [
        initAction,
        initEmailNotifications
    ]);
}
