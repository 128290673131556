<template>
  <div
      class="v-tooltip-small vts tooltip-icon-wrap"
      :style="{display:  (tooltipText === '') ? 'display: none;' : '' }"
  >
    <span
        class="vts__icon-wrap"
        data-bs-toggle="tooltip"
        data-html="true"
        :data-title="dataTitle"
        :data-tooltip-tpl="!tpl ? 'dark' : 'light'"
        :data-tooltip-body="toBody ? '1' : '0'"
    >
      <v-icon-svg name="icon-question-blue" class="vts__icon"/>
    </span>
  </div>
</template>

<script>
import VIconSvg from "./VIconSvg.vue";

export default {
  components: {VIconSvg},
  props: {
    tooltipText: {type:String, default: ''},
    tooltipTitle: {type:String, default:''},
    tpl: {},
    toBody: {}
  },
  computed: {
    dataTitle(){
      if(this.tooltipTitle?.length > 0){
        return `<div class="title">${this.tooltipTitle}</div>${this.tooltipText}`;
      }else{
        return `${this.tooltipText}`;
      }

    }
  },
  setup() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.v-tooltip-small.vts{
  width:16px;
  height:16px;


  .vts__icon-wrap{
    display:flex;
    width:16px;
    height:16px;
    justify-content: center;
    align-items: center;

    .vts__icon{
      display: block;
      opacity:0.5;
      transition-duration: 0.2s;
      &:hover{
        opacity:0.7;
      }
    }
  }

}
</style>