<template>
  <v-button class="v-button-delete vbd" color="link">
    <span class="vbd__inner">
      <v-icon-svg name="delete-icon"/>
      <slot name="default"/>
    </span>
  </v-button>
</template>

<script setup lang="ts">

import VIconSvg from "./VIconSvg.vue";
import VButton from "./VButton.vue";
</script>

<style scoped>
.v-button-delete{
  border: 1px solid #0D4E8F!important;
  padding:10px;
  opacity:0.5;
  transition-duration: 0.2s;
  transition-property: opacity;

  &:hover{
    background-color: transparent!important;
    opacity:1;
  }
  .vbd__inner{
    display: flex;
    gap:10px;
    color:#0D4E8F;
    align-items: center;
  }
}
</style>