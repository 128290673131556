<template>
  <div class="v-loader-ship v-loader-ship">
    <div :style="styleOne"></div>
    <div :style="styleTwo"></div>
  </div>
</template>

<script setup>

import {computed} from "vue";

const props = defineProps({
  colorLineOne: {default:'#ffffff33'},
  colorLineTwo: {default:'#fff'},
  lineThickness: {default:null,}
});

const styleOne = computed(() => {
  let response = {borderColor: props.colorLineOne}
  if(props.lineThickness!==null) response.borderWidth = props.lineThickness+'px';
  return response;
});
const styleTwo = computed(() => {
  let response = {borderColor: props.colorLineTwo}
  if(props.lineThickness!==null) response.borderWidth = props.lineThickness+'px';
  return response;
})

</script>

<style lang="scss" scoped>
.v-loader-ship {
  display: flex;
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.v-loader-ship div {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: 3px solid #ffffff33;
  border-radius: 50%;
}

.v-loader-ship div:nth-child(2) {
  clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 20%);
  animation: v_loader_ship_spin 1.2s infinite;
  animation-timing-function: linear;
  border: 3px solid #ffffff;
}

@keyframes v_loader_ship_spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>