<template>
  <div class="sc-project-keyword-mobile" :class="{'is-deleted': useKeyword.is_deleted}">

    <div class="header">

      <div class="keyword" :class="{'opacity-30':useKeyword.is_deleted}">
        <v-icon-flag :code="useKeyword.region" v-if="useKeyword.region"/>
        {{useKeyword.keyword}}
      </div>

      <v-link v-if="useKeyword.is_deleted" text="Restore" @click="restoreKeyword()"/>

      <div v-if="!useKeyword.is_deleted" class="ellipsis-menu" @click="refContextMenu.toggle()">
        <div class="ellipsis-menu__tooltip-anchor" ref="refEllipsisMenuAnchor"></div>
        <v-icon-svg class="ellipsis-menu__icon" as-img name="ellipsis-menu"/>
      </div>

    </div>

    <div class="deleted-tag"
         v-if="useKeyword.is_deleted"
         :class="{'has-region': !!useKeyword.region}"
         v-text="'Deleted'"/>

    <chart-seo-clicks
        v-if="!useKeyword.is_deleted"
        :chart="useKeyword.chart_data"
        class="chart"
        :class="{'opacity-30': !useKeyword.is_active}"
        :scale-hover="true"
    />

    <div class="footer" v-if="!useKeyword.is_deleted">

      <template v-if="useKeyword.is_active">
        <div class="footer__title">DAILY LIMIT</div>

        <div class="footer__content">
          <v-form-input-counter-edit class="footer__daily-limit"
                                     ref="refInputDailyLimit"
                                     :min="1"
                                     v-model="dailyLimit"
                                     :disabled="updateDailyLimitLoading"
                                     @save="(value) => saveDailyLimit(value)"/>
          <div class="footer__recommendations" v-if="refInputDailyLimit?.currentState !== 'expanded'">
            <sc-project-keyowrd-recommendations
                v-if="useRecommendations.hasRecommendation || !useKeyword.is_top100"
                :project-id="projectId"
                :keyword-id="keywordId"
            />
            <recommendation-daily-limit
                v-else-if="useKeyword.hasRecomDaily"
                :top1="useKeyword.recom_daily_top1"
                :top3="useKeyword.recom_daily_top3"
                :top10="useKeyword.recom_daily_top10"
                @choose-daily="chooseDaily"
            />
          </div>
        </div>
      </template>
      <template v-else-if="!useKeyword.is_active">
        <div class="control-active">
          Active project
          <v-form-checkbox-slider
              type="events"
              :value="!!useKeyword.is_active"
              @save="activateKeyword"
              ref="checkboxStatus"
          />
        </div>
      </template>

    </div>

    <v-tooltip v-if="!useKeyword.is_deleted" :get-linked-block="() => refEllipsisMenuAnchor"
               :offset="[0,0]"
               reset-tooltip
               ref="refContextMenu"
               placement="bottom-end"
               :btn-close-on="false">
      <template #content>
        <div class="context-menu">
          <v-link text="Pause" v-if="useKeyword.is_active" @click="deactivateKeyword"/>
          <v-link text="Resume" v-if="!useKeyword.is_active"/>
          <v-link text="Delete" @click="deleteModalConfirm.confirm()"/>
        </div>
      </template>
    </v-tooltip>

    <v-modal-confirm ref="deleteModalConfirm" @confirm="deleteKeyword">
      <template v-slot:title>
        Delete keyword
      </template>
      <template v-slot:description>
        Do you really want to delete a keyword ? <br>Keyword will be lost
      </template>
    </v-modal-confirm>

  </div>
</template>

<script setup>
import {
  useSeoClicksKeyword,
  useSeoClicksKeywordsRecommendations
} from "../../stores/useSeoClicks";
import VIconFlag from "../Base/VIconFlag.vue";
import VIconSvg from "../Base/VIconSvg.vue";
import ChartSeoClicks from "../Chart/ChartSeoClicks.vue";
import ScProjectKeyowrdRecommendations from "./ScProjectKeyowrdRecommendations.vue";
import RecommendationDailyLimit from "../Blocks/RecommendationDailyLimit.vue";
import {ref, watch} from "vue";
import VFormInputCounterEdit from "../VForm/VFormInputCounterEdit.vue";
import VTooltip from "../Base/VTooltip.vue";
import VLink from "../Base/VLink.vue";
import VFormCheckboxSlider from "../VForm/VFormCheckboxSlider.vue";
import VModalConfirm from "../Base/VModalConfirm.vue";

const props = defineProps({
  keywordId: {},
  projectId: {}
});

const useKeyword = useSeoClicksKeyword(props.projectId,props.keywordId);
const useRecommendations = useSeoClicksKeywordsRecommendations(props.keywordId);

const refInputDailyLimit = ref();
const refContextMenu = ref();
const refEllipsisMenuAnchor = ref();
const deleteModalConfirm = ref();
const checkboxStatus = ref();

const chooseDaily = (number) => {
  refInputDailyLimit.value.setContentEditable(number);
}

const dailyLimit = ref(1);
const updateDailyLimitLoading = ref(false);
watch(() => useKeyword.max_clicks_per_day, (value) => {
  dailyLimit.value = value;
},{immediate:true});

watch(() => dailyLimit.value, (value) => {
  setTimeout(() => {
    updateDailyLimit(value);
  },700);
});

function updateDailyLimit(value){
  if(parseInt(value) !== parseInt(dailyLimit.value)){
    return;
  }
  if(parseInt(value) !== parseInt(useKeyword.max_clicks_per_day)){
    updateDailyLimitLoading.value = true;
    useKeyword.updateDailyLimit(value).then(() => {
      updateDailyLimitLoading.value = false;
      dailyLimit.value = useKeyword.max_clicks_per_day;
    });
  }
}

function saveDailyLimit(value){
  useKeyword.updateDailyLimit(value).then(([status]) => {
    dailyLimit.value = useKeyword.max_clicks_per_day;
    if(status){
      refInputDailyLimit.value.saveWithSuccess();
    }else{
      refInputDailyLimit.value.saveWithError();
    }
  });
}

const restoreKeyword = async () => {
  let [status,message] = await useKeyword.updateIsDeleted(false);
}

const deleteKeyword = async () => {

  let [status,message] = await useKeyword.updateIsDeleted(true);
  if(status){
    deleteModalConfirm.value.closeWithSuccess();
  }else{
    deleteModalConfirm.value.closeWithError(message);
  }

}

const activateKeyword = async () => {
  let [status,message] = await useKeyword.updateIsActive(true);
  if(!status){
    checkboxStatus.value.saveWithError();
  }
}

const deactivateKeyword = async () => {
  refContextMenu.value.hide();
  useKeyword.$patch({is_active:false,});
  await useKeyword.updateIsActive(false);
}

</script>

<style lang="scss" scoped>
.sc-project-keyword-mobile{
  @include flex(column,start,stretch,13px);
  padding:17px 0px;

  &.is-deleted{
    gap:3px;
  }

  .header{
    @include flex(row,between,center);
  }

  .footer{
    @include flex(column,start,stretch, 7px);
    .footer__title{
      color:$dark-40;
      font:$font-mini-10-regu;
    }
    .footer__content{
      @include flex(row,between,center,21px);
    }
    .footer__daily-limit{
      flex-shrink:0;
    }
    .footer__recommendations{
      width:100%;
    }
  }

  .deleted-tag{
    font:$font-mini-12-regu;
    color:$dark-30;
    &.has-region{
      margin-left:27px;
    }
  }

  .keyword{
    @include flex(row,start,center,9px);
    color:#151515;
  }

  .chart{
    width:100%;
    height:40px;
  }

  .ellipsis-menu{
    position:relative;
    .ellipsis-menu__tooltip-anchor{
      position:absolute;
      width:100%;
      top:0px;
      left:0px;
    }
  }

  .control-active{
    @include flex(row,end,center,13px);
    width:100%;
    color:#6C6C6C;
  }

}
.context-menu{
  @include flex(column,start,start,17px);
  background:#fff;
  box-shadow: $box-shadow-default;
  padding:12px 13px;
  border-radius:5px;
}
</style>