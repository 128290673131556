<template>
  <span class="v-number-short">
    {{shortNumber(value)}}
  </span>
</template>

<script setup>
import {shortNumber} from "../../utils/utils";

const props = defineProps({
  value: {}
});

</script>

<style lang="scss" scoped>

</style>