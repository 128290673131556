<template>
  <div class="v-tag-active" :class="mainClass">
    <slot>Active</slot>
  </div>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
  /**
   * @type {'normal'|'mini'}
   */
  size: {type:String,default:'normal'},
});

const mainClass = computed(() => {
  return `size-is-${props.size}`;
});

</script>

<style lang="scss" scoped>
.v-tag-active{
  border: 1px solid $primary-green-100;
  background: $primary-green-5;
  color: $primary-green-100;
  text-transform:uppercase;
  font:$font-mini-9-semi;
  border-radius:4px;
  padding: 1px 4px;
  &.size-is-mini{
    font-size: 7px;
    padding: 2px 3px;
  }
}
</style>