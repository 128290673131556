import {
    formatNumber,
    formatTimeleft,
    formValidationErrorClear,
    formValidationErrorHandling,
    prepareChartData,
    runIfHasSelecter, abbrNumberFormat,
} from '../../utils/panel-lib';
import Chart from 'chart.js/auto';
import _, {isString} from 'lodash';
import route from '../index.js';
import 'toolcool-range-slider';
import {TempusDominus} from '@eonasdan/tempus-dominus';

const $speedCalc = document.getElementById('speed_calc');
const $bouncerateSlider = document.getElementById('bouncerate_slider');
const $returnrateSlider = document.getElementById('return_rate_slider');
const $increaseTrafficSlider = document.getElementById('increase_traffic_slider');

const initGaErrorMessages = function () {
    if (jsPageData.isDeleted) {
        return;
    }

    let nowTime = new Date().getTime();
    let createdProject = new Date(new Date(jsPageData.created_human).getTime() - new Date(jsPageData.created_human).getTimezoneOffset() * 60 * 1000).getTime();

    if (createdProject) {
        let expireTime = new Date(createdProject + 10 * 60000).getTime();

        if (nowTime >= expireTime) {
            $('.ga-error-edit').show();
        }
    }
}

const initModals = function () {
    $('#confirm-delete').on('show.bs.modal', function(e) {
        $(this).
            find('.btn-ok').
            attr('data-href', $(e.relatedTarget).data('href'));
    }).
        find('.btn-ok').
        on('click', function(e) {
            e.preventDefault();

            let $button = $(this);
            let $errorBox = $('#confirm-delete .error-box');

            $errorBox.html('').hide();
            $button.prop('disabled', true);

            axios.patch($button.data('href')).
                then(function(response) {
                    let data = (response.data ? response.data : {});

                    if (data && data.success) {
                        window.location.reload();
                    } else {
                        if (data.message) {
                            $errorBox.html(data.message).show();
                        }

                        $button.prop('disabled', false);
                    }
                }).
                catch(function() {
                    $button.prop('disabled', false);
                });
        });

    $('#confirm-recover').on('show.bs.modal', function(e) {
        $(this).
            find('.btn-ok').
            attr('data-href', $(e.relatedTarget).data('href'));
    }).
        find('.btn-ok').
        on('click', function(e) {
            e.preventDefault();

            let $button = $(this);
            let $errorBox = $('#confirm-recover .error-box');

            $errorBox.html('').hide();
            $button.prop('disabled', true);

            axios.patch($button.data('href')).
                then(function(response) {
                    let data = (response.data ? response.data : {});

                    if (data && data.success) {
                        window.location.reload();
                    } else {
                        if (data.message) {
                            $errorBox.html(data.message).show();
                        }

                        $button.prop('disabled', false);
                    }
                }).
                catch(function() {
                    $button.prop('disabled', false);
                });
        });

    $('#confirm-clone .btn-ok').on('click', function (e) {
        e.preventDefault();

        let $button = $(this);

        let data = {
            originalId: jsPageData.projectId
        }

        $button.prop('disabled', true);

        $.ajax({
            url: '/api/guaranteed/project/clone',
            method: 'POST',
            data: data,
            dataType: 'json',
            success: function (response) {
                let data = response.data;

                if (response.success) {
                    if (data.projectId) {
                        window.location.replace('/project?id=' + data.projectId);
                    }
                } else {
                    // addNotification(response.message);
                    $button.closest('.modal')
                    .find('.info-message-form')
                    .show()
                    .find('.info-message-form__desc')
                    .html(response.message);
                    $button.prop('disabled', false);
                }
            },
            error: function () {
                $button.prop('disabled', false);
            }
        });
    });
}

const initExpiration = function () {
    if (jsPageData.isDeleted) {
        return;
    }

    let $timer = $('[data-timeleft]');
    if ($timer.length === 0) {
        return;
    }

    let distance = parseInt($timer.data('timeleft'));
    let interval;

    const refreshTime = function () {
        $timer.text(formatTimeleft(distance));

        if (distance < 0) {
            clearInterval(interval);
            $timer.html('EXPIRED');
        }

        distance--;
    }
    refreshTime();

    interval = setInterval(refreshTime, 1000);
}

const initProjectStarting = function () {
    if (jsPageData.hasHits) {
        $('[data-panel-id="project-chart-realtime"]').show();

        return;
    }

    if (typeof lottie !== "undefined") {
        lottie.loadAnimation({
            container: document.querySelector('.project-starting-panel__indicator0 .lottie-blinking-circle'),
            renderer: 'svg',  // svg, canvas, html
            loop: true,
            autoplay: true,
            path: '/img/lottie/pulsing-circle-red.json'
        });

        lottie.loadAnimation({
            container: document.querySelector('.project-starting-panel__indicator1 .lottie-blinking-circle'),
            renderer: 'svg',  // svg, canvas, html
            loop: true,
            autoplay: true,
            path: '/img/lottie/pulsing-circle.json'
        });

        lottie.loadAnimation({
            container: document.querySelector('.project-starting-panel__indicator2 .lottie-blinking-circle'),
            renderer: 'svg',  // svg, canvas, html
            loop: true,
            autoplay: true,
            path: '/img/lottie/pulsing-circle.json'
        });

        lottie.loadAnimation({
            container: document.querySelector('.project-starting-panel__indicator3 .lottie-blinking-circle'),
            renderer: 'svg',  // svg, canvas, html
            loop: true,
            autoplay: true,
            path: '/img/lottie/pulsing-circle.json'
        });
    }

    let startingInterval;

    const startWaiting = function () {
        startingInterval = setInterval(function () {
            $.ajax({
                url: route('api.guaranteed.project.check_starting_indicators'),
                method: 'GET',
                data: {
                    id: jsPageData.projectId
                },
                dataType: 'json',
                success: function (response) {
                    if (response.success) {
                        let data = response.data;

                        if ((data.indicator1 === 1 && data.indicator2 === 1 && data.indicator3 === 1) || data.indicator3 === 1) {
                            $('[data-panel-id="project-chart-realtime"]').show();
                            $('[data-panel-id="project-starting-panel"]').hide();

                            clearInterval(startingInterval);
                            startingInterval = null;

                            return;
                        }

                        if (!data.isEnoughCredits) {
                            $('.project-starting-panel__indicator0').show();
                            $('.project-starting-panel__indicator0 .lottie-blinking-circle').show();
                            $('.project-starting-panel__indicator0 .icon-indicator-off').hide();
                            $('.project-starting-panel__indicator0 .icon-indicator-on').hide();

                            $('.project-starting-panel__indicator1 .lottie-blinking-circle').hide();
                            $('.project-starting-panel__indicator1 .icon-indicator-off').show();
                            $('.project-starting-panel__indicator1 .icon-indicator-on').hide();

                            $('.project-starting-panel__indicator2 .lottie-blinking-circle').hide();
                            $('.project-starting-panel__indicator2 .icon-indicator-off').show();
                            $('.project-starting-panel__indicator2 .icon-indicator-on').hide();

                            $('.project-starting-panel__indicator3 .lottie-blinking-circle').hide();
                            $('.project-starting-panel__indicator3 .icon-indicator-off').show();
                            $('.project-starting-panel__indicator3 .icon-indicator-on').hide();
                        } else {
                            $('.project-starting-panel__indicator0 .lottie-blinking-circle').hide();
                            $('.project-starting-panel__indicator0 .icon-indicator-off').hide();
                            $('.project-starting-panel__indicator0 .icon-indicator-on').show();

                            if (data.indicator1 === 0) {
                                $('.project-starting-panel__indicator1 .lottie-blinking-circle').hide();
                                $('.project-starting-panel__indicator1 .icon-indicator-off').show();
                                $('.project-starting-panel__indicator1 .icon-indicator-on').hide();
                            } else if (data.indicator1 === 1) {
                                $('.project-starting-panel__indicator1 .lottie-blinking-circle').hide();
                                $('.project-starting-panel__indicator1 .icon-indicator-off').hide();
                                $('.project-starting-panel__indicator1 .icon-indicator-on').show();
                            } else {
                                $('.project-starting-panel__indicator1 .lottie-blinking-circle').show();
                                $('.project-starting-panel__indicator1 .icon-indicator-off').hide();
                                $('.project-starting-panel__indicator1 .icon-indicator-on').hide();
                            }

                            if (data.indicator2 === 0) {
                                $('.project-starting-panel__indicator2 .lottie-blinking-circle').hide();
                                $('.project-starting-panel__indicator2 .icon-indicator-off').show();
                                $('.project-starting-panel__indicator2 .icon-indicator-on').hide();
                            } else if (data.indicator2 === 1) {
                                $('.project-starting-panel__indicator2 .lottie-blinking-circle').hide();
                                $('.project-starting-panel__indicator2 .icon-indicator-off').hide();
                                $('.project-starting-panel__indicator2 .icon-indicator-on').show();
                            } else {
                                $('.project-starting-panel__indicator2 .lottie-blinking-circle').show();
                                $('.project-starting-panel__indicator2 .icon-indicator-off').hide();
                                $('.project-starting-panel__indicator2 .icon-indicator-on').hide();
                            }

                            if (data.indicator3 === 0) {
                                $('.project-starting-panel__indicator3 .lottie-blinking-circle').hide();
                                $('.project-starting-panel__indicator3 .icon-indicator-off').show();
                                $('.project-starting-panel__indicator3 .icon-indicator-on').hide();
                            } else if (data.indicator3 === 1) {
                                $('.project-starting-panel__indicator3 .lottie-blinking-circle').hide();
                                $('.project-starting-panel__indicator3 .icon-indicator-off').hide();
                                $('.project-starting-panel__indicator3 .icon-indicator-on').show();
                            } else {
                                $('.project-starting-panel__indicator3 .lottie-blinking-circle').show();
                                $('.project-starting-panel__indicator3 .icon-indicator-off').hide();
                                $('.project-starting-panel__indicator3 .icon-indicator-on').hide();
                            }
                        }
                    } else {
                        console.log(response.message);
                    }
                }
            });
        }, 5000);
    }

    $('[data-panel-id="project-starting-panel"]').show();
    startWaiting();
}

const initChart = function () {
    if (jsPageData.isDeleted) {
        return;
    }

    let projectChart = null;
    let projectChartRealtime = null;
    let $dataPickerRange = $('[data-picker-range]');
    let fromDate = $dataPickerRange.find('[name="from"]').val();
    let toDate = $dataPickerRange.find('[name="to"]').val();
    let $typeTrafficSelect = $('[name="type_traffic"]');
    let $typeTrafficRealtimeSelect = $('[name="type_traffic_realtime"]');
    let typeTraffic = $typeTrafficSelect.val();
    let typeTrafficRealtime = $typeTrafficRealtimeSelect.val();
    // let deviceType = $('[data-device-type]').data('device-type');
    // let isMobile = (deviceType === 'phone' || deviceType === 'tablet');
    let timerChartRealtimeUpdate;

    if (typeof lottie !== "undefined") {
        lottie.loadAnimation({
            container: document.querySelector('.lottie-pulsing-circle'),
            renderer: 'svg',  // svg, canvas, html
            loop: true,
            autoplay: true,
            path: '/img/lottie/pulsing-circle.json'
        });
    }

    $dataPickerRange.find('input').on('show', function () {
        $(this).closest('label').find('.icon-arrow-down').addClass('open');
    });

    $dataPickerRange.find('input').on('hide', function () {
        $(this).closest('label').find('.icon-arrow-down').removeClass('open');
    });

    $dataPickerRange.find('input').on('changeDate', function (e) {
        let selectDate = new Date(e.date);
        let dateString = ('0' + selectDate.getDate()).slice(-2) + '.' + ('0' + (selectDate.getMonth() + 1)).slice(-2) + '.' + selectDate.getFullYear();

        $(this).val(dateString);
        fromDate = $dataPickerRange.find('[name="from"]').val();
        toDate = $dataPickerRange.find('[name="to"]').val();
        typeTraffic = $typeTrafficSelect.val();

        getChartData(jsPageData.projectId, fromDate, toDate, typeTraffic);
    });

    $typeTrafficSelect.on('change', function () {
        fromDate = $dataPickerRange.find('[name="from"]').val();
        toDate = $dataPickerRange.find('[name="to"]').val();
        typeTraffic = $(this).val();

        getChartData(jsPageData.projectId, fromDate, toDate, typeTraffic, 'line');
    });

    $typeTrafficRealtimeSelect.on('change', function () {
        typeTrafficRealtime = $(this).val();

        clearTimeout(timerChartRealtimeUpdate);
        getChartRealtimeData(jsPageData.projectId, null, null, typeTrafficRealtime, 'bar');
    });

    getChartData(jsPageData.projectId, fromDate, toDate, typeTraffic, 'line');
    getChartRealtimeData(jsPageData.projectId, null, null, typeTrafficRealtime, 'bar');

    function getChartData(
        projectId,
        fromDate = null,
        toDate = null,
        typeTraffic = 'clicks',
        typeChart = 'line',
    ) {
        if (!projectId) {
            return {};
        }

        if (!fromDate) {
            fromDate = new Date();
        }

        if (!toDate) {
            toDate = new Date();
            toDate.setDate(fromDate.getDate() - 1);
        }

        if (typeChart === 'bar') {
            fromDate = '';
            toDate = '';
        }

        let params = {
            projectId: projectId,
            from: fromDate,
            to: toDate,
            type_traffic: typeTraffic,
            type_chart: typeChart,
        };

        axios.get(`/api/guaranteed/projects/${projectId}/chart-data`, {
            params: params,
        }).then(function(response) {
            if (response.data && response.data.success && response.data.data) {
                let data = response.data.data;
                let $totalCount = $('[data-id="totalHitsOrVisitsCount"]');

                data.typeTraffic = typeTraffic;
                data.typeChart = typeChart;

                let titleVisits = '';
                if (data.translations && data.translations.visits) {
                    titleVisits = (typeChart === 'bar'
                        ? _.upperFirst(data.translations.visits.toLowerCase())
                        : data.translations.visits);
                }

                let titlePageViews = '';
                if (data.translations && data.translations.pageViews) {
                    titlePageViews = (typeChart === 'bar'
                        ? _.upperFirst(data.translations.pageViews.toLowerCase())
                        : data.translations.pageViews);
                }

                if (typeTraffic === 'visits') {
                    $('.type-traffic-title').text(titleVisits);
                } else if (typeTraffic === 'hits') {
                    $('.type-traffic-title').text(titlePageViews);
                }

                let totalHits = data.totalHits ? data.totalHits : 0;
                if (totalHits === 1) {
                    $totalCount.html(formatNumber(totalHits) + '&nbsp;<span style="font-weight: 400;">page&nbsp;view</span>');
                } else {
                    $totalCount.html(formatNumber(totalHits) + '&nbsp;<span style="font-weight: 400;">page&nbsp;views</span>');
                }

                if ($('.delivered-panel__progress-successfully').length > 0) {
                    if (totalHits >= 6000) {
                        $('.delivered-panel__progress-successfully').
                            css('width', '100%');

                        $('.delivered-panel__progress-successfully-text').
                            removeClass('hidden');
                        $('.delivered-panel__progress-pending-delivery span').html('0&nbsp;<span style="font-weight: 400;">page&nbsp;views</span>');
                        $('.delivered-panel__progress-pending-delivery').
                            addClass('hidden');
                        $('.delivered-panel__progress-pending-timeleft').
                            addClass('hidden');
                        $('.delivered-panel__progress-views').
                            html(totalHits + '/' + 6000);
                        $('.opacity-fixed-bg').addClass('active');
                        $('[data-panel-id="project-chart-realtime"]').show();
                        $('[data-panel-id="project-starting-panel"]').hide();
                    } else {
                        $('.delivered-panel__progress-successfully').
                            css('width', (totalHits / (6000 / 100)) + '%');

                        $('.delivered-panel__progress-successfully-text').addClass('hidden');
                        if (6000 - totalHits === 1) {
                            $('.delivered-panel__progress-pending-delivery span').html((6000 - totalHits) + '&nbsp;<span style="font-weight: 400;">page view</span>');
                        } else {
                            $('.delivered-panel__progress-pending-delivery span').html((6000 - totalHits) + '&nbsp;<span style="font-weight: 400;">page views</span>');
                        }
                        $('.delivered-panel__progress-pending-delivery').removeClass('hidden');
                        $('.delivered-panel__progress-pending-timeleft').removeClass('hidden');
                        $('.opacity-fixed-bg').removeClass('active');
                    }

                    if (jsPageData.isExpired) {
                        $('.opacity-fixed-bg').addClass('active');
                        $('.delivered-panel__progress-pending-timeleft').html('EXPIRED');
                        $('[data-panel-id="project-chart-realtime"]').show();
                        $('[data-panel-id="project-starting-panel"]').hide();
                    }
                }

                drawChart(data);
            }
        }).catch(function(error) {
            // console.error(error);
        });
    }

    function drawChart(chartData) {
        if (!chartData) {
            return;
        }

        let ctxEl = document.getElementById('project-chart');
        if (ctxEl) {
            let ctx = ctxEl.getContext('2d');
            let chartConfig = prepareChartData(ctx, chartData, 'normal');

            if (chartConfig) {
                if (projectChart) {
                    projectChart.data = chartConfig.data;
                    projectChart.options = chartConfig.options;
                    projectChart.update();
                } else {
                    projectChart = new Chart(ctx, chartConfig);
                }
            }
        }
    }

    function getChartRealtimeData(
        projectId,
        fromDate = null,
        toDate = null,
        typeTraffic = 'clicks',
        typeChart = 'line',
        updateEmpty = true,
    ) {
        if (!projectId) {
            return {};
        }

        if (!fromDate) {
            fromDate = new Date();
        }

        if (!toDate) {
            toDate = new Date();
            toDate.setDate(fromDate.getDate() - 1);
        }

        if (typeChart === 'bar') {
            fromDate = '';
            toDate = '';
        }

        let params = {
            projectId: projectId,
            from: fromDate,
            to: toDate,
            type_traffic: typeTraffic,
            type_chart: typeChart,
        };

        axios.get(`/api/guaranteed/projects/${projectId}/chart-data`, {
            params: params,
        }).then(function(response) {
            if (response.data && response.data.success && response.data.data) {
                let data = response.data.data;
                let $totalCount = $('[data-id="totalHitsOrVisitsRealtimeCount"]');
                let $projectChartRealtime = $('[data-panel-id="project-chart-realtime"]');

                let hitsInLast20minutes = 0;
                if (data.data && data.data.length >= 20) {
                    let count = 1;
                    for (let i = data.data.length - 1; i > 0; i--) {
                        if (count > 20) {
                            break;
                        }

                        hitsInLast20minutes += parseInt(data.data[i]);
                        count++;
                    }
                }

                if (jsPageData.projectSize.indexOf('demo') !== -1 && data.hasHits && hitsInLast20minutes === 0) {
                    $projectChartRealtime.find('.project-chart-header').hide();
                    $projectChartRealtime.find('.project-chart').hide();
                    $projectChartRealtime.find('.project-chart-realtime-total').hide();
                    $projectChartRealtime.find('.demo-is-over').show();
                } else {
                    $projectChartRealtime.find('.project-chart-header').show();
                    $projectChartRealtime.find('.project-chart').show();
                    $projectChartRealtime.find('.project-chart-realtime-total').show();
                    $projectChartRealtime.find('.demo-is-over').hide();
                }

                if (!updateEmpty && parseInt(data.totalHits) <= 0) {
                    return false;
                }

                data.typeTraffic = typeTraffic;
                data.typeChart = typeChart;

                let titleVisits = '';
                if (data.translations && data.translations.visits) {
                    titleVisits = (typeChart === 'bar'
                        ? _.upperFirst(data.translations.visits.toLowerCase())
                        : data.translations.visits);
                }

                let titlePageViews = '';
                if (data.translations && data.translations.pageViews) {
                    titlePageViews = (typeChart === 'bar'
                        ? _.upperFirst(data.translations.pageViews.toLowerCase())
                        : data.translations.pageViews);
                }

                if (typeTraffic === 'visits') {
                    $('.type-traffic-realtime-title').text(titleVisits);
                } else if (typeTraffic === 'hits') {
                    $('.type-traffic-realtime-title').text(titlePageViews);
                }

                let totalHits = data.totalHits ? data.totalHits : 0;
                $totalCount.text(formatNumber(totalHits));

                drawChartRealtime(data);
            }
        }).catch(function(error) {
            // console.error(error);
        }).finally(function() {
            if (typeChart === 'bar') {
                timerChartRealtimeUpdate = setTimeout(function() {
                    getChartRealtimeData(
                        projectId,
                        null,
                        null,
                        typeTraffic,
                        typeChart,
                        false,
                    );
                }, 10000);
            }
        });
    }

    function drawChartRealtime(chartData) {
        if (!chartData) {
            return;
        }

        let ctxEl = document.getElementById('project-chart-realtime');
        if (ctxEl) {
            let ctx = ctxEl.getContext('2d');
            let chartConfig = prepareChartData(ctx, chartData, 'normal');

            if (chartConfig) {
                if (projectChartRealtime) {
                    projectChartRealtime.data = chartConfig.data;
                    projectChartRealtime.options = chartConfig.options;
                    projectChartRealtime.update();
                } else {
                    projectChartRealtime = new Chart(ctx, chartConfig);
                }
            }
        }
    }
}

const initTrafficTypeChangeListener = function() {
    let $referrersGroup = $('#referrers-group');
    let $socialGroup = $('#social-group');
    let $keywordsGroup = $('#keywords-group');
    let $trafficType = $('#traffic_type');

    $trafficType.on('change', updateTrafficType);
    updateTrafficType();

    function updateTrafficType() {
        let type = $trafficType.val();

        $referrersGroup.hide();
        $socialGroup.hide();
        $keywordsGroup.hide();

        if (type === 'referral') $referrersGroup.show();
        if (type === 'social') $socialGroup.show();
        if (type === 'organic') $keywordsGroup.show();
    }
};

const initDailyHitsEstimates = function() {
    let $speed = $('[name="speed"]');
    let $speedText = $('[name="speed_text"]');
    let $bouncerate = $('[name="bouncerate"]');
    let $bouncerateText = $('[name="bouncerate_text"]');
    let $returnrate = $('[name="return_rate"]');
    let $returnrateText = $('[name="return_rate_text"]');
    let $weekdayrate = $('[name="weekday_rate"]');
    let $increaseTrafficVolumeBy = $('[name="increase_traffic_volume_by"]');
    let $increaseTrafficVolumeByText = $('[name="increase_traffic_volume_by_text"]');
    let $entryUrl = $('[name="url"]');
    let $innerUrl = $('[name="url_second"]');
    let $exitUrl = $('[name="url_array"]');
    let $shortener = $('[name="shortener"]');
    let $respectDayOfWeek = $('[name="respect_day_of_week"]');
    let isLastUseInput = false;
    let typeUrls = $exitUrl.data('type');

    function getUrlArrayDailyUsersKoef() {
        if ($('[name="url_array_11"]').val() !== '') {
            return 11;
        }

        if ($('[name="url_array_10"]').val() !== '') {
            return 10;
        }

        if ($('[name="url_array_9"]').val() !== '') {
            return 9;
        }

        if ($('[name="url_array_8"]').val() !== '') {
            return 8;
        }

        if ($('[name="url_array_7"]').val() !== '') {
            return 7;
        }

        if ($('[name="url_array_6"]').val() !== '') {
            return 6;
        }

        if ($('[name="url_array_5"]').val() !== '') {
            return 5;
        }

        if ($('[name="url_array_4"]').val() !== '') {
            return 4;
        }

        return 3;
    }

    function calculateDailyHitsEstimate() {
        let respectDayOfWeek = $respectDayOfWeek.prop('checked');
        let entryUrlVal = $entryUrl.val();
        let innerUrlVal = $innerUrl.val();
        let exitUrlVal = $exitUrl.val();
        let shortenerUrl = $shortener.val();
        let shortenerConsumptionKoef = (exitUrlVal !== '' ? 4 : 3);
        let dailyHits = jsPageData.dailyHits;
        let innerUrlsCount = parseInt($('[name="inner_urls_count"]').val() ?? 0);
        let countUrls = (exitUrlVal === '' ? 1 : 2) + innerUrlsCount;
        let dailyUsersKoef = getUrlArrayDailyUsersKoef();

        let bouncerate = parseInt($('#bouncerate').val()) / 100;
        let returnrate = parseInt($('#return_rate').val());
        let weekdayrate = (respectDayOfWeek ? parseInt($weekdayrate.val()) : 0);

        if (typeUrls !== 'v2') {
            countUrls = dailyUsersKoef;
        }

        let dailyViews = parseInt($('[name="speed"]').val());
        let dailyUsers = dailyViews / countUrls;

        $('#daily_views').html(abbrNumberFormat(dailyViews));
        $('#daily_users').html(abbrNumberFormat(dailyUsers));

        let bounceRateVDeduction = Math.round(dailyViews - (dailyViews * (1 - bouncerate * (1 - 1 / countUrls))));
        if (bouncerate === 0) {
            bounceRateVDeduction = 0;
        }
        $('.bounce-rate-v-deduction-value').text((bounceRateVDeduction > 0 ? '-' : '') + abbrNumberFormat(bounceRateVDeduction));
        if (bounceRateVDeduction !== 0) {
            $('.speed-calc-result__bounce-rate-v').removeClass('d-none');
        } else {
            $('.speed-calc-result__bounce-rate-v').addClass('d-none');
        }

        let shortenerConsumption = (shortenerUrl === '' ? 0 : dailyViews / shortenerConsumptionKoef);
        $('.shortener-deduction-value').text((shortenerConsumption > 0 ? '-' : '') + abbrNumberFormat(shortenerConsumption));
        if (shortenerConsumption !== 0) {
            $('.speed-calc-result__shortener').removeClass('d-none');
        } else {
            $('.speed-calc-result__shortener').addClass('d-none');
        }

        let rerurnRateDeduction = Math.round(dailyUsers * (returnrate / 100));
        if (returnrate === 0) {
            rerurnRateDeduction = 0;
        }
        $('.return-rate-deduction-value').text((rerurnRateDeduction > 0 ? '-' : '') + abbrNumberFormat(rerurnRateDeduction));
        if (rerurnRateDeduction !== 0) {
            $('.speed-calc-result__return-rate').removeClass('d-none');
        } else {
            $('.speed-calc-result__return-rate').addClass('d-none');
        }

        let weekdayRateVDeduction = 0;
        if (respectDayOfWeek) {
            $('.speed-calc-result__weekday').show();
        } else {
            $('.speed-calc-result__weekday').hide();
        }

        if (weekdayrate !== 0) {
            weekdayRateVDeduction = Math.round(dailyViews * (weekdayrate / 100));
            $('.speed-calc-result__weekday-rate-v-title').text($weekdayrate.find('option:selected').text());
            $('.weekday-rate-v-deduction-value').text((weekdayRateVDeduction > 0 ? '+' : '') + abbrNumberFormat(weekdayRateVDeduction));
            if (weekdayRateVDeduction < 0) {
                $('.weekday-rate-v-deduction-value').addClass('text_color_red');
                $('.weekday-rate-v-deduction-value').removeClass('text_color_green');
            } else {
                $('.weekday-rate-v-deduction-value').removeClass('text_color_red');
                $('.weekday-rate-v-deduction-value').addClass('text_color_green');
            }

            $('.speed-calc-result__weekday-rate-v').removeClass('d-none');
        } else {
            $('.speed-calc-result__weekday-rate-v').addClass('d-none');
        }

        let $resultTotalViews = $('#result_total_views');
        let totalViews = (dailyViews - bounceRateVDeduction - shortenerConsumption) + (weekdayRateVDeduction);
        if (innerUrlsCount === 0 && exitUrlVal === '' && innerUrlVal === '') {
            totalViews = 0;
        }
        $resultTotalViews.text(abbrNumberFormat(totalViews));
        if (totalViews !== dailyViews) {
            $('.speed-calc-result__total-views').removeClass('d-none');
        } else {
            $('.speed-calc-result__total-views').addClass('d-none');
        }


        let bounceRateUDeduction = Math.round(dailyUsers - ((1 - bouncerate) * dailyViews / countUrls));
        if (bouncerate === 0) {
            bounceRateUDeduction = 0;
        }
        $('.bounce-rate-u-deduction-value').text((bounceRateUDeduction > 0 ? '-' : '') + abbrNumberFormat(bounceRateUDeduction));
        if (bounceRateUDeduction !== 0) {
            $('.speed-calc-result__bounce-rate-u').removeClass('d-none');
        } else {
            $('.speed-calc-result__bounce-rate-u').addClass('d-none');
        }

        let weekdayRateUDeduction = 0;
        if (weekdayrate !== 0) {
            weekdayRateUDeduction = Math.round(dailyUsers * (weekdayrate / 100));
            $('.speed-calc-result__weekday-rate-u-title').text($weekdayrate.find('option:selected').text());
            $('.weekday-rate-u-deduction-value').text((weekdayRateUDeduction > 0 ? '+' : '') + abbrNumberFormat(weekdayRateUDeduction));
            if (weekdayRateUDeduction < 0) {
                $('.weekday-rate-u-deduction-value').addClass('text_color_red');
                $('.weekday-rate-u-deduction-value').removeClass('text_color_green');
            } else {
                $('.weekday-rate-u-deduction-value').removeClass('text_color_red');
                $('.weekday-rate-u-deduction-value').addClass('text_color_green');
            }

            $('.speed-calc-result__weekday-rate-u').removeClass('d-none');
        } else {
            $('.speed-calc-result__weekday-rate-u').addClass('d-none');
        }

        let showTotalUsers = true;

        let totalUsers = (dailyUsers - bounceRateUDeduction - rerurnRateDeduction) + (weekdayRateUDeduction);
        if (innerUrlsCount === 0 && exitUrlVal === '') {
            totalUsers = 0;
        }

        let $resultTotalUsers = $('#result_total_users');
        $resultTotalUsers.text(abbrNumberFormat(totalUsers));
        if (totalUsers !== dailyUsers) {
            $('.speed-calc-result__total-users').removeClass('d-none');
        } else {
            $('.speed-calc-result__total-users').addClass('d-none');
        }
    }

    function setPause() {
        if (!$('[data-ajax-action="pause"]').is(":visible")) {
            return;
        }

        $('.action-panel .action-panel__state').
            removeClass('no-active');
        $('.action-panel .action-panel__status').
            html('Active').
            removeClass('no-active');

        $('[data-ajax-action="pause"]').
            show().
            next('.tooltip-icon-wrap').
            show();
        $('[data-ajax-action="resume"]').
            hide().
            next('.tooltip-icon-wrap').
            hide();
    }

    function unsetPause() {
        if ($('[data-ajax-action="pause"]').is(":visible")) {
            return;
        }

        $('.action-panel .action-panel__state').
            removeClass('no-active');
        $('.action-panel .action-panel__status').
            html('Active').
            removeClass('no-active');

        $('[data-ajax-action="pause"]').
            show().
            next('.tooltip-icon-wrap').
            show();
        $('[data-ajax-action="resume"]').
            hide().
            next('.tooltip-icon-wrap').
            hide();
    }

    if (jsPageData.isDeleted || typeof $speedCalc === 'undefined') {
        return;
    }

    let $speedCalcData = [];
    for (let i = 0; i <= 1000000; i++) {
        if (i === 0) {
            $speedCalcData.push(i);
        } else if (i >= 500 && i < 1000 && i % 500 === 0) {
            $speedCalcData.push(i);
        } else if (i >= 1000 && i < 20000 && i % 500 === 0) {
            $speedCalcData.push(i);
        } else if (i >= 20000 && i < 50000 && i % 1000 === 0) {
            $speedCalcData.push(i);
        } else if (i >= 50000 && i % 25000 === 0) {
            $speedCalcData.push(i);
        }
    }
    $speedCalc.data = $speedCalcData;

    $speedCalc.addEventListener('change', (evt) => {
        if (typeof evt !== 'undefined' && typeof evt.detail !== 'undefined') {
            if (!isLastUseInput) {
                $speed.val(evt.detail.value).trigger('change');
                $speedText.val(abbrNumberFormat(evt.detail.value));
            }

            $('.speed-calc .speed-calc__label[data-value]').removeClass('active');
            $('.speed-calc .speed-calc__label[data-value="' + evt.detail.value + '"]').addClass('active');

            calculateDailyHitsEstimate();
        }
    });

    $speedCalc.addEventListener('onMouseDown', (evt) => {
        isLastUseInput = false;
    });

    $speedCalc.addEventListener('onMouseUp', (evt) => {
        isLastUseInput = false;
    });

    $speedCalc.addEventListener('onPointerClicked', (evt) => {
        isLastUseInput = false;
    });

    $speedCalc.addEventListener('onKeyDown', (evt) => {
        isLastUseInput = false;
    });

    $('.speed-calc .speed-calc__label[data-value]').on('click', function() {
        isLastUseInput = false;
        $speedCalc.value = parseInt($(this).data('value'));
    });

    $speed.on('change', function() {
        if (parseInt($(this).val()) <= 0) {
            setPause();
        } else {
            unsetPause();
        }
    });

    $speedText.on('input', function() {
        isLastUseInput = true;
        let valueText = $speedText.val();
        if (valueText.indexOf('K') !== -1) {
            valueText = valueText.replaceAll('K', '');
            valueText = valueText * 1000;
        } else if (valueText.indexOf('M') !== -1) {
            valueText = valueText.replaceAll('M', '');
            valueText = valueText * 1000000;
        }

        let speed = parseInt(valueText);
        if (isNaN(speed)) {
            speed = 0;
        } else if (speed < 0) {
            speed = 0;
        } else if (speed > 1000000) {
            speed = 1000000;
        }

        $(this).val(speed);
        $speed.val(speed);

        calculateDailyHitsEstimate();
    }).trigger('input');

    $speedText.on('keyup', function(e) {
        if (e.keyCode === 13){
            $(this).trigger('blur');
        }
    });

    $speedText.on('focus', function() {
        isLastUseInput = true;
        let value = $(this).val();

        if (value.indexOf('K') !== -1) {
            value = value.replaceAll('K', '');
            value = value * 1000;

            $(this).val(value);

            calculateDailyHitsEstimate();
        }
    });

    $speedText.on('blur', function() {
        isLastUseInput = true;
        let value = parseInt($(this).val());

        if (value > 0 && value < 200) {
            value = 200;
            $(this).val(value).trigger('input');
        }

        if (value >= 500 && value < 1000 && value % 500 === 0) {
            $speedCalc.value = value;
        } else if (value >= 1000 && value < 20000 && value % 500 === 0) {
            $speedCalc.value = value;
        } else if (value >= 20000 && value < 50000 && value % 1000 === 0) {
            $speedCalc.value = value;
        } else if (value >= 50000 && value % 25000 === 0) {
            $speedCalc.value = value;
        }

        for (let i in $speedCalcData) {
            let num = parseInt($speedCalcData[i]);
            if (num > value) {
                $speedCalc.value = num;
                break;
            }
        }

        $(this).val(abbrNumberFormat($(this).val()));

        $('.speed-calc .speed-calc__label[data-value]').removeClass('active');
        $('.speed-calc .speed-calc__label[data-value="' + $speedCalc.value + '"]').addClass('active');

        calculateDailyHitsEstimate();
    }).trigger('blur');

    isLastUseInput = false;
    $speedText.val(abbrNumberFormat($speedText.val()));


    // bouncerate
    $bouncerateSlider.addEventListener('change', (evt) => {
        if (typeof evt !== 'undefined' && typeof evt.detail !== 'undefined') {
            $bouncerate.val(evt.detail.value).trigger('change');
            $bouncerateText.val(evt.detail.value + '%');

            calculateDailyHitsEstimate();
        }
    });

    $bouncerateText.on('input', function() {
        $bouncerateSlider.value = parseInt($(this).val());
        $(this).val($bouncerate.val());
        $bouncerate.val(parseInt($(this).val()));

        calculateDailyHitsEstimate();
    });

    $bouncerateText.on('focus', function() {
        $(this).val($bouncerate.val());

        calculateDailyHitsEstimate();
    });

    $bouncerateText.on('blur', function() {
        $bouncerateSlider.value = parseInt($(this).val());
        $(this).val($bouncerate.val() + '%');

        calculateDailyHitsEstimate();
    }).trigger('blur');


    // return_rate
    $returnrateSlider.addEventListener('change', (evt) => {
        if (typeof evt !== 'undefined' && typeof evt.detail !== 'undefined') {
            $returnrate.val(evt.detail.value).trigger('change');
            $returnrateText.val(evt.detail.value + '%');

            calculateDailyHitsEstimate();
        }
    });

    $returnrateText.on('input', function() {
        $returnrateSlider.value = parseInt($(this).val());
        $(this).val($returnrate.val());
        $returnrate.val(parseInt($(this).val()));

        calculateDailyHitsEstimate();
    });

    $returnrateText.on('focus', function() {
        $(this).val($returnrate.val());

        calculateDailyHitsEstimate();
    });

    $returnrateText.on('blur', function() {
        $returnrateSlider.value = parseInt($(this).val());
        $(this).val($returnrate.val() + '%');

        calculateDailyHitsEstimate();
    }).trigger('blur');


    // increase_traffic_volume_by
    if ($increaseTrafficSlider) {
        $increaseTrafficSlider.addEventListener('change', (evt) => {
            if (typeof evt !== 'undefined' && typeof evt.detail !==
                'undefined') {
                $increaseTrafficVolumeBy.val(evt.detail.value).
                    trigger('change');
                $increaseTrafficVolumeByText.val(evt.detail.value + '%');
            }
        });
    }

    if ($increaseTrafficVolumeByText) {
        $increaseTrafficVolumeByText.on('input', function() {
            $increaseTrafficSlider.value = parseInt($(this).val());
            $(this).val($increaseTrafficVolumeBy.val());
            $increaseTrafficVolumeBy.val(parseInt($(this).val()));
        });

        $increaseTrafficVolumeByText.on('focus', function() {
            $(this).val($increaseTrafficVolumeBy.val());
        });

        $increaseTrafficVolumeByText.on('blur', function() {
            $increaseTrafficSlider.value = parseInt($(this).val());
            $(this).val($increaseTrafficVolumeBy.val() + '%');
        }).trigger('blur');
    }


    // inner_urls_count_range_slider
    let $innerUrlsCountRangeSlider = $('#inner_urls_count_range_slider');
    if (typeof $innerUrlsCountRangeSlider.slider === 'function') {
        $innerUrlsCountRangeSlider.slider({
            range: 'min',
            value: 0,
            min: 0,
            max: jsPageData.innerUrlsMaxCount,
            step: 1,
            slide: function(event, ui) {
                $('[name="inner_urls_count"]').val(ui.value).trigger('change');
                $('#inner_urls_count').text(ui.value);
                calculateDailyHitsEstimate();
            },
            change: function(event, ui) {
                $('[name="inner_urls_count"]').val(ui.value).trigger('change');
                $('#inner_urls_count').text(ui.value);
                calculateDailyHitsEstimate();
            },
        });
        $innerUrlsCountRangeSlider.slider('value', $('input[name="inner_urls_count"]').val());
    }

    $('select[name="weekday_rate"]').on('change', function() {
        calculateDailyHitsEstimate();
    }).trigger('change');

    $('[name="respect_day_of_week"]').on('change', function() {
        calculateDailyHitsEstimate();
    }).trigger('change');

    $entryUrl.on('input', function() {
        calculateDailyHitsEstimate();
    });

    $innerUrl.on('input', function() {
        calculateDailyHitsEstimate();
    });

    $exitUrl.on('input', function() {
        calculateDailyHitsEstimate();
    });

    $shortener.on('input', function() {
        calculateDailyHitsEstimate();
    });

    $('[name="url_array_4"], [name="url_array_5"], [name="url_array_6"], [name="url_array_7"], [name="url_array_8"], [name="url_array_9"], [name="url_array_10"], [name="url_array_11"]').
        on('input', function() {
            calculateDailyHitsEstimate();
        });

    calculateDailyHitsEstimate();
};

const initGeoTypeChange = function () {
    if (jsPageData.isDeleted) {
        return;
    }

    let $citiesGeoCountry = $('#cities_geo_country');
    let $geoType = $("#geo_type");

    onGeoTypeChange();

    $citiesGeoCountry.on('change', function () {
        fill_in_cities();
    });

    $geoType.on('change', function () {
        onGeoTypeChange();
    });

    function onGeoTypeChange() {
        let $countries = $('#countries');
        let $cities = $('#cities');
        let $geoTypeCountries = $('.geo-type-countries');
        let $geoTypeStates = $('.geo-type-states');
        let $geoTypeCities = $('.geo-type-cities');

        if ($geoType.val() === 'cities') {
            if ($cities.find('option:selected').length <= 0) {
                let countrySelect = $.trim($countries.find('option:selected').first().text());

                if (countrySelect) {
                    $citiesGeoCountry.find('option').each(function(index, el) {
                        if ($(el).text() === countrySelect) {
                            // $(el).attr('selected', 'selected');
                            $(el).prop('selected', true);
                            $citiesGeoCountry.selectpicker('destroy').selectpicker();
                        }
                    });
                }
            }

            $geoTypeCountries.hide();
            $geoTypeStates.hide();
            $geoTypeCities.show();

            fill_in_cities();
        } else if ($geoType.val() === 'countries') {
            $geoTypeCountries.show();
            $geoTypeStates.hide();
            $geoTypeCities.hide();
        } else {
            $geoTypeCountries.hide();
            $geoTypeStates.hide();
            $geoTypeCities.hide();
        }
    }

    function fill_in_cities(remove_all) {
        let $cities = $('#cities');

        if (remove_all) {
            $cities.find('option').remove();
        } else {
            $cities.find('option:not(:selected)').remove();
        }

        let selectedCitiesIds = [];
        $cities.find('option:selected').each(function(index, el) {
            selectedCitiesIds.push(parseInt($(el).val()));
        });

        let params = {
            'id': $citiesGeoCountry.val(),
        };

        axios.get(`/api/geolocations/cities-by-country`, {
            params: params,
        }).then(function(response) {
            if (response.data && response.data.success && response.data.data) {
                let data = response.data.data;

                for (let i = 0, len = data.length; i < len; i++) {
                    let $id = data[i]['id'];

                    if (selectedCitiesIds.includes($id)) {
                        continue;
                    }

                    $cities.append($('<option></option>').
                        attr('value', $id).
                        text(data[i]['name']));
                }

                $cities.selectpicker('destroy').selectpicker();
            }
        }).catch(function(error) {
            // console.error(error);
        });
    }
}

const initScrollEventsRangeSlider = function() {
    if (jsPageData.isDeleted) {
        return;
    }

    let $scrollEventsRangeSlider = $('#scroll_events_range_slider');
    if (typeof $scrollEventsRangeSlider.slider === 'function') {
        $scrollEventsRangeSlider.slider({
            range: 'min',
            value: 0,
            min: 0,
            max: 100,
            step: 1,
            slide: function(event, ui) {
                $('[name="scroll_events"]').val(ui.value).trigger('change');
                $('#scroll_events_percent').text(ui.value + '%');
            },
            change: function(event, ui) {
                $('[name="scroll_events"]').val(ui.value).trigger('change');
                $('#scroll_events_percent').text(ui.value + '%');
            },
        });
        $scrollEventsRangeSlider.slider('value', $('input[name="scroll_events"]').val());
    }
};

const initClickOoutboundEventsRangeSlider = function() {
    if (jsPageData.isDeleted) {
        return;
    }

    let $scrollClickOoutboundRangeSlider = $('#click_outbound_events_range_slider');
    if (typeof $scrollClickOoutboundRangeSlider.slider === 'function') {
        $scrollClickOoutboundRangeSlider.slider({
            range: 'min',
            value: 0,
            min: 0,
            max: 100,
            step: 1,
            slide: function(event, ui) {
                $('[name="click_outbound_events"]').
                    val(ui.value).
                    trigger('change');
                $('#click_outbound_events_percent').text(ui.value + '%');
            },
            change: function(event, ui) {
                $('[name="click_outbound_events"]').
                    val(ui.value).
                    trigger('change');
                $('#click_outbound_events_percent').text(ui.value + '%');
            },
        });
        $scrollClickOoutboundRangeSlider.slider('value', $('input[name="click_outbound_events"]').val());
    }
};

const initFormSubmitEventsRangeSlider = function() {
    if (jsPageData.isDeleted) {
        return;
    }

    let $formSubmitEventsRangeSlider = $('#form_submit_events_range_slider');
    if (typeof $formSubmitEventsRangeSlider.slider === 'function') {
        $formSubmitEventsRangeSlider.slider({
            range: 'min',
            value: 0,
            min: 0,
            max: 100,
            step: 1,
            slide: function(event, ui) {
                $('[name="form_submit_events"]').
                    val(ui.value).
                    trigger('change');
                $('#form_submit_events_percent').text(ui.value + '%');
            },
            change: function(event, ui) {
                $('[name="form_submit_events"]').
                    val(ui.value).
                    trigger('change');
                $('#form_submit_events_percent').text(ui.value + '%');
            },
        });
        $formSubmitEventsRangeSlider.slider('value', $('input[name="form_submit_events"]').val());
    }
};

const initDesktopRateRangeSlider = function() {
    if (jsPageData.isDeleted) {
        return;
    }

    let $desktopRateBlock = $('.desktop-rate-block');

    let $formDesktopRateSlider = $('#desktop_rate_slider');
    if (typeof $formDesktopRateSlider.slider === 'function') {
        $formDesktopRateSlider.slider({
            range: 'min',
            value: 0,
            min: 0,
            max: 100,
            step: 1,
            slide: function(event, ui) {
                let percent = ui.value;
                let percentDesktop = percent;
                let percentMobile = 100 - percent;

                $('[name="desktop_rate"]').val(percent).trigger('change');
                $desktopRateBlock.find('[data-slider-percent="desktop"]').
                    text(percentDesktop + '%');
                $desktopRateBlock.find('[data-slider-percent="mobile"]').
                    text(percentMobile + '%');
            },
            change: function(event, ui) {
                let percent = ui.value;
                let percentDesktop = percent;
                let percentMobile = 100 - percent;

                $('[name="desktop_rate"]').val(percent).trigger('change');
                $desktopRateBlock.find('[data-slider-percent="desktop"]').
                    text(percentDesktop + '%');
                $desktopRateBlock.find('[data-slider-percent="mobile"]').
                    text(percentMobile + '%');
            },
        });
        $formDesktopRateSlider.slider('value', $('input[name="desktop_rate"]').val());
    }
};

const initCheckLinkStatus = function() {
    if (jsPageData.projectSize !== 'nano' || jsPageData.isDeleted) {
        return;
    }

    let $btnreciprocalcheck = $('#button_check_link_status');
    let $elLinkFoundStatus = $('#link_found_status');
    let buttonInterval;
    let secondsLeft = 0;

    const updateButton = function(countPoints = 0) {
        let points = _.reduce(_.range(0, countPoints), function(acc) {
            return acc + '.';
        }, '');

        $btnreciprocalcheck.html(
            '<i class="fal fa-redo"></i>&nbsp;&nbsp;Waiting' + points);
    };

    const startWaiting = function() {
        secondsLeft = 0;

        updateButton();
        $btnreciprocalcheck.prop('disabled', true);

        buttonInterval = setInterval(function() {
            updateButton(secondsLeft++ % 4);
            $.ajax({
                url: '/api/guaranteed/project/check-reciprocal-check',
                method: 'GET',
                data: {
                    id: jsPageData.projectId,
                },
                dataType: 'json',
                success: function(response) {
                    if (response.success) {
                        if (!response.data.isDone) {
                            return;
                        }

                        if (response.data.isFound) {
                            checkReciprocalStatus(true);
                        } else {
                            checkReciprocalStatus(false);
                        }

                        $btnreciprocalcheck.html('<i class="fal fa-redo"></i>&nbsp;&nbsp;Check');
                        $btnreciprocalcheck.prop('disabled', false);
                        clearInterval(buttonInterval);
                        buttonInterval = null;
                    } else {
                        console.log(response.message);
                    }
                },
            });
        }, 2000);
    };

    const checkReciprocalStatus = function(isFound = false) {
        if (isFound) {
            $elLinkFoundStatus.html('<span style="color: green; ">FOUND, The traffic is doubled</span>');
        } else {
            $elLinkFoundStatus.html('<span style="color: red; ">NOT FOUND</span>');
        }
    };

    $btnreciprocalcheck.click(function(e) {
        e.preventDefault();

        $.ajax({
            url: '/api/guaranteed/project/start-reciprocal-check',
            method: 'POST',
            data: {
                id: jsPageData.projectId,
            },
            dataType: 'json',
        });

        startWaiting();
    });

    if (jsPageData.force_reciprocal_check === 1) {
        startWaiting();
    }

    // $.get("/check-reciprocal.php?campaignid=" + jsPageData.projectId, function (data) {
    //     if (data === 'FOUND') {
    //         $elLinkFoundStatus.html('<font color="green">FOUND, The traffic is doubled</font>');
    //         $("#guaranteed_monthly_hits").html(formatNumber(jsPageData.monthlyHits * 2));
    //     } else {
    //         $elLinkFoundStatus.html('<font color="red">NOT FOUND</font>');
    //         $("#guaranteed_monthly_hits").html(formatNumber(jsPageData.monthlyHits));
    //     }
    // })
};

const initCheckUpdateCountersStatus = function() {
    let $btnupdatecounters = $('#btnupdatecounters');
    let isAllowedCheckCounters = true;
    let checkCountersTimeout;
    let secondsLeft = 0;

    const updateButton = function(countPoints = 0) {
        const points = _.reduce(_.range(0, countPoints), function(acc) {
            return acc + '.';
        }, '');

        $btnupdatecounters.html('<i class="fal fa-redo"></i>&nbsp;&nbsp;Updating&nbsp;Counters' + points);
    };

    const startWaiting = function() {
        updateButton(secondsLeft++ % 4);

        $.ajax({
            url: '/api/guaranteed/project/check-counters-updating',
            method: 'GET',
            data: {
                id: jsPageData.projectId,
            },
            dataType: 'json',
            success: function(response) {
                try {
                    if (typeof response.success !== 'undefined' &&
                        response.success) {
                        if (response.data.isDone) {
                            $btnupdatecounters.html('<i class="fal fa-redo"></i>&nbsp;&nbsp;Update&nbsp;Counters');
                            $btnupdatecounters.prop('disabled', false);

                            isAllowedCheckCounters = false;
                        }
                    } else {
                        console.log(response.message);
                    }
                } catch (e) {
                    console.log('Error request "check-counters-updating": ' + e.responseText);
                }
            },
            complete: function() {
                if (!isAllowedCheckCounters) {
                    clearTimeout(checkCountersTimeout);
                    checkCountersTimeout = null;

                    return;
                }

                checkCountersTimeout = setTimeout(function() {
                    startWaiting();
                }, 2000);
            },
        });
    };

    $btnupdatecounters.on('click', function(e) {
        e.preventDefault();

        secondsLeft = 0;
        $btnupdatecounters.prop('disabled', true);
        updateButton(secondsLeft++ % 4);

        $.ajax({
            url: '/api/guaranteed/project/start-counters-updating',
            method: 'POST',
            data: {
                id: jsPageData.projectId,
            },
            dataType: 'json',
            complete: function() {
                isAllowedCheckCounters = true;
                startWaiting();
            },
        });
    });

    if (jsPageData.force_update_counters === 1) {
        $btnupdatecounters.prop('disabled', true);
        startWaiting();
    }
};

const initCheckUpdateBtnRecommendationsStatus = function() {
    let hostName = window.location.hostname;
    if (hostName.indexOf('localhost') !== -1 ||
        hostName.indexOf('127.0.0.1') !== -1) {
        return;
    }

    let $buttonUpdateRecommendations = $('.button-update-recommendations');
    let checkRecommendationsInterval;
    let secondsLeft = 0;

    const updateButton = function(countPoints = 0) {
        const points = _.reduce(_.range(0, countPoints), function(acc) {
            return acc + '.';
        }, '');

        $buttonUpdateRecommendations.html('<i class="fal fa-redo"></i>&nbsp;&nbsp;Updating&nbsp;Recommendations' + points);
    };

    const startWaiting = function() {
        secondsLeft = 0;
        updateButton();
        $buttonUpdateRecommendations.prop('disabled', true);

        checkRecommendationsInterval = setInterval(function() {
            updateButton(secondsLeft++ % 4);

            $.ajax({
                url: '/api/guaranteed/project/check-recommendations-updating',
                method: 'GET',
                data: {
                    id: jsPageData.projectId,
                },
                dataType: 'json',
                success: function(response) {
                    if (response.success) {
                        if (!response.data.isDone) {
                            return;
                        }

                        $buttonUpdateRecommendations.html('<i class="fal fa-redo"></i>&nbsp;&nbsp;Update&nbsp;Recommendations');
                        $buttonUpdateRecommendations.prop('disabled', false);
                        clearInterval(checkRecommendationsInterval);
                        checkRecommendationsInterval = null;
                    } else {
                        console.log(response.message);
                    }
                },
            });
        }, 2000);
    };

    $buttonUpdateRecommendations.click(function(e) {
        e.preventDefault();

        $.ajax({
            url: '/api/guaranteed/project/start-recommendations-updating',
            method: 'POST',
            data: {
                id: jsPageData.projectId,
            },
            dataType: 'json',
        });

        startWaiting();
    });

    if (jsPageData.force_check_recommendations === 1) {
        startWaiting();
    }
};

/** Project Pause */
const initPause = function() {
    $('[data-ajax-action="pause"]').on('click', function() {
        let $button = $(this);

        $button.prev('.error-box').html('').hide();
        $button.prop('disabled', true);

        axios.patch(`${route('api.guaranteed.projects.pause', [jsPageData.projectId])}`).
            then(function(response) {
                if (response.data && response.data.success && response.data.data) {
                    let data = response.data.data;

                    $('.action-panel .action-panel__state').
                        addClass('no-active');
                    $('.action-panel .action-panel__status').
                        html('Paused').
                        addClass('no-active');

                    $('[data-ajax-action="pause"]').
                        hide().
                        next('.tooltip-icon-wrap').
                        hide();
                    $('[data-ajax-action="resume"]').
                        show().
                        next('.tooltip-icon-wrap').
                        show();

                    if (typeof $speedCalc !== 'undefined') {
                        $speedCalc.value = 0;
                    }
                } else if (response.data.message) {
                    $button.prev('.error-box').html(response.data.message).show();
                }

                $button.prop('disabled', false);
            }).
            catch(function() {
                $button.prop('disabled', false);
            }).
            finally(function() {
                document.dispatchEvent(new Event("updateTooltips"));
            });
    });
}

/** Project Resume */
const initResume = function() {
    $('[data-ajax-action="resume"]').on('click', function() {
        let $button = $(this);
        let $errorBox = $button.prev('.error-box');

        $errorBox.html('').hide();
        $button.prop('disabled', true);

        axios.patch(`${route('api.guaranteed.projects.resume', [jsPageData.projectId])}`).
            then(function(response) {
                if (response.data && response.data.success && response.data.data) {
                    let data = response.data.data;

                    $('.action-panel .action-panel__state').
                        removeClass('no-active');
                    $('.action-panel .action-panel__status').
                        html('Active').
                        removeClass('no-active');

                    $('[data-ajax-action="pause"]').
                        show().
                        next('.tooltip-icon-wrap').
                        show();
                    $('[data-ajax-action="resume"]').
                        hide().
                        next('.tooltip-icon-wrap').
                        hide();

                    if (typeof $speedCalc !== 'undefined') {
                        $speedCalc.value = (data.projectSpeed ? data.projectSpeed : 0);
                    }

                    if (parseInt($('.speed-calc-sliders').data('allow')) === 1) {
                        $('.speed-calc-sliders').removeClass('disabled');
                    }

                    $button.prop('disabled', false);
                } else if (response.data.message) {
                    $errorBox.html(response.data.message).show();
                }
            }).
            catch(function() {
                $button.prop('disabled', false);
            }).
            finally(function() {
                document.dispatchEvent(new Event("updateTooltips"));
            });
    });
};

/** Project Renew */
const initRenew = function() {
    let buttonRenew = $('[data-ajax-action="renew"]');

    buttonRenew.on('click', function(e) {
        e.preventDefault();

        buttonRenew.prop('disabled', true);

        $.ajax({
            url: route('api.guaranteed.project.renew'),
            method: 'POST',
            data: {
                id: jsPageData.projectId,
                size: jsPageData.projectSize,
            },
            dataType: 'json',
            success: function(response) {
                if (response.success) {
                    window.location.reload();
                } else {
                    // addNotification(response.message);
                    alert(response.message);
                    buttonRenew.prop('disabled', false);
                }
            },
            error: function() {
                buttonRenew.prop('disabled', false);
            },
        });
    });

    // Animation
    let animSwipeRight = document.querySelector('.lottie-swipe-right');
    let animSwipeLeft = document.querySelector('.lottie-swipe-left');

    if (typeof lottie !== 'undefined') {
        let lottieSwipeRight = lottie.loadAnimation({
            container: animSwipeRight,
            renderer: 'svg',  // svg, canvas, html
            loop: true,
            autoplay: false,
            path: '/img/lottie/swipe-right.json',
        });

        let lottieSwipeLeft = lottie.loadAnimation({
            container: animSwipeLeft,
            renderer: 'svg',  // svg, canvas, html
            loop: true,
            autoplay: false,
            path: '/img/lottie/swipe-left.json',
        });

        lottieSwipeLeft.addEventListener('DOMLoaded', function() {
            lottieSwipeRight.play();
            lottieSwipeLeft.play();
        });
    }
};

const initForms = function() {
    const updateFormFields = function($form) {
        $form.find('input, select, textarea').each(function(index, el) {
            if ($(el).attr('type') === 'checkbox') {
                $(el).data('val', $(el).prop('checked'));
            } else {
                $(el).data('val', $(el).val());
            }
        });
    };

    const getFormFields = function($form) {
        let data = {};

        $form.find('input, select, textarea').each(function(index, el) {
            let fieldName = $(el).attr('name');
            if (fieldName) {
                fieldName = fieldName.replace('[]', '');

                if ($(el).attr('type') === 'checkbox') {
                    data[fieldName] = ($(el).prop('checked') ? 1 : 0);
                } else {
                    data[fieldName] = $(el).val();
                }
            }
        });

        return data;
    };

    let $forms = $('[data-role="guaranteed-project-edit-unlim"] form[data-form-id]');

    if ($forms.length === 0) {
        return;
    }

    $forms.each(function() {
        let $form = $(this);
        let $saveButton = $form.find('[data-ajax-action="save-form"]');
        let isChangesInputs = [];

        updateFormFields($form);

        $form.find('input, select, textarea').on('input change', function() {
            let isChanges = false;

            formValidationErrorClear($form);

            if ($(this).attr('type') === 'checkbox') {
                isChangesInputs[$(this).attr('name')] = $(this).data('val') !== $(this).prop('checked');
            } else {
                isChangesInputs[$(this).attr('name')] = $(this).data('val') !== $(this).val();
            }

            for (let key in isChangesInputs) {
                if (isChangesInputs.hasOwnProperty(key) && isChangesInputs[key]) {
                    isChanges = true;

                    break;
                }
            }

            if (isChanges) {
                $saveButton.find('.button-text').html('Save Changes');
                $saveButton.find('.icon-from-button').hide();
                $saveButton.prop('disabled', false);
            } else {
                $saveButton.find('.button-text').html('Saved');
                $saveButton.find('.icon-from-button').show();
                $saveButton.prop('disabled', true);
            }
        });

        $('[name="respect_time_of_day"]').on('change', function() {
            $('[name="timezone_from_gmt"]').val($(this).val());
        });

        $saveButton.on('click', function(e) {
            e.preventDefault();

            let $button = $(this);
            let $form = $button.closest('form');

            let dataRequest = {
                id: jsPageData.projectId,
                title: $('[name="title"]').data('val'),
                speed: $('[name="speed"]').data('val'),
                bouncerate: $('[name="bouncerate"]').data('val'),
                return_rate: $('[name="return_rate"]').data('val'),
                timeonpage: $('[name="timeonpage"]').data('val'),
                randomize_time: $('[name="randomize_time"]').data('val') ? 1 : 0,
                rss_feed: $('[name="rss_feed"]').data('val'),
                inner_urls_count: $('[name="inner_urls_count"]').data('val'),
                auto_crawl_entry_urls: $('[name="auto_crawl_entry_urls"]').data('val') ? 1 : 0,
                auto_crawl_inner_urls: $('[name="auto_crawl_inner_urls"]').data('val') ? 1 : 0,
                auto_crawl_exit_urls: $('[name="auto_crawl_exit_urls"]').data('val') ? 1 : 0,
                url: $('[name="url"]').data('val'),
                url_second: $('[name="url_second"]').data('val'),
                url_array: $('[name="url_array"]').data('val'),
                url_array_4: $('[name="url_array_4"]').data('val'),
                url_array_5: $('[name="url_array_5"]').data('val'),
                url_array_6: $('[name="url_array_6"]').data('val'),
                url_array_7: $('[name="url_array_7"]').data('val'),
                url_array_8: $('[name="url_array_8"]').data('val'),
                url_array_9: $('[name="url_array_9"]').data('val'),
                url_array_10: $('[name="url_array_10"]').data('val'),
                url_array_11: $('[name="url_array_11"]').data('val'),
                traffic_type: $('[name="traffic_type"]').data('val'),
                referrer: $('[name="referrer"]').data('val'),
                social_links: $('[name="social_links"]').data('val'),
                keywords: $('[name="keywords"]').data('val'),
                desktop_rate: $('[name="desktop_rate"]').data('val'),
                geo_type: $('[name="geo_type"]').data('val'),
                countries: $('[name="countries[]"]').data('val'),
                cities: $('[name="cities[]"]').data('val'),
                respect_time_of_day: $('[name="respect_time_of_day"]').data('val'),
                respect_day_of_week: $('[name="respect_day_of_week"]').data('val') ? 1 : 0,
                timezone_from_gmt: $('[name="timezone_from_gmt"]').data('val'),
                shortener: $('[name="shortener"]').data('val'),
                utm_source: $('[name="utm_source"]').data('val'),
                utm_medium: $('[name="utm_medium"]').data('val'),
                utm_campaign: $('[name="utm_campaign"]').data('val'),
                language: $('[name="language"]').data('val'),
                ga_id: $('[name="ga_id"]').data('val'),
                scroll_events: $('[name="scroll_events"]').data('val'),
                click_outbound_events: $('[name="click_outbound_events"]').data('val'),
                form_submit_events: $('[name="form_submit_events"]').data('val'),
                auto_increase_traffic_volume: $('[name="auto_increase_traffic_volume"]').data('val') ? 1 : 0,
                increase_traffic_volume_every: $('[name="increase_traffic_volume_every"]').data('val'),
                increase_traffic_volume_by: $('[name="increase_traffic_volume_by"]').data('val'),
            };

            let dataSave = getFormFields($form);
            dataRequest = {...dataRequest, ...dataSave};

            $button.find('.button-text').html('Saving...');
            $button.find('.icon-from-button').hide();
            $button.prop('disabled', true);

            $.ajax({
                url: '/api/guaranteed/project/update',
                method: 'POST',
                data: dataRequest,
                dataType: 'json',
                success: function(response) {
                    let data = (response.data ? response.data : []);

                    if (response.success) {
                        $button.find('.button-text').html('Saved');
                        $button.find('.icon-from-button').show();
                        formValidationErrorClear($form);

                        isChangesInputs = [];
                        updateFormFields($form);
                    } else {
                        $button.find('.button-text').html('Save Changes');
                        $button.find('.icon-from-button').hide();
                        $button.prop('disabled', false);

                        if (response.message === 'CREDITS') {
                            window.location.replace(route('cart', '#tab_web_traffic_credits'));
                        }

                        if (data.errorsForm) {
                            formValidationErrorHandling($form, data.errorsForm);
                        }
                    }
                },
                error: function() {
                    $button.find('.button-text').html('Save Changes');
                    $button.find('.icon-from-button').hide();
                    $button.prop('disabled', false);
                },
            });
        });
    });
};

const initDemoIsOver = function() {
    let $demoIsOver = $('.demo-is-over');

    $demoIsOver.find('[data-button-action="yes"]').on('click', function() {
        $demoIsOver.find('.demo-is-over__title').html('Great!');
        $demoIsOver.find('.demo-is-over__subtitle').html('Now, proceed to create a permanent project');

        $demoIsOver.find('[data-button-action="yes"]').hide();
        $demoIsOver.find('[data-button-action="no"]').hide();
        $demoIsOver.find('[data-button-action="create-project"]').show();
    });

    $demoIsOver.find('[data-button-action="no"]').on('click', function() {
        $demoIsOver.find('.demo-is-over__title').html('Oh!');
        $demoIsOver.find('.demo-is-over__subtitle').html('Let us fix it');
        $demoIsOver.find('.demo-is-over__text').show();

        $demoIsOver.find('[data-button-action="yes"]').hide();
        $demoIsOver.find('[data-button-action="no"]').hide();
    });
}

const initFetchUrlsFromRSS = function() {
    let buttonFetchUrls = $('[data-button-action="fetch-urls"]');

    // if ($('[name="rss_feed"]').val() === '') {
    //     buttonFetchUrls.prop('disabled', true);
    // } else {
    //     buttonFetchUrls.prop('disabled', false);
    // }

    buttonFetchUrls.on('click', function() {
        let $button = $(this);

        $button.prop('disabled', true);
        $button.find('.text-from-button').html('Fetching');

        axios.patch(`${route('api.guaranteed.projects.update_urls_from_rss', [jsPageData.projectId])}`).
            then(function(response) {
                let dataResponse = (response.data ? response.data : {});

                if (dataResponse && dataResponse.success) {
                    let data = dataResponse.data;
                    if (data[jsPageData.projectId]) {
                        $('[name="url"]').val(data[jsPageData.projectId].url);
                        $('[name="url_second"]').val(data[jsPageData.projectId].url_second);
                        $('[name="url_array"]').val(data[jsPageData.projectId].url_array);
                    }
                }

                $button.prop('disabled', false);
                $button.find('.text-from-button').html('Fetch');
            }).
            catch(function() {
                $button.prop('disabled', false);
                $button.find('.text-from-button').html('Fetch');
            });
    });
}

const initIncreaseTrafficChangeListener = function() {
    let $autoIncreaseTrafficVolume = $('[name="auto_increase_traffic_volume"]');
    let $increaseTrafficGroup = $('.increase-traffic-group');

    $autoIncreaseTrafficVolume.on('change', function() {
        let state = $(this).prop('checked');
        if (state) {
            $increaseTrafficGroup.show();
        } else {
            $increaseTrafficGroup.hide();
        }
    });
};

const initScheduleChangeListener = function() {
    let $schedule = $('.schedule');
    if ($schedule.length <= 0) {
        return;
    }

    let $startAtCheckbox = $schedule.find('[name="start_at"]');
    let $startAtForChecked = $startAtCheckbox.closest('.form-group').find('.for-checked');
    let $startAtForNoChecked = $startAtCheckbox.closest('.form-group').find('.for-nochecked');
    let $startAtDatetimepicker = $schedule.find('.start-at-datetimepicker');
    let $startAtDatetimepickerInput = $schedule.find('[name="start-at-to"]');

    let $endAtCheckbox = $schedule.find('[name="end_at"]');
    let $endAtForChecked = $endAtCheckbox.closest('.form-group').find('.for-checked');
    let $endAtForNoChecked = $endAtCheckbox.closest('.form-group').find('.for-nochecked');
    let $endAtDatetimepicker = $schedule.find('.end-at-datetimepicker');
    let $endAtDatetimepickerInput = $schedule.find('[name="end-at-to"]');

    let endAtRequestTimeout = null;
    let startAtRequestTimeout = null;

    // Start At
    let startAtPicker = new TempusDominus(document.querySelector('.start-at-datetimepicker .input-datetimepicker'), {
        display: {
            // viewMode: 'clock',
            // inline: true,
            components: {
                decades: false,
                year: false,
                month: true,
                date: true,
                hours: true,
                minutes: true,
                seconds: false,
            },
            icons: {
                time: 'bi bi-clock',
                date: 'bi bi-calendar',
                up: 'bi bi-arrow-up',
                down: 'bi bi-arrow-down',
                previous: 'bi bi-chevron-left',
                next: 'bi bi-chevron-right',
                today: 'bi bi-calendar-check',
                clear: 'bi bi-trash',
                close: 'bi bi-x',
            },
            buttons: {
                today: false,
                clear: false,
                close: false,
            },
        },
        restrictions: {
            daysOfWeekDisabled: [0, 6],
        },
        // keepInvalid: false,
        localization: {
            // dateFormats: DefaultFormatLocalization.dateFormats,
            dayViewHeaderFormat: { month: 'long', year: '2-digit' },
            locale: 'en-EN',
            format: 'dd.MM.yyyy HH:mm',
        },
    });

    $startAtCheckbox.on('change', function() {
        if ($(this).prop('checked')) {
            $startAtForChecked.show();
            $startAtForNoChecked.hide();

            if ($startAtDatetimepickerInput.data('value')) {
                $startAtDatetimepickerInput.
                    val($startAtDatetimepickerInput.data('value')).
                    trigger('change');
            }
        } else {
            $startAtForChecked.hide();
            $startAtForNoChecked.show();

            $startAtDatetimepickerInput.
                data('value', $startAtDatetimepickerInput.val()).
                val('').
                trigger('change');
        }
    });

    $startAtDatetimepickerInput.on('change', function(e) {
        let input = $(this);

        $startAtCheckbox.prop('disabled', true);
        input.prop('disabled', true);

        clearTimeout(startAtRequestTimeout);
        startAtRequestTimeout = setTimeout(function() {
            axios.patch(`${route('api.guaranteed.projects.update_start_at', [jsPageData.projectId])}`, {startAt: input.val()}).
                then(function(response) {
                    $startAtCheckbox.prop('disabled', false);
                    input.prop('disabled', false);
                }).
                catch(function() {
                    $startAtCheckbox.prop('disabled', false);
                    input.prop('disabled', false);
                });
        }, 1000);
    });

    // End At
    let endAtPicker = new TempusDominus(document.querySelector('.end-at-datetimepicker .input-datetimepicker'), {
        display: {
            // viewMode: 'clock',
            // inline: true,
            components: {
                decades: false,
                year: false,
                month: true,
                date: true,
                hours: true,
                minutes: true,
                seconds: false,
            },
            icons: {
                time: 'bi bi-clock',
                date: 'bi bi-calendar',
                up: 'bi bi-arrow-up',
                down: 'bi bi-arrow-down',
                previous: 'bi bi-chevron-left',
                next: 'bi bi-chevron-right',
                today: 'bi bi-calendar-check',
                clear: 'bi bi-trash',
                close: 'bi bi-x',
            },
            buttons: {
                today: false,
                clear: false,
                close: false,
            },
        },
        restrictions: {
            daysOfWeekDisabled: [0, 6],
        },
        // keepInvalid: false,
        localization: {
            // dateFormats: DefaultFormatLocalization.dateFormats,
            dayViewHeaderFormat: { month: 'long', year: '2-digit' },
            locale: 'en-EN',
            format: 'dd.MM.yyyy HH:mm',
        },
    });

    $endAtCheckbox.on('change', function() {
        if ($(this).prop('checked')) {
            $endAtForChecked.show();
            $endAtForNoChecked.hide();

            if ($endAtDatetimepickerInput.data('value')) {
                $endAtDatetimepickerInput.
                    val($endAtDatetimepickerInput.data('value')).
                    trigger('change');
            }
        } else {
            $endAtForChecked.hide();
            $endAtForNoChecked.show();

            $endAtDatetimepickerInput.
                data('value', $endAtDatetimepickerInput.val()).
                val('').
                trigger('change');
        }
    });

    $endAtDatetimepickerInput.on('change', function(e) {
        let input = $(this);

        $endAtCheckbox.prop('disabled', true);
        input.prop('disabled', true);

        clearTimeout(endAtRequestTimeout);
        endAtRequestTimeout = setTimeout(function() {
            axios.patch(`${route('api.guaranteed.projects.update_end_at', [jsPageData.projectId])}`, {endAt: input.val()}).
                then(function(response) {
                    $endAtCheckbox.prop('disabled', false);
                    input.prop('disabled', false);
                }).
                catch(function() {
                    $endAtCheckbox.prop('disabled', false);
                    input.prop('disabled', false);
                });
        }, 1000);
    });
}

export const initGuaranteedProjectEditUnlimScripts = () => {
    runIfHasSelecter('[data-role="guaranteed-project-edit-unlim"]', [
        initGaErrorMessages,
        initModals,
        initExpiration,
        initProjectStarting,
        initChart,
        initTrafficTypeChangeListener,
        initDailyHitsEstimates,
        initScrollEventsRangeSlider,
        initClickOoutboundEventsRangeSlider,
        initFormSubmitEventsRangeSlider,
        initDesktopRateRangeSlider,
        initGeoTypeChange,
        initCheckLinkStatus,
        initCheckUpdateCountersStatus,
        initCheckUpdateBtnRecommendationsStatus,
        initPause,
        initResume,
        initRenew,
        initForms,
        initDemoIsOver,
        initFetchUrlsFromRSS,
        initIncreaseTrafficChangeListener,
        initScheduleChangeListener,
    ]);
};
