<template>
  <div class="sc-project-preview-chart-clicks">
    <div class="title" v-if="!isMobile">
      Clicks
    </div>
    <div class="chart-wrap">
      <div class="chart-title" v-if="!isMobile">
                <span class="chart-title-current">
                  <v-number-short :value="useProject.chartTotalValues"/>
                </span>
        <v-number-diff class="chart-title-diff" :value="useProject.diff_clicks"/>
      </div>
      <div class="chart">
        <chart-seo-clicks-v2 :labels="useProject.chartLabels"
                             :values="useProject.chartValues"
                             :type="useProject.chartValueName"
                             class="chart_component"
                             :count-horizontal-lines="3"
                             :show-labels="!isMobile ? 'at-the-ends-absolute' : ''"/>
      </div>
    </div>
  </div>
</template>

<script setup>

import VNumberShort from "../Base/VNumberShort.vue";
import VNumberDiff from "../Base/VNumberDiff.vue";
import ChartSeoClicksV2 from "../Chart/ChartSeoClicksV2.vue";
import {useSeoClicksProject} from "../../stores/useSeoClicks";
import {computed, getCurrentInstance} from "vue";

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const props = defineProps({
  projectId: {},
});

const useProject = useSeoClicksProject(props.projectId);

</script>

<style lang="scss" scoped>
.sc-project-preview-chart-clicks{
  @include flex(column, start, stretch, 4px);

  @media(max-width:767px){
    @include flex(column, end, stretch, 4px);
  }

  .title{
    color: $dark-40;
    font: $font-mini-12-regu;
    line-height:18px;
    text-align:right;
  }
  .chart-wrap{
    @include flex(column,start,stretch,8px);
  }
  .chart-title{
    @include flex(row,end,center,4px);
    .chart-title-current{
      font:$font-16-semi;
      color:$dark-80;
    }
    .chart-title-diff{
      padding:5px 0px 2px;
      font: $font-mini-12-semi;
      &.is-zero{
        display:none;
      }
    }
  }
  .chart{
    width:100%;
    height:90px;
    position:relative;
    @media(max-width: 767px){
      height: calc(90px - 18px - 4px);
    }
    .chart_component{
      position:absolute;
      width:100%;
      height:100%;
      top:0;
      left:0;
    }
  }

}
</style>