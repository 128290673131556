<template>
  <div class="v-filter-simple vfs" :class="mainClasses">

    <div class="vfs__controller" ref="wrap" @click="refTooltip.toggle()">
      <div class="vfs__icon" :class="hasSelectedFilter ? 'vfs__icon--active' : ''">
        <v-icon-svg name="filter-v2" class="vfs__icon-svg" as-img as-block/>
      </div>
      <div class="vfs__title" v-if="hasButtonTitle">
        {{(buttonTitle === null) ? 'Filters' : buttonTitle}}
      </div>
    </div>

    <template v-if="hasSelected" v-for="(option,key) in model">
      <v-filter-simple-selected-item
          v-model="model[key]"
          @remove="removeOption(key)"
          :skin="skin"
      />
    </template>

    <v-tooltip-select
        ref="refTooltip"
        v-model="model"
        :get-linked-block="() => wrap"
        :options="options"
        :multi-select="true"
        :style="{maxWidth: tooltipMaxWidth}"
        :closeAfterSelection="true"
        :display-as-input-checked="tooltipDisplayAsInputChecked"
        :tooltip-title="tooltipTitle"
        :search-enable="searchEnable"
        :search-title="'Search ' + ((buttonTitle === null) ? '' : buttonTitle)"
    />

  </div>
</template>

<script setup>

import VIconSvg from "./VIconSvg.vue";
import {ref, defineProps, computed} from "vue";
import VTooltipSelect from "./VTooltipSelect.vue";
import VFilterSimpleSelectedItem from "./VFilterSimpleSelectedItem.vue";
import {isArray} from "lodash";

const wrap = ref();
const refTooltip = ref();

const props = defineProps({
  options: {type: Array, default: []},
  hasSelected:{type:Boolean,default:false},
  /**
   * @type {'on-white-bg'|'on-gray-bg'}
   */
  skin: {default: 'on-white-bg'},
  tooltipDisplayAsInputChecked: {type:Boolean,default:false,},
  tooltipMaxWidth: {default:'200px'},
  tooltipTitle: {default:null,},
  buttonTitle: {default:null,},
  hasButtonTitle: {type: Boolean, default:true,},
  searchEnable: {type:Boolean, default:false,}
});

const model = defineModel();

const removeOption = (key) => {
  model.value.splice(key,1);
}

const mainClasses = computed(() => {
  let classes = [];
  classes.push('vfs--skin-'+props.skin);
  if(refTooltip.value?.tooltipOpened){
    classes.push('vfs--tooltip-opened');
  }
  return classes;
});

const hasSelectedFilter = computed(() => {
  return isArray(model.value) && model.value.length > 0;
});

</script>

<style scoped lang="scss">
.v-filter-simple.vfs{
  display: flex;
  flex-wrap:wrap;
  gap: 10px;

  .vfs__controller{
    @include flex(row,center,center,6px);
    padding:0px 10px;
    height:28px;
    background: #F4F9FD;
    border-radius: 50px;
    cursor: pointer;
    min-width:40px;

    .vfs__icon{
      position: relative;
      &.vfs__icon--active{
        &:after{
          content:'';
          display:block;
          position:absolute;
          top:0px;
          right:0px;
          width:6px;
          height:6px;
          border-radius:50%;
          background:$secondary-blue-100;
          border:1px solid $white-100;
        }
      }
      .vfs__icon-svg{
      }
    }
    .vfs__title{
      color:$dark-50;
      font-size:14px;
      line-height:18px;
    }
  }

  &.vfs--skin-on-gray-bg{
    .vfs__controller{
      background:#E0EAF2;
      transition-duration: 0.2s;
      .vfs__title{
        color:$dark-50;
      }
      &:hover{
        background:#D6E3EF;
      }
    }
    &.vfs--tooltip-opened{
      .vfs__controller{
        background:#D6E3EF;
      }
    }
  }

}
</style>