<template>
  <div class="v-icon-svg-wrapped"
       :style="outerStyle">
    <v-icon-svg :name="name"
                :as-img="asImg"
                :as-block="asBlock"
                :style="innerStyle"/>
  </div>
</template>

<script setup>

import VIconSvg from "./VIconSvg.vue";
import {computed} from "vue";
import {isArray} from "lodash";

const props = defineProps({
  /**
   * @type {[number, number]|number} - [width, height] || widthAndHeight
   */
  outerSize: {default:null,},
  /**
   * @type {[number, number]|number} - [width, height] || widthAndHeight
   */
  innerSize: {default:null},
  name:    {type: String, default: undefined,},
  asImg:   {default: false, type: Boolean},
  asBlock: {default: true, type: Boolean},
});

const outerStyle = computed(() => {
  if(isArray(props.outerSize)){
    return {width:`${props.outerSize[0]}px`,height:`${props.outerSize[1]}px`};
  }else{
    return {width:`${props.outerSize}px`,height:`${props.outerSize}px`};
  }
});

const innerStyle = computed(() => {
  if(isArray(props.innerSize)){
    return {width:`${props.innerSize[0]}px`,height:`${props.innerSize[1]}px`};
  }else{
    return {width:`${props.innerSize}px`,height:`${props.innerSize}px`};
  }
});

</script>

<style lang="scss" scoped>
.v-icon-svg-wrapped{
  @include flex(row,center,center);
  flex-shrink:0;
}
</style>