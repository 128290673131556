import {
  addToCart,
  formatNumberK,
  formatPrice,
  getExchangeRate,
  runIfHasSelecter,
  updateCoupon,
  updatePaymentOrder,
  updateVatId,
} from '../../utils/panel-lib';
import {api} from '../../utils/panel-api.js';
import axios from 'axios';
import route from '../index.js';
import _ from 'lodash';

const initCommon = function() {
  api.event.eventPageViewed();

  let orderId = 0;
  let checkOrderStatusTimeout = null;
  let promoTooltipTimeout = null;
  let guaranteedPlansOptions = jsPageData.guaranteedPlansOptions;
  let $inputPlanSize = $('[name="plan_size"]');
  let $inputPlanType = $('[name="guaranteed_plan_type"]');
  let oldCarItems = null;
  let $cartListBlock = $('.cart-step');

  // const updateFastSpringSession = () => {
  //   let params = '';
  //   if (jsPageData.isCartFromOrder && jsPageData.orderId && jsPageData.paymentMethod === 'fastspring') {
  //     params = '?orderId=' + jsPageData.orderId;
  //   }
  //
  //   fetch(route('api.fastspring.create_session') + params, {
  //     method: 'GET',
  //   }).
  //       then(sessionResponse => {
  //         if (!sessionResponse.ok) {
  //           throw new Error('HTTP error ' + sessionResponse.status);
  //         }
  //         $('body').removeClass('loading');
  //         return sessionResponse.json();
  //       }).
  //       then(session => {
  //         // console.log(JSON.stringify(session, null, 2));
  //         if (fastspring) {
  //           // if (session.taxId) {
  //              // fastspring.builder.taxId(session.taxId);
  //           // }
  //
  //           // fastspring.builder.secure({
  //           //   "taxId": session.taxId
  //           // }, session.secureKey);
  //
  //           fastspring.builder.checkout(session.id);
  //         }
  //
  //         checkOrderStatus(parseInt(session.orderId));
  //         $('[data-pay-by="fastspring"]').prop('disabled', false);
  //         $('[data-pay-by="fastspring-popup"]').prop('disabled', false);
  //         $('body').removeClass('loading');
  //       }).
  //       catch(error => {
  //         console.log('There was an error:', error);
  //         $('[data-pay-by="fastspring"]').prop('disabled', false);
  //         $('[data-pay-by="fastspring-popup"]').prop('disabled', false);
  //         $('body').removeClass('loading');
  //       });
  // };

  const updateFastSpringSession = () => {
    let params = '';
    if (jsPageData.isCartFromOrder && jsPageData.orderId && jsPageData.paymentMethod === 'fastspring') {
      params = '?orderId=' + jsPageData.orderId;
    }

    fetch(route('api.fastspring.get_session_data') + params, {
      method: 'GET',
    }).
        then(sessionResponse => {
          if (!sessionResponse.ok) {
            throw new Error('HTTP error ' + sessionResponse.status);
          }
          $('body').removeClass('loading');
          return sessionResponse.json();
        }).
        then(session => {
          // console.log(JSON.stringify(session, null, 2));
          if (fastspring) {
            fastspring.builder.secure(session.securePayload, session.secureKey);
            fastspring.builder.checkout(session.id);
          }

          checkOrderStatus(parseInt(session.orderId));
          $('[data-pay-by="fastspring"]').prop('disabled', false);
          $('[data-pay-by="fastspring-popup"]').prop('disabled', false);
          $('body').removeClass('loading');
        }).
        catch(error => {
          console.log('There was an error:', error);
          $('[data-pay-by="fastspring"]').prop('disabled', false);
          $('[data-pay-by="fastspring-popup"]').prop('disabled', false);
          $('body').removeClass('loading');
        });
  };

  const checkOrderStatus = (orderId) => {
    clearTimeout(checkOrderStatusTimeout);

    if (orderId) {
      axios.get(route('api.order.get_status', [orderId])).
          then(function(response) {
            let data = (response.data ? response.data : {});

            if (data.status) {
              if (data.status === 'pending') {
                checkOrderStatusTimeout = setTimeout(function() {
                  checkOrderStatus(orderId);
                }, 1000);
              } else if (data.status === 'paid') {
                updatePaymentOrder();
              } else if (data.status === 'declined') {
                $('.checkout-block .fastspring-checkout').hide();
                $('.checkout-block .checkout-block-info').show();
              }
            } else {
              checkOrderStatusTimeout = setTimeout(function() {
                checkOrderStatus(orderId);
              }, 1000);
            }
          }).
          catch(function(error) {
            // console.log(error);
            checkOrderStatusTimeout = setTimeout(function() {
              checkOrderStatus(orderId);
            }, 5000);
          });
    }
  };

  const setOldCartItems = () => {
    let $cartList = $('.cart-list');

    oldCarItems = [];
    $cartList.find('.cart-list__item').each(function(index, elem) {
      oldCarItems.push({
        'id': $(this).data('id'),
        'quantity': $(this).data('quantity'),
        'size': $(this).data('size'),
      });
    });
  };

  const startCartQuantityAnimation = () => {
    let $cartList = $('.cart-list');

    if (oldCarItems) {
      $cartList.find('.cart-list__item').each(function(index, elem) {
        let isNewElem = true;

        oldCarItems.forEach(function(item) {
          if (item.id === $(elem).data('id')) {
            isNewElem = false;

            if (item.quantity !== $(elem).data('quantity')) {
              $(elem).addClass('animAddToCart');
            }
          }
        });

        if (isNewElem) {
          $(elem).addClass('animAddToCart');
        }
      });
    }
  };

  // Add to Cart
  $('[data-ajax-action="add-to-cart"]').on('click', function() {
    let planOptionId = parseInt($(this).data('plan-option-id'));
    let planSize = $inputPlanSize.val();
    let planType = $inputPlanType.val();
    let planSizeHits = parseInt($inputPlanSize.data('hits') / 1000);
    let creditType = $(this).data('credit-type');
    let quantity = parseInt($(this).data('quantity'));
    let quantityX = parseInt($(this).data('quantity-x'));

    // if (creditType === 'website-traffic') {
    // let $button = $(this);

    // planType = (planType === 'economy' ? '' : '_' + planType);
    // let planUnlimType = planType.replace('alexa', 'pro');
    // if (planSize === 'unlim') {
    //   planType = planUnlimType;
    // }

    // let selectedFirstKey = _.keys(guaranteedPlansOptions["bySize"][planSize + planType])[0];
    // let selectedPlanOption1 = guaranteedPlansOptions["bySize"][planSize + planType][selectedFirstKey];
    // let selectedPlanOptionId = parseInt(selectedPlanOption1["id"]);

    // let firstKey = _.keys(guaranteedPlansOptions["bySize"]['unlim' + planUnlimType])[0];
    // let planOption1 = guaranteedPlansOptions["bySize"]['unlim' + planUnlimType][firstKey];

    // addToCart(planOptionId, quantity, quantityX, false, 'button');
    // } else {
    // let $button = $(this);
    // let planOptionId = parseInt($(this).data('plan-option-id'));
    // let quantity = parseInt($(this).data('quantity'));

    // addToCart(planOptionId, quantity, quantityX, false, 'button');
    // }

    addToCart(planOptionId, parseInt(quantity), parseInt(quantityX), false, 'button');

    if ($cartListBlock.length > 0) {
      $('html, body').animate({
        scrollTop: $cartListBlock.offset().top - 30,
      }, {
        duration: 150,
        easing: 'linear',
      });
    }
  });

  $('.payment-order').on('click', function(e) {
    // Apply Vat Id
    if ($(e.target).hasClass('button-apply-vat-id') ||
        $(e.target).closest('.button-apply-vat-id').length > 0) {
      updateVatId();
    }

    // Apply Coupon
    if ($(e.target).hasClass('button-apply-coupon') ||
        $(e.target).closest('.button-apply-coupon').length > 0) {
      updateCoupon();
    }
  });

  // Currency
  $('select[name="currency"]').on('change', function () {
    let amount = parseFloat($('.payment-order__total').data('amount'));
    let selectCurrency = $(this).val();
    let selectCurrencySymbol = $(this).find('option:selected').data('symbol');
    let amountCurrency = 0.0;

    if (selectCurrency === 'USD') {
      $('.amount-currency .for-js').html('');
      $('.amount-currency').hide();
    } else {
      getExchangeRate(selectCurrency).then(function(response) {
        if (response.data && response.data.success && response.data.data) {
          let data = response.data.data;
          let rate = data.rate ? data.rate : 1.0;

          amountCurrency = (amount * parseFloat(rate)).toFixed(2);
          $('.amount-currency .for-js').html(selectCurrencySymbol + '&nbsp;' + amountCurrency);
          $('.amount-currency').show();
        }
      });
    }

    $('[data-pay-by="paycg"]').attr('href', $('[data-pay-by="paycg"]').data('href') + '&currency=' + selectCurrency);
    $('[data-pay-by="paymentwall"]').attr('href', $('[data-pay-by="paymentwall"]').data('href') + '&currency=' + selectCurrency);
  });

  $('#bank-transfer-currency').on('change', function() {
    let $proformaLinkEl = $('#proforma-link');
    $proformaLinkEl.attr('href', '/cart/invoice?currency=' +
        $('#bank-transfer-currency').find(':selected').val());
  }).trigger('change');

  $('[name="crypto_asset_id"]').on('change', function() {
    let selectedCoin = $(this).val();
    let cryptoCoins = jsPageData["cryptoCoins"];
    let $cryptoBlockchain = $('[name="crypto_blockchain"]');

    $cryptoBlockchain.find('option').each(function(index, el) {
      let blockchain = $(el).text();

      if (jQuery.inArray(selectedCoin, cryptoCoins[blockchain]) !== -1) {
        $(el).prop('selected', true).show();
      } else {
        $(el).prop('selected', false).hide();
      }
    });
    $cryptoBlockchain.selectpicker('destroy').selectpicker();

    $('[data-pay-by="crypto"]').attr('href', '/cart/do-pay?system=crypto&asset_id=' + selectedCoin + '&blockchain=' + $('[name="crypto_blockchain"]').val());
    $('[data-pay-by="crypto"]').removeClass('disabled');
  }).trigger('change');

  $('[name="crypto_blockchain"]').change(function() {
    let selectedBlockchain = $(this).val();

    $('[data-pay-by="crypto"]').attr('href', '/cart/do-pay?system=crypto&asset_id=' + $('[name="crypto_asset_id"]').val() + '&blockchain=' + selectedBlockchain);
  });

  $('.coupon-action .coupon-action__button').on('click', function() {
    let couponCode = $(this).closest('[data-role]').data('coupon-code');
    let $couponBlock = $('.coupon-block');

    if ($couponBlock.length > 0) {
      $('html, body').animate({
        scrollTop: $couponBlock.offset().top,
      }, {
        duration: 150,
        easing: 'linear',
      });
    }

    $('#coupon_code').val(couponCode);
    $('#apply_coupon').trigger('click');

    return false;
  });

  // Selected Tab
  let $tabEl = $('.buy-credits-nav-tabs a[data-bs-toggle="tab"]');
  $tabEl.each(function(index, elem) {
    if ($(elem).hasClass('active')) {
      if ($(elem).attr('href') === '#tab_web_traffic_credits') {
        $('.promo-cart').hide();
        $('.promo-cart.promo-cart__website-traffic').show();
      } else if ($(elem).attr('href') === '#tab_seo_traffic_credits') {
        $('.promo-cart').hide();
        $('.promo-cart.promo-cart__seo-traffic').show();
      } else if ($(elem).attr('href') === '#tab_backlinks_credits') {
        $('.promo-cart').hide();
        $('.promo-cart.promo-cart__backlinks').show();
      }
    }
  });

  $tabEl.on('shown.bs.tab', function (event) {
    let $tooltipsSlider = $('.tooltip-slider');
    let $tooltipPromo = $('[data-bs-toggle="promo-tooltip"]');

    if ($(event.target).attr('href') === '#tab_web_traffic_credits') {
      // Position point from slider
      $('.guaranteed-sizes__slider-titles .for-js-width').each(function(index, elem) {
        $(elem).css('margin-left', -1 * ($(elem).width() / 2));
      });

      $('.promo-cart').hide();
      $('.promo-cart.promo-cart__website-traffic').show();
      $tooltipPromo.tooltip('update');
      $tooltipsSlider.show();
      if (typeof $('.ui-slider-handle').tooltip === 'function') {
        $('.ui-slider-handle').tooltip('update');
      }
    } else if ($(event.target).attr('href') === '#tab_seo_traffic_credits') {
      $('.promo-cart').hide();
      $('.promo-cart.promo-cart__seo-traffic').show();
      $tooltipPromo.tooltip('update');
      $tooltipsSlider.hide();
    } else if ($(event.target).attr('href') === '#tab_backlinks_credits') {
      $('.promo-cart').hide();
      $('.promo-cart.promo-cart__backlinks').show();
      $tooltipPromo.tooltip('update');
      $tooltipsSlider.hide();
    }
  });

  if (jsPageData.isCartFromOrder && jsPageData.orderId && jsPageData.paymentMethod === 'fastspring') {
    window.scroll({top: document.body.scrollHeight, behavior: 'smooth'});
    $('body').addClass('loading');
    updatePaymentOrder(jsPageData.orderId);
  } else {
    updatePaymentOrder();
  }

  let $cartElements = $('.payment-order');
  $cartElements.on('cart-updating', function() {
    // Promo Tooltip
    $('[data-bs-toggle="promo-tooltip"]').tooltip('dispose');
  });

  $cartElements.on('cart-updated-complete', function(event, data) {
    if (typeof data.source !== 'undefined' && data.source === 'button') {
      startCartQuantityAnimation();
    }
    setOldCartItems();

    if (jsPageData.requestCouponCode && $cartListBlock.length > 0) {
      let $couponBlock = $('.coupon-block');
      let $cartBlock = $('.cart-step');
      let $scrollToEl = $couponBlock

      if ($scrollToEl.length > 0) {
        if ($('#coupon_code').val() === jsPageData.requestCouponCode) {
          $scrollToEl = $cartBlock;
        } else {
          $('#coupon_code').val(jsPageData.requestCouponCode);
          jsPageData.requestCouponCode = null;
        }

        $('html, body').animate({
          scrollTop: $scrollToEl.offset().top,
        }, {
          duration: 150,
          easing: 'linear',
        });
      }
    }

    $('select[name="currency"]').trigger('change');

    if (jsPageData.isCartFromOrder && jsPageData.orderId && jsPageData.paymentMethod === 'fastspring') {
      $('[data-pay-by="fastspring-popup"]').trigger('click');
    } else if (jsPageData.cartId && jsPageData.paymentMethod === 'fastspring') {
      $('[data-pay-by="fastspring-popup"]').trigger('click');
    }

    if ($('.fastspring-iframe-wrap').length > 0) {
      $('[data-pay-by="fastspring"]').trigger('click');
    }

    // Promo Tooltip
    const deviceType = $('[data-device-type]').data('device-type');
    const tooltipTriggerList = document.querySelectorAll(
        '[data-bs-toggle="promo-tooltip"]');

    [...tooltipTriggerList].map(function(tooltipTriggerEl) {
      if (!$(tooltipTriggerEl).is(':visible')) {
        return;
      }

      const template = '<div class="tooltip promo" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>';

      return new bootstrap.Tooltip(tooltipTriggerEl, {
            container: 'body',
            placement: (deviceType === 'phone' || deviceType === 'tablet'
                ? 'bottom'
                : 'top'),
            // fallbackPlacements: ['top', 'bottom', 'right', 'left'],
            fallbackPlacements: ['top', 'bottom'],
            trigger: 'manual',
            template: template,
            sanitize: false,
          },
      );
    });

    $('[data-bs-toggle="promo-tooltip"]').on('shown.bs.tooltip', function(event) {
      let $this = $(this);
      let tooltipId = $this.attr('aria-describedby');
      if (!tooltipId) {
        return;
      }

      $('#' + tooltipId).find('[data-action="tooltip-no"]').
          off('click').
          on('click', function() {
            $this.tooltip('hide');
          });

      $('#' + tooltipId).find('[data-action="tooltip-yes"]').
          off('click').
          on('click', function() {
            let planOptionId = parseInt($(this).data('plan-option-id'));
            let quantity = parseInt($(this).data('quantity'));
            let quantityX = parseInt($(this).data('quantity-x'));

            addToCart(planOptionId, quantity, quantityX, true);

            $this.tooltip('hide');
          });
    });

    clearTimeout(promoTooltipTimeout);
    promoTooltipTimeout = setTimeout(function() {
      $('[data-bs-toggle="promo-tooltip"]:visible').tooltip('show');
    }, 500);
  });

  $('.checkout-block [data-button-action="retry"]').on('click', function() {
    // $('.fastspring-iframe-wrap').empty();
    // $('.fastspring-iframe-wrap').html('<script id="fsc-api" src="https://sbl.onfastspring.com/sbl/1.0.1/fastspring-builder.min.js" type="text/javascript" data-storefront="' + jsPageData.fastspringEmbeddedStorefront  + '" data-debug="false"></script>\n<div id="fsc-embedded-checkout-container" class="position-relative"></div>');

    $('.checkout-block .fastspring-checkout').show();
    $('.checkout-block .checkout-block-info').hide();
    // updateFastSpringSession();
  });

  $('[data-pay-by="fastspring"]').on('click', function() {
    $(this).prop('disabled', true).hide();

    if ($('.fastspring-iframe-wrap').length > 0) {
      $('.fastspring-iframe-popup-wrap').empty();
      $('.fastspring-iframe-wrap').empty();
      $('.fastspring-iframe-wrap').
          html(`<script id="fsc-api" src="https://sbl.onfastspring.com/sbl/1.0.1/fastspring-builder.min.js" type="text/javascript" data-storefront="${jsPageData.fastspringEmbeddedStorefront}" data-access-key="${jsPageData.fastspringAccessKey}" data-debug="false"></script><div id="fsc-embedded-checkout-container" class="position-relative"></div>`);

      updateFastSpringSession();
    }
  });

  $('[data-pay-by="fastspring-popup"]').on('click', function() {
    $(this).prop('disabled', true);

    if ($('.fastspring-iframe-popup-wrap').length > 0) {
      $('.fastspring-iframe-wrap').empty();
      $('.fastspring-iframe-popup-wrap').empty();
      $('.fastspring-iframe-popup-wrap').
          html(`<script id="fsc-api" src="https://sbl.onfastspring.com/sbl/1.0.1/fastspring-builder.min.js" type="text/javascript" data-storefront="${jsPageData.fastspringPopupStorefront}" data-access-key="${jsPageData.fastspringAccessKey}" data-debug="false"></script>`);

      $('[data-pay-by="fastspring"]').prop('disabled', false).show();

      updateFastSpringSession();
    }
  });

  $('[data-button-action="repeat"]').on('click', function() {
    $(this).prop('disabled', true);
    document.location.reload();
  });

  if (jsPageData.isRequestAddToCart && $cartListBlock.length > 0) {
    $('html, body').animate({
      scrollTop: $cartListBlock.offset().top - 30,
    }, {
      duration: 150,
      easing: 'linear',
    });
  }
};

const initPayCommerceGateIframe = function() {
  let $checkoutBlock = $('.commerce-gate-checkout-old');
  let $buttonPay = $checkoutBlock.find('[data-ajax-action="pay-now"]');
  let $iframeWrap = $('.commerce-gate-iframe-wrap');
  let $iframe = $('.commerce-gate-iframe');
  let $cart = $('.payment-order');
  let checkOrderStatusTimeout = null;

  function showErrorBlock() {
    $('.commerce-gate-payment').hide();
    $('.commerce-gate-payment__error').show();
    $('.commerce-gate-payment__success').hide();
  }

  function showSuccessBlock() {
    $('.commerce-gate-payment').hide();
    $('.commerce-gate-payment__error').hide();
    $('.commerce-gate-payment__success').show();
  }

  function checkOrderStatus(orderId) {
    clearTimeout(checkOrderStatusTimeout);

    if (orderId) {
      axios.get(route('api.order.get_status', [orderId])).
          then(function(response) {
            let data = (response.data ? response.data : {});

            if (data.status) {
              if (data.status === 'pending') {
                checkOrderStatusTimeout = setTimeout(function() {
                  checkOrderStatus(orderId)
                }, 1000);
              } else if (data.status === 'paid') {
                showSuccessBlock();
              } else {
                showErrorBlock();
              }
            } else {
              checkOrderStatusTimeout = setTimeout(function() {
                checkOrderStatus(orderId)
              }, 5000);
            }
          }).
          catch(function(error) {
            checkOrderStatusTimeout = setTimeout(function() {
              checkOrderStatus(orderId)
            }, 5000);
          });
    }
  }

  function getPaymentForm() {
    let currency = $('select[name="cg_currency"]').val();

    $buttonPay.prop('disabled', true);

    axios.get(route('api.commerce_gate.get_payment_form_url') + '?currency=' + currency).
        then(function(response) {
          let data = (response.data ? response.data : {});
          let orderId = data?.orderId;
          let formUrl = data?.formUrl;

          $buttonPay.closest('.payment-options__button').hide();
          $iframeWrap.removeClass('hidden');

          if (formUrl && formUrl !== '/') {
            $iframe.attr('src', formUrl);

            if (orderId) {
              checkOrderStatus(orderId);
            }
          }
        }).
        catch(function(error) {
          console.error(error);
          $buttonPay.prop('disabled', false);
        });
  }
  // getPaymentForm();

  $buttonPay.on('click', function() {
    getPaymentForm();
  });

  $('select[name="cg_currency"]').on('change', function () {
    let amount = parseFloat($('.payment-order__total').data('amount'));
    let selectCurrency = $(this).val();
    let selectCurrencySymbol = $(this).find('option:selected').data('symbol');
    let amountCurrency = 0.0;

    if (selectCurrency === 'USD') {
      $('.amount-cg-currency .for-js').html('');
      $('.amount-cg-currency').hide();
    } else {
      getExchangeRate(selectCurrency).then(function(response) {
        if (response.data && response.data.success && response.data.data) {
          let data = response.data.data;
          let rate = data.rate ? data.rate : 1.0;

          amountCurrency = (amount * parseFloat(rate)).toFixed(2);
          let formattedAmountCurrency = amountCurrency;

          if (jsAppData.expSt912) {
            let amountCurrencyArr = formattedAmountCurrency.split('.');

            if (amountCurrencyArr[0] && amountCurrencyArr[1]) {
              if (jsAppData.expSt912 === 'top') {
                formattedAmountCurrency = '<span>' + amountCurrencyArr[0] + '</span><sup class="format-price">.' + amountCurrencyArr[1] + '</sup>';
              } else if (jsAppData.expSt912 === 'bottom') {
                formattedAmountCurrency = '<span>' + amountCurrencyArr[0] + '</span><sub class="format-price">.' + amountCurrencyArr[1] + '</sub>';
              }
            }
          }

          $('.amount-cg-currency .for-js').html(selectCurrencySymbol + '&nbsp;' + formattedAmountCurrency);
          $('.amount-cg-currency').show();
        }
      });
    }

    if (!$iframeWrap.hasClass('hidden')) {
      getPaymentForm();
    }
  });

  $cart.on('cart-updated-complete', function() {
    $('select[name="cg_currency"]').trigger('change');

    $iframeWrap.addClass('hidden').attr('src', '');
    $buttonPay.closest('.payment-options__button').show();
    $buttonPay.prop('disabled', false);
  });
}

export const initCartScriptsOld = () => {
  /**
   * @property {object} jsPageData
   */

  runIfHasSelecter('[data-role="cart"]', [
    initCommon,
    initPayCommerceGateIframe
  ]);
};
