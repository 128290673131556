<template>
  <canvas id="projects-chart-realtime" ref="graph"></canvas>
</template>

<script setup>

import Chart from "chart.js/auto";
import {formatNumber, prepareChartData} from "../../../../utils/panel-lib";
import {onMounted, ref,watch} from "vue";
import axios from "axios";
import {getScssVar} from "../../utils/utils";

const props = defineProps({
  chartData: {}
});

const graph = ref();
let projectsChartRealtime = false;

const base = '{"translations":{"pageViews":"Page Views","hits":"Hits","visits":"Visits","clicks":"Clicks"},"data":[[0,0.1],[0,0.1],[0,0.1],[0,0.1],[0,0.1],[0,0.1],[0,0.1],[0,0.1],[0,0.1],[0,0.1],[0,0.1],[0,0.1],[0,0.1],[0,0.1],[0,0.1],[0,0.1],[0,0.1],[0,0.1],[0,0.1],[0,0.1],[0,0.1],[0,0.1],[0,0.1],[0,0.1],[0,0.1],[0,0.1],[0,0.1],[0,0.1],[0,0.1],[0,0.1]],"totalHits":0,"maxHits":0,"labels":["18 Feb","19 Feb","20 Feb","21 Feb","22 Feb","23 Feb","24 Feb","25 Feb","26 Feb","27 Feb","28 Feb","29 Feb","01 Mar","02 Mar","03 Mar","04 Mar","05 Mar","06 Mar","07 Mar","08 Mar","09 Mar","10 Mar","11 Mar","12 Mar","13 Mar","14 Mar","15 Mar","16 Mar","17 Mar","18 Mar"],"typeTraffic":"clicks","typeChart":"bar"}';
let firstDraw = true;

function updateChart(data) {

  if(!data){return}

  data.data = data.data.map((item) => {
    if(!item || item[1] === 0){
      return 0.1;
    }else{
      return item[1];
    }
  });
  data.typeChart = 'bar';

  drawChart(data);
}
function drawChart(chartData) {
  if (!chartData) {return;}
  let ctxEl = graph.value;
  if (ctxEl) {
    let ctx = ctxEl.getContext('2d');
    let chartConfig = prepareChartData(ctx, chartData, 'normal');   // prj/frontend/src/utils/panel-lib.js
    chartConfig.data.datasets[0].backgroundColor = getScssVar('--seo-traffic-color-light');
    chartConfig.data.datasets[0].borderColor = getScssVar('--seo-traffic-color');
    chartConfig.data.datasets[0].borderWidth = {
      top: 1,
      left: 0,
      right: 0,
      bottom: 0,
    };
    chartConfig.data.datasets[0].barPercentage = 1.1;
    chartConfig.options.plugins.tooltip.enabled = false;
    chartConfig.options.maintainAspectRatio = false;
    chartConfig.options.animation = {duration: 0};
    chartConfig.options.hover = {mode:null};
    chartConfig.options.scales.y.border.color = '#EEF2FB';
    chartConfig.options.scales.x.border.color = '#EEF2FB';
    chartConfig.options.scales.y.border.dash = [];
    chartConfig.options.scales.y.grid.color = 'transparent';

    if (chartConfig) {
      if (projectsChartRealtime) {
        projectsChartRealtime.data = chartConfig.data;
        projectsChartRealtime.options = chartConfig.options;
        projectsChartRealtime.update();
      } else {
        projectsChartRealtime = new Chart(ctx, chartConfig);
      }
    }
  }
}

onMounted(() => {
  updateChart(props.chartData);
})

defineExpose({updateChart});

</script>

<style scoped>

</style>