<template>
  <div class="v-form-checkbox vfc" :class="(value > 0) ? 'checked' : ''" @click="$emit('change-request')">
    <v-icon-svg name="check-mark" class="vfc__icon-check" v-if="value === 2"/>
    <v-icon-svg name="check-minus" class="vfc__icon-check" v-if="value === 1"/>
  </div>
</template>

<script setup lang="ts">
import VIconSvg from "../Base/VIconSvg.vue";
import {ref, watch} from "vue";

const props = defineProps({
  value: {}
});

const emit = defineEmits(['change-request']);

//watch(() => listChecked.value, (value) => {
//  let checkedItems = 0;
//  listChecked.value.forEach((checked) => {
//    if(checked){
//      checkedItems++;
//    }
//  });
//  if(checkedItems === listChecked.value.length){
//    checkboxModel.value = 2;
//  }else if(checkedItems > 0){
//    checkboxModel.value = 1;
//  }else{
//    checkboxModel.value = 0;
//  }
//},{deep:true});
//
//const clickCheckox = () => {
//  let status = true;
//  if(checkboxModel.value === 2){
//    status = false;
//  }
//  listChecked.value.forEach((item,key) => {
//    listChecked.value[key] = status;
//  });
//}

</script>

<style scoped lang="scss">
.v-form-checkbox.vfc{
  background-color: #fff;
  width:16px;
  height:16px;
  border-radius: 4px;
  border:1px solid #D6E3EF;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  .vfc__icon-check{
    display: block;
    opacity:0;
  }
  .vfc__icon-minus{}
  input{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    opacity:0;
    cursor: pointer;
  }

  &.checked{
    background: #1C7EE0;
    border-color:#1C7EE0;
    .vfc__icon-check{
      opacity:1
    }
  }

}
</style>