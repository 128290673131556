<template>
  <v-container class="sc-project scp">

    <v-breadcrumbs class="breadcrumbs" :items="breadcrumbs" no-padding/>

    <bc-balance :credit-type="CREDIT_TYPE_SEO"/>

    <div class="main-content">

      <sc-project-demo-bar class="main-content__demo-bar" v-if="useProject.is_demo && !isMobile" :project-uniquekey="getProjectId()"/>

      <v-white-container
          class="main-content__wrap"
          :class="mainContentWrapClasses"
      >

        <sc-project-demo-blackout v-if="useProject.is_demo && useProject.demoCompleted && !isMobile" :project-id="useProject.id"/>
        <sc-project-balance-blackout v-if="useProject.insufficientBalance && !useProject.is_demo"/>

        <div class="main-content__top">

          <div class="main-title-tag" v-if="useProject.is_demo">
            DEMO PROJECT
          </div>

          <v-form-string-input
              data-name="project-name"
              label="Project name"
              :value="useProject.name"
              ref="inputProjectName"
              @save="saveProjectName"
              required
          >
            <template v-slot:default>
              <v-head-title>
                {{useProject.name}}
              </v-head-title>
            </template>
          </v-form-string-input>

          <v-form-string-input
              data-name="website"
              label="Website"
              :value="useProject.url"
              ref="inputProjectUrl"
              @save="saveProjectUrl"
              required
          >
            <template v-slot:default>
              <div>
                <v-link :href="useProject.correctUrl" target="_blank" rel="noopener noreferrer">{{useProject.shortUrl}}</v-link>
              </div>
            </template>
          </v-form-string-input>

        </div>

        <sc-project-demo-bar class="main-content__demo-bar-mobile" v-if="useProject.is_demo && isMobile" :project-uniquekey="getProjectId()"/>

        <v-white-container class="main-content__bottom" level-out>
          <template #level-out-outer-before>
            <sc-project-demo-blackout v-if="useProject.is_demo && useProject.demoCompleted && isMobile" :project-id="useProject.id"/>
          </template>
          <template #level-out-inner>
            <div class="main-content__bottom-inner">

              <sc-project-info
                  :project-id="useProject.id"
                  @recommendation-group-click="blockProjectKeywordsWrap.scrollIntoView({behavior: 'smooth'})"
                  v-scroll-anchor
                  data-scroll-id="settings"
              />

              <sc-project-add-keywords
                  class="main-content__add-keywords"
                  :project-id="useProject.id"
                  :website="useProject.url"
                  @updated="updateKeywords"
              />

              <div class="main-content__keywords-wrap">

                <div class="keywords-filter">
                  <v-search-bar placeholder="Search keywords" v-model="searchKeyword"/>
                  <div class="keywords-filter__options" :class="(filterSimpleSelected.length > 0) ? 'has-filter-simple' : ''">
                    <v-filter-simple :has-selected="true" :options="useKeywords._typesFilter" v-model="filterSimpleSelected"></v-filter-simple>
                    <v-sort-by-simple :options="useKeywords._typesSortBy" v-model="sortBySimpleSelected" class="keywords-filter__sort"></v-sort-by-simple>
                  </div>
                </div>

                <div class="main-content__keywords"
                     ref="blockProjectKeywordsWrap"
                     v-scroll-anchor
                     data-scroll-id="keywords"
                >
                  <sc-project-keywords
                      :project-id="useProject.id"
                      ref="blockProjectKeywords"
                  />
                </div>

              </div>

            </div>
          </template>
        </v-white-container>


      </v-white-container>

    </div>

    <v-button-delete @click="deleteModalConfirm.confirm();" class="delete-project">Delete Project</v-button-delete>

    <v-modal-confirm ref="deleteModalConfirm" @confirm="deleteProject">
      <template v-slot:title>
        Delete Project
      </template>
      <template v-slot:description>
        Do you really want to delete a project ? <br>All keywords will be lost
      </template>
    </v-modal-confirm>

  </v-container>
</template>

<script setup>

import VBreadcrumbs from "../Base/VBreadcrumbs.vue";
import VContainer from "../Base/VContainer.vue";
import VHeadTitle from "../Base/VHeadTitle.vue";
import {computed, getCurrentInstance, ref, watch} from "vue";
import VLink from "../Base/VLink.vue";
import ScProjectInfo from "./ScProjectInfo.vue";
import VFilterSimple from "../Base/VFilterSimple.vue";
import VSortBySimple from "../Base/VSortBySimple.vue";
import VSearchBar from "../Base/VSearchBar.vue";
import ScProjectKeywords from "./ScProjectKeywords.vue";
import VFormStringInput from "../VForm/VFormStringInput.vue";
import ScProjectAddKeywords from "./ScProjectAddKeywords.vue";
import {useSeoClicksKeywords, useSeoClicksProject} from "../../stores/useSeoClicks.js";
import VButtonDelete from "../Base/VButtonDelete.vue";
import VModalConfirm from "../Base/VModalConfirm.vue";
import {useRoutes} from "../../stores/useRoutes.js";
import BcBalance from "../BuyCredits/BcBalance.vue";
import {CREDIT_TYPE_SEO} from "../../stores/useBilling";
import ScProjectDemoBar from "./ScProjectDemoBar.vue";
import ScProjectDemoBlackout from "./ScProjectDemoBlackout.vue";
import VWhiteContainer from "../Base/VWhiteContainer.vue";
import ScProjectBalanceBlackout from "./ScProjectBalanceBlackout.vue";

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const searchKeyword = ref();

const blockProjectKeywords = ref();
const blockProjectKeywordsWrap = ref();

const getProjectId = () => {
  const url = window.location.pathname; // Получаем текущий URL из адресной строки
  const segments = url.split('/'); // Разделяем URL на сегменты
  return segments.pop();
}

const useProject = useSeoClicksProject(getProjectId());
const useKeywords = useSeoClicksKeywords(getProjectId());
useKeywords.download();

const downloadUseProject = () => {
  useProject.download().then(() => {
    if(useProject.is_demo && !useProject.demoCompleted){
      setTimeout(() => {
        downloadUseProject();
      },2000);
    }
  });
}
downloadUseProject();

const inputProjectName = ref();
const saveProjectName = async (name) => {
  let [status,message] = await useProject.saveName(name);
  status ? inputProjectName.value.saveWithSuccess() : inputProjectName.value.saveWithError(message);
}

const inputProjectUrl = ref();
const saveProjectUrl = async (url) => {
  let [status,message] = await useProject.saveUrl(url);
  status ? inputProjectUrl.value.saveWithSuccess() : inputProjectUrl.value.saveWithError(message);
}

const breadcrumbs = computed(() => [
  {url: '/', title: 'SEO Traffic',},
  {url: useRoutes().url('seo-clicks.list'), title: 'My Projects',},
  {title: useProject.name,}
]);

const filterSimpleSelected = ref([]);
const sortBySimpleSelected = ref(useKeywords._typesSortBy[0]);

const updateKeywords = () => {
  blockProjectKeywords.value.updateKeywords();
}

const deleteModalConfirm = ref();
const deleteProject = async () => {
  let [status,message] = await useProject.saveIsDeleted(1);
  window.location.href=useRoutes().url('seo-clicks.list');
  status ? deleteModalConfirm.value.closeWithSuccess() : deleteModalConfirm.value.closeWithError(message);
}

useKeywords.setFilters({
  filter: filterSimpleSelected.value,
  sortBy: sortBySimpleSelected.value,
  searchKeyword: searchKeyword.value
})

watch(() => filterSimpleSelected.value,() => {
  useKeywords.setFilters({filter: filterSimpleSelected.value});
},{deep:true});

watch(() => sortBySimpleSelected.value,() => {
  useKeywords.setFilters({sortBy: sortBySimpleSelected.value});
},{deep:true});

watch(() => searchKeyword.value,() => {
  useKeywords.setFilters({searchKeyword: searchKeyword.value})
},{deep:true});

const mainContentWrapClasses = computed(() => {
  let response = [];

  if(useProject.is_demo) response.push('is-demo')
  if(useProject.is_demo && useProject.demoCompleted) response.push('is-demo-completed');
  if(useProject.insufficientBalance) response.push('is-insufficient-balance');

  return response;
});

</script>

<style scoped lang="scss">
.sc-project.scp{
  @include flex(column,start,stretch,20px);
  max-width:1160px;
  margin-bottom:30px;

  @media(max-width:767px){
    padding-bottom:70px;
  }

  .breadcrumbs{
    @media(max-width: 991px){
      padding-top:10px;
    }
  }

  .main-content{

    .main-content__demo-bar{
      border-bottom-left-radius:0px;
      border-bottom-right-radius:0px;
    }

    .main-content__wrap{
      @include flex(column,start,stretch,30px);
      max-width: 1100px;
      padding-bottom:0px;
      @media(max-width:767px){
        @include flex(column,start,stretch,20px);
      }

      &.is-insufficient-balance{
        position:relative;
        overflow:hidden;
      }

      &.is-demo.is-demo-completed{
        position:relative;
        overflow:hidden;
        .main-content__bottom{
          margin-top:-7px;
          padding-top:7px;
          position:relative;
          overflow:hidden;
        }
      }

      @media(min-width:768px){
        &.is-demo{
          border-top-left-radius:0px;
          border-top-right-radius:0px;
        }
      }
    }

    .main-content__top {
      @include flex(column,start,stretch,9px);
      @media(max-width:767px){
        @include flex(column,start,stretch,4px);
      }
    }

    .main-content__bottom-inner{
      @include flex(column,start,stretch,30px);
      padding-bottom:20px;
      @media(max-width:767px){
        @include flex(column,start,stretch,20px);
      }
    }

    .main-content__add-keywords{
      position:relative;
      z-index:5000;
    }

    .main-content__keywords-wrap{
      @include flex(column,start,stretch,20px);
      @media(max-width: 767px){
        gap:16px;
      }
    }

    .main-content__keywords{
      margin-left:-30px;
      margin-right: -30px;
      @media(max-width:$mobile-width){
        margin-left:0px;
        margin-right: 0px;
      }
    }

  }

  .main-title-tag{
    font-size: 11px;
    font-weight: 600;
    color:#AEB7C7;
  }

  .keywords-filter{
    @include flex(column,start,stretch,20px);

    .keywords-filter__options{
      @include flex(row,between,center);

      &.has-filter-simple{
        @media(max-width:767px) {
          @include flex(column, start, start, 20px);

          .keywords-filter__sort {
            align-self: flex-end;
          }
        }

      }

    }

  }

  .delete-project{
    align-self:flex-start;
  }

}
</style>