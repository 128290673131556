import {runIfHasSelecter} from "../../utils/panel-lib";
import { api } from "../../utils/panel-api";

const initScripts = function () {
    const $historyTable = $('#billing-history-table');
    const $showOnlyPayments = $('#show-only-payments');
    const $searchInput = $('.search-box input[type="search"]');
    const $loadingBlock = $('.loading-block');

    $historyTable.dataTable({
        responsive: true,
        processing: true,
        dom: "<'row'<'col-sm-12 col-md-6 d-flex align-items-center justify-content-start'f><'col-sm-12 col-md-6 d-flex align-items-center justify-content-end'B>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 d-none'i><'col-12'p>>",
        order: [[0, "desc"]],
        buttons: [],
        columns: [
            {"width": "15%"},
            {"width": "10%"},
            {"width": "20%"},
            {"width": "10%"},
            {"width": "35%", orderable: false},
            {"width": "10%", orderable: false}
        ],
        columnDefs: [
            { responsivePriority: 1, targets: 0 },
            { responsivePriority: 2, targets: 4 },
            { responsivePriority: 3, targets: 1 },
            { responsivePriority: 4, targets: 2 },
            { responsivePriority: 5, targets: 3 },
            { responsivePriority: 6, targets: 5 },
        ],
        createdRow: function (row, data) {
            if (parseInt(data.is_decline) === 1) {
                $(row).addClass('bg-red-light');
            }
        }
    });

    $("#show-only-payments").prop('checked', jsPageData.billingShowOnlyPayments);

    function getShowOnlyParameter() {
        return $showOnlyPayments.is(":checked") ? 1 : 0;
    }

    $showOnlyPayments.change(function () {
        updateBillingHistory();

        api.user.saveInterfaceSettings({
            billingShowOnlyPayments: getShowOnlyParameter(),
        });
    });

    // Search
    let localStorageSearch;
    try {
        localStorageSearch = window.localStorage.getItem('st-billing-history-table-search');
    } catch (e) {
        console.log('Error localStorage.getItem: ' + e.responseText);
    }

    if (localStorageSearch !== null && localStorageSearch !== 'null' && localStorageSearch) {
        $searchInput.val(localStorageSearch);
    }

    $searchInput.on('keyup search', function () {
        $historyTable.DataTable().search($(this).val()).draw();
        window.localStorage.setItem('st-billing-history-table-search', $(this).val());
    });

    updateBillingHistory();

    function updateBillingHistory() {
        const url = "/api/billing/history?showOnlyPayments=" + getShowOnlyParameter();

        $historyTable.DataTable().search($searchInput.val()).ajax.url(url);

        /** DataTables before receiving the data */
        $historyTable.on('preXhr.dt', function () {
            $loadingBlock.addClass('processing');
        });

        /** DataTables after receiving the data */
        $historyTable.on('xhr.dt', function () {
            $loadingBlock.removeClass('processing');
            $('body').removeClass('loading');
        }).DataTable().ajax.reload();
    }
}

export const initBillingHistoryScripts = () => {
    /**
     * @property {object} jsPageData
     */

    runIfHasSelecter('[data-role="billing-history"]', [
        initScripts
    ]);
}
