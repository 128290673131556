<template>
  <v-container class="sc-projects-page">

    <v-breadcrumbs :items="breadcrumbs" no-padding/>

    <bc-balance :credit-type="CREDIT_TYPE_BACKLINK"/>

    <v-head-title>My Projects</v-head-title>

    <v-search-bar v-if="!isMobile && projectsIds.length > 0"
                  skin="gray-on-gray"
                  placeholder="Search by ID, date or description"
                  v-model="searchProjectStr"/>

    <div class="controls" v-if="projectsIds.length > 0 && !isMobile">
      <v-button color="green"
                tag="a"
                :href="useRoutes().url('backlinks-project.add')"
                text="+ Add project" />
      <div class="filters">

        <v-filter-simple :has-selected="false"
                         skin="on-gray-bg"
                         v-model="filterDomains"
                         :options="useLinkBuildProjects().listDomainsForSelect"
                         tooltip-display-as-input-checked
                         button-title="Websites"
                         search-enable/>

        <v-filter-simple :has-selected="false"
                         skin="on-gray-bg"
                         v-model="filterKeywords"
                         :options="useLinkBuildProjects().listKeywordsForSelect"
                         tooltip-display-as-input-checked
                         button-title="Keywords"
                         search-enable/>

        <v-filter-simple :has-selected="false"
                         skin="on-gray-bg"
                         v-model="filterTypes"
                         :options="filterTypesOptions"
                         button-title="Types"
                         tooltip-display-as-input-checked/>

        <v-sort-by-simple :options="typesSortBy"
                          v-model="sortBySimpleSelected"
                          v-model:sorting-direct="sortBySimpleDirect"
                          class="filter-sort"
                          arrow-sorting-direct/>

      </div>
    </div>

    <div class="list" v-show="projectsIdsFiltered.length > 0">
      <template v-for="uniquekey in projectsIdsSorted" :key="uniquekey">
        <lb-project-preview v-if="!isMobile"
                            :project-uniquekey="uniquekey"
                            v-show="projectsIdsFiltered.includes(uniquekey)"/>
        <lb-projects-preview v-if="isMobile"
                            :project-uniquekey="uniquekey"/>
      </template>
    </div>

    <v-not-found-projects v-if="projectsIds.length === 0"
                          :link="useRoutes().url('backlinks-project.add')"/>

    <div class="footer">
      {{projectsIdsFiltered.length}} Link-Building {{pluralize('project', projectsIdsFiltered.length)}}
    </div>

  </v-container>
</template>

<script setup>
import VContainer from "../Base/VContainer.vue";
import VBreadcrumbs from "../Base/VBreadcrumbs.vue";
import {CREDIT_TYPE_BACKLINK} from "../../stores/useBilling";
import BcBalance from "../BuyCredits/BcBalance.vue";
import VHeadTitle from "../Base/VHeadTitle.vue";
import VButton from "../Base/VButton.vue";
import {computed, getCurrentInstance, reactive, ref, watch} from "vue";
import {useRoutes} from "../../stores/useRoutes";
import VSearchBar from "../Base/VSearchBar.vue";
import VSortBySimple from "../Base/VSortBySimple.vue";
import {
  useLinkBuildProject,
  useLinkBuildProjects
} from "../../stores/useLinkBuilding";
import VFilterSimple from "../Base/VFilterSimple.vue";
import VNotFoundProjects from "../Base/VNotFoundProjects.vue";
import LbProjectPreview from "./LbProjectPreview.vue";
import {isValidDate, parseDateString, pluralize} from "../../utils/utils";
import {intersection, toString} from "lodash";
import LbProjectsPreview from "./LbProjectsPreview.vue";

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const breadcrumbs = ref([
  {'url': useRoutes().url('backlinks-project.list'), 'title': 'Link-building Projects'},
  { 'title': 'My Projects'}
]);

useLinkBuildProjects().download().then(async () => {
  let dateTo = new Date();
  let dateFrom = new Date(dateTo.getFullYear(), dateTo.getMonth() - 11, 1);
  useLinkBuildProjects().downloadProjectsOrders(dateFrom,dateTo);
});

const projectsIds = computed(() => useLinkBuildProjects().list.map((item) => item.uniquekey));

const searchProjectStr = ref('');
const filterDomains = reactive([]);
const filterKeywords = reactive([]);
const filterTypes = reactive([]);
const filterTypesOptions = reactive([
  {value: 'show_deleted', name: 'Show deleted',},
  {value: 'show_inactive', name: 'Show inactive',},
]);

const projectsIdsFilteredTypes = computed(() => {
  let showInactive = filterTypes.some((type) => 'show_inactive' === type.value);
  let showDeleted = filterTypes.some((type) => 'show_deleted' === type.value);
  return projectsIds.value.filter((uniquekey) => {
    let project = useLinkBuildProject(uniquekey);
    if(!showDeleted && project.is_deleted) return false;
    if(!showInactive && !project.is_active) return false;
    return true;
  });
});

const projectsIdsFilteredDomains = computed(() => {
  if(filterDomains.length === 0) return projectsIds.value;
  return projectsIds.value.filter((uniquekey) => {
    return filterDomains.some((domain) => useLinkBuildProject(uniquekey).domain === domain.value)
  });
});

const projectsIdsFilteredKeywords = computed(() => {
  if(filterKeywords.length === 0) return projectsIds.value;
  return projectsIds.value.filter((uniquekey) => {
    return filterKeywords.some((keyword) => useLinkBuildProject(uniquekey).keyword === keyword.value);
  });
});

const projectsIdsFilteredStr = computed(() => {

  let searchStr = searchProjectStr.value.trim().toLowerCase();
  if(searchStr.length === 0) return projectsIds.value;

  let searchStrAsDate = parseDateString(searchStr);
  searchStrAsDate = isValidDate(searchStrAsDate) ? searchStrAsDate.toISOString().split('T')[0] : null;


  return projectsIds.value.filter((projectId) => {
    return (
        toString(useLinkBuildProject(projectId).uniquekey).toLowerCase().includes(searchStr)
        || toString(useLinkBuildProject(projectId).name).toLowerCase().includes(searchStr)
        || toString(useLinkBuildProject(projectId).category?.name).toLowerCase().includes(searchStr)
        || toString(useLinkBuildProject(projectId).url).toLowerCase().includes(searchStr)
        || (searchStrAsDate && useLinkBuildProject(projectId).createdDateStringISO && searchStrAsDate === useLinkBuildProject(projectId).createdDateStringISO)
    );
  });
});

const projectsIdsFiltered = computed(() => {
  return intersection(
      projectsIdsFilteredTypes.value,
      projectsIdsFilteredDomains.value,
      projectsIdsFilteredKeywords.value,
      projectsIdsFilteredStr.value,
  );
});

const typesSortBy = reactive([
  {value: 'date_created', name: 'Date created',},
  {value: 'name', name: 'Name',},
]);
const sortBySimpleSelected = ref(typesSortBy[0]);
const sortBySimpleDirect = ref('desc');
const sortedFunction = computed(() => {
  if(sortBySimpleDirect.value === 'asc'){
    if(sortBySimpleSelected.value.value === 'name'){
      return (a,b) => a.name.localeCompare(b.name);
    }else if(sortBySimpleSelected.value.value === 'date_created'){
      return (a,b) => new Date(a.createdAt) - new Date(b.createdAt);
    }
  }else{
    if(sortBySimpleSelected.value.value === 'name'){
      return (a,b) => b.name.localeCompare(a.name);
    }else if(sortBySimpleSelected.value.value === 'date_created'){
      return (a,b) => new Date(b.createdAt) - new Date(a.createdAt);
    }
  }
});
const projectsIdsSorted = computed(() => {
  return projectsIds.value.sort((a,b) => sortedFunction.value(useLinkBuildProject(a), useLinkBuildProject(b)));
});

</script>

<style lang="scss" scoped>
.sc-projects-page{
  @include flex(column,start,stretch,24px);
  padding-bottom:100px!important;
  @media (max-width: 767px){
    gap:16px;
  }
  .list{
    @include flex(column,start,stretch,20px);
    @media (max-width: 767px){
      gap:16px;
    }
  }
  .footer{
    color:$dark-30;
    text-align:center;
  }
  .controls{
    @include flex(row,between,center);
  }
  .filters{
    @include flex(row,end,center,11px);
  }
  .filter-sort{
    color:$dark-50;
  }
  .project-preview-old{
    margin-bottom:0px;
  }
}
</style>