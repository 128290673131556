<template>
  <div class="sc-project-preview-countries">
    <div class="title">
      PV TOP 5 Countries
    </div>
    <div class="timer" v-if="Object.keys(useProject.clicks_by_country).length === 0">
      <v-icon-svg name="timer" class="timer-icon"/>
    </div>
    <div class="countries-table-wrap" v-else>
      <table class="countries-table" v-for="countryGroup in countryGroups">
        <tr v-for="[countryCode,countryData] in countryGroup">
          <td class="countries-table__flag-group">
                <span>
                  <v-icon-flag :code="countryCode"/> {{countryCode}}
                </span>
          </td>
          <td class="countries-table__number">
            <v-number-short :value="countryData.count"/>
          </td>
          <td class="countries-table__diff">
            <v-number-diff :value="countryData.diff"/>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script setup>

import VIconSvg from "../Base/VIconSvg.vue";
import VNumberShort from "../Base/VNumberShort.vue";
import VIconFlag from "../Base/VIconFlag.vue";
import VNumberDiff from "../Base/VNumberDiff.vue";
import {useSeoClicksProject} from "../../stores/useSeoClicks";
import {computed, getCurrentInstance} from "vue";
import {chunk} from "lodash";
const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const props = defineProps({
  projectId: {},
});

const useProject = useSeoClicksProject(props.projectId);

const countryGroups = computed(() => {
  if(!isMobile.value){
    return [Object.entries(useProject.clicks_by_country)];
  }
  return chunk(Object.entries(useProject.clicks_by_country), 3);
});

</script>

<style lang="scss" scoped>
.sc-project-preview-countries{
  @include flex(column,start,stretch,10px);

  .title{
    color: $dark-40;
    font: $font-mini-12-regu;
    line-height:18px;
    text-align:left;
    width:100%;
  }
  .timer{
    @include flex(row,center,center);
    width:100%;
    flex-grow:2;
  }

  .countries-table-wrap{
    @include flex(row,between,start,26px);
    width:100%;
  }

  .countries-table{
    table-layout: fixed;
    flex-grow: 2;

    tr{
      td{padding-bottom:9px;}
      &:last-child{ td{padding-bottom:0px;} }
    }

    .countries-table__flag-group{
      text-transform:uppercase;
      font:$font-mini-12-regu;
      color:$dark-50;
      span{
        @include flex(row,start,center,4px);
      }
      text-align:left;
    }

    .countries-table__number{
      width:40px;
      font:$font-mini-12-regu;
      color:$dark-50;
      text-align:right;
      @media(max-width: 767px){
        font:$font-mini-12-semi;
      }
    }

    .countries-table__diff{
      width:32px;
      text-transform:uppercase;
      font:$font-mini-12-regu;
      text-align:right;
    }

  }

}
</style>