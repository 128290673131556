import {
  formatPrice,
  runIfHasSelecter,
} from '../../utils/panel-lib';
import _, {forEach} from 'lodash';

const initCommon = function () {
  let $cart = $('.payment-order');
  let $backlinksOptionsWrap = $('.backlinks-options-wrap');
  let backlinksPlansOptions = jsPageData.backlinksPlansOptions;
  let $rangePlanSizes = $('#range_plan_sizes');
  let $inputPlanSize = $('[name="backlinks_plan_size"]');
  let $inputSelectQuantity = $('[name="backlinks_select_quantity"]');

  function initPlusMinusToCart() {
    let $quantityInputNumber = $('.backlinks-options .quantity-plus-minus .input-number');
    let $quantityBtnNumber = $('.backlinks-options .quantity-plus-minus .btn-number');

    $quantityBtnNumber.on('click', function(e) {
      e.preventDefault();

      let fieldName = $(this).data('field');
      let type = $(this).data('type');
      let input = $('.backlinks-options .quantity-plus-minus input[name="' + fieldName + '"]');
      let currentVal = parseInt(input.val());

      if (!isNaN(currentVal)) {
        if (type === 'minus') {
          if (currentVal > parseInt(input.attr('min'))) {
            input.val(currentVal - 1).change();
          }
          if (parseInt(input.val()) === parseInt(input.attr('min'))) {
            $(this).prop('disabled', true);
          }
        } else if (type === 'plus') {
          if (currentVal < parseInt(input.attr('max'))) {
            input.val(currentVal + 1).change();
          }
          if (parseInt(input.val()) === parseInt(input.attr('max'))) {
            $(this).prop('disabled', true);
          }
        }
      } else {
        input.val(0);
      }
    });

    $quantityInputNumber.on('focusin', function() {
      $(this).data('oldValue', $(this).val());
    });

    $quantityInputNumber.on('change', function() {
      let minValue = parseInt($(this).attr('min'));
      let maxValue = parseInt($(this).attr('max'));
      let valueCurrent = parseInt($(this).val());
      let name = $(this).attr('name');
      let needUpdateCart = true;

      if (valueCurrent >= minValue) {
        $('.backlinks-options .quantity-plus-minus .btn-number[data-type="minus"][data-field="' + name + '"]').
            prop('disabled', false);
      } else {
        $(this).val($(this).data('oldValue'));
      }
      if (valueCurrent <= maxValue) {
        $('.backlinks-options .quantity-plus-minus .btn-number[data-type="plus"][data-field="' + name + '"]').
            prop('disabled', false);
      } else {
        $(this).val($(this).data('oldValue'));
      }

      if ($(this).val() > 1) {
        $('.backlinks-options .quantity-plus-minus .btn-number[data-type="minus"]').prop('disabled', false);
      } else {
        $('.backlinks-options .quantity-plus-minus .btn-number[data-type="minus"]').prop('disabled', true);
      }

      $('[name="backlinks_plan_option_id"]').val($(this).data('plan_option_id'));
      $('[name="backlinks_select_quantity"]').val($(this).val());
      $(this).closest('.backlinks-options__container.plus-minus').find('[data-ajax-action="add-to-cart"]').data('quantity', $(this).val());
      $(this).closest('.plan-option').find('.cart-toss-number .number').text($(this).val());

      if ($(this).val() > 1) {
        $(this).closest('.plan-option').find('.promo-quantity').show().text('+' + parseInt($(this).val() / 2) + ' Free');
      } else {
        $(this).closest('.plan-option').find('.promo-quantity').hide().text('+' + parseInt($(this).val() / 2) + ' Free');
      }

      updateData();
    });

    $quantityInputNumber.on('keydown', function(e) {
      // Allow: backspace, delete, tab, escape, enter and .
      if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 190]) !== -1 ||
          // Allow: Ctrl+A
          (e.keyCode === 65 && e.ctrlKey === true) ||
          // Allow: home, end, left, right
          (e.keyCode >= 35 && e.keyCode <= 39)) {
        // let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
          (e.keyCode < 96 || e.keyCode > 105)) {
        e.preventDefault();
      }
    });
  }

  function updateData() {
    let planSize = $inputPlanSize.val();
    let selectQuantity = parseInt($inputSelectQuantity.val());

    $('.backlinks-plans__header[data-plan-size], .backlinks-plans__item[data-plan-size], .backlinks-plans__features[data-plan-size]').removeClass('active');
    $('.backlinks-plans__header[data-plan-size="' + planSize + '"], .backlinks-plans__item[data-plan-size="' + planSize + '"], .backlinks-plans__features[data-plan-size="' + planSize + '"]').addClass('active');

    // try {
    //   window.localStorage.setItem('st-backlinks-plan-size', planSize);
    //   window.localStorage.setItem('st-backlinks-plan-range', planSizeRangeValue);
    // } catch (e) {
    //   console.log('Error localStorage.setItem: ' + e.responseText);
    // }

    let selectPlanOptionId = $('.backlinks-options .backlinks-options_' + planSize + ' .backlinks-options__container.plus-minus').data('plan-option-id');
    let planOptionId = parseInt(selectPlanOptionId);
    let planOption = backlinksPlansOptions["byId"][planOptionId];
    // let planId = backlinksPlansOptions["byId"][planOptionId].plan_id;
    let planOptionName = planOption.plan_name;
    let planOptionQuantity = parseInt(planOption.quantity);
    let planOptionSelectQuantity = parseInt($('[name="backlinks_select_quantity"]').val());

    // try {
    //   window.localStorage.setItem('st-backlinks-option-quantity', selectQuantity);
    // } catch (e) {
    //   console.log('Error localStorage.setItem: ' + e.responseText);
    // }

    let firstKey = _.keys(backlinksPlansOptions["bySize"][planSize])[0]
    let planOption1 = backlinksPlansOptions["bySize"][planSize][firstKey];

    let planOptionPerCredit = parseFloat(planOption1.price_per_unit);

    let $planQuantity = 1;
    if (planOptionSelectQuantity >= 3 && planOptionSelectQuantity < 6) {
      $planQuantity = 3;
    } else if (planOptionSelectQuantity >= 6 && planOptionSelectQuantity < 12) {
      $planQuantity = 6;
    } else if (planOptionSelectQuantity >= 12) {
      $planQuantity = 12;
    }

    let planOptionOriganalPerCredit = planOptionPerCredit;
    let planOptionAmount = planOptionPerCredit * planOptionSelectQuantity;
    let planOptionSaving = 0;

    for (const key in backlinksPlansOptions["bySize"][planSize]) {
      let item = backlinksPlansOptions["byId"][key];

      if (parseInt(item.quantity) === $planQuantity) {
        planOptionOriganalPerCredit = parseFloat(item.original_price_per_unit);
        planOptionPerCredit = parseFloat(item.price_per_unit);
        planOptionAmount = planOptionPerCredit * planOptionSelectQuantity;
        planOptionSaving = parseFloat(item.savings_per_unit) * planOptionSelectQuantity;

        break;
      }
    }

    let discount = 0;
    if (planOptionSaving > 0) {
      discount = Math.round(100 - ((Math.round(planOptionPerCredit) / Math.round(planOptionOriganalPerCredit)) * 100));
    }

    $backlinksOptionsWrap.removeClass('active');
    $('.backlinks-options_' + planSize).addClass('active');

    $('.backlinks-options__container').removeClass('active');
    $('.backlinks-options__container[data-plan-option-id="' + planOptionId + '"]').addClass('active');

    $('.backlinks-options__for-js-name').html(planOptionSelectQuantity + 'x');
    $('.backlinks-options__for-js-price-per-credit').html('<span>Price per credit</span>&nbsp;&nbsp;$' + formatPrice(planOptionPerCredit));
    $('.backlinks-options__for-js-amount').html('$' + formatPrice(planOptionAmount));

    $('[name="backlinks_plan_option_id"]').val(planOptionId);
    $('[name="backlinks_select_quantity"]').val(planOptionSelectQuantity);
    $('[name="backlinks_plan_price_per_credit"]').val(planOptionPerCredit.toFixed(2));
    $('[name="backlinks_plan_amount"]').val(planOptionAmount.toFixed(2));

    let $backlinksOptionsContainerPlusMinus = $('.backlinks-options__container.plus-minus[data-plan-option-id="' + planOptionId + '"]');
    let formattedAmountCurrency = formatPrice(planOptionAmount);

    if (jsAppData.expSt912) {
      let amountCurrencyArr = formattedAmountCurrency.split('.');

      if (amountCurrencyArr[0] && amountCurrencyArr[1]) {
        if (jsAppData.expSt912 === 'top') {
          formattedAmountCurrency = '<span>' + amountCurrencyArr[0] + '</span><sup class="format-price">.' + amountCurrencyArr[1] + '</sup>';
        } else if (jsAppData.expSt912 === 'bottom') {
          formattedAmountCurrency = '<span>' + amountCurrencyArr[0] + '</span><sub class="format-price">.' + amountCurrencyArr[1] + '</sub>';
        }
      }
    }

    $backlinksOptionsContainerPlusMinus.find('.backlinks-options__amount span').html('$' + formattedAmountCurrency);
    if (planOptionSaving > 0) {
      // $backlinksOptionsContainerPlusMinus.find('.backlinks-options__saving span').html('Save $' + formatPrice(planOptionSaving, 0)).show();
      if (jsAppData.expSt916 === 'desktop_new-mobile_left' || jsAppData.expSt916 === 'desktop_new-mobile_right') {
        $backlinksOptionsContainerPlusMinus.find('.backlinks-options__saving span').html('Discount -' + discount + '%').show();
      } else {
        $backlinksOptionsContainerPlusMinus.find('.backlinks-options__saving span').html('-' + discount + '% Discount').show();
      }
    } else {
      // $backlinksOptionsContainerPlusMinus.find('.backlinks-options__saving span').html('Save $' + formatPrice(planOptionSaving, 0)).hide();
      if (jsAppData.expSt916 === 'desktop_new-mobile_left' || jsAppData.expSt916 === 'desktop_new-mobile_right') {
        $backlinksOptionsContainerPlusMinus.find('.backlinks-options__saving span').html('Discount -' + discount + '%').hide();
      } else {
        $backlinksOptionsContainerPlusMinus.find('.backlinks-options__saving span').html('-' + discount + '% Discount').hide();
      }
    }
    $backlinksOptionsContainerPlusMinus.find('.backlinks-options__price-per-credit').html('Price per credit $' + formatPrice(planOptionPerCredit));

    // $('.button-select-quantity[data-credit-type="website-traffic"] .js-insert').html('+ Select quantity (' + planOptionName + ') ');
    $('.bc-backlinks-traffic .add-to-cart-step').html('Add to Cart <span class="plan-color--purple">' + planOption.plan_name + '</span>');
  }

  // plan size
  $(".backlinks-plans__header[data-plan-size], .backlinks-plans__item[data-plan-size]").on('click', function () {
    $inputPlanSize.val($(this).data('plan-size')).trigger('change');
  });

  $inputPlanSize.on('change', function () {
    updateData();
  });

  // plan options
  $(".backlinks-options__container").on('click', function () {
    $('.backlinks-options__container').removeClass('active');
    $(this).addClass('active');
    $('[name="backlinks_plan_quantity"]').val($(this).data('quantity'));

    updateData();
  });

  let stPlanSize;
  // try {
  //   stPlanSize = window.localStorage.getItem('st-backlinks-plan-size');
  // } catch (e) {
  //   console.log('Error localStorage.getItem: ' + e.responseText);
  // }

  if (stPlanSize) {
    $inputPlanSize.val(stPlanSize);
  }

  let stPlanOptionQuantity = 12;
  // try {
  //   stPlanOptionQuantity = window.localStorage.getItem('st-backlinks-option-quantity');
  // } catch (e) {
  //   console.log('Error localStorage.getItem: ' + e.responseText);
  // }

  if (stPlanOptionQuantity) {
    $inputSelectQuantity.val(stPlanOptionQuantity);
  }
  if (jsPageData.isReseller) {
    $inputSelectQuantity.val(12);
  }

  initPlusMinusToCart();
  updateData();
};

export const initBuyBacklinksScripts = () => {
  /**
   * @property {object} jsPageData
   */

  runIfHasSelecter('[data-role="cart"]', [
    initCommon
  ]);
};
