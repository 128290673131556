export default function(keywords, allSuggestKeywordsTags){
    let response = [];
    keywords.forEach((item) => {
        let is_suggested = allSuggestKeywordsTags.some(tag => {
            if(item.hasOwnProperty('countryCode') ^ tag.hasOwnProperty('countryCode')){
                return false;
            }
            if(item.hasOwnProperty('countryCode')){
                if(item.countryCode.toLowerCase() !== tag.countryCode.toLowerCase()){
                    return false;
                }
            }
            return item.name === tag.name;
        });

        if(!!item?.countryCode){
            response.push({keyword: item.name, geo: item.countryCode, is_suggested: is_suggested});
        }else{
            response.push({keyword: item.name, geo: null, is_suggested: is_suggested});
        }
    });
    return response;
}