import {runIfHasSelecter, updatePaymentOrder} from '../../utils/panel-lib';
import axios from 'axios';
import route from '../index.js';

const initAutoRenewStatus = function () {
    let $loadingBlock = $('.loading-block');

    $('[data-ajax-action="auto-renew-status"]').on('click', function () {
        let $button = $(this);
        let subscriptionId = $button.data('subscription-id');
        let isPaused = $button.data('paused');
        let status = isPaused ? 'true' : 'false';

        if (!subscriptionId) {
            return;
        }

        let data = {
            subscription_id: subscriptionId,
            status: status
        }

        $button.prop('disabled', true);
        $loadingBlock.addClass('processing');

        $.ajax({
            url: '/api/billing/subscription/auto-renew-status',
            method: 'POST',
            data: data,
            dataType: 'json',
            success: function (response) {
                if (response.success) {
                    let data = response.data;

                    $button.data('paused', data.isPaused);

                    if (data.isPaused) {
                        $button.text('Resume Subscription');
                    } else {
                        $button.text('Pause Subscription');
                    }
                }

                $button.prop('disabled', false);
                $loadingBlock.removeClass('processing');
                // addNotification(response.message);
            },
            error: function () {
                $button.prop('disabled', false);
                $loadingBlock.removeClass('processing');
            }
        });
    });
}

const initPauseUntil = function () {
    let $loadingBlock = $('.loading-block');

    $('[data-ajax-action="pause-until"]').on('click', function () {
        let $button = $(this);
        let subscriptionId = $button.data('subscription-id');
        let dateInterval = $button.data('value');

        if (!subscriptionId) {
            return;
        }

        let data = {
            subscription_id: subscriptionId,
            date_interval: dateInterval
        }

        $button.prop('disabled', true);
        $loadingBlock.addClass('processing');

        $.ajax({
            url: '/api/billing/subscription/pause-until',
            method: 'POST',
            data: data,
            dataType: 'json',
            success: function (response) {
                if (response.success) {
                    // let data = response.data;

                    // $button.hide();
                    // $button.closest('div').find('[data-ajax-action="resume-until"]').show();

                    document.location.reload();
                } else {
                    $button.prop('disabled', false);
                    $loadingBlock.removeClass('processing');
                }
            },
            error: function () {
                $button.prop('disabled', false);
                $loadingBlock.removeClass('processing');
            }
        });
    });
}

const initResumeUntil = function () {
    let $loadingBlock = $('.loading-block');

    $('[data-ajax-action="resume-until"]').on('click', function () {
        let $button = $(this);
        let subscriptionId = $button.data('subscription-id');

        if (!subscriptionId) {
            return;
        }

        let data = {
            subscription_id: subscriptionId
        }

        $button.prop('disabled', true);
        $loadingBlock.addClass('processing');

        $.ajax({
            url: '/api/billing/subscription/resume-until',
            method: 'POST',
            data: data,
            dataType: 'json',
            success: function (response) {
                if (response.success) {
                    // let data = response.data;

                    // $button.hide();
                    // $button.closest('div').find('[data-ajax-action="resume-until"]').show();

                    document.location.reload();
                } else {
                    $button.prop('disabled', false);
                    $loadingBlock.removeClass('processing');
                }
            },
            error: function () {
                $button.prop('disabled', false);
                $loadingBlock.removeClass('processing');
            }
        });
    });
}

const initRecover = function () {
    let $loadingBlock = $('.loading-block');

    $('[data-ajax-action="recover"]').on('click', function () {
        let $button = $(this);
        let subscriptionId = $button.data('subscription-id');

        if (!subscriptionId) {
            return;
        }

        let data = {
            subscription_id: subscriptionId
        }

        $button.prop('disabled', true);
        $loadingBlock.addClass('processing');

        $.ajax({
            url: '/api/billing/subscription/recover',
            method: 'POST',
            data: data,
            dataType: 'json',
            success: function (response) {
                if (response.success) {
                    // let data = response.data;

                    // $button.hide();
                    // $button.closest('div').find('[data-ajax-action="resume-until"]').show();

                    document.location.reload();
                } else {
                    $button.prop('disabled', false);
                    $loadingBlock.removeClass('processing');
                }
            },
            error: function () {
                $button.prop('disabled', false);
                $loadingBlock.removeClass('processing');
            }
        });
    });
}

const initBonus = function () {
    let $popupSubscriptionBonusWrap = $('.popup-subscription-bonus-wrap');

    $('[data-button-action="open-popup"]').on('click', function () {
        $popupSubscriptionBonusWrap.addClass('open');
    });

    $('[data-button-action="close-popup"]').on('click', function () {
        $popupSubscriptionBonusWrap.removeClass('open');
    });

    $('[data-button-action="set-settings"]').on('click', function () {
        let $button = $(this);
        let orderId = $button.data('order-id');
        let settingName = $button.data('setting-name');

        $button.prop('disabled', true);

        axios.patch(`${route('api.order.update_settings', [orderId])}`,
            {[settingName]: 1}).
            then(function(response) {
                if (settingName === 'keepSubscription') {
                    window.location.href = route('billing.subscriptions.index');
                } else {
                    window.location.reload();
                }
            }).
            catch(function(error) {
                console.log(error);
                $button.prop('disabled', false);
                $popupSubscriptionBonusWrap.removeClass('open');
            });
    });
}

export const initBillingSubscriptionScripts = () => {
    runIfHasSelecter('[data-role="billing-subscriptions"]', [
        initAutoRenewStatus,
        initPauseUntil,
        initResumeUntil,
        initRecover,
        initBonus
    ]);
}
