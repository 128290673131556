<template>
  <div
      class="v-form-tagify vft"
      ref="bTagify"
      @click="setFocusOnInput"
      :class="mainClass"
  >

    <transition-group
        name="list"
        tag="div"
        class="v-form-tagify__items"
        :style="{marginBottom: (textareaValue.length > 0) ? '' : '0px'}"
    >
      <div
          v-for="(item,index) in textareaValue"
          class="v-form-tagify__item"
          :class="item._isError ? 'v-form-tagify__item--error' : ''"
          :key="item"
      >
        <span v-if="item.countryCode" class="v-form-tagify__item-flag">
          <v-icon-flag :code="item.countryCode" class="bordered"/>
        </span>
        <span class="v-form-tagify__item-text">{{item.name}}</span>
        <span
            class="v-form-tagify__item-close"
            @click="emit('remove-tag',item)"
        ></span>
      </div>
    </transition-group>

    <div
        class="v-form-tagify__input"
        :contenteditable="!disabled"
        placeholder="Start type a keyword here or paste here"
        ref="bTagifyInput"
        @focusout="bTagifyInputFocusout"
        @focusin="bTagifyInputFocus"
        @keyup.enter="addTagFromInput()"
        @keydown.enter.prevent
        @keydown.backspace="removeLastTag"
        @input="inputValue = $refs.bTagifyInput.textContent"
        @paste.prevent="handlePaste"
    />

    <teleport to="body">
      <transition>
        <div
            class="v-form-tagify__dropdown" ref="bDropdown" :style="{width:tagifyWidth+'px'}"
            v-show="showDropdown"
            tabindex="0"
        >
          <div class="v-form-tagify__dropdown-inner">
            <div
                class="v-form-tagify__dropdown-item"
                v-if="inputValue.trim().length > 0 && hasGeo"
                @click="() => {addTagFromInput(true)}"
            >
            <span v-if="hasGeo" class="v-form-tagify__dropdown-item-flag">
              <v-icon-flag :code="currentCountryCode"/>
            </span>
              <span class="v-form-tagify__dropdown-item-text" v-html="inputValue"/>
            </div>
            <div
                class="v-form-tagify__dropdown-item v-form-tagify__dropdown-item--border-bottom"
                v-if="inputValue.trim().length > 0"
                @click="() => {addTagFromInput(false);}"
            >
              <span class="v-form-tagify__dropdown-item-text" v-html="inputValue"/>
            </div>
            <div
                class="v-form-tagify__dropdown-item"
                v-for="item in suggestedTagsLocalFilter"
                @click="() => {addTag(item);}"
            >
            <span v-if="item.countryCode" class="v-form-tagify__dropdown-item-flag">
              <v-icon-flag :code="item.countryCode"/>
            </span>
              <span class="v-form-tagify__dropdown-item-text">{{item.name}}</span>
            </div>
          </div>
        </div>
      </transition>
    </teleport>

  </div>

</template>

<script setup>
/**
 * Компонента, которая показывает textarea и дает возможности:
 * - введения ключевых слов, сохранение в виде тегов
 * - использование стран в тегах
 * - выпадающий список с предлагаемыми ключевыми словами
 */

import {computed, onMounted, ref, watch, watchEffect} from "vue";
import VIconFlag from "../Base/VIconFlag.vue";
import {createPopper} from "@popperjs/core";
import {useStore} from "vuex";

const props = defineProps({
  /**
   * Список предлагаемых слов в dropdown меню
   * {{name: String, [countryCode]: String}[]}
   * */
  suggestedTags: {type: Array, default: []},

  /** Текущая страна - для выбора в dropdown, чтобы вставить определенную страну */
  currentCountryCode: {default: null},
  isError:{default:false},
  disabled:{type:Boolean,default:false},
});

const emit = defineEmits(['add-tag','remove-tag']);

const hasGeo = computed(() => {
  return props.currentCountryCode && props.currentCountryCode != 'no_geo';
});

/** Список выбранных тегов */
const textareaValue = defineModel();

/** Текущее значение введенное пользователем */
const inputValue = ref('');

/** Ссылки на DOM элементы */
const bTagify = ref();
const bTagifyInput = ref();
const bDropdown = ref();

/** Хранит в себе текущую ширины bTagify */
const tagifyWidth = ref(null);

/** Store понадобится для использования resizeObserverModule  */
const store = useStore();

/** Отвечает за пока dropdown */
const showDropdown = ref(false);

/** Мониторинг фокуса пользователя на bTagifyInput */
const bTagifyInputIsFocused = ref(false);

/** Мониторинг изменение bTagifyInput, в зависимости от введенного значения показываем или нет dropdown */
watch(()=> inputValue.value.length, (newValue, oldValue)=>{
  if(newValue > 0){
    showDropdown.value = true;
    dropdownPopper.update();
  }else if(newValue === 0 && oldValue > 0 && props.suggestedTags.length === 0){
    showDropdown.value = false;
  }
});

watch(() => inputValue.value, (newValue) => {
  if(newValue.includes(',')){
    addTagFromInput();
  }
})

/** Мониторим событие focus на bTagify, и показываем или нет dropdown */
const bTagifyInputFocus = (e) => {
  if(props.disabled){return;}
  bTagifyInputIsFocused.value = true;
  if(props.suggestedTags.length > 0 || inputValue.value.length > 0){
    showDropdown.value = true;
  }
  dropdownPopper.update();
}

/** Мониторим событие расфокусировки с bTagifyInput, скрываем dropdown, если он не был нажат */
const bTagifyInputFocusout = (e) => {
  bTagifyInputIsFocused.value = false;
  if(e.relatedTarget && e.relatedTarget.classList.contains('v-form-tagify__dropdown')){
      bTagifyInput.value.focus();
      return;
  }
  addTagFromInput();
  showDropdown.value = false;
}

/**
 * Блок кода отвечающий за инициализацию dropdown
 */
let dropdownPopper;
onMounted(() => {
  dropdownPopper = createPopper(bTagifyInput.value, bDropdown.value,{
    placement: "bottom",
    strategy:'fixed',
    modifiers: [
      {
        name: 'flip',
        enabled: false // Отключаем flip, чтобы tooltip не перемещался
      },
      {
        name: 'preventOverflow',
        enabled: false // Отключаем preventOverflow, чтобы tooltip не перемещался
      }
    ]
  });
  tagifyWidth.value = bTagify.value.offsetWidth;
  bTagify.value.observeUpdateFunc = () => {
    tagifyWidth.value = bTagify?.value?.offsetWidth;
  }
  store.state.gResizeObserver.observer.observe(bTagify.value);
});

const addTag = (tag) => {
  tag.name = tag.name.trim();
  emit('add-tag',tag);
}

/** Добавить тег в textarea из данных введенных в input */
const addTagFromInput = (hasCountry = true) => {

  let valueArr = bTagifyInput.value.textContent.split(/[\n,]+/);
  let arrForInsert = [];

  valueArr.forEach((value) => {
    value = value.trim();
    if(value.length === 0){return;}

    let data = {}
    if(props.currentCountryCode && hasCountry && props.currentCountryCode!=='no_geo'){
      data.countryCode = props.currentCountryCode
    }
    data.name = value;

    arrForInsert.push(data);
  });

  if(arrForInsert.length > 0){
    arrForInsert.forEach((itemTag) => {
      addTag(itemTag);
    })
  }

  bTagifyInput.value.innerHTML = '';
  inputValue.value = '';

}

/** Костыль для правильного отображения dropdown. Включается каждый раз, когда список введенных тегов обновлен */
watch(() => textareaValue.value, () => {
  if(dropdownPopper){
    dropdownPopper.update();
    setTimeout(() => {
      dropdownPopper.update();
    },350);
  }
}, {deep:true});

/** Если пользователь нажал на блок, но не конкретно на input, то делаем фокус на input */
const setFocusOnInput = (e) => {
  if(props.disabled){return;}
  if(
      (
          e.target.classList.contains('v-form-tagify')
          || e.target.classList.contains('v-form-tagify__items')
      )
      && !e.target.classList.contains('v-form-tagify__items')
  ){
    bTagifyInput.value.focus();
  }
}

const handlePaste = (e) => {
  const text = e.clipboardData.getData('text/plain');
  const selection = window.getSelection();

  if (!selection.rangeCount) return;
  selection.deleteFromDocument();
  selection.getRangeAt(0).insertNode(document.createTextNode(text));
  selection.removeAllRanges();

  const range = document.createRange();
  range.selectNodeContents(bTagifyInput.value);
  range.collapse(false);
  selection.addRange(range);

  addTagFromInput();

}

const removeLastTag = () => {
  if(inputValue.value.length === 0 && textareaValue.value.length > 0){
    emit('remove-tag', textareaValue.value[textareaValue.value.length-1]);
  }
}

const suggestedTagsLocalFilter = computed(() => {
  if(inputValue.value.length > 0){
    return props.suggestedTags.filter(item => item.name.includes(inputValue.value));
  }else{
    return props.suggestedTags;
  }
})

const mainClass = computed(() => {
  let classes = [];
  if(props.isError){
    classes.push('invalid');
  }
  if(props.disabled){
    classes.push('vft--disabled');
  }
  return classes;
});

</script>

<style lang="scss" scoped>
@keyframes redBlink {
  0%, 100% { background-color: initial; }
  50%, 50% { background-color: #ff8383; }
}
.v-form-tagify{
  display: block;
  border-radius: 4px;
  border: 1px solid #D6E3EF;
  padding:12px 10px;
  cursor:text;
  min-height:140px;
  resize: vertical;
  overflow: auto;
}
.v-form-tagify.invalid{
  border-color:#F2001D;
}
.v-form-tagify__items{
  display:flex;
  flex-direction:column;
  align-items: flex-start;
  margin-bottom:6px;
  cursor: default;
}
.v-form-tagify__item{
  display:flex;
  align-items:center;
  padding:5px 9px;
  border-radius: 4px;
  background-color: #F4F9FD;
  margin-bottom:6px;
  color:#0D4E8F;
  cursor: default;
  min-height: 28px;
  position: relative;
}
.v-form-tagify__item:before{
  content:'';
  position: absolute;
  width:100%;
  height:100%;
  background:#ddecf9;
  top:0px;
  left:0px;
  border-radius: 4px;
  transform:scaleX(1.0) scaleY(1.0);
  opacity:0;
  transition-duration: 0.3s;
  transition-property: opacity, transform;
}
.v-form-tagify__item:hover:before{
  transform:scaleX(1.05) scaleY(1.1);
  opacity:1;
}
.v-form-tagify__item-flag{
  flex-shrink: 0;
  margin-right:6px;
  position: relative;
}
.v-form-tagify__item-text{
  position: relative;
}
.v-form-tagify__item-close{
  background-image: url(/img/icons/close-icon-blue.svg);
  width: 14px;
  height: 14px;
  flex-shrink: 0;
  margin-left:6px;
  cursor: pointer;
  position: relative;
  background-color: #CBE3F600;
  border-radius: 50%;
  transition-duration: 0.3s;
  transition-property: background-color;
}
.v-form-tagify__item-close:hover{
  background-color: #cbe3f6;
}

.v-form-tagify__input{
  padding: 0px;
  min-height: 28px;
  outline: 0;
  box-shadow: none;
  border-radius: 4px;
}
.v-form-tagify__input.error{
  animation: redBlink 0.3s ease-in-out 2;
}

.v-form-tagify__input[placeholder]:empty:before {
  content: attr(placeholder);
  color: #0000004D;
}
.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.v-form-tagify__dropdown{
  z-index: 999999;
}
.v-form-tagify__dropdown-inner{
  width:100%;
  box-shadow: $box-shadow-default;
  border-radius: 8px;
  background: #fff;
  transform: translateY(0px);
  max-height: 250px;
  overflow: auto;
}
.v-form-tagify__dropdown-item{
  display: flex;
  padding:11px 10px 11px 10px;
  align-items: center;
  cursor:pointer;
  min-height: 40px;
  transition-property: background-color;
  transition-duration: 0.2s;
}
.v-form-tagify__dropdown-item:hover{
  background-color: #ddecf9;
}
.v-form-tagify__dropdown-item-flag{
  display: flex;
  margin-right:6px;
  margin-top:-1px;
}
.v-form-tagify__dropdown-item-text{
  display: flex;
}

.v-form-tagify__dropdown.v-enter-active,
.v-form-tagify__dropdown.v-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
}
.v-form-tagify__dropdown.v-enter-from,
.v-form-tagify__dropdown.v-leave-to {
  opacity: 0;
}

.v-form-tagify__dropdown.v-enter-active .v-form-tagify__dropdown-inner,
.v-form-tagify__dropdown.v-leave-active .v-form-tagify__dropdown-inner {
  transition-duration: 0.3s;
  transition-property: transform;
}
.v-form-tagify__dropdown.v-enter-from .v-form-tagify__dropdown-inner,
.v-form-tagify__dropdown.v-leave-to .v-form-tagify__dropdown-inner {
  transform: translateY(30px);
}
.v-form-tagify__item--error,.v-form-tagify__item--error:before{
  background:#f8d8d8;
}

.v-form-tagify__dropdown-item--border-bottom {
  border-bottom:1px solid #D6E3EF80;
}
.v-form-tagify.vft{
  &.vft--disabled{
    position:relative;
    border: 1px solid #D6E3EF;
    cursor: initial;
    background-color: #F9FAFA;
    >*{
      opacity:0.5;
    }
    &:after{
      content:'';
      position:absolute;
      width:100%;
      height:100%;
      top:0px;
      left:0px;
      display:block;
      z-index:99;
    }
  }
}
</style>