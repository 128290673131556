<template>
  <v-container class="sc-projects-page">

    <v-breadcrumbs :items="breadcrumbs" no-padding/>

    <bc-balance :credit-type="CREDIT_TYPE_SEO"/>

    <v-head-title>My Projects</v-head-title>

    <v-search-bar v-if="projectsIds.length > 0"
                  skin="gray-on-gray"
                  placeholder="Search by ID, date or description"
                  v-model="searchProjectStr"/>

    <div class="controls" v-if="projectsIds.length > 0">
      <v-button color="green"
                tag="a"
                :href="useRoutes().url('seo-clicks.add')"
                :text="isMobile ? '+ Add' : '+ Add project'" />
      <div class="filters">
        <v-filter-simple :has-selected="false"
                         skin="on-gray-bg"
                         v-model="selectedFilter"
                         :options="filterOptions"
                         tooltip-display-as-input-checked
                         :has-button-title="!isMobile"/>
        <v-sort-by-simple :options="typesSortBy"
                          v-model="sortBySimpleSelected"
                          v-model:sorting-direct="sortBySimpleDirect"
                          class="filter-sort"
                          arrow-sorting-direct
                          :has-text="!isMobile"/>
      </div>
    </div>

    <div class="list" v-if="projectsIdsFiltered.length > 0">
      <template v-for="projectId in projectsIdsSorted" :key="projectId">
        <sc-project-preview :project-id="projectId"
                            v-show="projectsIdsFiltered.includes(projectId)"/>
      </template>
    </div>

    <v-not-found-projects v-if="projectsIds.length === 0"
                          :link="useRoutes().url('seo-clicks.add')"/>

    <div class="footer" v-if="projectsIds.length > 0">
      {{projectsIdsFiltered.length}} SEO Traffic {{pluralize('project', projectsIdsFiltered.length)}}
    </div>

  </v-container>
</template>

<script setup>
import VContainer from "../Base/VContainer.vue";
import VBreadcrumbs from "../Base/VBreadcrumbs.vue";
import {CREDIT_TYPE_SEO} from "../../stores/useBilling";
import BcBalance from "../BuyCredits/BcBalance.vue";
import VHeadTitle from "../Base/VHeadTitle.vue";
import VButton from "../Base/VButton.vue";
import ScProjectPreview from "./ScProjectPreview.vue";
import {computed, getCurrentInstance, onMounted, reactive, ref, watch} from "vue";
import {useRoutes} from "../../stores/useRoutes";
import {useSeoClicksProject, useSeoClicksProjects} from "../../stores/useSeoClicks";
import VSearchBar from "../Base/VSearchBar.vue";
import VSortBySimple from "../Base/VSortBySimple.vue";
import VFilterSimple from "../Base/VFilterSimple.vue";
import ScProjectsPreview from "./ScProjectsPreview.vue";
import VNotFoundProjects from "../Base/VNotFoundProjects.vue";
import {isValidDate, parseDateString, pluralize} from "../../utils/utils";
import {toString} from "lodash";

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const breadcrumbs = ref([
  {url: useRoutes().url('seo-clicks.list'), title: 'SEO Traffic',},
  {title: 'My Projects',}
]);

const useProjects = useSeoClicksProjects();
useProjects.download();

function updateRealtimes(){
  setTimeout(() => {
    useProjects.updateRealtime().then(() => {
      updateRealtimes();
    });
  },20_000);
}

onMounted(() => {
  updateRealtimes();
});

const searchProjectStr = ref('');

const typesSortBy = reactive([
  {
    value: 'date_created',
    name: 'Date created',
  },
  {
    value: 'name',
    name: 'Name',
  },
]);
const sortBySimpleSelected = ref(typesSortBy[0]);
const sortBySimpleDirect = ref('desc');

const selectedFilter = reactive([]);
const filterOptions = ref([
  {
    value: 'show_deleted',
    name: 'Show deleted',
  },
  {
    value: 'show_inactive',
    name: 'Show inactive',
  }
]);
const showDeleted = computed(() => selectedFilter.some( i => i.value === 'show_deleted'));
const showInactive = computed(() => selectedFilter.some( i => i.value === 'show_inactive'));

const projectsIds = computed(() => {
  return useProjects._projects.map(item => item.id);
});

const projectsIdsFilteredBase = computed(() => {
  return projectsIds.value.filter((projectId) => {
    return (
        (showDeleted.value || !useSeoClicksProject(projectId).is_deleted)
        && (showInactive.value || useSeoClicksProject(projectId).is_active)
    );
  });
});

const projectsIdsFilteredStr = computed(() => {
  let searchStr = searchProjectStr.value.trim().toLowerCase();
  if(searchStr.length === 0) return projectsIds.value;

  let searchStrAsDate = parseDateString(searchStr);
  searchStrAsDate = isValidDate(searchStrAsDate) ? searchStrAsDate.toISOString().split('T')[0] : null;

  return projectsIds.value.filter((projectId) => {
    return (
      toString(useSeoClicksProject(projectId).id).toLowerCase().includes(searchStr)
      || toString(useSeoClicksProject(projectId).name).toLowerCase().includes(searchStr)
      || toString(useSeoClicksProject(projectId).url).toLowerCase().includes(searchStr)
      || (searchStrAsDate && useSeoClicksProject(projectId).createdDateStringISO && searchStrAsDate === useSeoClicksProject(projectId).createdDateStringISO)
    );
  });
});

const projectsIdsFiltered = computed(() => {
  return projectsIdsFilteredBase.value.filter((id) => projectsIdsFilteredStr.value.includes(id));
});

const sortedFunction = computed(() => {
  if(sortBySimpleDirect.value === 'asc'){
    if(sortBySimpleSelected.value.value === 'name'){
      return (a,b) => a.name.localeCompare(b.name);
    }else if(sortBySimpleSelected.value.value === 'date_created'){
      return (a,b) => a.createdDate - b.createdDate;
    }
  }else{
    if(sortBySimpleSelected.value.value === 'name'){
      return (a,b) => b.name.localeCompare(a.name);
    }else if(sortBySimpleSelected.value.value === 'date_created'){
      return (a,b) => b.createdDate - a.createdDate;
    }
  }
});

const projectsIdsSorted = computed(() => {
  return projectsIds.value.sort((a,b) => sortedFunction.value(useSeoClicksProject(a), useSeoClicksProject(b)));
});

</script>

<style lang="scss" scoped>
.sc-projects-page{
  @include flex(column,start,stretch,24px);
  padding-bottom:100px!important;
  @media (max-width: 767px){
    gap:16px;
  }
  .list{
    @include flex(column,start,stretch,20px);
    @media (max-width: 767px){
      gap:16px;
    }
  }
  .footer{
    color:$dark-30;
    text-align:center;
  }
  .controls{
    @include flex(row,between,center);
  }
  .filters{
    @include flex(row,end,center,11px);
  }
  .filter-sort{
    color:$dark-50;
  }
}
</style>