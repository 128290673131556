import {runIfHasSelecter} from '../../../utils/panel-lib.js';

const initCommon = function() {
  $('#bank-transfer-currency').on('change', function() {
    let $proformaLinkEl = $('#proforma-link');
    $proformaLinkEl.attr('href', '/cart/invoice?currency=' +
        $('#bank-transfer-currency').find(':selected').val());
  }).trigger('change');
};

export const initCartPaymentMethodBankTransferScripts = () => {
  /**
   * @property {object} jsPageData
   */

  runIfHasSelecter('[data-role="cart"]', [
    initCommon
  ]);
};
