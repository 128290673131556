import {runIfHasSelecter} from "../../utils/panel-lib.js";
import route from '../index.js';

const initButtonStartFreeTrial = () => {
    let buttonStartFreeTrial = $('[data-ajax-action="start-free-trial"]');

    buttonStartFreeTrial.on('click', function(e) {
        e.preventDefault();

        let $button = $(this);

        $button.prop('disabled', true);

        $.ajax({
            url: '/api/seo-traffic/start-free-trial',
            method: 'POST',
            dataType: 'json',
            success: function (response) {
                if (response.success) {
                    window.location.replace(route('seo_traffic.projects.create'));
                } else {
                    window.location.replace(route('seo_traffic.buy'));
                }
            },
            error: function () {
                $button.prop('disabled', false);
            }
        });
    });
}

const initCalculator = () => {
    let $calculatorInput = $('[data-id="search-calculator-input"]');
    let $monthlySearchVolumeRangeSlider = $('#monthly_search_volume_slider');
    let $currentPositionRangeSlider = $('#current_position_slider');
    let $targetPositionRangeSlider = $('#target_position_slider');
    let $currentPositionInput = $('#current_position');
    let $targetPositionInput = $('#target_position');

    if ($calculatorInput.length === 0) {
        return;
    }

    if (typeof $monthlySearchVolumeRangeSlider.slider === 'function') {
        $monthlySearchVolumeRangeSlider.slider({
            range: "min",
            value: 0,
            min: 0,
            max: 100,
            step: 1,
            slide: function(event, ui) {
                $calculatorInput.val(ui.value).trigger('change');
            },
            change: function(event, ui) {
                $calculatorInput.val(ui.value).trigger('change');
            }
        });
    }

    if (typeof $currentPositionRangeSlider.slider === 'function') {
        $currentPositionRangeSlider.slider({
            range: "min",
            value: 1,
            min: 1,
            max: 100,
            step: 1,
            slide: function(event, ui) {
                $currentPositionInput.val(ui.value).trigger('change');
            },
            change: function(event, ui) {
                $currentPositionInput.val(ui.value).trigger('change');
            }
        });
    }

    if (typeof $targetPositionRangeSlider.slider === 'function') {
        $targetPositionRangeSlider.slider({
            range: "min",
            value: 1,
            min: 1,
            max: 10,
            step: 1,
            slide: function(event, ui) {
                $targetPositionInput.val(ui.value).trigger('change');
            },
            change: function(event, ui) {
                $targetPositionInput.val(ui.value).trigger('change');
            }
        });
    }

    $calculatorInput.on('change', recalcuateEstimate);
    recalcuateEstimate();

    function recalcuateEstimate() {
        let monthly_search_volume = $('#monthly_search_volume').val();
        let current_position = $currentPositionInput.val();
        let target_position = $targetPositionInput.val();

        monthly_search_volume = Number(monthly_search_volume) || 1;
        current_position = Number(current_position) || 1;
        target_position = Number(target_position) || 1;

        monthly_search_volume = Math.round(Math.pow(monthly_search_volume / 4, 3.57607) + 200)
        let m = { 1: 32, 2: 14, 3: 10, 4: 7, 5: 6, 6: 4, 7: 4, 8: 4, 9: 4, 10: 4 };
        let f = ((m[current_position] || 0) * monthly_search_volume) / 100,
          g = ((m[target_position] || 1) * monthly_search_volume) / 100,
          h = Math.max(Math.round(1.25 * g - f), 1),
          b = Math.round( (0.19 * h * 100) / 100 );

        $('#monthly_search_volume_info').html(monthly_search_volume.toLocaleString("en-US", { minimumFractionDigits: 0 }));
        $('#current_position_info').html(current_position.toLocaleString("en-US", { minimumFractionDigits: 0 }));
        $('#target_position_info').html(target_position.toLocaleString("en-US", { minimumFractionDigits: 0 }));
        $('#clicks_estimated').html( h.toLocaleString("en-US", { minimumFractionDigits: 0 }) + " Clicks" );
        $('#budget_estimated').html( "from $"+b.toLocaleString("en-US", { minimumFractionDigits: 0 }) + "/month" );
    }
}

export const initSeoClicksStartFreeTrial = () => {
    runIfHasSelecter('[data-role="seo-clicks-start-free-trial"]', [
        initCalculator,
        initButtonStartFreeTrial,
    ]);
}
