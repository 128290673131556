<template>
  <div class="recommendation-top-100 rt100">

    <div class="rt100__non-top" v-if="status === 1">
      <recommendation-keyword degree="danger">
        <template v-slot:text>
          <div class="rt100__non-top-message-wrap">
            <div class="rt100__non-top-message">
              <template v-if="isMobile">Verified: <br></template>
              Not in TOP 100
            </div>
            <div class="rt100__non-top-recheck" @click="check">
              <div class="rt100__non-top-recheck-icon-wrap">
                <v-icon-svg name="reload" class="rt100__non-top-recheck-icon"/>
              </div>
              <div class="rt100__non-top-recheck-label">Сheck now</div>
            </div>
          </div>
        </template>
      </recommendation-keyword>
    </div>

    <div class="rt100__loading" v-if="status === 2">
      <v-loader-ship color-line-one="#1C7EE080" color-line-two="#1C7EE0" class="rt100__loading-icon"/>
    </div>

    <div class="rt100__support" v-if="status === 3">
      <recommendation-keyword :has-tooltip="true" degree="danger">
        <template v-slot:text>
          <span class="rt100__support-message">
            Verified: Not in TOP 100
          </span>
        </template>
        <template v-slot:tooltip>
          <div class="rt100__support-tooltip">
            <div class="rt100__support-tooltip-message">
              We verified that your site not in Google Top 100. If you have any questions please connect support
            </div>
            <v-button-support class="rt100__support-tooltip-button" @click="chatSupportShowMessage('Hello, my site is not in the top 100. Please assist me.')"/>
          </div>

        </template>
      </recommendation-keyword>
    </div>

  </div>
</template>

<script setup>

import {computed, defineProps, getCurrentInstance, onMounted} from 'vue';
import {useSeoClicksKeyword} from '../../stores/useSeoClicks.js'
import VIconSvg from "../Base/VIconSvg.vue";
import VLoaderShip from "../Base/VLoaderShip.vue";
import RecommendationKeyword from "./RecommendationKeyword.vue";
import VButtonSupport from "../Base/VButtonSupport.vue";
import {chatSupportShowMessage} from '../../utils/chatWithSupport.js';

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const props = defineProps({
  keywordId: {},
  projectId: {}
});

const useKeyword = useSeoClicksKeyword(props.projectId, props.keywordId);

const status = computed(() => {
  if(!useKeyword.check_position_now_ended && !useKeyword.check_position_now && !useKeyword.is_top100){
    return 1;
  }else if(useKeyword.check_position_now && !useKeyword.is_top100){
    return 2;
  }else if(useKeyword.check_position_now_ended && !useKeyword.check_position_now && !useKeyword.is_top100){
    return 3;
  }else{
    return 0;
  }
});

const check = () => {
  useKeyword.startPositionUpdating().then((response) => {
    let [status] = response;
    if(status){
      useKeyword.startIntervalCheckPositionUpdating();
    }
  });
}

onMounted(() => {
  if(status.value === 2){
    useKeyword.startIntervalCheckPositionUpdating();
  }
});

</script>

<style scoped>
.recommendation-top-100.rt100{
  .rt100__non-top{

  }

  .rt100__loading{
    min-width:230px;
    .rt100__loading-icon{
      width:18px;
      height:18px;
    }
  }

  .rt100__support{
    .rt100__support-message{
      color: #1F293BCC;
      @media(max-width:767px){
        color:#7F7F7F;
      }
    }
  }

}

.rt100__non-top-message-wrap{
  display: flex;
  align-items: center;
  gap:8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;

  @media(max-width: 767px){
    gap:8px;
  }

  .rt100__non-top-message{
    color: #1F293BCC;
    white-space: nowrap;
    @media(max-width:767px){
      color:#7F7F7F;
    }
  }
  .rt100__non-top-recheck{
    display:flex;
    align-items: center;
    gap:8px;
    color: #1D94FF;
    cursor: pointer;

    .rt100__non-top-recheck-icon-wrap{
      width:18px;
      position: relative;
      top:-1px;
      :deep(path){
        fill: #1D94FF;
      }
    }
    .rt100__non-top-recheck-label{
      white-space: nowrap;
      @media(max-width:767px){
        display:none;
      }
    }

    &:hover{
      color:#186CBF;
      :deep(.rt100__non-top-recheck-icon path){
        fill:#186CBF;
      }
    }

  }

}

.rt100__support-tooltip{
  .rt100__support-tooltip-message{
    margin-bottom:13px;
  }
  .rt100__support-tooltip-button{

  }
}
</style>