<template>
  <div class="checklists-widget"
       :class="checklists?.checklist?.enable_default_styles ? 'tpl-default' : ''"
       v-if="loaded && !dismissed && !finished"
  >
    <button class="checklists-widget-button" type="button" @click="show =! show">
      <div class="row g-0 align-items-center">
        <div class="col-auto">
          <div class="checklists-widget-button__icon">
            <VIconSvg name="icon_checklists-widget-checked"/>
          </div>
        </div>
        <div class="col">
          <div class="checklists-widget-button__text"
               v-html="checklists?.checklist?.data?.bubble_title"></div>
        </div>
      </div>
      <div class="checklists-widget-button__count">{{ checklists?.countNotCompleted }}</div>
    </button>
    <div class="checklists-widget-content js-closed"
         :class="{'expanded':!dismissed && !finished && show}"
    >
      <div class="checklists-widget-content__header">
        <div class="checklists-widget-content__header-title"
             v-html="checklists?.checklist?.data?.title"></div>
        <div class="checklists-widget-content__header-description"
             v-html="checklists?.checklist?.data?.description"></div>
        <div class="row g-0 align-items-center">
          <div class="col-auto">
            <div class="checklists-completed-caption">{{ Math.round(completedPercent) + '%' }}</div>
          </div>
          <div class="col">
            <div class="checklists-progress-bar">
              <div class="checklists-progress-bar__complete"
                   :style="{ width: completedPercent + '%' }"></div>
            </div>
          </div>
        </div>
        <div class="checklists-widget-content__close"
             @click="show = false"
        >
          <VIconSvg name="icon_checklists-widget-close-v1"/>
        </div>
      </div>
      <div class="checklists-widget-content__task">
        <ul>
          <li v-for="task in checklists.checklist.data.tasks"
              :class="{'completed':task.completed === 1}"
              @click="startTaskExecution(task.code, task.link);"
          >
            <div class="row g-0 align-items-center">
              <div class="col-auto">
                <div class="checklists-widget-content__task-icon">
                  <VIconSvg name="icon_checklists-widget-task-checked-v1"/>
                </div>
              </div>
              <div class="col">
                <div class="checklists-widget-content__task-title"
                     v-html="task.title"
                ></div>
              </div>
            </div>
          </li>
        </ul>
        <div class="row g-0 justify-content-start align-items-center">
          <div class="col-auto">
            <button class="checklists-widget-content__no-thanks-button"
                    type="button"
                    @click="setDismissed(checklists.id); dismissed = true;"
            >
              No thanks
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import VIconSvg from './Base/VIconSvg.vue';

// let uploadDataChecklistsTimeout = null;

export default {
  components: {
    VIconSvg,
  },
  props: {
    dismissed: {type: Boolean, default: false},
  },
  data() {
    return {
      show: true,
    };
  },
  methods: {
    ...mapActions({
      fetchChecklists: 'checklistsWidget/fetchChecklists',
      setDismissedChecklists: 'checklistsWidget/setDismissedChecklists',
      startTaskExecutionChecklists: 'checklistsWidget/startTaskExecutionChecklists',
      checkCompletedTasks: 'checklistsWidget/checkCompletedTasks',
    }),
    uploadData() {
      this.checkCompletedTasks().then(() => {
        this.fetchChecklists().then(() => {
          if (this.checklists
            && typeof this.checklists.checklist !== 'undefined'
            && this.checklists.checklist.enable_custom_styles
            && this.checklists.checklist.custom_styles
          ) {
            let style = document.createElement('style');
            style.innerHTML = this.checklists.checklist.custom_styles;
            document.head.append(style);
          }

          if (this.isCurrentUrlBelongsToTask()) {
            this.show = false;
          }
        });
      });

      // this.fetchChecklists().then(() => {
      // clearTimeout(uploadDataChecklistsTimeout);
      // uploadDataChecklistsTimeout = setTimeout(() => this.uploadData(), 10000);
      // });
    },
    setDismissed(id) {
      this.setDismissedChecklists(id);
    },
    startTaskExecution(taskCode, taskLink) {
      this.startTaskExecutionChecklists(taskCode).then(() => {
        if (taskLink) {
          window.location.href = taskLink;
        }
      });
    },
    isCurrentUrlBelongsToTask() {
      if (!this.checklists) {
        return false;
      }

      let tasks = this.checklists.checklist.data.tasks;
      for (let key in tasks) {
        let task = tasks[key];

        if (task.link.indexOf(window.location.pathname) !== -1) {
          return true;
        }
      }

      return false;
    },
  },
  computed: {
    ...mapState({
      checklists: state => state.checklistsWidget.checklists,
      loaded: state => state.checklistsWidget.loaded,
      completedPercent: state => state.checklistsWidget.completedPercent,
      finished: state => state.checklistsWidget.finished,
    }),
  },
  watch: {
    show(newVal) {
      if (!this.isCurrentUrlBelongsToTask()) {
        try {
          localStorage.stChecklistsWidgetIsShow = newVal;
        } catch (e) {
          console.log('Error localStorage.setItem: ' + e.responseText);
        }
      }
    },
  },
  mounted() {
    try {
      if (localStorage.stChecklistsWidgetIsShow) {
        this.show = localStorage.stChecklistsWidgetIsShow === 'true';
      }
    } catch (e) {
      console.log('Error localStorage.getItem: ' + e.responseText);
    }

    this.uploadData();
  },
};
</script>

<style lang="scss" scoped>
.checklists-widget.tpl-default {
  position: fixed;
  bottom: 30px;
  left: 30px;
  z-index: 99999;
  transition: all 0.5s ease-in-out;

  .checklists-widget-button {
    position: relative;
    height: 42px;
    padding: 11px 17px 11px 15px;
    margin: 8px;
    font-family: Lato, "Source Sans Pro", Helvetica, Arial, sans-serif;
    color: white;
    background-color: rgb(13, 44, 109);
    border-radius: 120px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
    white-space: nowrap;
    cursor: pointer;
    z-index: 2;
    user-select: none;
    outline: none;
    transition: background-color 0.25s ease-out;

    .checklists-widget-button__icon {
      width: 20px;
      height: 20px;
      margin-right: 8px;

      svg {
        display: block;
        width: 20px;
        height: 20px;
        color: #FFF;
      }
    }

    .checklists-widget-button__text {
      font-size: 16px;
      font-weight: 700;
      line-height: 1;
      color: #FFF;
    }

    .checklists-widget-button__count {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -6px;
      right: -6px;
      width: 20px;
      height: 20px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      color: #0d2c6d;
      font-size: 14px;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      background-color: #fff;
      border-radius: 50%;
    }
  }

  .checklists-completed-caption {
    margin-right: 5px;
    white-space: nowrap;
    font-size: 13px;
    font-weight: 700;
    line-height: 18px;
  }

  .checklists-progress-bar {
    position: relative;
    width: 100%;
    height: 15px;
    background: #EBEBEB;
    border-radius: 50px;
    overflow: hidden;

    .checklists-progress-bar__complete {
      width: 0;
      height: 15px;
      // background: #4998E6;
      background-image: linear-gradient(-60deg, #4998E6 25%, #1C7EE0 25%, #1C7EE0 50%, #4998E6 50%, #4998E6 75%, #1C7EE0 75%, #1C7EE0);
      background-size: 30px 40px;
      animation: move 2s linear infinite;
    }
  }

  .checklists-widget-content {
    position: absolute;
    bottom: 30px;
    width: 340px;
    margin: 0 8px 15px;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0 4px 14px 0 rgba(28, 126, 224, 0.18);
    z-index: 99999;
    opacity: 0;
    transition: all 0.15s ease;
    pointer-events: none;

    .checklists-widget-content__header {
      padding: 20px 20px 15px 20px;
      border-bottom: 1px solid #E8F2FB;
      cursor: default;

      .checklists-widget-content__header-title {
        font-size: 18px;
        font-weight: 700;
        color: #1F293B;
      }

      .checklists-widget-content__header-description {
        margin: 5px 0 20px 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color: #1F293B;
      }
    }

    .checklists-widget-content__task {
      .checklists-widget-content__task-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        margin-right: 16px;
        background-color: #FFF;
        border: 1px solid #BAD5F0;
        border-radius: 100px;

        svg {
          display: inline-block;
          width: 18px;
          height: 18px;
          color: #FFF;
        }
      }

      .checklists-widget-content__task-title {
        padding: 13px 10px;
        font-size: 15px;
        font-weight: 600;
        line-height: 18px;
        text-align: center;
        color: #FFF;
        background: #1D94FF;
        border-radius: 4px;

        &:hover {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), #1D94FF;
        }
      }

      ul {
        margin-top: 0.5rem;
        margin-bottom: 0;
        padding: 0;

        li {
          padding: 6px 20px;
          list-style-type: none;
          color: #555;
          cursor: pointer;
        }
      }

      a {
        text-decoration: none;
      }

      .completed {
        .checklists-widget-content__task-icon {
          background-color: #749EC8;
          border: none;
        }

        .checklists-widget-content__task-title {
          font-weight: 400;
          color: rgba(#749EC8, 0.5);
          background: #F3F6F9;
          text-decoration: line-through;
        }
      }
    }

    .checklists-widget-content__no-thanks-button {
      width: auto;
      margin: 12px 20px 20px 20px;
      padding: 0;
      font-size: 14px;
      font-weight: 400;
      font-style: italic;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.5);
      opacity: 0.3;
      cursor: pointer;
      user-select: none;

      &:hover {
        color: #0D2C6D;
      }
    }

    .checklists-widget-content__close {
      position: absolute;
      top: 20px;
      right: 20px;
      color: #1F293B;
      cursor: pointer;
      user-select: none;

      svg {
        display: block;
        width: 20px;
        height: 20px;
        color: #1F293B;
      }

      &:hover {
        color: #474747;

        svg {
          color: #474747;
        }
      }
    }

    &.expanded {
      opacity: 1;
      bottom: 50px;
      pointer-events: auto;
    }

    @media (max-width: 575.98px) {
    }
  }
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 30px 0;
  }
}
</style>
