import {runIfHasSelecter} from '../../utils/panel-lib';
import route from '../index.js';

const initScripts = function() {
  $(document).on('click', function (e) {

    /** Button Activate */
    if ($(e.target).closest('[data-button-action="activate"]').length > 0 || typeof $(e.target).data('button-action') !== 'undefined') {
      let $button = $(e.target);
      if ($(e.target).closest('[data-button-action="activate"]').length > 0) {
        $button = $(e.target).closest('[data-button-action="activate"]');
      }

      let $form = $button.closest('form');
      let $errorBox = $form.find('.error-box');
      let url = $form.find('[name="url"]').val();
      let anchor = $form.find('[name="anchor"]').val();

      let params = {
        blogId: $button.data('blog-id'),
        period: $button.data('period'),
        url: url,
        anchor: anchor,
      };

      $errorBox.html('').hide();
      $button.prop('disabled', true);

      axios.post(route('api.backlinks.links.create'), params).
          then(function(response) {
            let data = (response.data ? response.data : {});

            if (data && data.success) {
              document.location.href = route('backlinks.homepage.index');
            } else {
              $errorBox.html(data.message).show();
              $button.prop('disabled', false);
            }
          }).
          catch(function() {
            $errorBox.html('Internal error').show();
            $button.prop('disabled', false);
          });
    }

  });
};

export const initBacklinksHomepageActivateScripts = () => {
  runIfHasSelecter('[data-role="backlinks-homepage-activate"]', [
    initScripts,
  ]);
};
