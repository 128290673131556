<template>
  <Carousel
      class="screenshots-carousel d-none d-sm-block"
      v-if="!loading && screenshots"
      ref="screenshotsCarousel"
      :autoplay="113000"
      :wrap-around="true"
      :i18n="{
        'iconArrowLeft': iconArrowLeft,
        'iconArrowRight': iconArrowRight
      }"
  >
    <Slide v-for="(screenshot, key) in screenshots" :key="key">
      <div class="carousel__item">
        <div class="carousel__item-img">
          <img :src="screenshot.imageSrc" :alt="key">
        </div>
        <div class="carousel__item-title" v-html="screenshot.title"></div>
        <div class="carousel__item-desc" v-html="screenshot.description"></div>
      </div>
    </Slide>
    <template #addons>
      <Navigation/>
      <Pagination/>
    </template>
  </Carousel>
</template>

<script>
import {defineComponent, ref} from 'vue';
import {Carousel, Navigation, Pagination, Slide} from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

const screenshots = ref(null);
const errors = ref(null);
const loading = ref(false);

export default defineComponent({
  name: 'Screenshot Carousel',
  components: {
    Carousel,
    Slide,
    Navigation,
    Pagination,
  },
  setup() {
    return {
      screenshots,
      errors,
      loading,
      iconArrowLeft: '<svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 1L2 9L10 17" stroke="currentColor" stroke-width="1.5"></path></svg>',
      iconArrowRight: '<svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L9 9L1 17" stroke="currentColor" stroke-width="1.5"></path></svg>',
    };
  },
  methods: {
    fetchData() {
      errors.value = null;
      loading.value = true;

      axios.get('/api/screenshots-carousel').then(response => {
        loading.value = false;
        if (response && response.data) {
          screenshots.value = response.data.data.screenshots;
        }
      }).catch(error => {
        loading.value = false;
        if (errors && errors.response) {
          errors.value = error.response.data.message || error.message;
        }
      });
    },
  },
  mounted() {
    this.fetchData();
  },
});
</script>

<style lang="scss">
.screenshots-carousel {
  .carousel__item {
    position: relative;
    width: 100%;
    min-height: 380px;

    .carousel__item-img {
      padding: 28px;

      img {
        width: auto;
        height: 260px;
        border: 3px solid #CFDDF5;
        border-radius: 5px;
        filter: drop-shadow(0px 4px 22px rgba(255, 255, 255, 0.25));
      }
    }

    .carousel__item-title {
      margin: 0 auto 9px auto;
      max-width: 380px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #fff;
    }

    .carousel__item-desc {
      margin: 0 auto 16px auto;
      max-width: 380px;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #FFF;
      opacity: 0.5;
    }
  }

  .carousel__slide {
    padding: 10px;
  }

  .carousel__prev,
  .carousel__next {
    box-sizing: content-box;
    color: #FFF;
    background: none;
    border: none;
  }

  .carousel__prev {
    margin: -68px 0 0 -20px;
  }

  .carousel__next {
    margin: -68px -20px 0 0;
  }

  .carousel__pagination-button {
    padding: 8px;

    &:after {
      width: 5px;
      height: 5px;
      background: #D9D9D9;
      border-radius: 50%;
      opacity: .3;
    }
  }

  .carousel__pagination-button--active {
    &:after {
      background: #FFF;
      opacity: 1;
    }
  }
}
</style>