<template>
  <div class="v-white-container-wrap vwcw">
    <div class="vwcw__top-bar" v-if="hasTopBar">
      <slot name="top-bar"/>
    </div>
    <v-white-container has-wrap :wrap-has-top-bar="hasTopBar">
      <slot name="content"></slot>
    </v-white-container>
  </div>
</template>

<script setup>
import {computed} from "vue";
import VWhiteContainer from "./VWhiteContainer.vue";

const props = defineProps({
  hasTopBar: {type:Boolean, default:false,}
});
const mainClass = computed(() => {
  let response = [];
  if(props.hasTopBar){
    return 'vwc--has-top-bar';
  }
  return response;
});
</script>

<style lang="scss" scoped>
.v-white-container-wrap.vwcw{

  width:100%;
  max-width:920px;
  border-radius: 8px;
  background:#fff;
  box-shadow: $box-shadow-default;

  &.vwc--has-top-bar{
    padding-top:0px;
  }

  .vwcw__top-bar{
    border-radius: 8px 8px 0px 0px;
    overflow:hidden;
  }

}
</style>