<template>
  <v-tooltip
      placement="bottom"
      ref="tooltip"
      :style="{
          width:'100%',
          maxWidth:'200px',
          padding:'0px 0px',
          borderRadius:'4px',
          border: '1px solid #D6E3EF',
      }"
      :btn-close-on="false"
      class="v-tooltip-select vts"
      :get-linked-block="props.getLinkedBlock"
      :max-height-visible-area="true"
  >
    <template v-slot:content>
      <div class="vts__tooltip" :class="mainClasses">
        <div class="vts__item vts__tooltip-title" v-if="tooltipTitle!==null">
          {{tooltipTitle}}
        </div>
        <div class="vts__item vts__tooltip-search" v-if="searchEnable">
          <v-search-bar
              :placeholder="searchTitle"
              v-model="searchOptions"
              skin="built-in"
          />
        </div>
        <template v-for="option in optionsFiltered">
          <div
              class="vts__item"
              @click="toggleOptionToModel(option)"
              :class="optionIsSelected(option) ? 'vts__item--selected' : ''"
          >
            <v-form-checkbox
                v-if="displayAsInputChecked"
              :value="optionIsSelected(option)"
              only-show
            />

            <span class="vts__item-name">
              {{option.name}}
              <span v-if="option.label" class="vts__item-label">
                {{option.label}}
              </span>
            </span>

            <v-icon-svg
                v-if="!displayAsInputChecked"
                name="select-checked"
                class="vts__item-svg"
            />

          </div>
        </template>
      </div>
    </template>
  </v-tooltip>
</template>

<script setup lang="ts">

import VIconSvg from "./VIconSvg.vue";
import VTooltip from "./VTooltip.vue";
import {computed, onMounted, provide, ref} from "vue";
import VFormCheckbox from "../VForm/VFormCheckbox.vue";
import VSearchBar from "./VSearchBar.vue";

const props = defineProps({
  options: {},
  getLinkedBlock: {type:Function, default:{}},
  multiSelect: {type:Boolean,default:false},
  closeAfterSelection: {type: Boolean, default:false},
  displayAsInputChecked: {type:Boolean, default:false},
  tooltipTitle: {default:null,},
  searchEnable: {default:false,},
  searchTitle: {default:null,},
});

const model = defineModel();
const emit = defineEmits(['change']);

const tooltip = ref();

const searchOptions = ref('');

const toggleOptionToModel = (option) => {

  if(props.multiSelect){
    let index = modelIndexOf(option);
    if(index === -1){
      model.value.push(option);
    }else{
      model.value.splice(index,1);
    }
  }else{
    if(model.value?.value !== option.value){
      model.value = option;
    }
  }

  if(props.closeAfterSelection){
    tooltip.value.hide();
  }

  emit('change', option);

}

const optionIsSelected = (option) => {
  if(props.multiSelect){
    return modelIndexOf(option) !== -1;
  }else{
    return model.value?.value === option.value
  }
}

const modelIndexOf = (option) => {
  let findKey = -1;
  model.value.some((item,key) => {
    if(item.value === option.value){
      findKey = key;
      return true;
    }
  });
  return findKey;
}

const toggle = () => {
  tooltip.value.toggle();
}
const hide = () => {
  tooltip.value.hide();
}

const tooltipOpened = computed(() => {
  return tooltip.value.dataShow;
});

const mainClasses = computed(() => {
  return props.displayAsInputChecked ? 'vts__tooltip--as-input-checked' : '';
});

const optionsFiltered = computed(() => {
  if(searchOptions.value!== null && searchOptions.value.trim().length > 0){
    return props.options.filter((option) => {
       return option.name.toLowerCase().includes(searchOptions.value.trim().toLowerCase());
    });
  }else{
    return props.options;
  }
});

defineExpose({toggle,hide,tooltipOpened});

</script>

<style scoped>
.vts__tooltip{
  .vts__item{
    padding:0px 10px;
    height:40px;
    transition-property: background-color;
    transition-duration: 0.2s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .vts__item-name{
      display: flex;
      align-items: center;

      .vts__item-label{
        font-size:12px;
        color:#596C8F;
        margin-left:4px;
      }
    }

    &:hover{
      background-color: #F4F9FD;
    }

    .vts__item-svg{
      width:20px;
      height:20px;
      opacity:0;
    }

    &.vts__item--selected{
      .vts__item-svg{
        opacity:1;
      }
    }

  }

  &.vts__tooltip--as-input-checked{
    .vts__item{
      padding:0px 16px;
      min-height:44px;
      height:initial;
      justify-content:flex-start;
      gap:16px;
      .vts__item-name{
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color:#384967;
      }
      &.vts__tooltip-title{
        font-size: 12px;
        font-weight: 400;
        color:#798CAE;
        min-height:initial;
        height:initial;
        padding-top:8px;
        padding-bottom:8px;
        &:hover{
          background-color:#fff;
          cursor:initial;
        }
      }
      &.vts__tooltip-search{
        padding:0px;
        border-bottom: 1px solid #D6E3EF80;
        &:hover{
          background-color:#fff;
          cursor:initial;
        }
      }
    }
  }
}
</style>