import {
  addToCart,
  formatNumberK,
  formatPrice,
  getExchangeRate,
  runIfHasSelecter,
  updateCoupon,
  updatePaymentOrder,
  updateVatId,
} from '../../utils/panel-lib';
import {api} from '../../utils/panel-api.js';
import axios from 'axios';
import route from '../index.js';
import _, {toString} from 'lodash';

const initCommon = function() {
  api.event.eventPageViewed();

  let orderId = 0;
  let promoTooltipTimeout = null;
  let $inputPlanSize = $('[name="plan_size"]');
  let $inputPlanType = $('[name="guaranteed_plan_type"]');
  let oldCarItems = null;
  let $cartListBlock = $('.cart-step');
  let check3dsTimeout = null;

  const setOldCartItems = () => {
    let $cartList = $('.cart-list');

    oldCarItems = [];
    $cartList.find('.cart-list__item').each(function(index, elem) {
      oldCarItems.push({
        'id': $(this).data('id'),
        'quantity': $(this).data('quantity'),
        'size': $(this).data('size'),
      });
    });
  };

  const startCartQuantityAnimation = () => {
    let $cartList = $('.cart-list');

    if (oldCarItems) {
      $cartList.find('.cart-list__item').each(function(index, elem) {
        let isNewElem = true;

        oldCarItems.forEach(function(item) {
          if (item.id === $(elem).data('id')) {
            isNewElem = false;

            if (item.quantity !== $(elem).data('quantity')) {
              $(elem).addClass('animAddToCart');
            }
          }
        });

        if (isNewElem) {
          $(elem).addClass('animAddToCart');
        }
      });
    }
  };

  // Add to Cart
  $('[data-ajax-action="add-to-cart"]').on('click', function() {
    let planOptionId = parseInt($(this).data('plan-option-id'));
    let planSize = $inputPlanSize.val();
    let planType = $inputPlanType.val();
    let planSizeHits = parseInt($inputPlanSize.data('hits') / 1000);
    let creditType = $(this).data('credit-type');
    let quantity = parseInt($(this).data('quantity'));
    let quantityX = parseInt($(this).data('quantity-x'));

    // if (creditType === 'website-traffic') {
    // let $button = $(this);

    // planType = (planType === 'economy' ? '' : '_' + planType);
    // let planUnlimType = planType.replace('alexa', 'pro');
    // if (planSize === 'unlim') {
    //   planType = planUnlimType;
    // }

    // let selectedFirstKey = _.keys(guaranteedPlansOptions["bySize"][planSize + planType])[0];
    // let selectedPlanOption1 = guaranteedPlansOptions["bySize"][planSize + planType][selectedFirstKey];
    // let selectedPlanOptionId = parseInt(selectedPlanOption1["id"]);

    // let firstKey = _.keys(guaranteedPlansOptions["bySize"]['unlim' + planUnlimType])[0];
    // let planOption1 = guaranteedPlansOptions["bySize"]['unlim' + planUnlimType][firstKey];

    // addToCart(planOptionId, quantity, quantityX, false, 'button');
    // } else {
    // let $button = $(this);
    // let planOptionId = parseInt($(this).data('plan-option-id'));
    // let quantity = parseInt($(this).data('quantity'));

    // addToCart(planOptionId, quantity, quantityX, false, 'button');
    // }

    addToCart(planOptionId, parseInt(quantity), parseInt(quantityX), false, 'button');

    if ($cartListBlock.length > 0) {
      $('html, body').animate({
        scrollTop: $cartListBlock.offset().top - 30,
      }, {
        duration: 150,
        easing: 'linear',
      });
    }
  });

  $('.payment-order').on('click', function(e) {
    // Apply Vat Id
    if ($(e.target).hasClass('button-apply-vat-id') ||
        $(e.target).closest('.button-apply-vat-id').length > 0) {
      updateVatId();
    }

    // Apply Coupon
    if ($(e.target).hasClass('button-apply-coupon') ||
        $(e.target).closest('.button-apply-coupon').length > 0) {
      updateCoupon();
    }
  });

  // Currency
  $('select[name="currency"]').on('change', function () {
    let amount = parseFloat($('.payment-order__total').data('amount'));
    let selectCurrency = $(this).val();
    let selectCurrencySymbol = $(this).find('option:selected').data('symbol');
    let amountCurrency = 0.0;

    if (selectCurrency === 'USD') {
      $('.amount-currency .for-js').html('');
      $('.amount-currency').hide();
    } else {
      getExchangeRate(selectCurrency).then(function(response) {
        if (response.data && response.data.success && response.data.data) {
          let data = response.data.data;
          let rate = data.rate ? data.rate : 1.0;

          amountCurrency = (amount * parseFloat(rate)).toFixed(2);
          $('.amount-currency .for-js').html(selectCurrencySymbol + '&nbsp;' + amountCurrency);
          $('.amount-currency').show();
        }
      });
    }

    $('[data-pay-by="paycg"]').attr('href', $('[data-pay-by="paycg"]').data('href') + '&currency=' + selectCurrency);
    $('[data-pay-by="paymentwall"]').attr('href', $('[data-pay-by="paymentwall"]').data('href') + '&currency=' + selectCurrency);
  });

  $('.coupon-action .coupon-action__button').on('click', function() {
    let couponCode = $(this).closest('[data-role]').data('coupon-code');
    let $couponBlock = $('.coupon-block');

    if ($couponBlock.length > 0) {
      $('html, body').animate({
        scrollTop: $couponBlock.offset().top,
      }, {
        duration: 150,
        easing: 'linear',
      });
    }

    $('#coupon_code').val(couponCode);
    $('#apply_coupon').trigger('click');

    return false;
  });

  // Selected Tab
  let $tabEl = $('.buy-credits-nav-tabs a[data-bs-toggle="tab"]');
  $tabEl.each(function(index, elem) {
    if ($(elem).hasClass('active')) {
      if ($(elem).attr('href') === '#tab_web_traffic_credits') {
        $('.promo-cart').hide();
        $('.promo-cart.promo-cart__website-traffic').show();
      } else if ($(elem).attr('href') === '#tab_seo_traffic_credits') {
        $('.promo-cart').hide();
        $('.promo-cart.promo-cart__seo-traffic').show();
      } else if ($(elem).attr('href') === '#tab_backlinks_credits') {
        $('.promo-cart').hide();
        $('.promo-cart.promo-cart__backlinks').show();
      }
    }
  });

  $tabEl.on('shown.bs.tab', function (event) {
    let $tooltipsSlider = $('.tooltip-slider');
    let $tooltipPromo = $('[data-bs-toggle="promo-tooltip"]');

    if ($(event.target).attr('href') === '#tab_web_traffic_credits') {
      // Position point from slider
      $('.guaranteed-sizes__slider-titles .for-js-width').each(function(index, elem) {
        $(elem).css('margin-left', -1 * ($(elem).width() / 2));
      });

      $('.promo-cart').hide();
      $('.promo-cart.promo-cart__website-traffic').show();
      $tooltipPromo.tooltip('update');
      $tooltipsSlider.show();
      if (typeof $('.ui-slider-handle').tooltip === 'function') {
        $('.ui-slider-handle').tooltip('update');
      }
    } else if ($(event.target).attr('href') === '#tab_seo_traffic_credits') {
      $('.promo-cart').hide();
      $('.promo-cart.promo-cart__seo-traffic').show();
      $tooltipPromo.tooltip('update');
      $tooltipsSlider.hide();
    } else if ($(event.target).attr('href') === '#tab_backlinks_credits') {
      $('.promo-cart').hide();
      $('.promo-cart.promo-cart__backlinks').show();
      $tooltipPromo.tooltip('update');
      $tooltipsSlider.hide();
    }
  });

  let $cartElements = $('.payment-order');
  $cartElements.on('cart-updating', function() {
    // Promo Tooltip
    $('[data-bs-toggle="promo-tooltip"]').tooltip('dispose');
  });

  $cartElements.on('cart-updated-complete', function(event, data) {
    if (typeof data.isEasy !== 'undefined' && data.isEasy) {
      return;
    }

    if (typeof data.source !== 'undefined' && data.source === 'button') {
      startCartQuantityAnimation();
    }
    setOldCartItems();

    if (jsPageData.requestCouponCode && $cartListBlock.length > 0) {
      let $couponBlock = $('.coupon-block');
      let $cartBlock = $('.cart-step');
      let $scrollToEl = $couponBlock

      if ($scrollToEl.length > 0) {
        if ($('#coupon_code').val() === jsPageData.requestCouponCode) {
          $scrollToEl = $cartBlock;
        } else {
          $('#coupon_code').val(jsPageData.requestCouponCode);
          jsPageData.requestCouponCode = null;
        }

        $('html, body').animate({
          scrollTop: $scrollToEl.offset().top,
        }, {
          duration: 150,
          easing: 'linear',
        });
      }
    }

    // Promo Tooltip
    const deviceType = $('[data-device-type]').data('device-type');
    const tooltipTriggerList = document.querySelectorAll(
        '[data-bs-toggle="promo-tooltip"]');

    [...tooltipTriggerList].map(function(tooltipTriggerEl) {
      if (!$(tooltipTriggerEl).is(':visible')) {
        return;
      }

      const template = '<div class="tooltip promo" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>';

      return new bootstrap.Tooltip(tooltipTriggerEl, {
            container: 'body',
            placement: (deviceType === 'phone' || deviceType === 'tablet'
                ? 'bottom'
                : 'top'),
            // fallbackPlacements: ['top', 'bottom', 'right', 'left'],
            fallbackPlacements: ['top', 'bottom'],
            trigger: 'manual',
            template: template,
            sanitize: false,
          },
      );
    });

    $('[data-bs-toggle="promo-tooltip"]').on('shown.bs.tooltip', function(event) {
      let $this = $(this);
      let tooltipId = $this.attr('aria-describedby');
      if (!tooltipId) {
        return;
      }

      $('#' + tooltipId).find('[data-action="tooltip-no"]').
          off('click').
          on('click', function() {
            $this.tooltip('hide');
          });

      $('#' + tooltipId).find('[data-action="tooltip-yes"]').
          off('click').
          on('click', function() {
            let planOptionId = parseInt($(this).data('plan-option-id'));
            let quantity = parseInt($(this).data('quantity'));
            let quantityX = parseInt($(this).data('quantity-x'));

            addToCart(planOptionId, quantity, quantityX, true);

            $this.tooltip('hide');
          });
    });

    clearTimeout(promoTooltipTimeout);
    promoTooltipTimeout = setTimeout(function() {
      $('[data-bs-toggle="promo-tooltip"]:visible').tooltip('show');
    }, 500);
  });

  if (jsPageData.isRequestAddToCart && $cartListBlock.length > 0) {
    $('html, body').animate({
      scrollTop: $cartListBlock.offset().top - 30,
    }, {
      duration: 150,
      easing: 'linear',
    });
  }

  if (jsPageData.isCartFromOrder && jsPageData.orderId && jsPageData.paymentMethod === 'fastspring') {
    window.scroll({top: document.body.scrollHeight, behavior: 'smooth'});
    $('body').addClass('loading');
    updatePaymentOrder(jsPageData.orderId);
  } else {
    updatePaymentOrder();
  }
};

const initFormPaymentCard = function() {
  let $iframe3dVerification = $('.iframe-3d-verification');
  let form = document.querySelector('form[data-form-id="form-payment-card"]');
  let name = document.getElementById('card_name');
  let number = document.getElementById('card_number');
  let date = document.getElementById('card_expiration');
  let dateM = document.getElementById('card_expiration_m');
  let dateY = document.getElementById('card_expiration_y');
  let cvv = document.getElementById('card_cvv');
  let $userSavedCards = $('.user-saved-cards');

  function displayIframe3dVerification(isShow = true) {
    if (isShow) {
      $('.iframe-3d-verification').show();
      $('.form-payment-card').hide();
    } else {
      $('.iframe-3d-verification').hide();
      $('.form-payment-card').show();
    }
  }

  /*  SHOW ERROR  */
  function showError(element, error) {
    if (error === true) {
      element.style.opacity = '1';
    } else {
      element.style.opacity = '0';
    }
  }

  function numberAutoFormat() {
    let valueNumber = number.value;
    // if white space change to ''. If is not a number between 0-9 change to ''
    let v = valueNumber.replace(/\s+/g, '').replace(/[^0-9]/gi, '');

    // the value got min of 4 digits and max of 16
    let matches = v.match(/\d{4,16}/g);
    let match = matches && matches[0] || '';
    let parts = [];

    for (let i = 0; i < match.length; i += 4) {
      // after 4 digits add a new element to the Array
      // e.g. "4510023" -> [4510, 023]
      parts.push(match.substring(i, i + 4));
    }

    if (parts.length) {
      // add a white space after 4 digits
      return parts.join(' ');
    } else {
      return valueNumber;
    }
  }

  function isNotDate(element) {
    let actualDate = new Date();
    let month = actualDate.getMonth() + 1; // start january 0 we need to add + 1
    let year = Number(actualDate.getFullYear().toString().substr(-2)); // 2022 -> 22

    let dateNumber = element.value.match(/\d{2,4}/g);
    if (!dateNumber) {
      return true;
    }

    let monthNumber = Number(dateNumber[0]);
    let yearNumber = Number(dateNumber[1]);

    return element.value === '' || monthNumber < 1 || monthNumber > 12 ||
        yearNumber < year || (monthNumber <= month && yearNumber === year);
  }

  function dateAutoFormat() {
    let dateValue = date.value;
    // if white space -> change to ''. If is not a number between 0-9 -> change to ''
    let v = dateValue.replace(/\s+/g, '').replace(/[^0-9]/gi, '');

    // min of 2 digits and max of 4
    let matches = v.match(/\d{2,4}/g);
    let match = matches && matches[0] || '';
    let parts = [];

    for (let i = 0; i < match.length; i += 2) {
      // after 4 digits add a new element to the Array
      // e.g. "4510023" -> [4510, 023]
      parts.push(match.substring(i, i + 2));
    }

    if (parts.length) {
      // add a white space after 4 digits
      return parts.join('/');
    } else {
      return dateValue;
    }
  }

  /*  CHANGE THE FORMAT NAME  */
  if (name) {
    name.addEventListener('input', function() {
      let alert1 = document.getElementById('alert-1');
      let error = this.value === '';
      showError(alert1, error);
    });
  }

  /*  CHANGE THE FORMAT CARD NUMBER*/
  if (number) {
    number.addEventListener('input', function(e) {
      this.value = numberAutoFormat();

      //show error when is different of 16 numbers and 3 white space
      let error = this.value.length !== 19;
      let alert2 = document.getElementById('alert-2');
      showError(alert2, error);
    });
  }

  /*  CHANGE THE FORMAT DATE  */
  if (date) {
    date.addEventListener('input', function(e) {
      this.value = dateAutoFormat();

      // show error if is not a valid date
      let alert3 = document.getElementById('alert-3');
      let isNoDate = isNotDate(this);
      showError(alert3, isNoDate);

      if (!isNoDate) {
        let dateNumber = date.value.match(/\d{2,4}/g);
        dateM.value = dateNumber[0];
        dateY.value = dateNumber[1];
      }
    });
  }

  /*  CHANGE THE FORMAT CVV  */
  if (cvv) {
    cvv.addEventListener('input', function(e) {
      let alert4 = document.getElementById('alert-4');
      let error = this.value.length < 3;
      showError(alert4, error);
    });
  }

  /*  VALIDATION FORM WHEN PRESS THE BUTTON   */
  if (form) {
    form.addEventListener('submit', function(e) {
      let carId = parseInt($('[name="card_id"]').val());
      if (carId) {
        return true;
      }

      let inputs = document.querySelectorAll('form[data-form-id="form-payment-card"] input');
      for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].value === '' &&
            (inputs[i].getAttribute('type') === 'text' ||
                inputs[i].getAttribute('type') === 'password'))
        {
          inputs[i].nextElementSibling.style.opacity = '1';
        }
      }

      if (name.value === '' || number.value.length !== 19 ||
          date.value.length !== 5 || isNotDate(date) === true ||
          cvv.value.length < 3)
      {
        e.preventDefault();

        return false;
      }
    });
  }

  window.addEventListener(
      'message',
      (e) => {
        console.log('message', e.data);
        if (e.data === '3DS-complete') {
          displayIframe3dVerification(false);
          $iframe3dVerification.remove();
        }
      },
      false,
  );

  // userSavedCards block
  // let cardForPaymentId = parseInt($userSavedCards.find('[name="card_for_payment"]').val());
  // if (cardForPaymentId === 0) {
  //   $('.for-saved-card input').val('');
  //   $('.for-saved-card').show();
  //   $('.form-payment-card__right').show();
  // } else {
  //   $('.for-saved-card').hide();
  //   $('.form-payment-card__right').hide();
  // }
  //
  // $userSavedCards.find('select[name="card_for_payment"]').on('change', function() {
  //   let cardForPaymentId = parseInt($(this).val());
  //   if (cardForPaymentId === 0) {
  //     $('.for-saved-card input').val('');
  //     $('.for-saved-card').show();
  //     $('.form-payment-card__right').show();
  //   } else {
  //     $('.for-saved-card').hide();
  //     $('.form-payment-card__right').hide();
  //   }
  //
  //   $('[name="card_id"]').val($(this).val());
  // });
};

export const initCartScripts = () => {
  /**
   * @property {object} jsPageData
   */

  runIfHasSelecter('[data-role="cart"]', [
    initCommon,
    initFormPaymentCard
  ]);
};
