import {formValidationErrorHandling, runIfHasSelecter} from "../../utils/panel-lib";
import route from '../index.js';

const initButtonStartFreeTrial = () => {
    const buttonStartFreeTrial = $('[data-ajax-action="start-free-trial"]');

    buttonStartFreeTrial.on('click', function(e) {
        e.preventDefault();

        let $button = $(this);
        let $form = $button.closest('form');
        let url = $('[name="url"]').val();
        let keyword = $('[name="keyword"]').val();

        let data = {
            url: url,
            keyword: keyword
        }

        $button.prop('disabled', true);

        $.ajax({
            url: '/api/backlinks/projects',
            method: 'POST',
            data: data,
            dataType: 'json',
            success: function (response) {
                let data = (response.data ? response.data : []);

                if (response.success) {
                    $.ajax({
                        url: '/api/backlinks/start-free-trial',
                        method: 'POST',
                        dataType: 'json',
                        success: function (response) {
                            if (response.success) {
                                window.location.replace(route('backlinks.projects.index'));
                            } else {
                                $button.prop('disabled', false);
                            }
                        },
                        error: function () {
                            $button.prop('disabled', false);
                        }
                    });
                } else {
                    $button.prop('disabled', false);

                    if (response.message) {
                        $form.find('.error-box').html(response.message).show();
                    }

                    if (data.errorsForm) {
                        formValidationErrorHandling($form, data.errorsForm);
                    }
                }
            },
            error: function () {
                $form.find('.error-box').html('An error occurred while creating project').show();

                $button.prop('disabled', false);
            }
        });
    });
}

export const initBacklinksStartFreeTrial = () => {
    runIfHasSelecter('[data-role="backlinks-start-free-trial"]', [
        initButtonStartFreeTrial,
    ]);
}
