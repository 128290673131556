import {defineStore} from "pinia";
import {ApiResource} from "../plugins/ApiExtends";
import {useRoutes} from "./useRoutes";
import {isArray} from "lodash";
import {isObject, isValidDate} from "../utils/utils";
import {useUserBalance} from "./useStaticData";
import {CREDIT_TYPE_SEO} from "./useBilling";

export const useSeoClicksProjects = defineStore('/api/v1/seo-traffic/projects', {
    state: () => {
        return {
            _projects: [],
        }
    },
    actions: {
        async download(){
            let resource = new ApiResource({url: '/api/v1/seo-traffic/projects'});
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.$patch((state) => {
                    state._projects = [];
                    resource.data.forEach((project) => {
                        const useProject = useSeoClicksProject(project.id);
                        useProject.init(project);
                        state._projects.push(useProject);
                    })
                });
            }else{
                this.$patch({_projects: []});
            }
        },
        async updateRealtime() {

            let projectsIds = this._projects.map(i => i.id_integer);
            if(projectsIds.length === 0) return [true];

            let resource = new ApiResource({url: '/api/minutes-chart-data-by-group', params: {project_ids: projectsIds}});
            let [status, data] = await resource.downloadAsync();
            if(status){
                Object.entries(data).forEach(([idInteger, chart_data_minutes]) => {

                    this._projects.forEach((project) => {
                        if(project.id_integer == idInteger){
                            project.init({chart_data_minutes});
                        }
                    });
                });
            }
        }
    },
});

export const useSeoClicksProject = (projectId) => defineStore('/api/v1/seo-traffic/projects/'+projectId, {
    state: () => {
        return {
            _api_params: {url:'/api/v1/seo-traffic/projects/'+projectId},
            _first_download: false,
            name: null,
            chart_data: null,
            count_keywords: null,
            count_recommendations: null,
            max_degree_recommendations: null,
            countries: null,
            daily_limit: null,
            devices: null,
            id: projectId,
            id_integer: null,
            is_active: null,
            is_deleted: false,
            search_engines_id: null,
            url: null,
            device_targeting_enabled: null,
            is_demo: null,
            count_clicks: null,
            diff_clicks: null,
            max_count_clicks: 50,
            /**
             * @typedef {Object} clicks_by_country_item
             * @property {number} count - Количество элементов.
             * @property {number} diff - Разница между значениями.
             */
            /**
             * @type {Object.<string, clicks_by_country_item>}
             */
            clicks_by_country: {},
            created_at: null,
            errors: {
                create: {
                    message: null,
                    errors: null,
                }
            },
            _insufficientBalance:null,
        }
    },
    getters: {
        chartLabels: (state) => {
            if(!isArray(state.chart_data?.labels)) return [];
            return state.chart_data.labels;
        },
        chartValues: (state) => {
            if(!isArray(state.chart_data?.data)) return [];
            return state.chart_data.data.map(item => item[1]);
        },
        chartValueName: (state) => {
            if(!state.chart_data.typeTraffic) return null;
            if(!state.chart_data.translations?.hasOwnProperty(state.chart_data.typeTraffic)) return state.typeTraffic;
            return state.chart_data.translations[state.chart_data.typeTraffic];
        },
        chartTotalValues(){
            return this.chartValues.reduce((s,i) => s+i);
        },

        countKeywords: (state) => state.count_keywords,
        countryCodes: (state) => state.countryCodes,
        devicesType: (state) => {
            if(!state.device_targeting_enabled){
                return null;
            }else{
                return state.devices;
            }
        },
        deviceTypeLabel: (state) => {
            switch(state.devicesType){
                case 'mobile' : return 'Mobile';
                case 'desktop' : return 'Desktop';
                case null : return 'All';
                default: return useProject.devices;
            }
        },
        status: (state) => Number(state.is_active) === 1,
        recommendations: (state) => {
            if(state.count_recommendations > 0){
                return [{type:state.max_degree_recommendations,title:state.count_recommendations}];
            }else{
                return [];
            }
        },
        linkProject: (state) => {
            return useRoutes().url('seo-clicks.get', {id:state.id});
        },
        shortUrl: (state) => {
            if(!state.url) return '';
            let strippedPrefix = state.url.replace(/^(https?:\/\/)/, '');
            let strippedSuffix = strippedPrefix.replace(/\/+$/, '');

            return strippedSuffix;
        },
        correctUrl: (state) => {
            if(!state.url)return '';
            if (!state.url.startsWith('http://') && !state.url.startsWith('https://')) {
                return 'http://' + state.url;
            }
            return state.url;
        },
        demoCompleted(state){
            return state.is_demo && this.demoCountClicks >= state.max_count_clicks;
        },
        demoCountClicks: (state) => {
            return state.count_clicks > state.max_count_clicks ? state.max_count_clicks : state.count_clicks;
        },
        demoMaxCountClicks: (state) => {
            return state.max_count_clicks;
        },
        /**
         * Баланс недостаточный?
         * @return {boolean} - true, если денег не достаточно
         */
        insufficientBalance: (state) => {

            if(state._insufficientBalance!==null){
                return state._insufficientBalance;
            }

            const balance = useUserBalance().getByTypeConstant(CREDIT_TYPE_SEO)?.balance;
            //const hasRegionKeyword = useSeoClicksKeywords(state.id).idOfActiveKeywords.some((keywordId) => {
            //    return useSeoClicksKeyword(state.id,keywordId).region !== null;
            //});

            if(balance === undefined){return false;}

            return (
                balance <= 0
                //|| (hasRegionKeyword && balance <= 1)
                //|| (hasRegionKeyword && state.device_targeting_enabled && balance <= 2)
            );

        },
        createdDate: (state) => {
            let date = new Date(state.created_at);
            return isValidDate(date) ? date : null;
        },
        createdDateStringISO(state){
            return this.createdDate ? this.createdDate.toISOString().split('T')[0] : null;
        },
    },
    actions: {
        downloadFirst(){
            if(!this._first_download){
                this.download();
            }
        },
        async download(){
            let resource = new ApiResource(this._api_params);
            let [status, data] = await resource.downloadAsync();
            if(status){
                this.init(data)
                return [status,data];
            }else{
                return [status,resource.errorMessage];
            }
        },
        init(data){
            this.$patch((state) => {
                Object.entries(data).forEach(([key,value]) => {
                    if(key === 'clicks_by_country'){
                        state[key] = isObject(value) ? value : {};

                    }else if(key === 'chart_data_minutes'){
                        useRealtimeSeoClicksProject(state.id).init(value);

                    }else if(state.hasOwnProperty(key)){
                        state[key] = value;
                    }
                });
            });
            return this;
        },
        async saveInfoProject(params, projectId = null){

            let resource = new ApiResource({
                url:'/api/v1/seo-traffic/projects/'+ (projectId ?? this.id),
                method:'put',
                params: params
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.$patch((state) => {
                    Object.keys(params).forEach((key) => state[key] = params[key]);
                });
                return [true];
            }else{
                return [false,resource.errorMessage];
            }
        },
        async saveName(name, projectId = null){
            return await this.saveInfoProject({name:name},projectId);
        },
        async saveUrl(url, projectId = null){
            let response = await this.saveInfoProject({url:url},projectId);
            let [status] = response;
            if(status){
                await useSeoClicksKeywords(this.id).startIntervalCheckPositionUpdating();
            }
            return response;
        },
        async saveDeviceType(deviceType, projectId = null){

            let params = {}

            if(!deviceType){
                params.device_targeting_enabled = 0;
            }else{
                params.device_targeting_enabled = 1;
                params.device = deviceType;
            }

            let resource = new ApiResource({
                url:'/api/v1/seo-traffic/projects/'+ (projectId ?? this.id),
                method:'put',
                params: params
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.$patch((state) => {
                    if(params.hasOwnProperty('device')){
                        state.devices = params.device;
                    }
                    state.device_targeting_enabled = params.device_targeting_enabled;
                });
                return [true];
            }else{
                return [false,resource.errorMessage];
            }

        },
        async saveSearchEnginesId(search_engines_id, projectId = null){
            return await this.saveInfoProject({search_engines_id:search_engines_id},projectId);
        },
        async saveDailyLimit(daily_limit, projectId = null){
            return await this.saveInfoProject({daily_limit:daily_limit},projectId);
        },
        async saveIsActive(isActive, projectId = null){

            let resource = new ApiResource({
                url:'/api/v1/seo-traffic/projects/'+(projectId ?? this.id)+'/active',
                method:'put',
                params: {
                    is_active: isActive ? 1 : 0
                }
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.$patch({is_active: !!isActive});
                return [true];
            }else{
                this.$patch({is_active: !isActive});
                return [false,resource.errorMessage];
            }
        },
        async saveIsDeleted(isDeleted, projectId = null){
            return await this.saveInfoProject({is_deleted: isDeleted ? 1 : 0},projectId);
        },
        formatDate(date) {
            const year = date.getFullYear();
            // Добавляем 1, потому что месяцы начинаются с 0
            // `padStart(2, '0')` добавляет ведущий ноль, если месяц состоит из одной цифры
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');

            return `${year}-${month}-${day}`;
        },
        async updateChartData(startDate,endDate,projectId = null){

            let resource = new ApiResource({
                url:'/api/v1/seo-traffic/projects/'+(projectId ?? this.id)+'/daily-chart-data',
                params: {
                    from: this.formatDate(startDate),
                    to: this.formatDate(endDate),
                }
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.$patch({chart_data: resource.data});
                return [true];
            }else{
                return [false,resource.errorMessage];
            }

        },

        /**
         * Создание проекта SeoTraffic
         *
         * @param {Object} fields
         *  @param {string} fields.url
         *
         *  @param {Array.<{keyword: String, geo?: String, is_suggested?: Boolean}>} fields.keywords Массив объектов.
         *
         *  @param {boolean} fields.daily_limit_enabled
         *  @param {number} fields.daily_limit
         *
         *  @param {boolean} fields.device_targeting_enabled
         *  @param {number} fields.search_engines_id
         *
         *  @param {boolean} fields.geo_targeting_enabled
         *
         * @returns {[status:boolean, response?:Object]}
         */
        async create(fields){

            let resource = new ApiResource({
                url: '/api/v1/seo-traffic/projects',
                method: 'post',
                data: fields
            });

            await resource.downloadAsync();

            if(resource.isSuccess()){
                this.errors.create.message = null;
                this.errors.create.errors = null;
                return [true,resource.data];
            }else{
                this.errors.create.message = resource.errorMessage;
                this.errors.create.errors = resource.errorErrors;
                return [false];
            }
        },

        async convertDemoToReal(){
            let resource = new ApiResource({
                url: `/api/v1/seotraffic-projects/${this.id}/convert-demo`,
                method: 'PUT',
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                await this.download();
                return [true];
            }else{
                return [false, resource.errorMessage];
            }
        }
    },
})();

export const useRealtimeSeoClicksProject = (projectId) => defineStore('/api/v1/seo-traffic/projects/'+projectId+'/minutes-chart-data',{
    state: () => {
        return {
            _chartData:null,
            projectId: projectId,
        }
    },
    getters: {
        chartData: function(state){
            return state._chartData;
        },
        values: (state) => {
            if(!isArray(state._chartData?.data)) return [];
            return state._chartData.data.map(item => item[1]);
        },
    },
    actions: {
        async update () {
            let resource = new ApiResource({
                url:'/api/v1/seo-traffic/projects/'+this.projectId+'/minutes-chart-data/',
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.init(resource.data);
            }else{
                console.log('error realtime');
            }
        },
        init(data){
            this.$patch({_chartData: data});
        }
    }
})();

export const useSeoClicksKeywords = (projectId) => defineStore('/api/v1/seo-traffic/projects/'+projectId+'/keywords', {
    state: () => {
        return {
            _projectId: projectId,
            _keywords: {},
            _downloading: false,
            _api_params: {url:'/api/v1/seo-traffic/projects/'+projectId+'/keywords'},
            _filter: null,
            _sortBy: null,
            _searchKeyword: null,
            _listChecked: {},
            _checkboxTriState: 0,
            _typesSortBy: [
                {
                    value: 'date_added_decrease',
                    name: 'New first',
                    func: (a,b) => {
                        return (a.id < b.id) ? 1 : -1;
                    },
                },
                {
                    value: 'date_added_increase',
                    name: 'New last',
                    func: (a,b) => {
                        return (a.id > b.id) ? 1 : -1;
                    },
                },
                {
                    value: 'name_increase',
                    name: 'Name, ascending',
                    func: (a,b) => {
                        let textA = a.keyword.toUpperCase();
                        let textB = b.keyword.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    },
                },
                {
                    value: 'name_decrease',
                    name: 'Name, descending',
                    func: (a,b) => {
                        let textA = b.keyword.toUpperCase();
                        let textB = a.keyword.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    },
                },
            ],
            _typesFilter: [
                {
                    value: 'hide_inactive',
                    name: 'Hide inactive',
                    func: (item) => {return !!item.is_active}
                },
                {
                    value: 'show_deleted',
                    name: 'Show deleted',
                    func: (item) => {return true}
                }
            ]
        }
    },
    getters: {
        isDownloaded: function(state){
            return !state._downloading;
        },
        keywordsShowedIds: function(state){
            let showedIds = [];
            let funcsFilter = state._filter ? state._filter.map((filter) => {return filter.func;}) : [];
            let searchKeyword = (typeof state._searchKeyword === 'string' && state._searchKeyword.length > 0) ? state._searchKeyword : false
            let hasFilterShowDeleted = state._filter ? state._filter.some((filter) => filter.value==='show_deleted') : false

            Object.keys(state._keywords).forEach((keywordId) => {
                let keyword = state._keywords[keywordId];
                if(!keyword.is_deleted || hasFilterShowDeleted){
                    showedIds.push({is_show:true,id:keyword.id});
                }else{
                    showedIds.push({is_show:false,id:keyword.id});
                }
                let showedId = showedIds.find((item) => item.id === keyword.id);

                funcsFilter.forEach((funcFilter) => {
                    if(showedId.is_show){
                        showedId.is_show = funcFilter(keyword);
                    }
                });

                if(searchKeyword && showedId.is_show) {
                    showedId.is_show = keyword.keyword.includes(searchKeyword);
                }

            });

            if(state._sortBy){
                showedIds = showedIds.sort((a,b) => state._sortBy?.func(state._keywords[a.id],state._keywords[b.id]));
            }
            return showedIds;
        },
        countChecked: function(state){
            let counter = 0;
            for(let [key,showedId] of Object.entries(state.keywordsShowedIds)){
                if(showedId.is_show && state._listChecked[showedId.id]){
                    counter++;
                }
            }
            return counter;
        },
        countShowedIds: function(state){
            let counter = 0;
            for(let [key,showedId] of Object.entries(state.keywordsShowedIds)){
                if(showedId.is_show){
                    counter++;
                }
            }
            return counter;
        },
        /**
         * @return {*[]}
         */
        idOfActiveKeywords: function(state){
            let response = [];
            Object.keys(state._keywords).forEach((keywordId) => {
                let keyword = state._keywords[keywordId];
                if(keyword.is_active && !keyword.is_deleted){
                    response.push(keywordId);
                }
            });
            return response;
        }
    },
    actions: {
        async download(){
            this.$patch({_downloading:true});
            let resource = new ApiResource(this._api_params);
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.$patch((state) => {
                    state._keywords = {}
                    state._listChecked = {}

                    const currentUrl = new URL(window.location.href);
                    const demoKeywords = currentUrl.searchParams.get('demo-keywords');
                    if (demoKeywords === 'true') {
                        let data = '[' +
                            '{"id":94564501,"keyword":"buy organic traffic","is_active":1,"is_deleted":0,"max_clicks_per_day":1,"language":"en","region":null,"chart_data":{"translations":{"pageViews":"Page Views","hits":"Hits","visits":"Visits","clicks":"Clicks"},"data":[[0,0],[1,0],[2,0],[3,0],[4,0],[5,0],[6,10],[7,0],[8,0],[9,8],[10,0],[11,0],[12,0],[13,0],[14,0],[15,0],[16,0],[17,0],[18,0],[19,0],[20,0],[21,0],[22,0],[23,0],[24,0],[25,0],[26,0],[27,0],[28,0],[29,0],[30,0],[31,0]],"totalHits":0,"maxHits":0,"labels":["16 Aug","17 Aug","18 Aug","19 Aug","20 Aug","21 Aug","22 Aug","23 Aug","24 Aug","25 Aug","26 Aug","27 Aug","28 Aug","29 Aug","30 Aug","31 Aug","01 Sep","02 Sep","03 Sep","04 Sep","05 Sep","06 Sep","07 Sep","08 Sep","09 Sep","10 Sep","11 Sep","12 Sep","13 Sep","14 Sep","15 Sep","16 Sep"],"typeTraffic":"clicks"},"recommendations":[],"is_top100":1,"check_position_now":1,"daily_limit_recommendations":{"top_1":0,"top_3":0,"top_10":0}},' +
                            '{"id":94564502,"keyword":"buy website traffic searchseo","is_active":1,"is_deleted":0,"max_clicks_per_day":1,"language":"en","region":"af","chart_data":{"translations":{"pageViews":"Page Views","hits":"Hits","visits":"Visits","clicks":"Clicks"},"data":[[0,0],[1,0],[2,0],[3,0],[4,0],[5,0],[6,0],[7,0],[8,0],[9,0],[10,0],[11,0],[12,0],[13,0],[14,0],[15,0],[16,0],[17,0],[18,0],[19,0],[20,0],[21,0],[22,0],[23,0],[24,0],[25,0],[26,0],[27,0],[28,0],[29,0],[30,0],[31,0]],"totalHits":0,"maxHits":0,"labels":["16 Aug","17 Aug","18 Aug","19 Aug","20 Aug","21 Aug","22 Aug","23 Aug","24 Aug","25 Aug","26 Aug","27 Aug","28 Aug","29 Aug","30 Aug","31 Aug","01 Sep","02 Sep","03 Sep","04 Sep","05 Sep","06 Sep","07 Sep","08 Sep","09 Sep","10 Sep","11 Sep","12 Sep","13 Sep","14 Sep","15 Sep","16 Sep"],"typeTraffic":"clicks"},"recommendations":[{"message":"Your website should be in the Top 100 for the keyword","degree":"danger","type":"NOT_TOP_100"}],"is_top100":0,"check_position_now":1,"daily_limit_recommendations":{"top_1":0,"top_3":0,"top_10":0}},' +
                            '{"id":94564503,"keyword":"free traffic bot","is_active":1,"is_deleted":0,"max_clicks_per_day":28,"language":"en","region":"as","chart_data":{"translations":{"pageViews":"Page Views","hits":"Hits","visits":"Visits","clicks":"Clicks"},"data":[[0,0],[1,0],[2,0],[3,0],[4,0],[5,0],[6,0],[7,0],[8,0],[9,0],[10,0],[11,0],[12,0],[13,0],[14,0],[15,0],[16,0],[17,0],[18,0],[19,0],[20,0],[21,0],[22,0],[23,0],[24,0],[25,0],[26,0],[27,0],[28,0],[29,0],[30,0],[31,0]],"totalHits":0,"maxHits":0,"labels":["16 Aug","17 Aug","18 Aug","19 Aug","20 Aug","21 Aug","22 Aug","23 Aug","24 Aug","25 Aug","26 Aug","27 Aug","28 Aug","29 Aug","30 Aug","31 Aug","01 Sep","02 Sep","03 Sep","04 Sep","05 Sep","06 Sep","07 Sep","08 Sep","09 Sep","10 Sep","11 Sep","12 Sep","13 Sep","14 Sep","15 Sep","16 Sep"],"typeTraffic":"clicks"},"recommendations":[{"message":"Your website should be in the Top 100 for the keyword","degree":"danger","type":"NOT_TOP_100"}],"is_top100":0,"check_position_now":0,"daily_limit_recommendations":{"top_1":0,"top_3":0,"top_10":0}},' +
                            '{"id":94564504,"keyword":"buy website traffic searchseo","is_active":1,"is_deleted":0,"max_clicks_per_day":29,"language":"en","region":null,"chart_data":{"translations":{"pageViews":"Page Views","hits":"Hits","visits":"Visits","clicks":"Clicks"},"data":[[0,0],[1,0],[2,0],[3,0],[4,0],[5,0],[6,5],[7,0],[8,0],[9,0],[10,4],[11,0],[12,0],[13,0],[14,0],[15,0],[16,0],[17,0],[18,0],[19,0],[20,0],[21,0],[22,0],[23,0],[24,0],[25,0],[26,0],[27,0],[28,0],[29,0],[30,0],[31,0]],"totalHits":0,"maxHits":0,"labels":["16 Aug","17 Aug","18 Aug","19 Aug","20 Aug","21 Aug","22 Aug","23 Aug","24 Aug","25 Aug","26 Aug","27 Aug","28 Aug","29 Aug","30 Aug","31 Aug","01 Sep","02 Sep","03 Sep","04 Sep","05 Sep","06 Sep","07 Sep","08 Sep","09 Sep","10 Sep","11 Sep","12 Sep","13 Sep","14 Sep","15 Sep","16 Sep"],"typeTraffic":"clicks"},"recommendations":[],"is_top100":1,"check_position_now":1,"daily_limit_recommendations":{"top_1":0,"top_3":0,"top_10":0}},' +
                            '{"id":94564505,"keyword":"buy website traffic searchseo","is_active":0,"is_deleted":0,"max_clicks_per_day":29,"language":"en","region":null,"chart_data":{"translations":{"pageViews":"Page Views","hits":"Hits","visits":"Visits","clicks":"Clicks"},"data":[[0,0],[1,0],[2,0],[3,0],[4,0],[5,0],[6,0],[7,0],[8,0],[9,0],[10,0],[11,0],[12,0],[13,0],[14,0],[15,0],[16,0],[17,0],[18,0],[19,0],[20,0],[21,0],[22,0],[23,0],[24,0],[25,0],[26,0],[27,0],[28,0],[29,0],[30,0],[31,0]],"totalHits":0,"maxHits":0,"labels":["16 Aug","17 Aug","18 Aug","19 Aug","20 Aug","21 Aug","22 Aug","23 Aug","24 Aug","25 Aug","26 Aug","27 Aug","28 Aug","29 Aug","30 Aug","31 Aug","01 Sep","02 Sep","03 Sep","04 Sep","05 Sep","06 Sep","07 Sep","08 Sep","09 Sep","10 Sep","11 Sep","12 Sep","13 Sep","14 Sep","15 Sep","16 Sep"],"typeTraffic":"clicks"},"recommendations":[],"is_top100":1,"check_position_now":1,"daily_limit_recommendations":{"top_1":0,"top_3":0,"top_10":0}},' +
                            '{"id":94564506,"keyword":"buy website traffic searchseo","is_active":0,"is_deleted":1,"max_clicks_per_day":29,"language":"en","region":null,"chart_data":{"translations":{"pageViews":"Page Views","hits":"Hits","visits":"Visits","clicks":"Clicks"},"data":[[0,0],[1,0],[2,0],[3,0],[4,0],[5,0],[6,0],[7,0],[8,0],[9,0],[10,0],[11,0],[12,0],[13,0],[14,0],[15,0],[16,0],[17,0],[18,0],[19,0],[20,0],[21,0],[22,0],[23,0],[24,0],[25,0],[26,0],[27,0],[28,0],[29,0],[30,0],[31,0]],"totalHits":0,"maxHits":0,"labels":["16 Aug","17 Aug","18 Aug","19 Aug","20 Aug","21 Aug","22 Aug","23 Aug","24 Aug","25 Aug","26 Aug","27 Aug","28 Aug","29 Aug","30 Aug","31 Aug","01 Sep","02 Sep","03 Sep","04 Sep","05 Sep","06 Sep","07 Sep","08 Sep","09 Sep","10 Sep","11 Sep","12 Sep","13 Sep","14 Sep","15 Sep","16 Sep"],"typeTraffic":"clicks"},"recommendations":[],"is_top100":1,"check_position_now":1,"daily_limit_recommendations":{"top_1":0,"top_3":0,"top_10":0}},' +
                            '{"id":94564507,"keyword":"buy website traffic searchseo","is_active":1,"is_deleted":0,"max_clicks_per_day":29,"language":"en","region":null,"chart_data":{"translations":{"pageViews":"Page Views","hits":"Hits","visits":"Visits","clicks":"Clicks"},"data":[[0,0],[1,0],[2,0],[3,0],[4,0],[5,0],[6,0],[7,0],[8,0],[9,0],[10,0],[11,0],[12,0],[13,0],[14,0],[15,0],[16,0],[17,0],[18,0],[19,0],[20,0],[21,0],[22,0],[23,0],[24,0],[25,0],[26,0],[27,0],[28,0],[29,0],[30,0],[31,0]],"totalHits":0,"maxHits":0,"labels":["16 Aug","17 Aug","18 Aug","19 Aug","20 Aug","21 Aug","22 Aug","23 Aug","24 Aug","25 Aug","26 Aug","27 Aug","28 Aug","29 Aug","30 Aug","31 Aug","01 Sep","02 Sep","03 Sep","04 Sep","05 Sep","06 Sep","07 Sep","08 Sep","09 Sep","10 Sep","11 Sep","12 Sep","13 Sep","14 Sep","15 Sep","16 Sep"],"typeTraffic":"clicks"},"recommendations":[],"is_top100":1,"check_position_now":1,"daily_limit_recommendations":{"top_1":1234,"top_3":931,"top_10":561}}' +
                        ']';
                        resource.dataRaw.data = JSON.parse(data);
                    }

                    resource.data.forEach((keyword) => {
                        state._keywords[keyword.id] = useSeoClicksKeyword(state._projectId,keyword.id).init(keyword);
                        state._listChecked[keyword.id] = false;
                    });
                });
            }else{
                this.$patch({_keywords: this.$patch({_keywords:{}})});
            }
            this.$patch({_downloading:false});
        },

        async updateDailyLimit(keywordsIds, maxClicksPerDay){

            let resource = new ApiResource({
                url:'/api/v1/seo-traffic/projects/keywords-group',
                method:'put',
                params: {
                    keywords_ids: keywordsIds,
                    max_clicks_per_day: maxClicksPerDay,
                }
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.groupUpdated(resource.data.updatedKeywords);
                return [true];
            }else{
                return [false,resource.errorMessage];
            }

        },
        async updateIsActive(keywordsIds, isActive){

            isActive = isActive ? 1 : 0;

            let resource = new ApiResource({
                url:'/api/v1/seo-traffic/projects/keywords-group',
                method:'put',
                params: {
                    keywords_ids: keywordsIds,
                    is_active: isActive,
                }
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.groupUpdated(resource.data.updatedKeywords);
                return [true];
            }else{
                return [false,resource.errorMessage];
            }

        },
        async updateIsDeleted(keywordsIds, isDeleted){
            isDeleted = isDeleted ? 1 : 0;

            let resource = new ApiResource({
                url:'/api/v1/seo-traffic/projects/keywords-group',
                method:'put',
                params: {
                    keywords_ids: keywordsIds,
                    is_delete: isDeleted,
                }
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.groupUpdated(resource.data.updatedKeywords);
                return [true];
            }else{
                return [false,resource.errorMessage];
            }
        },

        groupUpdated(updatedKeywords){
            updatedKeywords.forEach((updatedKeyword) => {
                this._keywords[updatedKeyword.id].setData(updatedKeyword);
            });
        },

        setFilters(filters){
            let newFilters = {};
            if(filters.hasOwnProperty('filter')){
                newFilters._filter = filters.filter;
            }
            if(filters.hasOwnProperty('sortBy')){
                newFilters._sortBy = filters.sortBy;
            }
            if(filters.hasOwnProperty('searchKeyword')){
                newFilters._searchKeyword = filters.searchKeyword;
            }
            this.$patch(newFilters);
        },

        hasNoChecked(){
            let hasNoChecked = false;
            for(let [key,showedId] of Object.entries(this.keywordsShowedIds)){
                if(showedId.is_show && !this._listChecked[showedId.id]){
                    hasNoChecked = true;
                }
            }
            return hasNoChecked;
        },

        hasChecked(){
            let hasChecked = false;
            for(let [key,showedId] of Object.entries(this.keywordsShowedIds)){
                if(showedId.is_show && this._listChecked[showedId.id]){
                    hasChecked = true;
                }
            }
            return hasChecked
        },

        setCheckedAll(status = true){
            this.$patch((state) => {
                Object.keys(state._listChecked).forEach((id) => {
                    state._listChecked[id] = status;
                })
            });
        },

        tristateUpdate(){
            if(this.hasNoChecked()){
                this.setCheckedAll();
            }else{
                this.setCheckedAll(false);
            }
        },

        updateTriStateCheckbox(){
            if(this.hasChecked()){
                if(this.hasNoChecked()){
                    this.$patch({_checkboxTriState:1});
                }else{
                    this.$patch({_checkboxTriState:2});
                }
            }else{
                this.$patch({_checkboxTriState:0});
            }
        },

        getSelectedIds(){
            let ids = [];
            for(let [key,showedId] of Object.entries(this.keywordsShowedIds)){
                if(showedId.is_show && this._listChecked[showedId.id]){
                    ids.push(showedId.id);
                }
            }
            return ids;
        },

        async startIntervalCheckPositionUpdating(){
            let keys = Object.keys(this._keywords);
            let batchSize = 5;
            for (let i = 0; i < keys.length; i += batchSize) {
                const batch = keys.slice(i, i + batchSize);
                const results = await Promise.all(batch.map(async (key) => {
                    if (this._keywords[key].is_active) {
                        let response = await this._keywords[key].startPositionUpdating()
                        return {
                            response: response,
                            key: key
                        }
                    }
                }));
                //console.log(`Группа с ${i + 1} по ${i + batch.length} выполнена`);
                results.forEach((result) => {
                    if(result !== undefined && result.response[0] !== undefined) {
                        this._keywords[result.key].startIntervalCheckPositionUpdating();
                    }
                })
            }

            //for(let key of keys){
            //    await this._keywords[key].startPositionUpdating();
            //}
            return true;
        }

    },
})();

export const useSeoClicksKeyword = (projectId,keywordId) => defineStore('/api/v1/seo-traffic/projects/'+projectId+'/keywords/'+keywordId, {
    state: () => {
        return {
            project_id: projectId,
            chart_data: null,
            id: keywordId,
            is_active: null,
            is_deleted: null,
            keyword: null,
            language: null,
            max_clicks_per_day: null,
            /**
             * @type {string|null}
             */
            region: null,
            is_top100: null,
            check_position_now: null,
            check_position_now_ended: false,
            daily_limit_recommendations: {
                top_1: null,
                top_3: null,
                top_10: null,
            }
        }
    },
    getters: {
        hasRecomDaily: function(state){
            return this.recom_daily_top1!==null || this.recom_daily_top3!==null || this.recom_daily_top10!==null;
        },
        recom_daily_top1: function (state) {
            if(
                state.hasOwnProperty('daily_limit_recommendations')
                && state.daily_limit_recommendations.hasOwnProperty('top_1')
                && state.daily_limit_recommendations.top_1 !== null
                && state.daily_limit_recommendations.top_1 > 0
                && state.daily_limit_recommendations.top_1 < 100000
            ){
                return state.daily_limit_recommendations.top_1;
            }else{
                return null;
            }
        },
        recom_daily_top3: function (state) {
            if(
                state.hasOwnProperty('daily_limit_recommendations')
                && state.daily_limit_recommendations.hasOwnProperty('top_3')
                && state.daily_limit_recommendations.top_3 !== null
                && state.daily_limit_recommendations.top_3 > 0
                && state.daily_limit_recommendations.top_3 < 100000
            ){
                return state.daily_limit_recommendations.top_3;
            }else{
                return null;
            }
        },
        recom_daily_top10: function (state) {
            if(
                state.hasOwnProperty('daily_limit_recommendations')
                && state.daily_limit_recommendations.hasOwnProperty('top_10')
                && state.daily_limit_recommendations.top_10 !== null
                && state.daily_limit_recommendations.top_10 > 0
                && state.daily_limit_recommendations.top_10 < 100000
            ){
                return state.daily_limit_recommendations.top_10;
            }else{
                return null;
            }
        },
    },
    actions: {
        init(data){
            this.$patch((state) => {
                state.chart_data = data?.chart_data ?? null;
                state.is_active = data?.is_active ?? null;
                state.is_deleted = data?.is_deleted ?? null;
                state.keyword = data?.keyword ?? null;
                state.language = data?.language ?? null;
                state.max_clicks_per_day = data?.max_clicks_per_day ?? null;
                state.region = data?.region ?? null;
                state.is_top100 = data?.is_top100 ?? null;
                state.check_position_now = data?.check_position_now ?? null;
                state.check_position_now_ended = data?.check_position_now_ended ?? null;
                state.daily_limit_recommendations.top_1 = data?.daily_limit_recommendations?.top_1 ?? null;
                state.daily_limit_recommendations.top_3 = data?.daily_limit_recommendations?.top_3 ?? null;
                state.daily_limit_recommendations.top_10 = data?.daily_limit_recommendations?.top_10 ?? null;
            });
            this.updateRecommendations(data.recommendations);
            return this;
        },
        updateRecommendations(recommendations) {
            useSeoClicksKeywordsRecommendations(keywordId).init(recommendations);
        },
        setData(data){
            this.$patch((state) => {
                Object.keys(data).forEach((key) => {
                    if(key === 'is_active' || key === 'is_deleted'){
                        state[key] = Boolean(data[key]);
                    }else if(key === 'recommendations'){
                        this.updateRecommendations(data[key]);
                    }else {
                        state[key] = data[key];
                    }
                });
            });
        },

        async updateData(){
            let resource = new ApiResource({url:'/api/v1/seo-traffic/projects/keywords/'+this.id});
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.setData(resource.data);
                return [true,resource.data];
            }else{
                return [false, resource.errorMessage];
            }
        },

        async updateDailyLimit(maxClicksPerDay){
            return useSeoClicksKeywords(this.project_id).updateDailyLimit([this.id], maxClicksPerDay);
        },
        async updateIsActive(isActive){
            return useSeoClicksKeywords(this.project_id).updateIsActive([this.id], isActive);
        },
        async updateIsDeleted(isDeleted){
            return useSeoClicksKeywords(this.project_id).updateIsDeleted([this.id], isDeleted);
        },

        async startPositionUpdating(){

            this.$patch({check_position_now: 1});
            this.$patch({is_top100: 0});

            let resource = new ApiResource({
                url:'/api/v1/seo-traffic/projects/start-position-updating',
                method:'post',
                params: {
                    id: this.id,
                }
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                return [true];
            }else{
                this.$patch({check_position_now: 0});
                return [false];
            }
        },

        startIntervalCheckPositionUpdating(){

            setTimeout(() => {
                this.checkPositionUpdating().then(([status,response]) => {
                    if(!status || !response.isDone){
                        this.startIntervalCheckPositionUpdating();
                    }else{
                        this.updateData();
                    }
                });
            },2000);

        },

        async checkPositionUpdating() {
            let resource = new ApiResource({
                url:'/api/v1/seo-traffic/projects/check-position-updating',
                method:'get',
                params: {
                    id: this.id,
                }
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                return [true,{isDone:resource.data.isDone, isTop100: resource.data.isTop100}];
            }else{
                return [false];
            }

        }
    }
})()

export const useSeoClicksKeywordsRecommendations = (keywordId) => defineStore('seotraffic-keyword-'+keywordId+'-recommendations',{
    state: () => {
        return {
            keywordId: keywordId,
            list: [],
        }
    },
    getters: {
        hasRecommendation: (state) => {
            return state.list.length > 0;
        },
        mainRecommendation: (state) => {
            return state.list.find((recommendation) => recommendation.type==='NOT_TOP_100') ??
                state.list.find((recommendation) => recommendation.degree==='danger') ??
                state.list.find((recommendation) => recommendation.degree==='warning') ??
                state.list.at(0) ?? null;
        }
    },
    actions: {
        init(recommendations){
            this.$patch((state) => {
                state.list = [];
                recommendations.forEach((recommendation, index) => {
                    state.list.push(useSeoClicksKeywordRecommendation(state.keywordId, index).init(recommendation));
                });
            })
        }
    }
})();

export const useSeoClicksKeywordRecommendation = (keywordId, recommendationId) => defineStore('seotraffic-keyword-'+keywordId+'-recommendation-'+recommendationId,{
    state: () => {
        return {
            keywordId: keywordId,
            recommendationId: recommendationId,
            /**
             * @type {string|null}
             */
            message: null,
            /**
             * @type {'warning'|'danger'}
             */
            degree: 'warning',
            /**
             * @type {'SIMPLE'|'NOT_TOP_100'}
             */
            type: 'SIMPLE',
        }
    },
    actions: {
        init(recommendation){
            this.$patch((state) => {
                state.message = recommendation?.message || null;
                state.degree = recommendation?.degree || 'warning';
                state.type = recommendation?.type || 'SIMPLE';
            });
            return this;
        }
    }
})()

export const useSeoClicksProjectsDailyChart = defineStore('/api/v1/seo-traffic/projects/daily-chart-data',{
    state: () => {
        return {
            _chartData:null,
        }
    },
    getters: {
        chartData: function(state){
            return state._chartData;
        }
    },
    actions: {
        async update () {
            let resource = new ApiResource({
                url:'/api/v1/seo-traffic/projects/daily-chart-data',
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.$patch({_chartData: resource.data});
            }else{
                console.log('error chart data daily for projects');
            }
        },
        formatDate(date) {
            const year = date.getFullYear();
            // Добавляем 1, потому что месяцы начинаются с 0
            // `padStart(2, '0')` добавляет ведущий ноль, если месяц состоит из одной цифры
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');

            return `${year}-${month}-${day}`;
        },
        async updateFromTo(startDate,endDate,projectId = null){

            let resource = new ApiResource({
                url:'/api/v1/seo-traffic/projects/daily-chart-data',
                params: {
                    from: this.formatDate(startDate),
                    to: this.formatDate(endDate),
                }
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.$patch({_chartData: resource.data});
                return [true];
            }else{
                return [false,resource.errorMessage];
            }

        }
    }
})

export const useRealtimeSeoClicksProjects = defineStore('/api/v1/seo-traffic/projects/minutes-chart-data',{
    state: () => {
        return {
            _chartData:null
        }
    },
    getters: {
        chartData: function(state){
            return state._chartData;
        }
    },
    actions: {
        async update () {
            let resource = new ApiResource({
                url:'/api/v1/seo-traffic/projects/minutes-chart-data',
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.$patch({_chartData: resource.data});
            }else{
                console.log('error realtime projects');
            }
        },

    }
});
