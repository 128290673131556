<template>
  <chart-partioned-strip
      class="lbpr__difficulty-chart"
      :value="value"
      :percents="useKeywordDifficultySettings().percents"
      :colors="useKeywordDifficultySettings().colors"
  />
</template>

<script setup>

import ChartPartionedStrip from "../../Chart/ChartPartionedStrip.vue";
import {useKeywordDifficultySettings} from "../../../stores/useLinkBuilding";

const props = defineProps({
  value: {}
});

</script>

<style lang="scss" scoped>

</style>