
import {defineStore} from "pinia";
import {ApiResource} from "../plugins/ApiExtends";
import {useSecretCodes, useStaticData} from "./useStaticData";
import {isArray} from "lodash";

export const useSuggestKeywords = defineStore('extractKeywords', {
    state: () => {
        return {
            currentUrl: null,
            currentLocation: null,
            selectedLocation: {'name': 'No Geo-targeting', value: 'no_geo'},
            optionNoGeo: {'name': 'No Geo-targeting', value: 'no_geo'},
            suggestedKeywordsOriginal: [],
            selectedKeywords: [],
            message: '',
            loading: false,
            hasFirstDownload: false,
        }
    },
    getters: {
        selectedLocationIsNoGeo: (state) => {
            return state.selectedLocation?.value === 'no_geo';
        },

        suggestedKeywords: (state) => {
            return state.suggestedKeywordsOriginal.map((keyword) => {
                if(keyword.geo?.toLowerCase() === 'gb'){
                    keyword.geo = 'uk';
                }
                let is_suggested = (
                    state.selectedLocation?.value?.toLowerCase() === state.currentLocation
                    || (
                        state.selectedLocation?.value === 'no_geo'
                        && !state.currentLocation
                    )
                );
                return {
                    countryCode: state.selectedLocation?.value ?? null,
                    name: keyword.keyword,
                    is_suggested: is_suggested,
                }
            })
        },

        remainingSuggestedKeywords(state){
            return this.suggestedKeywords.filter((item) => {
                return !state.hasSelectedKeyword(item);
            });
        },

        selectedKeywordsForSend: (state) => {
            let response = [];
            state.selectedKeywords.forEach((item) => {
                if(!item.hasOwnProperty('name')) return

                let responseItem = {
                    keyword: item.name,
                    is_suggested: item?.is_suggested ?? false,
                };

                if(!!item?.countryCode && item.countryCode !== 'no_geo'){
                    responseItem.geo = item.countryCode?.toLowerCase();
                }

                response.push(responseItem);
            });
            return response;
        },

        keywordsNotFound(state){
            return state.hasFirstDownload && this.suggestedKeywords.length === 0;
        },

        regionsSelectOptions: (state) => {
            return [state.optionNoGeo].concat(useStaticData().regionsForSelect);
        }

    },
    actions: {

        async updateSuggestedKeywords(url, geoCode){
            let resource = new ApiResource({
                url:'/api/keywords/get-extract-keywords',
                method:'get',
                params: {
                    url: url,
                    secret: useSecretCodes().apiSecretAuth,
                    location: geoCode,
                }
            });

            this.$patch({loading: true, hasFirstDownload:true});
            await resource.downloadAsync();
            this.$patch({loading: false});

            if(resource.isSuccess()){
                this.$patch((state) => {

                    state.currentUrl = url;
                    if(resource.data.geo?.toLowerCase() === 'gb'){
                        resource.data.geo = 'uk';
                    }
                    state.currentLocation = resource.data.geo;
                    state.message = resource.data.message;
                    state.suggestedKeywordsOriginal = [];

                    if(state.currentLocation!==null && state.selectedLocation?.value?.toLowerCase() !== state.currentLocation?.toLowerCase()){
                        let region = state.currentLocation?.toLowerCase();
                        let foundRegion = this.regionsSelectOptions.find((item) => item.value.toLowerCase() === region);
                        if(foundRegion){
                            state.selectedLocation = foundRegion;
                        }
                    }
                    if(isArray(resource.data.keywords) && resource.data.keywords.length > 0){
                        resource.data.keywords.forEach((keyword) => {
                            state.suggestedKeywordsOriginal.push(keyword);
                        });
                    }

                });
            }else{
                this.reset();
            }

        },

        isSuggestedKeyword(tag){

            return this.suggestedKeywords.find((suggestedTag) => {
                console.log(this.compareSelectedKeywords(suggestedTag,tag), suggestedTag?.is_suggested);
                return this.compareSelectedKeywords(suggestedTag,tag) && suggestedTag?.is_suggested
            });

        },

        addSelectedKeyword(tag){
            if(!tag || !tag.hasOwnProperty('name') || !tag.name){return}
            this.$patch((state) => {
                let forInsert = {name: tag.name};
                if(tag.hasOwnProperty('countryCode') && tag.countryCode){
                    forInsert.countryCode = tag.countryCode;
                }

                if(this.isSuggestedKeyword(tag)){
                    forInsert.is_suggested = true;
                }

                if(!this.hasSelectedKeyword(forInsert)){
                    state.selectedKeywords.push(forInsert);
                }

            });
        },

        compareSelectedKeywords(tagA, tagB){
            if(tagA.hasOwnProperty('countryCode') ^ tagB.hasOwnProperty('countryCode')){
                return false;
            }
            if(tagA.hasOwnProperty('countryCode')){
                if(tagA.countryCode?.toLowerCase() !== tagB.countryCode?.toLowerCase()){
                    return false;
                }
            }
            return tagA.name === tagB.name;
        },

        hasSelectedKeyword(tag){

            let find = this.selectedKeywords.find((item) => {
                return this.compareSelectedKeywords(tag, item);
            });
            return find !== undefined;

        },

        removeSelectedKeyword(tag){
            let removeIndexes = [];
            this.selectedKeywords.forEach((value,index) => {
                if(this.compareSelectedKeywords(tag,value)){
                    removeIndexes.push(index);
                }
            });

            removeIndexes.sort((a, b) => b - a);

            this.$patch((state) => {
                removeIndexes.forEach(index => {
                    state.selectedKeywords.splice(index, 1);
                });
            });

        },

        reset(){
            this.$patch({
                currentUrl: null,
                currentLocation: null,
                suggestedKeywordsOriginal: [],
                message:'',
                loading:false,
                hasFirstDownload:false,
            })
        },

    }
})