import {
  formValidationErrorClear,
  formValidationErrorHandling,
  runIfHasSelecter,
} from '../../utils/panel-lib.js';
import route from '../index.js';

const initForms = function() {
  const updateFormFields = function($form) {
    $form.find('input, select, textarea').each(function(index, el) {
      if ($(el).attr('type') === 'checkbox') {
        $(el).data('val', $(el).prop('checked'));
      } else {
        $(el).data('val', $(el).val());
      }
    });
  };

  const getFormFields = function($form) {
    let data = {};

    $form.find('input, select, textarea').each(function(index, el) {
      let fieldName = $(el).attr('name');
      if (fieldName) {
        fieldName = fieldName.replace('[]', '');

        if ($(el).attr('type') === 'checkbox') {
          data[fieldName] = ($(el).prop('checked') ? 1 : 0);
        } else {
          data[fieldName] = $(el).val();
        }
      }
    });

    return data;
  };

  let $forms = $('[data-role="billing-settings"] form[data-form-id]');

  if ($forms.length === 0) {
    return;
  }

  $forms.each(function() {
    let $form = $(this);
    let $saveButton = $form.find('[data-ajax-action="save-form"]');
    let isChangesInputs = [];

    updateFormFields($form);

    $form.find('input, select, textarea').on('input change', function() {
      let isChanges = false;

      formValidationErrorClear($form);

      if ($(this).attr('type') === 'checkbox') {
        isChangesInputs[$(this).attr('name')] = $(this).data('val') !== $(this).prop('checked');
      } else {
        isChangesInputs[$(this).attr('name')] = $(this).data('val') !== $(this).val();
      }

      for (var key in isChangesInputs) {
        if (isChangesInputs.hasOwnProperty(key) && isChangesInputs[key]) {
          isChanges = true;

          break;
        }
      }

      if (isChanges) {
        $saveButton.find('.button-text').html('Save Changes');
        $saveButton.find('.icon-from-button').hide();
        $saveButton.prop('disabled', false);
      } else {
        $saveButton.find('.button-text').html('Saved');
        $saveButton.find('.icon-from-button').show();
        $saveButton.prop('disabled', true);
      }
    });

    $saveButton.on('click', function(e) {
      e.preventDefault();

      let $button = $(this);
      let $form = $button.closest('form');

      let data = {};
      $form.find('[name]').each(function(index, elem) {
        data[$(elem).attr('name')] = $(elem).val();
      });

      let dataSave = getFormFields($form);
      data = {...data, ...dataSave};

      $button.find('.button-text').html('Saving...');
      $button.find('.icon-from-button').hide();
      $button.prop('disabled', true);

      axios.patch(route('api.billing.settings.update'), data).
          then(function(response) {
            let data = (response.data ? response.data : {});

            if (data && data.success) {
              $button.find('.button-text').html('Saved');
              $button.find('.icon-from-button').show();
              formValidationErrorClear($form);

              isChangesInputs = [];
              updateFormFields($form);
            } else {
              $button.find('.button-text').html('Save Changes');
              $button.find('.icon-from-button').hide();
              $button.prop('disabled', false);

              if (data.message) {
                $form.find('.error-box').
                    html(data.message).
                    show();
              }

              if (data.errors) {
                formValidationErrorHandling($form, data.errors);
              }
            }
          }).
          catch(function() {
            $form.find('.error-box').
                html('An error occurred while saving').
                show();
            $button.find('.button-text').html('Save Changes');
            $button.find('.icon-from-button').hide();
            $button.prop('disabled', false);
          });
    });
  });
};

export const initBillingSettingsScripts = () => {
  runIfHasSelecter('[data-role="billing-settings"]', [
    initForms,
  ]);
};
