<template>
  <v-project-preview class="lb-project-preview"
                     type="link-building"
                     :is-demo="useProject.is_demo"
                     :modal-blackout="modalBlackout">

    <template #default>
      <div class="content">

        <div class="info content__info">

          <div class="info__top">

            <div class="info__id" :class="modalBlackout ? 'info__id--hide' : ''">
              {{useProject.uniquekey}}
              <div class="info__tag-demo" v-if="useProject.is_demo" v-text="'DEMO'"/>
              <div class="info__tag-deleted" v-if="useProject.is_deleted" v-text="'DELETED'"/>
            </div>

            <div class="info__title"
                 :class="useProject.is_deleted ? 'opacity-50' : ''"
                 v-text="useProject.name"/>

            <div class="info__category"
                 v-if="useProject.category?.name"
                 v-text="useProject.category?.name"/>

            <v-link class="info__domain"
                    :href="useProject.correctUrlLink"
                    :class="useProject.is_deleted ? 'opacity-50' : ''"
                    v-text="useProject.shortUrl"/>

          </div>

          <v-button class="info__button"
                    tag="a"
                    :href="useRoutes().url('backlinks-project.get',{uniquekey: useProject.uniquekey})"
                    color="blue2-stroke"
                    :class="modalBlackout ? 'info__button--hide' : ''"
                    text="Settings"/>

        </div>

        <div class="charts content__charts"
             v-if="!useProject.is_deleted"
             :class="useProject.is_demo ? 'charts--demo' : ''">

          <div class="optimization-info chart__rating-optimization">
            <div class="optimization-info__top">
              <div class="optimization-info__title">
                Optimization
              </div>
              <v-lb-optimization-short-row ref="refOptimizationShort"
                                           class="optimization-info__short-data"
                                           :project-uniquekey="projectUniquekey"
                                           @mouseenter="showTooltipOptimization"
                                           @mouseleave="hideTooltipOptimization"/>
              <v-tooltip ref="refTooltipOptimization"
                         reset-tooltip
                         :btn-close-on="false"
                         :get-linked-block="() => refOptimizationShort"
                         placement="bottom-end">
                <template #content>
                  <v-lb-optimization-tooltip
                      :project-uniquekey="projectUniquekey"
                      @mouseenter="showTooltipOptimization"
                      @mouseleave="hideTooltipOptimization"
                  />
                </template>
              </v-tooltip>
            </div>
            <v-circle-state :value="useProject.optimizationRatingPercent"
                            :color="useProject.optimizationRatingType"
                            :size="66"
                            color="normal"
                            @click="!hasTooltip ? redirectIntoProject() : null"
                            @mouseenter="showTooltipOptimization"
                            @mouseleave="hideTooltipOptimization"
                            class="optimization-info__circle"/>
          </div>

          <div class="chart-activity chart__chart-activity" v-if="!useProject.is_demo">
            <div class="chart-activity__title">Backlink activity (last year)</div>
            <chart-backlinks2 class="chart-activity__chart"
                              :values="values"
                              :labels="labels"
                              type="Actions"/>
          </div>

          <lb-project-demo-bar v-if="useProject.is_demo"
                               class="charts__demo-info"
                               :project-uniquekey="projectUniquekey"
                               is-compact/>

        </div>

      </div>
    </template>

    <template #blackout>
      <div class="content content--modal">
        <div class="info">
          <div class="info__id info__id--white" v-text="'DG2MN2P9'"/>
          <v-button class="info__button"
                    color="white-stroke"
                    :href="useRoutes().url('backlinks-project.get',{uniquekey: useProject.uniquekey})"
                    tag="a"
                    text="Settings"/>
        </div>
      </div>
    </template>

    <template #content-after v-if="!useProject.is_deleted">

      <div class="modal-info" v-if="!useProject.is_active">
        <div class="modal-info__title">Paused</div>
        <v-button class="modal-info__button" color="green"
                  :click-action="[useProject.saveIsActive,true]"
                  icon="icon-play-white"
                  text="Resume"/>
      </div>

      <div class="modal-info" v-else-if="useProject.demo_status === 'delivered'">
        <div class="modal-info__title">Demo Completed</div>
        <div class="modal-info__sub_title" v-if="useProject.insufficientBalance">
          <v-icon-svg name="icon_notification-warning" as-img width="20"/>
          Buy credits to start Project
        </div>
        <v-button v-if="useProject.insufficientBalance"
                  text="Buy credits"
                  icon="shopping-cart-white"
                  class="modal-info__button"
                  color="green"
                  tag="a"
                  :href="useRoutes().url('buy-credits')"/>
        <v-button v-else
                  text="Start Project"
                  icon="icon-play-white"
                  class="modal-info__button"
                  color="green"
                  :click-action="[convertDemoToReal]"/>
      </div>

      <div class="modal-info" v-else-if="useProject.insufficientBalance && !useProject.is_demo">
        <div class="modal-info__title">Not enough credits</div>
        <v-button class="modal-info__button"
                  color="green"
                  text="Buy credits"
                  icon="shopping-cart-white"
                  tag="a"
                  :href="useRoutes().url('buy-credits')"/>
      </div>

    </template>

  </v-project-preview>
</template>

<script setup>

import {useRoutes} from "../../stores/useRoutes";
import VIconSvg from "../Base/VIconSvg.vue";
import VButton from "../Base/VButton.vue";
import VProjectPreview from "../Base/VProjectPreview.vue";
import VLink from "../Base/VLink.vue";
import {computed, onMounted, ref} from "vue";
import VLbOptimizationShortRow from "../Base/LinkBuilding/VLbOptimizationShortRow.vue";
import VCircleState from "../Base/VCircleState.vue";
import VTooltip from "../Base/VTooltip.vue";
import VLbOptimizationTooltip from "../Base/LinkBuilding/VLbOptimizationTooltip.vue";
import ChartBacklinks2 from "../Chart/ChartBacklinks2.vue";
import LbProjectDemoBar from "./LbProjectDemoBar.vue";
import {useLinkBuildProject, useLinkBuildProjectOrders} from "../../stores/useLinkBuilding";

const props = defineProps({
  /**
   * @type {'start'|'mini'|'normal'}
   */
  fullness: {type:String,default:'normal',},
  projectUniquekey: {},
});

const useProject = useLinkBuildProject(props.projectUniquekey);
const useProjectOrders = useLinkBuildProjectOrders(props.projectUniquekey);

const refOptimizationShort = ref();
const refTooltipOptimization = ref();

const modalBlackout = computed(() => {
  if(useProject.is_deleted) return null;
  if(useProject.demo_status === 'delivered') return 'black';
  if(!useProject.is_active) return 'black';
  if(useProject.insufficientBalance && !useProject.is_demo) return 'red';
  return null;
});

const labels = computed(() => {
  const months = [];
  let dateTo = new Date();
  let dateFrom = new Date(dateTo.getFullYear(), dateTo.getMonth() - 11, 1);
  let currentDate = new Date(dateFrom.getFullYear(), dateFrom.getMonth(), 1);

  while (currentDate <= dateTo) {
    const month = currentDate.toLocaleString('en-US', { month: 'short' });
    const year = currentDate.getFullYear().toString().slice(-2);
    const label = `${month.toLowerCase()} '${year}`;
    months.push(label);

    // Увеличиваем месяц
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return months;
});

const values = computed(() => {
  let dateTo = new Date();
  let dateFrom = new Date(dateTo.getFullYear(), dateTo.getMonth() - 11, 1);
  let currentDate = new Date(dateFrom.getFullYear(), dateFrom.getMonth(), 1);

  let response = [];

  while (currentDate <= dateTo) {

    let key = `${currentDate.getFullYear()}-${currentDate.getMonth()}`;

    if(useProjectOrders.countOrdersByMonth.hasOwnProperty(key)){
      response.push(useProjectOrders.countOrdersByMonth[key].count);
    }else{
      response.push(0);
    }

    currentDate.setMonth(currentDate.getMonth() + 1);
  }
  return response;
});


const hasTooltip = computed(() => {
  return (
      useProject.recommendations_danger_count > 0
      || useProject.recommendations_warning_count> 0
      || useProject.recommendations_succss_count > 0
  );
});

const tooltipOptimizationTrigger = ref(false);

function showTooltipOptimization(){
  if(!hasTooltip.value) return
  tooltipOptimizationTrigger.value = true;
  refTooltipOptimization.value.show();
}

function hideTooltipOptimization(){
  if(!hasTooltip.value) return;
  tooltipOptimizationTrigger.value = false;
  setTimeout(() => {
    if(!tooltipOptimizationTrigger.value) refTooltipOptimization.value.hide()
  },300);
}

function redirectIntoProject(){
  window.location.href=useRoutes().url('backlinks-project.get',{uniquekey: useProject.uniquekey})
}

const downloadProjectAndOrders = async () => {
  if(
      (
          useProject.is_demo
          && useProject.demo_status!== 'expired'
          && useProject.demo_status!== 'delivered'
      )
      || (
          useProject.is_demo
          && (useProject.demo_status=== 'expired' || useProject.demo_status=== 'delivered')
          && !useProject.demoOrderHasLink
      )
  ){
    await Promise.all([
      useProject.downloadProject(),
      useProjectOrders.download(),
    ]);
    setTimeout(() => downloadProjectAndOrders(),5000);
  }
}

onMounted(() => setTimeout(() => downloadProjectAndOrders(), 5000));

async function convertDemoToReal(){
  let [status] = await useProject.convertDemoToReal();
  if(status){
    await Promise.all([
      useProject.downloadProject(),
      useProjectOrders.download(),
    ]);
  }
}

</script>

<style lang="scss" scoped>
.lb-project-preview{

  .content{
    @include flex(row,between,stretch,24px);
    width:100%;
    padding:24px 24px 24px 20px;
    &.content--modal{
      height:100%;
    }
    .content__info{
      width:calc(100% - 600px - 24px);
    }
    .content__charts{
      flex-shrink:0;
      width:600px;
    }
  }

  .info {
    @include flex(column, between, start, 19px);

    .info__top{
      @include flex(column,start,start,6px);
      max-width:100%;
    }

    .info__id{
      @include flex(row,start,center, 6px);
      font:$font-mini-12-regu;
      color:$dark-40;
      &.info__id--white{
        color:#fff;
      }
      &.info__id--hide{
        opacity:0;
      }
    }

    .info__title{
      @include ellipsis();
      font:$font-16-semi;
      color:$dark-80;
    }

    .info__domain{
      @include ellipsis();
    }
    .info__category{
      @include ellipsis();
      color:$dark-50;
    }

    .info__tag-demo{
      font:$font-mini-12-semi;
      color:$dark-40;
    }

    .info__tag-deleted{
      font:$font-mini-12-semi;
      color:$optional-red_120;
    }

    .info__button{
      padding:0px 14px;
      height:32px;
      &.info__button--hide{
        opacity:0;
      }
    }

  }

  .charts {
    @include flex(row, between, stretch, 36px);

    .charts__demo-info{
      flex-grow:2;
      align-self:flex-start;
    }
    .chart__chart-activity{
      flex-grow:2;
    }

    .chart__rating-optimization{
      width:170px;
      flex-shrink:0;
    }

  }

  .optimization-info{
    @include flex(column,start,end,12px);
    .optimization-info__top{
      @include flex(column,start,end,8px);
    }
    .optimization-info__title{
      color:$dark-40;
    }
    .optimization-info__short-data{
      cursor:initial;
    }
    .optimization-info__circle{
      cursor:initial;
    }

  }

  .chart-activity{
    @include flex(column,start,start,8px);
    .chart-activity__title{
      color:$dark-40;
      font:$font-mini-12-regu;
    }
    .chart-activity__chart{
      height:142px;
    }
  }

  .modal-info{
    @include flex(column,center,center, 12px);
    position:absolute;
    z-index:10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.modal-info--has-subtitle{
      gap:8px;
    }

    .modal-info__title{
      font:$font-h2-20-semi;
      color:$white-100;
    }
    .modal-info__sub_title{
      @include flex(row,center,center,8px);
      color: $white-100;
    }
    .modal-info__button{
      @include flex(row,center,center,8px);
    }
  }

}
</style>