<template>
  <div class="user-notifications-item" v-if="is_read === 0">
    <div class="row g-0">
      <div class="col-auto me-10">
        <div class="user-notifications-item__icon">
          <VIconSvg name="icon_notification-error"
                      v-if="type_id === 1"
          />
          <VIconSvg name="icon_notification-warning"
                      v-if="type_id === 2"
          />
          <VIconSvg name="icon_notification-success"
                      v-if="type_id === 3"
          />
        </div>
      </div>
      <div class="col">
        <div class="user-notifications-item__title" v-html="title"></div>
        <div class="user-notifications-item__message" v-html="message"></div>
        <div class="row justify-content-between align-items-center">
          <div class="col-auto">
            <div class="user-notifications-item__button" v-show="trim(button_title) !== '' && trim(button_url) !== ''">
              <a class="button button-main" :href="button_url" v-html="button_title"></a>
            </div>
          </div>
          <div class="col-auto">
            <div class="user-notifications-item__mark-as-read">
              <button class="button button-link-dashed" type="button" @click="$parent.setRead(id);">
                <span>mark as read</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VIconSvg from "./Base/VIconSvg.vue";
import {trim} from "lodash";
import {computed, ref} from 'vue';

// const show = ref(1);

export default {
  props: {
    id: {type: Number, required: true},
    type_id: {type: Number, required: true},
    type_name: {type: String, required: true},
    title: {type: String, required: true},
    message: {type: String, required: true},
    button_title: {type: String, required: true},
    button_url: {type: String, required: true},
    is_read: {type: Number, default: 0, readOnly: false},
  },
  components: {
    VIconSvg
  },
  methods: {
    trim,
  },
}
</script>

<style lang="scss" scoped>
.user-notifications-item {
  position: relative;
  padding: 20px 26px 20px 16px;
  border-bottom: 1px solid rgba(214, 227, 239, 0.50);

  .user-notifications-item__title {
    margin-top: 2px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #1F293B;
  }

  .user-notifications-item__message {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #1F293B;
  }

  .user-notifications-item__button {
    display: inline-block;
    margin-top: 10px;

    .button {
      height: 32px;
      padding: 0 12px;
    }
  }

  .user-notifications-item__mark-as-read {
    display: inline-block;
    margin-top: 10px;
    font-size: 12px;
    font-weight: 400;
    text-align: right;
  }

  &:hover {
    background: #F9FCFF;
  }
}
</style>
