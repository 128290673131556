import {runIfHasSelecter} from '../../utils/panel-lib';
import route from '../index.js';

const initScripts = function() {
  const $table = $('#seo-traffic-ab-test-table');
  const $loadingBlock = $('.loading-block');

  $table.dataTable({
    responsive: true,
    processing: true,
    dom: `<'row'<'col-sm-12 col-md-6 d-flex align-items-center justify-content-start'f><'col-sm-12 col-md-6 d-flex align-items-center justify-content-end'B>>
            <'row'<'col-sm-12'tr>>
            <'row'<'col-sm-12 col-md-5 d-none'i><'col-12'p>>`,
    order: [[0, 'desc']],
    buttons: [],
    columns: [
      {'width': '60%', orderable: false},
      {'width': '20%', 'className': 'vertical-align-center'},
      {'width': '20%', 'className': 'vertical-align-center'},
      {'class': 'hidden'},
    ],
    columnDefs: [
      {
        targets: 1,
        render: function(data) {
          return `<div class="text-center" style="padding-right: 30px;">${data}</div>`;
        },
      },
      {
        targets: 2,
        render: function(data) {
          return `<div class="text-center" style="padding-right: 30px;">${data}</div>`;
        },
      },
    ],
    createdRow: function(row, data) {},
  });

  updateTable();

  function updateTable() {
    $table.DataTable().ajax.url(route('api.seo_traffic.ab_tests.get-test') + '?id=' + jsPageData.testId);

    /** DataTables before receiving the data */
    $table.on('preXhr.dt', function() {
      $loadingBlock.addClass('processing');
    });

    /** DataTables after receiving the data */
    $table.on('xhr.dt', function() {
      $loadingBlock.removeClass('processing');
      $('body').removeClass('loading');
    }).DataTable().ajax.reload();
  }
};

export const initSeoTrafficABTestScripts = () => {
  /**
   * @property {object} jsPageData
   */

  runIfHasSelecter('[data-role="seo-traffic-ab-test"]', [
    initScripts,
  ]);
};
