<template>
  <table class="v-lb-table-autorepeat">
    <thead>
      <tr>
        <th>ID</th>
        <th>Type</th>
        <th class="th--auto-repeat">Auto-repeat</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="order in orders" :key="order.id">
        <td class="td--id">
          {{order.id}}
        </td>
        <td>
          <v-product :type-constant="order.type" :is-short/>
        </td>
        <td class="td--autorepeat">
          <v-icon-svg :name="order.isAutoRenewal ? 'circle-green-ok' : 'circle-gray-minus'"/>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
/**
 * @typedef {Object} Order
 * @property {*} id - id
 * @property {string} type - тип ссылки
 * @property {boolean} isAutoRenewal - Флаг автоповтора.
 */
import VProduct from "../VProduct.vue";
import VIconSvg from "../VIconSvg.vue";

const props = defineProps({
  /**
   * Массив объектов типа Item.
   * @type {Order[]}
   */
  orders: {default:[],},
});

</script>

<style lang="scss" scoped>
.v-lb-table-autorepeat{
  table-layout: fixed;

  thead{
    th{
      color:#798CAE;
      font-size: 12px;
      text-align: left;
      padding-bottom:10px;
      border-bottom: 1px solid #D6E3EF80;
      vertical-align:middle;
      font-weight:400;
      &.th--auto-repeat{
        text-align:center;
        width:73px;
      }
    }
  }
  tbody{
    td{
      padding-top:20px;
      text-align:left;
      vertical-align: middle;
      &.td--id{
        font-size: 12px;
        color:#AEB7C7;
        padding-right:18px;
        width:75px
      }
      &.td--type{
        font-size: 16px;
      }
      &.td--autorepeat{
        text-align:center;
      }
    }
  }
}
</style>