import {runIfHasSelecter} from "../../utils/panel-lib";
import route from '../index.js';

const initScripts = function (currentData, translations) {
    const $historyTable = $('#cashback-history-table');
    const $searchInput = $('.search-box input[type="search"]');
    const $loadingBlock = $('.loading-block');

    $historyTable.dataTable({
        responsive: true,
        processing: true,
        dom: "<'row'<'col-sm-12 col-md-6 d-flex align-items-center justify-content-start'f><'col-sm-12 col-md-6 d-flex align-items-center justify-content-end'B>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 d-none'i><'col-12'p>>",
        order: [[0, "desc"]],
        buttons: [],
        columns: [
            {"width": "10%"},
            {"width": "20%"},
            {"width": "20%"},
            {"width": "20%"},
            {"width": "30%"},
        ],
        createdRow: function (row, data) {
        }
    });

    // Search
    let localStorageSearch;
    try {
        localStorageSearch = window.localStorage.getItem('st-cashback-history-table-search');
    } catch (e) {
        console.log('Error localStorage.getItem: ' + e.responseText);
    }

    if (localStorageSearch !== null && localStorageSearch !== 'null' && localStorageSearch) {
        $searchInput.val(localStorageSearch);
    }

    $searchInput.on('keyup search', function () {
        $historyTable.DataTable().search($(this).val()).draw();
        window.localStorage.setItem('st-cashback-history-table-search', $(this).val());
    });

    updateHistory();

    function updateHistory() {
        const url = route('api.cashback.get_history');

        $historyTable.DataTable().search($searchInput.val()).ajax.url(url);

        /** DataTables before receiving the data */
        $historyTable.on('preXhr.dt', function () {
            $loadingBlock.addClass('processing');
        });

        /** DataTables after receiving the data */
        $historyTable.on('xhr.dt', function () {
            $loadingBlock.removeClass('processing');
            $('body').removeClass('loading');
        }).DataTable().ajax.reload();
    }
}

export const initCashbackHistoryScripts = () => {
    /**
     * @property {object} jsPageData
     */

    runIfHasSelecter('[data-role="cashback-history"]', [
        initScripts,
    ]);
}
