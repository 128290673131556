<template>
  <tr class="sc-project-keyword-desktop table-keyword" :class="mainClasses">
    <td class="table-keyword__checkbox-col">

      <v-form-checkbox v-model="useKeywords._listChecked[useKeyword.id]"/>

    </td>
    <td class="table-keyword__keyword-col">

      <div class="keyword">
        <v-icon-flag :code="useKeyword.region" v-if="useKeyword.region"/>
        {{useKeyword.keyword}}
      </div>

    </td>
    <td class="table-keyword__recommendations-col">

      <sc-project-keyowrd-recommendations
          v-if="!useKeyword.is_deleted && (useRecommendations.hasRecommendation || !useKeyword.is_top100)"
          class="recommendations-col-inner"
          :project-id="projectId"
          :keyword-id="keywordId"
      />
      <recommendation-daily-limit
          v-else-if="!useKeyword.is_deleted && useKeyword.hasRecomDaily"
          :top1="useKeyword.recom_daily_top1"
          :top3="useKeyword.recom_daily_top3"
          :top10="useKeyword.recom_daily_top10"
          @choose-daily="chooseDaily"
      />

    </td>
    <td class="table-keyword__chart-col">

      <chart-seo-clicks
          v-show="!useKeyword.is_deleted"
          :chart="useKeyword.chart_data"
          :no-if-null="true"
          class="chart"
          :scale-hover="true"
      />

    </td>
    <td class="table-keyword__daily-limit-col">

      <v-form-input-number-edit
          v-show="!useKeyword.is_deleted"
          type="events"
          :value="useKeyword.max_clicks_per_day"
          @save="saveMaxClicksPerDay"
          :filter-data="(val) => {return val >= 1;}"
          ref="inputMaxClicksPerDay"
      />
      <span v-show="!!useKeyword.is_deleted" class="label-is-deleted">Deleted</span>

    </td>
    <td class="table-keyword__delete-col">

      <div
          class="delete"
          :class="!useKeyword.is_active ? 'is-project-none-active' : ''"
          v-show="!useKeyword.is_deleted"
      >
        <v-icon-svg name="delete-icon" class="delete-icon" @click="deleteConfirm"/>
        <v-modal-confirm ref="deleteModalConfirm" @confirm="deleteKeyword">
          <template v-slot:title>
            Delete keyword
          </template>
          <template v-slot:description>
            Do you really want to delete a keyword ? <br>Keyword will be lost
          </template>
        </v-modal-confirm>
      </div>

    </td>
    <td class="table-keyword__status-col">

      <v-form-checkbox-slider
          v-show="!useKeyword.is_deleted"
          type="events"
          :value="!!useKeyword.is_active"
          @save="saveCheckboxStatus"
          ref="checkboxStatus"
      />
      <v-link href="#" v-show="!!useKeyword.is_deleted" class="restore" @click.prevent="restoreKeyword">Restore</v-link>

    </td>
  </tr>
</template>

<script setup>
import {computed, ref} from "vue";
import {
  useSeoClicksKeyword,
  useSeoClicksKeywords,
  useSeoClicksKeywordsRecommendations
} from "../../stores/useSeoClicks";
import VFormCheckbox from "../VForm/VFormCheckbox.vue";
import VIconFlag from "../Base/VIconFlag.vue";
import ScProjectKeyowrdRecommendations from "./ScProjectKeyowrdRecommendations.vue";
import RecommendationDailyLimit from "../Blocks/RecommendationDailyLimit.vue";
import ChartSeoClicks from "../Chart/ChartSeoClicks.vue";
import VFormInputNumberEdit from "../VForm/VFormInputNumberEdit.vue";
import VIconSvg from "../Base/VIconSvg.vue";
import VModalConfirm from "../Base/VModalConfirm.vue";
import VFormCheckboxSlider from "../VForm/VFormCheckboxSlider.vue";
import VLink from "../Base/VLink.vue";

const props = defineProps({
  keywordId: {},
  projectId: {}
});

const useKeyword = useSeoClicksKeyword(props.projectId,props.keywordId);
const useRecommendations = useSeoClicksKeywordsRecommendations(props.keywordId);
const useKeywords = useSeoClicksKeywords(props.projectId);

const mainClasses = computed(() => {
  return {
    'no-active': !useKeyword?.is_active,
    'is-deleted': !!useKeyword?.is_deleted
  }
});


const deleteConfirm = () => {
  deleteModalConfirm.value.confirm();
}

const inputMaxClicksPerDay = ref();
const saveMaxClicksPerDay = async (value) => {
  let [status,message] = await useKeyword.updateDailyLimit(value);
  if(status){
    inputMaxClicksPerDay.value.saveWithSuccess();
  }else{
    inputMaxClicksPerDay.value.saveWithError();
  }
}


const checkboxStatus = ref();
const saveCheckboxStatus = async (value) => {
  let [status,message] = await useKeyword.updateIsActive(value);
  if(status){
    checkboxStatus.value.saveWithSuccess();
  }else{
    checkboxStatus.value.saveWithError();
  }
}

const deleteModalConfirm = ref();
const deleteKeyword = async () => {

  let [status,message] = await useKeyword.updateIsDeleted(true);
  if(status){
    deleteModalConfirm.value.closeWithSuccess();
  }else{
    deleteModalConfirm.value.closeWithError(message);
  }

}

const restoreKeyword = async () => {
  let [status,message] = await useKeyword.updateIsDeleted(false);
}

const chooseDaily = (number) => {
  inputMaxClicksPerDay.value.setContentEditable(number);
}

</script>

<style lang="scss" scoped>
.sc-project-keyword-desktop{

  &.table-keyword{
    transition-duration: 0.2s;
    transition-property: background-color;

    > td{
      vertical-align: middle;
      padding:10px 0px;
      height:67px;
    }

    .table-keyword__checkbox-col{
      width:67px;
      padding-left:30px;
      padding-right:10px;
    }
    .table-keyword__keyword-col{
      max-width:250px;
      padding-right:10px;
    }
    .table-keyword__recommendations-col{
      padding-right:10px;
      max-width:250px;
    }
    .table-keyword__chart-col{
      width:130px;
      padding-right:25px;
    }
    .table-keyword__daily-limit-col{
      width:108px;
      padding-left:5px;
      padding-right:5px;
    }
    .table-keyword__status-col{
      width:87px;
      padding-left:5px;
      padding-right:32px;
    }
    .table-keyword__delete-col{
      opacity:0;
      transition-duration: 0.2s;
      transition-property: opacity;
      cursor: pointer;
      padding-left:5px;
      padding-right:5px;
      text-align: center;
      width:30px;
    }

    &:hover{
      background-color: #F4F9FD;
      .table-keyword__delete-col{
        opacity:1;
      }
    }
  }

  .delete{
    opacity:0.8;
    transition-duration: 0.2s;
    transition-property: opacity;
    cursor: pointer;
    width:20px;
    height:20px;
    display: inline-block;
  }

  .delete:hover{
    opacity:1;
  }

  .keyword{
    display: flex;
    align-items: center;
    gap:8px;
  }

  .chart{
    width:161px;
    height:44px;
    @media(max-width: 1190px){
      width:111px;
    }
  }

  &.no-active{
    .keyword{
      color:#1F293B;
    }
    .keyword > span{
      opacity:0.3;
    }
    .recommendations-col-inner{
      opacity:0.3;
    }
    .chart{
      opacity: 0.3;
    }
  }

  @media(max-width: $mobile-width){
    .chart{
      width:108px;
      height:32px;
    }
    .delete{
      opacity:0!important;
    }
    .delete.is-project-none-active{
      opacity:0.5!important;
    }
  }

  .label-is-deleted{
    color: #AEB7C7;
  }
  .restore{
    position: relative;
    left:-11px;
  }

}
</style>