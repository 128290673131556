import {
  addToCart,
  formatNumberK,
  formatPrice,
  runIfHasSelecter,
} from '../../utils/panel-lib';
import _, {trim} from 'lodash';

const initCommon = function () {
  let $cart = $('.payment-order');
  let arrPlanSize = jsPageData.guaranteedAvailableSizesForSlider;
  let $guaranteedOptionsWrap = $('.guaranteed-options-wrap');
  let guaranteedPlansOptions = jsPageData.guaranteedPlansOptions;
  let $rangePlanSizes = $('#range_plan_sizes');
  let $inputPlanSize = $('[name="plan_size"]');
  let $inputPlanType = $('[name="guaranteed_plan_type"]');
  let $inputSelectQuantity = $('[name="guaranteed_select_quantity"]');
  let sliderTooltipTimeout = null;

  function initPlusMinusToCart() {
    let $quantityInputNumber = $('.guaranteed-options .quantity-plus-minus .input-number');
    let $quantityBtnNumber = $('.guaranteed-options .quantity-plus-minus .btn-number');

    $quantityBtnNumber.on('click', function(e) {
      e.preventDefault();

      let fieldName = $(this).data('field');
      let type = $(this).data('type');
      let input = $('.guaranteed-options .quantity-plus-minus input[name="' + fieldName + '"]');
      let currentVal = parseInt(input.val());

      if (!isNaN(currentVal)) {
        if (type === 'minus') {
          if (currentVal > parseInt(input.attr('min'))) {
            input.val(currentVal - 1).change();
          }
          if (parseInt(input.val()) === parseInt(input.attr('min'))) {
            $(this).prop('disabled', true);
          }
        } else if (type === 'plus') {
          if (currentVal < parseInt(input.attr('max'))) {
            input.val(currentVal + 1).change();
          }
          if (parseInt(input.val()) === parseInt(input.attr('max'))) {
            $(this).prop('disabled', true);
          }
        }
      } else {
        input.val(0);
      }
    });

    $quantityInputNumber.on('focusin', function() {
      $(this).data('oldValue', $(this).val());
    });

    $quantityInputNumber.on('change', function() {
      let minValue = parseInt($(this).attr('min'));
      let maxValue = parseInt($(this).attr('max'));
      let valueCurrent = parseInt($(this).val());
      let name = $(this).attr('name');
      let needUpdateCart = true;

      if (valueCurrent >= minValue) {
        $('.guaranteed-options .quantity-plus-minus .btn-number[data-type="minus"][data-field="' + name + '"]').
            prop('disabled', false);
      } else {
        $(this).val($(this).data('oldValue'));
      }
      if (valueCurrent <= maxValue) {
        $('.guaranteed-options .quantity-plus-minus .btn-number[data-type="plus"][data-field="' + name + '"]').
            prop('disabled', false);
      } else {
        $(this).val($(this).data('oldValue'));
      }

      if ($(this).val() > 1) {
        $('.guaranteed-options .quantity-plus-minus .btn-number[data-type="minus"]').prop('disabled', false);
      } else {
        $('.guaranteed-options .quantity-plus-minus .btn-number[data-type="minus"]').prop('disabled', true);
      }

      $('[name="guaranteed_plan_option_id"]').val($(this).data('plan_option_id'));
      $('[name="guaranteed_select_quantity"]').val($(this).val());
      $(this).closest('.guaranteed-options__container.plus-minus').find('[data-ajax-action="add-to-cart"]').data('quantity', $(this).val());
      $(this).closest('.plan-option').find('.cart-toss-number .number').text($(this).val());

      if ($(this).val() > 1) {
        $(this).closest('.plan-option').find('.promo-quantity').show().text('+' + parseInt($(this).val() / 2) + ' Free');
      } else {
        $(this).closest('.plan-option').find('.promo-quantity').hide().text('+' + parseInt($(this).val() / 2) + ' Free');
      }

      updateData();
    });

    $quantityInputNumber.on('keydown', function(e) {
      // Allow: backspace, delete, tab, escape, enter and .
      if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 190]) !== -1 ||
          // Allow: Ctrl+A
          (e.keyCode === 65 && e.ctrlKey === true) ||
          // Allow: home, end, left, right
          (e.keyCode >= 35 && e.keyCode <= 39)) {
        // let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
          (e.keyCode < 96 || e.keyCode > 105)) {
        e.preventDefault();
      }
    });
  }

  function updateData() {
    let planSize = $inputPlanSize.val();
    let planSizeHits = parseInt($inputPlanSize.data('hits'));
    let planSizeRangeValue = $inputPlanSize.data('range-value');
    let planType = $inputPlanType.val();
    let planTypeU = planType;
    let selectQuantity = parseInt($inputSelectQuantity.val());

    $('.guaranteed-plans-wrap').removeClass('active');
    $('.guaranteed-plans_' + planSize).addClass('active');

    $('[data-plan-type]').removeClass('active');
    $('[data-plan-type="' + planType + '"]').addClass('active');

    if (planType === 'expert') {
      // $('.of-our-clients-choses-expert').hide();
    } else {
      $('.of-our-clients-choses-expert').show();
    }

    planType = (planType === 'economy' ? '' : '_' + planType);
    if (planSize === 'unlim') {
      planType = planType.replace('alexa', 'pro');
    }

    planTypeU = '_' + planTypeU;
    planTypeU = planTypeU.replace('alexa', 'pro');

    // let selectPlanOptionId = $('.guaranteed-options .guaranteed-options_' + planSize + planType + ' .guaranteed-options__container.plus-minus').data('plan-option-id');
    // let selectPlanOptionId = $('.guaranteed-options .guaranteed-options_' + planSize + planType + ' [data-quantity="' + selectQuantity + '"]').data('plan-option-id');
    let selectPlanOptionId = $('.guaranteed-options .guaranteed-options_' + (planSizeHits / 1000) + planTypeU + ' [data-quantity="' + selectQuantity + '"]').data('plan-option-id');

    let planOptionId = parseInt(selectPlanOptionId);
    let planOption = guaranteedPlansOptions["byId"][planOptionId];
    // let planId = guaranteedPlansOptions["byId"][planOptionId].plan_id;
    let planOptionName = planOption.plan_name;
    let planOptionQuantity = parseInt(planOption.quantity);
    let planOptionSelectQuantity = parseInt($('[name="guaranteed_select_quantity"]').val());

    let firstKey = _.keys(guaranteedPlansOptions["bySize"][planSize + planType])[0]
    let planOption1 = guaranteedPlansOptions["bySize"][planSize + planType][firstKey];
    let planOptionPerCredit = parseFloat(planOption.price_per_unit);

    if (planOptionSelectQuantity >= 3 && planOptionSelectQuantity < 12) {
      planOptionPerCredit = planOptionPerCredit - (planOptionPerCredit * 0.1);
    } else if (planOptionSelectQuantity >= 12 && planOptionSelectQuantity < 24) {
      planOptionPerCredit = planOptionPerCredit - (planOptionPerCredit * 0.2);
    } else if (planOptionSelectQuantity >= 24) {
      planOptionPerCredit = planOptionPerCredit - (planOptionPerCredit * 0.4);
    }

    // let planOptionAmount = planOptionPerCredit * planOptionSelectQuantity;
    let planOptionAmount = parseFloat(planOption.price) * planOptionSelectQuantity;
    // let planOptionSaving = (parseFloat(planOption1.price) - planOptionPerCredit) * planOptionSelectQuantity;
    let planOptionSaving = parseFloat(planOption.savings) * planOptionSelectQuantity;

    $guaranteedOptionsWrap.removeClass('active');
    $('.guaranteed-options_' + (planSizeHits / 1000) + planTypeU).addClass('active');

    $('.guaranteed-options__container').removeClass('active');
    $('.guaranteed-options__container[data-plan-option-id="' + planOptionId + '"]').addClass('active');

    $('[name="guaranteed_plan_option_id"]').val(planOptionId);
    $('[name="guaranteed_select_quantity"]').val(planOptionSelectQuantity);
    $('[name="guaranteed_plan_price_per_credit"]').val(planOptionPerCredit.toFixed(2));
    $('[name="guaranteed_plan_amount"]').val(planOptionAmount.toFixed(2));

    let $guaranteedOptionsContainerPlusMinus = $('.guaranteed-options__container.plus-minus[data-plan-option-id="' + planOptionId + '"]');
    $guaranteedOptionsContainerPlusMinus.find('.guaranteed-options__amount span').html('$' + formatPrice(planOptionAmount));
    if (planOptionSaving > 0) {
      $guaranteedOptionsContainerPlusMinus.find('.guaranteed-options__saving span').html('Save $' + formatPrice(planOptionSaving, 0)).show();
    } else {
      $guaranteedOptionsContainerPlusMinus.find('.guaranteed-options__saving span').html('Save $' + formatPrice(planOptionSaving, 0)).hide();
    }
    $guaranteedOptionsContainerPlusMinus.find('.guaranteed-options__price-per-credit').html('Price per credit $' + formatPrice(planOptionPerCredit));

    // $('.button-select-quantity[data-credit-type="website-traffic"] .js-insert').html('+ Select quantity (' + planOptionName + ') ');
    $('.bc-website-traffic .add-to-cart-step').html('Add to Cart <span class="plan-color--purple d-block d-lg-inline-block">' + planOption.plan_name + '</span>');

    let price = planOption1.price_per_unit;
    $('.buy-credits__package-calc .js-insert').html('$' + formatPrice(price));

    let savings = planOption1.savings;
    let discount = Math.round(100 - ((Math.round(planOption1.price) / Math.round(planOption1.original_price)) * 100));

    $('.buy-credits__package-calc .js-insert-savings').html('-' + discount + '% Discount');
    if (savings > 0) {
      $('.buy-credits__package-calc .js-insert-savings').show();
    } else {
      $('.buy-credits__package-calc .js-insert-savings').hide();
    }

    $('.guaranteed-sizes__container[data-plan-size]').each(function(index, elem) {
      let slidePlanSize = $(elem).data('plan-size');
      let planType = $inputPlanType.val();
      let planHits = parseInt($(elem).data('plan-hits'));

      planType = (planType === 'economy' ? '' : '_' + planType);
      planType = planType.replace('alexa', 'pro');

      let slidePlanType;
      if (slidePlanSize === 'unlim') {
        slidePlanType = planType.replace('alexa', 'pro');
      } else {
        slidePlanType = planType.replace('pro', 'alexa');
      }

      let firstKey = _.keys(guaranteedPlansOptions["bySize"][slidePlanSize + slidePlanType])[0]
      let planOption1 = guaranteedPlansOptions["bySize"][slidePlanSize + slidePlanType][firstKey];

      let savings = planOption1.savings;
      let discount = Math.round(100 - ((Math.round(planOption1.price) / Math.round(planOption1.original_price)) * 100));

      let typeSavings = $(elem).find('.block-savings').data('type');
      if (typeSavings === 'percents') {
        $(elem).find('.block-savings span').
            data('discount', discount).
            html('-' + discount + '%');
      } else {
        $(elem).find('.block-savings span').
            data('discount', discount).
            html('-$' + formatPrice(savings, 0));
      }

      if (savings > 0) {
        $(elem).find('.block-savings').show();
      } else {
        $(elem).find('.block-savings').hide();
      }
    });

    $('.guaranteed-sizes__slider-titles .for-js-width').each(function (index, elem) {
      $(elem).css('margin-left', -1 * ($(elem).width() / 2));
    });

    // Slider Tooltip
    let nextRangeValue = parseInt(planSizeRangeValue) + 1;
    showTooltip(nextRangeValue);
  }

  function showTooltip(rangeValue) {
    if (!jsPageData.showTooltipToSlider) {
      return;
    }

    let hideTooltips = false;
    let $tabEl = $('.buy-credits-nav-tabs a[data-bs-toggle="tab"]');
    $tabEl.each(function(index, elem) {
      if ($(elem).hasClass('active')) {
        let $tooltipSlider = $('.tooltip-slider');
        if ($(elem).attr('href') !== '#tab_web_traffic_credits') {
          hideTooltips = true;
        }
      }
    });

    $('#range_plan_sizes .ui-slider-handle').tooltip('dispose');

    rangeValue = parseInt(rangeValue);
    // let savings = $('.guaranteed-sizes__container[data-range-value="' + rangeValue + '"] .block-savings [data-for-js]').html();
    let discountNext = parseInt($('.guaranteed-sizes__container[data-range-value="' + rangeValue + '"] .block-savings [data-for-js]').data('discount'));
    let discountCurrent = 0;
    let needHits = '0';

    if (rangeValue > 0) {
      discountCurrent = parseInt($('.guaranteed-sizes__container[data-range-value="' + (rangeValue - 1) + '"] .block-savings [data-for-js]').data('discount'));
    }

    if (rangeValue > 5 || discountNext === 0 || discountCurrent === discountNext) {
      return;
    }

    if (rangeValue === 1) {
      needHits = '240K';
    } else if (rangeValue === 2) {
      needHits = '300K';
    } else if (rangeValue === 3) {
      needHits = '400K';
    } else if (rangeValue === 4) {
      needHits = '9000K';
    } else if (rangeValue === 5) {
      needHits = '40000K';
    }

    if (jsAppData.expSt878 && jsAppData.expSt878 === '50-200-500') {
      if (rangeValue === 1) {
        needHits = '150K';
      } else if (rangeValue === 2) {
        needHits = '300K';
      } else if (rangeValue === 3) {
        needHits = '500K';
      } else if (rangeValue === 4) {
        needHits = '9000K';
      } else if (rangeValue === 5) {
        needHits = '40000K';
      }
    }

    let deviceType = $('[data-device-type]').data('device-type');
    let tooltipTriggerEl = document.querySelector('#range_plan_sizes .ui-slider-handle');
    let template = '<div class="tooltip promo tooltip-slider" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>';
    let title = `<div class='row g-0 align-items-center'><div class='col'><div class='title'>+Add ${needHits} more</div><div class='text'>To get -${discountNext}%</div></div><div class='col-auto'><button class='button button-main button_size_32' data-next-slider="${parseInt(rangeValue)}" data-action='tooltip-yes'>Yes</button><button class='button button-main button_size_32' data-action='tooltip-no'>No</button></div></div>`;

    let tooltip = new bootstrap.Tooltip(tooltipTriggerEl, {
      container: 'body',
      placement: (deviceType === 'phone' || deviceType === 'tablet'
          ? 'bottom'
          : 'top'),
      fallbackPlacements: ['top', 'bottom'],
      trigger: 'manual',
      template: template,
      sanitize: false,
      html: true,
      title: title
    });

    clearTimeout(sliderTooltipTimeout);
    sliderTooltipTimeout = setTimeout(function() {
      tooltip.show();
      if (hideTooltips) {
        let $tooltipsSlider = $('.tooltip-slider');
        $tooltipsSlider.hide();
      }
    }, 500);

    $('#range_plan_sizes .ui-slider-handle').on('shown.bs.tooltip', function(event) {
      let $this = $(this);
      let tooltipId = $this.attr('aria-describedby');
      if (!tooltipId) {
        return;
      }

      $('#' + tooltipId).find('[data-action="tooltip-no"]').
          off('click').
          on('click', function() {
            $this.tooltip('hide');
          });

      $('#' + tooltipId).find('[data-action="tooltip-yes"]').
          off('click').
          on('click', function() {
            let nextSlider = parseInt($(this).data('next-slider'));

            if (typeof $('#range_plan_sizes').slider === 'function') {
              let sizeHits = 0;
              if (nextSlider === 0) {
                sizeHits = 60000;
              } else if (nextSlider === 1) {
                sizeHits = 300000;
              } else if (nextSlider === 2) {
                sizeHits = 600000;
              } else if (nextSlider === 3) {
                sizeHits = 1000000;
              } else if (nextSlider === 4) {
                sizeHits = 10000000;
              } else if (nextSlider === 5) {
                sizeHits = 50000000;
              }

              if (jsAppData.expSt878 && jsAppData.expSt878 === '50-200-500') {
                if (nextSlider === 0) {
                  sizeHits = 50000;
                } else if (nextSlider === 1) {
                  sizeHits = 200000;
                } else if (nextSlider === 2) {
                  sizeHits = 500000;
                } else if (nextSlider === 3) {
                  sizeHits = 1000000;
                } else if (nextSlider === 4) {
                  sizeHits = 10000000;
                } else if (nextSlider === 5) {
                  sizeHits = 50000000;
                }
              }

              $('#range_plan_sizes').slider('value', nextSlider);
              $('[name="plan_size"]').
                  data('range-value', nextSlider).
                  data('hits', sizeHits).
                  trigger('change');
            }

            $this.tooltip('hide');
          });
    });
  }

  let maxSlider = 5;

  // plan slider
  if (typeof $rangePlanSizes.slider === 'function') {
    $rangePlanSizes.slider({
      range: "min",
      value: 3,
      min: 0,
      max: maxSlider,
      slide: function(event, ui) {
        let sizeHits = 0;
        let rangeValue = parseInt(ui.value);

        if (rangeValue === 0) {
          sizeHits = 60000;
        } else if (rangeValue === 1) {
          sizeHits = 300000;
        } else if (rangeValue === 2) {
          sizeHits = 600000;
        } else if (rangeValue === 3) {
          sizeHits = 1000000;
        } else if (rangeValue === 4) {
          sizeHits = 10000000;
        } else if (rangeValue === 5) {
          sizeHits = 50000000;
        }

        if (jsAppData.expSt878 && jsAppData.expSt878 === '50-200-500') {
          if (rangeValue === 0) {
            sizeHits = 50000;
          } else if (rangeValue === 1) {
            sizeHits = 200000;
          } else if (rangeValue === 2) {
            sizeHits = 500000;
          } else if (rangeValue === 3) {
            sizeHits = 1000000;
          } else if (rangeValue === 4) {
            sizeHits = 10000000;
          } else if (rangeValue === 5) {
            sizeHits = 50000000;
          }
        }

        $('[name="plan_size"]').
            data('range-value', rangeValue).
            data('hits', sizeHits).
            trigger('change');
      }
    });
  }

  $('.guaranteed-sizes__container').on('click', function () {
    let sizeHits = 0;
    let rangeValue = parseInt($(this).data('range-value'));

    if (rangeValue === 0) {
      sizeHits = 60000;
    } else if (rangeValue === 1) {
      sizeHits = 300000;
    } else if (rangeValue === 2) {
      sizeHits = 600000;
    } else if (rangeValue === 3) {
      sizeHits = 1000000;
    } else if (rangeValue === 4) {
      sizeHits = 10000000;
    } else if (rangeValue === 5) {
      sizeHits = 50000000;
    }

    if (jsAppData.expSt878 && jsAppData.expSt878 === '50-200-500') {
      if (rangeValue === 0) {
        sizeHits = 50000;
      } else if (rangeValue === 1) {
        sizeHits = 200000;
      } else if (rangeValue === 2) {
        sizeHits = 500000;
      } else if (rangeValue === 3) {
        sizeHits = 1000000;
      } else if (rangeValue === 4) {
        sizeHits = 10000000;
      } else if (rangeValue === 5) {
        sizeHits = 50000000;
      }
    }

    if (typeof $('#range_plan_sizes').slider === 'function') {
      $('#range_plan_sizes').slider('value', $(this).data('range-value'));
    }

    $('[name="plan_size"]').
        data('range-value', rangeValue).
        data('hits', sizeHits).
        trigger('change');
  });

  $inputPlanSize.on('change', function () {
    $(this).val(arrPlanSize[$(this).data('range-value')]);

    $('.guaranteed-sizes__slider-info-hits').text(formatNumberK($(this).data('hits')));
    $('.guaranteed-sizes__container').removeClass('active');
    $('.guaranteed-sizes__container[data-range-value="' + $(this).data('range-value') + '"]').addClass('active');

    updateData();
  }).trigger('change');

  $('.guaranteed-sizes__slider-titles .for-js-width').each(function (index, elem) {
    $(elem).css('margin-left', -1 * ($(elem).width() / 2));
  });

  // plan type
  $('.guaranteed-plans__header[data-plan-type], .guaranteed-plans__item[data-plan-type]').on('click', function () {
    $('.see-all-compare .fcell').removeClass('selected');
    $('.see-all-compare .guaranteed-plans__item-compare[data-plan-type="' + $(this).data('plan-type') + '"]').closest('.fcell').addClass('selected');
    $('.see-all-compare .plan-type-checkbox[data-plan-type="' + $(this).data('plan-type') + '"]').closest('.fcell').addClass('selected');

    $('.plan-type-checkbox').prop('checked', false);
    $('.plan-type-checkbox[data-plan-type="' + $(this).data('plan-type') + '"]').prop('checked', true);
    $inputPlanType.val($(this).data('plan-type')).trigger('change');
  });

  $('.plan-type-checkbox').on('click', function (e) {
    $('.see-all-compare .fcell').removeClass('selected');
    $('.see-all-compare .guaranteed-plans__item-compare[data-plan-type="' + $(this).data('plan-type') + '"]').closest('.fcell').addClass('selected');
    $('.see-all-compare .plan-type-checkbox[data-plan-type="' + $(this).data('plan-type') + '"]').closest('.fcell').addClass('selected');

    $('.plan-type-checkbox').prop('checked', false);
    $(this).prop('checked', true);
    $inputPlanType.val($(this).data('plan-type')).trigger('change');
  });

  $('.see-all-compare .guaranteed-plans__item-compare').on('click', function (e) {
    $(this).closest('.guaranteed-plans-wrap').
        find('.plan-type-checkbox[data-plan-type="' + $(this).data('plan-type') + '"]').
        trigger('click');

    $inputPlanType.val($(this).data('plan-type')).trigger('change');
  });

  $inputPlanType.on('change', function () {
    updateData();
  });

  $('.button-show-all-compare-list').on('click', function () {
    let $seeAllCompareEl = $('.see-all-compare');

    $seeAllCompareEl.toggleClass('hidden');
    $(this).
        toggleClass('hidden').
        find('span').
        text($seeAllCompareEl.hasClass('hidden') ? 'Show All Features' : 'Hide');
  });

  initPlusMinusToCart();
  updateData();
};


export const initBuyWebsiteTrafficScripts = () => {
  /**
   * @property {object} jsPageData
   */

  runIfHasSelecter('[data-role="cart"]', [
    initCommon
  ]);
};
