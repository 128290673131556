import {checkOrderStatus, runIfHasSelecter} from '../../../utils/panel-lib.js';
import axios from 'axios';
import route from '../../index.js';

const initCommon = function()    {
  let $paymentFormWrap = $('.form-payment-card-wrap');
  let $paymentForm = $('form[data-form-id="form-payment-card"]');
  let $paymentFormError = $paymentForm.find('.form-payment-card__error');
  let $buttonPay = $paymentForm.find('[data-ajax-action="pay-now"]');
  let $cart = $('.payment-order');
  let isCheckoutTopForm = $('.commerce-gate-checkout.top-form').length > 0;
  let check3dsTimeout = null;
  let $iframe3dVerification = $('.iframe-3d-verification');
  let $loadingBlock = $paymentFormWrap.find('.loading-block');
  let loadedIframe3ds = false;
  let completedCheck3ds = false;
  let getOrderTimeout = null;

  function displayIframe3dVerification(isShow = true) {
    if (isShow) {
      $('.iframe-3d-verification').show();
      $('.form-payment-card').hide();
      $buttonPay.prop('disabled', true);
    } else {
      $('.iframe-3d-verification').hide();
      $('.form-payment-card').show();
      $buttonPay.prop('disabled', false);
    }
  }

  function showCheckoutErrorBlock() {
    $('.payment-options').hide();
    $('.checkout-block-payment').hide();
    $('.checkout-block-info').addClass('error');
    $('.checkout-block-qr-code').hide();
    displayIframe3dVerification(false);
    $('.loading-block').hide();

    $('.checkout-block-info [data-button-action="repeat"]').
        off('click').
        on('click', function() {
          $(this).prop('disabled', true);
          // document.location.reload();

          $('.payment-options').show();
          $('.checkout-block-payment').show();
          $('.checkout-block-info').removeClass('error').removeClass('success');
        });
  }

  function showCheckoutSuccessBlock() {
    $('.payment-options').hide();
    $('.checkout-block-payment').hide();
    $('.checkout-block-info').addClass('success');
    $('.checkout-block-qr-code').hide();
    displayIframe3dVerification(false);
    $('.loading-block').hide();
  }

  function updateCommerceGateSession() {
    let isPaidSuccess = $('.checkout-block-info').hasClass('success');
    if (isPaidSuccess) {
      return;
    }

    let $currencySel = $('select[name="cg_currency"]');
    let currency = $currencySel.length > 0 ? $currencySel.val() : 'USD';

    if (!isCheckoutTopForm) {
      $buttonPay.prop('disabled', true);
    }

    axios.get(route('api.commerce_gate.get_payment_form_url') + '?currency=' + currency).
        then(function(response) {
          let data = (response.data ? response.data : {});
          let orderId = data?.orderId;
          let formUrl = data?.formUrl;

          if (!isCheckoutTopForm) {
            $buttonPay.closest('.payment-options__button').hide();
          }
          $iframeWrap.removeClass('hidden');

          if (formUrl) {
            if (isCheckoutTopForm) {
              $('.checkout-block').show();
              $('.checkout-block-payment').show();
            }
            $iframe.attr('src', formUrl);

            if (orderId) {
              checkOrderStatus(orderId);
            }
          } else {
            if (isCheckoutTopForm) {
              $('.checkout-block-payment').hide();
            }
          }
        }).
        catch(function(error) {
          console.error(error);
          if (isCheckoutTopForm) {
            $('.checkout-block-payment').hide();
          } else {
            $buttonPay.prop('disabled', false);
          }
        });
  }

  const createSubscription = (data = {}) => {
    let isPaidSuccess = $('.checkout-block-info').hasClass('success');
    if (isPaidSuccess) {
      return;
    }

    let $currencySel = $('select[name="cg_currency"]');
    data.currency = $currencySel.length > 0 ? $currencySel.val() : 'USD';

    $buttonPay.prop('disabled', true);
    $loadingBlock.show();

    axios.post(route('api.payment.commerce_gate.create_subscription'), data).
        then(response => {
          let data = (response.data ? response.data : {});
          if (!data) {
            $buttonPay.prop('disabled', false);
            return;
          }

          let status = data.status ?? '';
          if (status === 'failed' || data.errMessage) {
            if (data.errMessage) {
              $paymentFormError.html(data.errMessage).show();
            } else if (status.errMessage) {
              $paymentFormError.html(status.errMessage).show();
            }

            $buttonPay.prop('disabled', false);
            $loadingBlock.hide();
          } else if (status === 'pending') {
            let orderId = parseInt(data.MetaData?.orderId ?? null);
            let referenceOrderId = data.orderId ?? null;

            if (orderId) {
              checkOrderStatus(orderId);
            }
            getOrder(referenceOrderId);
          } else if (status === 'success') {
            showCheckoutSuccessBlock();
            $loadingBlock.hide();
          }
        }).
        catch(error => {
          console.log('There was an error:', error);
          $buttonPay.prop('disabled', false);
          $loadingBlock.hide();
        });
  };

  const recurringPayment = (orderId, referenceOrderId) => {
    let isPaidSuccess = $('.checkout-block-info').hasClass('success');
    if (isPaidSuccess) {
      return;
    }

    let $currencySel = $('select[name="cg_currency"]');
    let data = {
      currency: $currencySel.length > 0 ? $currencySel.val() : 'USD',
      orderId: orderId,
      referenceOrderId: referenceOrderId,
    };

    $buttonPay.prop('disabled', true);

    axios.post(route('api.payment.commerce_gate.recurring_payment'), data).
        then(response => {
          let data = (response.data ? response.data : {});
          if (!data) {
            return;
          }

          if (data.errMessage) {
            $paymentFormError.html(data.errMessage).show();
            $buttonPay.prop('disabled', false);
          }
        }).
        catch(error => {
          console.log('There was an error:', error);
          $buttonPay.prop('disabled', false);
        });
  };

  const getOrder = (referenceOrderId) => {
    clearTimeout(check3dsTimeout);

    let isPaidSuccess = $('.checkout-block-info').hasClass('success');
    if (isPaidSuccess) {
      return;
    }

    let data = {
      referenceOrderId: referenceOrderId,
    };

    $buttonPay.prop('disabled', true);

    axios.post(route('api.payment.commerce_gate.get_order'), data).
        then(response => {
          let data = (response.data ? response.data : {});
          if (!data) {
            return;
          }

          let status = (data.status ? data.status : {});
          let orderId = parseInt(data.MetaData?.orderId ?? null);
          let referenceOrderId = data.orderId ?? null;

          if (status === 'failed') {
            if (data.errMessage) {
              $paymentFormError.html(data.errMessage).show();
            }
            $buttonPay.prop('disabled', false);
          } else if (status === 'success') {
            // recurringPayment(orderId, referenceOrderId);
            showCheckoutSuccessBlock();
          } else if (status === 'threeDSecureRequested') {
            displayIframe3dVerification(true);

            if (data.ThreeDInfo && data.ThreeDInfo.acsServerUrl) {
              $loadingBlock.show();
              $iframe3dVerification.attr('src', data.ThreeDInfo.acsServerUrl);
              $iframe3dVerification.on('load', function(){
                $loadingBlock.hide();
                loadedIframe3ds = true;
              });

              setTimeout(function() {
                if (!loadedIframe3ds) {
                  displayIframe3dVerification(false);
                  $iframe3dVerification.remove();
                  $loadingBlock.hide();
                  $paymentFormError.html('Payment failed').show();
                }
              }, 60000);
            }
          } else {
            check3dsTimeout = setTimeout(function() {
              getOrder(referenceOrderId);
            }, 1000);
          }
        }).
        catch(error => {
          console.log('There was an error:', error);
          check3dsTimeout = setTimeout(function() {
            getOrder(referenceOrderId);
          }, 5000);
        });
  };

  $paymentForm.on('submit', function(e) {
    e.preventDefault();

    $paymentFormError.html('').hide();

    const cardId = $paymentForm.find('[name="card_id"]').val();
    const cardNumber = $paymentForm.find('[name="card_number"]').val();
    const cardName = $paymentForm.find('[name="card_name"]').val();
    const cardExpirationM = $paymentForm.find('[name="card_expiration_m"]').val();
    const cardExpirationY = $paymentForm.find('[name="card_expiration_y"]').val();
    const cardCvv = $paymentForm.find('[name="card_cvv"]').val();

    if (!cardNumber || !cardName || !cardExpirationY || !cardExpirationY || !cardCvv) {
      return;
    }

    createSubscription({
      cardId: cardId,
      cardNumber: cardNumber,
      cardName: cardName,
      cardExpirationM: cardExpirationM,
      cardExpirationY: cardExpirationY,
      cardCvv: cardCvv
    });
  });

  // $buttonPay.on('click', function() {
  //   updateCommerceGateSession();
  // });

  // $('select[name="cg_currency"]').on('change', function () {
  //   let amount = parseFloat($('.payment-order__total').data('amount'));
  //   let selectCurrency = $(this).val();
  //   let selectCurrencySymbol = $(this).find('option:selected').data('symbol');
  //   let amountCurrency = 0.0;
  //
  //   if (selectCurrency === 'USD') {
  //     $('.amount-cg-currency .for-js').html('');
  //     $('.amount-cg-currency').hide();
  //
  //     if (isCheckoutTopForm) {
  //       $iframeWrap.addClass('hidden');
  //       $iframe.attr('src', '');
  //       updateCommerceGateSession();
  //     } else {
  //       if (!$iframeWrap.hasClass('hidden')) {
  //         updateCommerceGateSession();
  //       } else {
  //         $buttonPay.closest('.payment-options__button').show();
  //         $buttonPay.prop('disabled', false);
  //       }
  //     }
  //   } else {
  //     getExchangeRate(selectCurrency).then(function(response) {
  //       if (response.data && response.data.success && response.data.data) {
  //         let data = response.data.data;
  //         let rate = data.rate ? data.rate : 1.0;
  //
  //         amountCurrency = (amount * parseFloat(rate)).toFixed(2);
  //         $('.amount-cg-currency .for-js').html(selectCurrencySymbol + '&nbsp;' + amountCurrency);
  //         $('.amount-cg-currency').show();
  //       }
  //
  //       $iframeWrap.addClass('hidden');
  //       $iframe.attr('src', '');
  //
  //       if (isCheckoutTopForm) {
  //         $iframeWrap.addClass('hidden');
  //         $iframe.attr('src', '');
  //         updateCommerceGateSession();
  //       } else {
  //         if (!$iframeWrap.hasClass('hidden')) {
  //           updateCommerceGateSession();
  //         } else {
  //           $buttonPay.closest('.payment-options__button').show();
  //           $buttonPay.prop('disabled', false);
  //         }
  //       }
  //     });
  //   }
  // });

  // $cart.on('cart-updated-complete', function() {
  //   $('select[name="cg_currency"]').trigger('change');
  // });

  $cart.on('cart-updated-complete', function() {
    // $('.checkout-block-payment').hide();

    if (isCheckoutTopForm) {
      $('.checkout-block').show();

      if ($('.payment-order__total-amount').hasClass('amount-zero')) {
        $('.checkout-block-payment').hide();
        // $('.checkout-block-qr-code').hide();
      } else {
        $('.checkout-block-payment').show();
        // $('.checkout-block-qr-code').show();
        // createSubscription();
      }
    }
  });
};

export const initCartPaymentMethodCommerceGateScripts = () => {
  /**
   * @property {object} jsPageData
   */

  runIfHasSelecter('[data-role="cart"]', [
    initCommon
  ]);
};
