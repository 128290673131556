import {defineStore} from "pinia";
import {ApiResource} from "../plugins/ApiExtends";
import {useLinkBuildingType} from "./useLinkBuilding";

export const CREDIT_TYPE_SEO = 'credits_search'
export const CREDIT_TYPE_BACKLINK = 'backlinks'

export const useCreditTypes = defineStore('credit-types',{
    state: () => {
        return {
            "credit_types" : [],
            "is_show_promo_banner": null,
            "promo_current_time": null,
            "promo_target_time": null,
            "promo_settings": null,
        }
    },
    getters: {
        creditTypeSeo: (state) => {
            return state.credit_types.find((credit_type) => credit_type.type === CREDIT_TYPE_SEO);
        },
        creditTypesBacklink: (state) => {
            return state.credit_types.filter((credit_type) => credit_type.type === CREDIT_TYPE_BACKLINK);
        },
    },
    actions: {
        async download(){
            let resource = new ApiResource({
                'url' : '/api/v1/billing/plan-options-packages',
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.init(resource.data);
                return [true];
            }else{
                return [false,resource.errorMessage];
            }
        },
        init(data){
            this.$patch((state) => {

                state.is_show_promo_banner = data.is_show_promo_banner;
                state.promo_current_time = data.current_time;

                if(data.is_show_promo_banner){
                    state.promo_target_time = new Date();
                    state.promo_target_time.setSeconds(state.promo_target_time.getSeconds() + data.current_time);
                }

                state.promo_settings = data.is_show_promo_banner;

                data.credit_types?.forEach((credit_type) => {
                    useCreditType(credit_type.id).init(credit_type);
                    if(!this.hasCreditType(credit_type.id)){
                        state.credit_types.push(useCreditType(credit_type.id));
                    }
                });

            });
        },
        hasCreditType(id){
            return this.credit_types.some((credit_type) => credit_type.id === id);
        },
        clearPromo(){
            this.$patch({
                promo_current_time: null,
                promo_target_time: null,
                is_show_promo_banner: false,
            });
        }
    }
});

export const useCreditType = (id) => defineStore('credit-type-'+id, {
    state: () => {
        return {
            "id": id,
            "name": null,
            "sort_order": null,
            "short_name": null,
            "active": null,
            "title": null,
            "description": null,
            "type": null,
            "plans_options" : [],
        }
    },
    getters: {
        firstPlanOptions: (state) => {
            if(state.plans_options.length > 0){
                return state.plans_options[0]
            }else{
                return null;
            }
        }
    },
    actions: {
        init(data){
            this.$patch((state) => {
                state.name = data.name;
                state.sort_order = data.sort_order;
                state.short_name = data.short_name;
                state.active = data.active;
                state.title = data.title;
                state.description = data.description;
                state.type = data.type;

                data.plans_options?.forEach((plan_options) => {
                    usePlanOptions(plan_options.id).init(plan_options);
                    if(!this.hasPlanOption(plan_options.id)){
                        state.plans_options.push(usePlanOptions(plan_options.id));
                    }
                })

            });
        },
        hasPlanOption(id){
            return this.plans_options.some(plan_option => plan_option.id === id);
        }
    }
})();

export const usePlanOptions = (id) => defineStore('plan-options-'+id,{
    state: () => {
        return {
            "id": id,
            "plan_id": null,
            "quantity": null,
            "paypal_plan_id": null,
            "name": null,
            "price": null,
            "is_active": null,
            "credit_type_id": null,
            "plan_options_packages": [],
        }
    },
    getters: {
        packagesSortedByPrice: (state) => {
            return state.plan_options_packages.sort((a,b) => {
                return a.price > b.price ? 1 : -1;
            })
        },
        minQuantity(){
            let pack = this.packagesSortedByPrice.at(0);
            return pack !== undefined ? pack.quantity : null;
        },
        /**
         *  Цена за 1 quantity в данном planOptions без скидки
         * @returns {number}
         */
        basePrice(){
            let pack = this.packagesSortedByPrice.at(0);
            return pack !== undefined ? (pack.price + pack.discount_amount) / pack.quantity : null
        },
        typeConstantBacklink(state) {
            return useLinkBuildingType().getConstant(state.name);
        }
    },
    actions: {

        init(data){
            this.$patch((state) => {
                state.plan_id = data.plan_id;
                state.quantity = data.quantity;
                state.paypal_plan_id = data.paypal_plan_id;
                state.name = data.name;
                state.price = data.price;
                state.is_active = data.is_active;
                state.credit_type_id = data.credit_type_id;

                data.plan_options_packages?.forEach((plan_options_package) => {
                    usePlanOptionsPackage(plan_options_package.id).init(plan_options_package);
                    if(!this.hasPLanOptionsPackage(plan_options_package)){
                        state.plan_options_packages.push(usePlanOptionsPackage(plan_options_package.id));
                    }
                });

            });
        },

        hasPLanOptionsPackage(id){
            return this.plan_options_packages.some(pack => pack.id === id);
        },

        /**
         * Package для переданного количества quantity
         * @param quantity {number}
         */
        packForQuantity(quantity){
            return this.packagesSortedByPrice.slice().reverse().find((pack) => quantity >= pack.quantity);
        },

        /**
         * Сколько всего денег экономится при данном количестве товара
         * @param quantity {number}
         * @returns {number}
         */
        discountAmountForQuantity(quantity){
            let pack = this.packForQuantity(quantity);
            return pack!==undefined ? this.basePrice * quantity - pack.priceForOne * quantity : null
        },

        /**
         * Стоимость переданного количества с учетом скидки
         * @param quantity {number}
         * @returns {number}
         */
        priceForQuantity(quantity){
            let pack = this.packForQuantity(quantity);
            return pack!==undefined ? pack.priceForOne * quantity : null;
        },

        promoAmountForQuantity(quantity){
            if(!useCreditTypes().is_show_promo_banner){
                return 0;
            }
            if(useCreditType(this.credit_type_id)?.type === 'backlink' && quantity <= 1){
                return 0;
            }else{
                return Math.floor(quantity / 2);
            }
        },

    }
})();

export const usePlanOptionsPackage = (id) => defineStore('plan-options-package-'+id,{
    state: () => {
        return {
            "id": id,
            "name": null,
            "title": null,
            "description": null,
            "plan_id": null,
            "quantity": null,
            "price": null,
            "discount": null,
            "type": null,
            "is_best_price": null,
            "is_most_popular": null,
            "created_at": null,
            "updated_at": null,
            "discount_amount": null,
        }
    },
    getters: {
        /**
         *  Цена за 1 quantity в данном package с учетом скидки
         * @returns {number}
         */
        priceForOne: (state) => {
            return state.price / state.quantity;
        },
        typeConstantBacklink: (state) => {
            return useLinkBuildingType().getConstant(state.name);
        }
    },
    actions: {
        init(data){
            this.$patch((state) => {
                state.name = data.name;
                state.title = data.title;
                state.description = data.description;
                state.plan_id = data.plan_id;
                state.quantity = data.quantity;
                state.price = data.price;
                state.discount = data.discount;
                state.type = data.type;
                state.is_best_price = data.is_best_price;
                state.is_most_popular = data.is_most_popular;
                state.created_at = data.created_at;
                state.updated_at = data.updated_at;
                state.discount_amount = data.discount_amount;
            });
        }
    }
})();

export const useBillingCart = defineStore('billing-cart', {
    state: () => {
        return {
            items: [],
            requestExecution: {
                update: false,
                add:false,
                deleteItem:false,
                updateItem:false,
            },
        }
    },
    getters: {

        totalPrice: (state) => {
            const totalPrice = state.items.reduce((sum, item) => sum+item.price,0);
            console.log(totalPrice);
            return totalPrice;
        },

        totalPriceWithoutDiscount: (state) => {
            return state.items.reduce((sum, item) => sum+item.priceWithoutDiscount,0);
        },

        requestExecutionAny: (state) => {
            for (const key in state.requestExecution) {
                if (state.requestExecution[key] === true) {
                    return true;
                }
            }
            return false;
        },

    },
    actions: {

        startRequestExecution(name){
            this.$patch((state) => {
                state.requestExecution[name] = true;
            });
        },

        endRequestExecution(name){
            this.$patch((state) => {
                state.requestExecution[name] = false;
            });
        },

        async update(){

            this.startRequestExecution('update');

            let resource = new ApiResource({
                'url' : '/api/v1/cart',
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){

                resource.data.forEach((item) => {

                    useBillingCartItem(item.id).init(item);

                    if(!this.has(item.id)){
                        this.$patch((state) => state.items.push(useBillingCartItem(item.id)));
                    }

                });

                this.endRequestExecution('update');

                return [true];
            }else{
                this.endRequestExecution('update');
                return [false,resource.errorMessage];
            }
        },

        async add(plan_id, quantity){

            this.startRequestExecution('add');

            let item = this.getItemByPlanId(plan_id);
            if(item){
                quantity = item.quantity+quantity;
            }
            let resource = new ApiResource({
                'url' : '/api/v1/cart',
                'method' : 'POST',
                'data': {
                    plan_id: plan_id,
                    quantity: quantity,
                }
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                await useBillingCart().update();
                this.endRequestExecution('add');
                return [true];
            }else{
                this.endRequestExecution('add');
                return [false,resource.errorMessage];
            }
        },

        async deleteItem(id){
            this.startRequestExecution('deleteItem');

            let resource = new ApiResource({
                'url' : '/api/v1/cart/'+id,
                'method' : 'DELETE',
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.deleteFromItems(id);
                this.endRequestExecution('deleteItem');
                return [true];
            }else{
                this.endRequestExecution('deleteItem');
                return [false,resource.errorMessage];
            }

        },

        async updateItem(plan_id, quantity){
            this.startRequestExecution('updateItem');

            let resource = new ApiResource({
                'url' : '/api/v1/cart/',
                'method' : 'POST',
                'data': {
                    plan_id: plan_id,
                    quantity: quantity,
                },
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.getItemByPlanId(plan_id).quantity = quantity;
                this.endRequestExecution('updateItem');
                return [true];
            }else{
                this.endRequestExecution('updateItem');
                return [false,resource.errorMessage];
            }

        },

        deleteFromItems(id){
            const index = this.items.findIndex((item) => {
                return item.id === id
            });
            if (index !== -1) {
                this.items.splice(index, 1);
            }
        },

        getItemByPlanId(plan_id){
            return this.items.find((item) => item.plan_id === plan_id);
        },

        has(id){
            return this.items.some((item) => item.id === id);
        },

        /**
         * @param type {'stripe'|'paypal'|'crypto'|'bank'}
         */
        async getPayData(type){
            let resource = new ApiResource({
                'url' : '/api/v1/cart/pay/'+type,
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                return [true,resource.data];
            }else{
                return [false,resource.errorMessage];
            }
        }

    }
});

export const useBillingCartItem = (id) => defineStore('billing-cart-item-'+id, {
    state: () => {
        return {
            id: id,
            quantity: null,
            plan_id: null,
            plan_option_id: null,
        }
    },
    getters: {
        plan_options: (state) => {
            return usePlanOptions(state.plan_option_id);
        },
        price(state){
            return this.plan_options.priceForQuantity(state.quantity);
        },
        priceWithoutDiscount(state){
            return this.plan_options.basePrice * state.quantity;
        },
        discountAmount(state){
            return this.plan_options.discountAmountForQuantity(state.quantity);
        },
        pricePerCredit(state){
            return this.price / state.quantity;
        },
        promoAmount(state){
            return this.plan_options?.promoAmountForQuantity(state.quantity);
        },
    },
    actions: {
        init(data){
            this.$patch({
                quantity: data.quantity,
                plan_id: data.plan_id,
                plan_option_id: data.plan_option.id,
            });
        },
        async delete() {
            return await useBillingCart().deleteItem(id);
        },
        async update(quantity){
            return await useBillingCart().updateItem(this.plan_id, quantity);
        },
    },
})();