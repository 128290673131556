<template>
  <v-button class="v-button-support vbs" color="link">
    <span class="vbs__inner">
      <v-icon-svg name="support" class="vbs__inner-icon"/>
      <slot name="default">Connect to Support</slot>
    </span>
  </v-button>
</template>

<script setup lang="ts">

import VIconSvg from "./VIconSvg.vue";
import VButton from "./VButton.vue";
</script>

<style scoped>
.v-button-support{
  border: 1px solid #1D94FF!important;
  padding:10px;
  background-color: transparent;

  .vbs__inner{
    display: flex;
    gap:10px;
    color:#1D94FF;
    align-items: center;
    transition-duration: 0.2s;
    path{
      transition-duration: 0.2s;
    }
  }

  &:hover{
    border-color: #186CBF!important;
    .vbs__inner{
      color:#186CBF;
    }
    :deep(.vbs__inner-icon path){
      fill:#186CBF;
    }
  }
}
</style>