<template>
  <div class="v-alert-inline vai">
      <div class="vai__text">
        <slot></slot>
      </div>
      <div class="vai-close">
        <v-icon-svg class="vai-close-icon" name="icon-close" @click="emit('close')"/>
      </div>
  </div>
</template>

<script setup>

import VIconSvg from "./VIconSvg.vue";

const emit = defineEmits('close');

</script>

<style lang="scss" scoped>
.v-alert-inline.vai{
  padding:20px;
  gap: 4px;
  border-radius: 4px;
  display:flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F7FAFF;

  .vai__text{
    font-size: 14px;
    font-weight: 600;
    color:#384967;
  }
  .vai-close{
    flex-shrink: 0;
    .vai-close-icon{
      cursor:pointer;
      opacity:0.7;
      transition-duration: 0.2s;
      &:hover{
        opacity:1;
      }
    }
  }
}
</style>