import {
    formatNumber,
    formatTimeleft,
    formValidationErrorClear,
    formValidationErrorHandling,
    prepareChartData,
    runIfHasSelecter
} from "../../utils/panel-lib";
import Chart from 'chart.js/auto';
import _ from "lodash";
import route from '../index.js';

const initGaErrorMessages = function () {
    if (jsPageData.isDeleted) {
        return;
    }

    let nowTime = new Date().getTime();
    let createdProject = new Date(new Date(jsPageData.created_human).getTime() - new Date(jsPageData.created_human).getTimezoneOffset() * 60 * 1000).getTime();

    if (createdProject) {
        let expireTime = new Date(createdProject + 10 * 60000).getTime();

        if (nowTime >= expireTime) {
            $('.ga-error-edit').show();
        }
    }
}

const initModals = function () {
    $('#confirm-delete').on('show.bs.modal', function(e) {
        $(this).
            find('.btn-ok').
            attr('data-href', $(e.relatedTarget).data('href'));
    }).
        find('.btn-ok').
        on('click', function(e) {
            e.preventDefault();

            let $button = $(this);
            let $errorBox = $('#confirm-delete .error-box');

            $errorBox.html('').hide();
            $button.prop('disabled', true);

            axios.patch($button.data('href')).
                then(function(response) {
                    let data = (response.data ? response.data : {});

                    if (data && data.success) {
                        window.location.reload();
                    } else {
                        if (data.message) {
                            $errorBox.html(data.message).show();
                        }

                        $button.prop('disabled', false);
                    }
                }).
                catch(function() {
                    $button.prop('disabled', false);
                });
        });

    $('#confirm-recover').on('show.bs.modal', function(e) {
        $(this).
            find('.btn-ok').
            attr('data-href', $(e.relatedTarget).data('href'));
    }).
        find('.btn-ok').
        on('click', function(e) {
            e.preventDefault();

            let $button = $(this);
            let $errorBox = $('#confirm-recover .error-box');

            $errorBox.html('').hide();
            $button.prop('disabled', true);

            axios.patch($button.data('href')).
                then(function(response) {
                    let data = (response.data ? response.data : {});

                    if (data && data.success) {
                        window.location.reload();
                    } else {
                        if (data.message) {
                            $errorBox.html(data.message).show();
                        }

                        $button.prop('disabled', false);
                    }
                }).
                catch(function() {
                    $button.prop('disabled', false);
                });
        });

    $('#confirm-clone .btn-ok').on('click', function (e) {
        e.preventDefault();

        let $button = $(this);

        let data = {
            originalId: jsPageData.projectId
        }

        $button.prop('disabled', true);

        $.ajax({
            url: '/api/guaranteed/project/clone',
            method: 'POST',
            data: data,
            dataType: 'json',
            success: function (response) {
                let data = response.data;

                if (response.success) {
                    if (data.projectId) {
                        window.location.replace('/project?id=' + data.projectId);
                    }
                } else {
                    // addNotification(response.message);
                    $button.closest('.modal')
                        .find('.info-message-form')
                        .show()
                        .find('.info-message-form__desc')
                        .html(response.message);
                    $button.prop('disabled', false);
                }
            },
            error: function () {
                $button.prop('disabled', false);
            }
        });
    });

    $('#confirm-upgrade').on('show.bs.modal', function (e) {
        $(this).find('.btn-ok').attr('href', $(e.relatedTarget).data('href'));
    });
}

const initExpiration = function () {
    if (jsPageData.isDeleted) {
        return;
    }

    let $timer = $('[data-timeleft]');
    if ($timer.length === 0) {
        return;
    }

    let distance = parseInt($timer.data('timeleft'));
    let interval;

    const refreshTime = function () {
        $timer.text(formatTimeleft(distance));

        if (distance < 0) {
            clearInterval(interval);
            $timer.html('EXPIRED');
        }

        distance--;
    }
    refreshTime();

    interval = setInterval(refreshTime, 1000);
}

const initProjectStarting = function () {
    if (jsPageData.hasHits) {
        $('[data-panel-id="project-chart-realtime"]').show();

        return;
    }

    if (typeof lottie !== "undefined") {
        lottie.loadAnimation({
            container: document.querySelector('.project-starting-panel__indicator0 .lottie-blinking-circle'),
            renderer: 'svg',  // svg, canvas, html
            loop: true,
            autoplay: true,
            path: '/img/lottie/pulsing-circle.json'
        });

        lottie.loadAnimation({
            container: document.querySelector('.project-starting-panel__indicator1 .lottie-blinking-circle'),
            renderer: 'svg',  // svg, canvas, html
            loop: true,
            autoplay: true,
            path: '/img/lottie/pulsing-circle.json'
        });

        lottie.loadAnimation({
            container: document.querySelector('.project-starting-panel__indicator2 .lottie-blinking-circle'),
            renderer: 'svg',  // svg, canvas, html
            loop: true,
            autoplay: true,
            path: '/img/lottie/pulsing-circle.json'
        });

        lottie.loadAnimation({
            container: document.querySelector('.project-starting-panel__indicator3 .lottie-blinking-circle'),
            renderer: 'svg',  // svg, canvas, html
            loop: true,
            autoplay: true,
            path: '/img/lottie/pulsing-circle.json'
        });
    }

    let startingInterval;

    const startWaiting = function () {
        startingInterval = setInterval(function () {
            $.ajax({
                url: route('api.guaranteed.project.check_starting_indicators'),
                method: 'GET',
                data: {
                    id: jsPageData.projectId
                },
                dataType: 'json',
                success: function (response) {
                    if (response.success) {
                        let data = response.data;

                        if ((data.indicator1 === 1 && data.indicator2 === 1 && data.indicator3 === 1) || data.indicator3 === 1) {
                            $('[data-panel-id="project-chart-realtime"]').show();
                            $('[data-panel-id="project-starting-panel"]').hide();

                            clearInterval(startingInterval);
                            startingInterval = null;

                            return;
                        }

                        // if (!data.isEnoughCredits && data.isExpired) {
                        //     $('.project-starting-panel__indicator0').show();
                        //     $('.project-starting-panel__indicator0 .lottie-blinking-circle').show();
                        //     $('.project-starting-panel__indicator0 .icon-indicator-off').hide();
                        //     $('.project-starting-panel__indicator0 .icon-indicator-on').hide();
                        //
                        //     $('.project-starting-panel__indicator1 .lottie-blinking-circle').hide();
                        //     $('.project-starting-panel__indicator1 .icon-indicator-off').show();
                        //     $('.project-starting-panel__indicator1 .icon-indicator-on').hide();
                        //
                        //     $('.project-starting-panel__indicator2 .lottie-blinking-circle').hide();
                        //     $('.project-starting-panel__indicator2 .icon-indicator-off').show();
                        //     $('.project-starting-panel__indicator2 .icon-indicator-on').hide();
                        //
                        //     $('.project-starting-panel__indicator3 .lottie-blinking-circle').hide();
                        //     $('.project-starting-panel__indicator3 .icon-indicator-off').show();
                        //     $('.project-starting-panel__indicator3 .icon-indicator-on').hide();
                        // } else {
                            $('.project-starting-panel__indicator0 .lottie-blinking-circle').hide();
                            $('.project-starting-panel__indicator0 .icon-indicator-off').hide();
                            $('.project-starting-panel__indicator0 .icon-indicator-on').show();

                            if (data.indicator1 === 0) {
                                $('.project-starting-panel__indicator1 .lottie-blinking-circle').hide();
                                $('.project-starting-panel__indicator1 .icon-indicator-off').show();
                                $('.project-starting-panel__indicator1 .icon-indicator-on').hide();
                            } else if (data.indicator1 === 1) {
                                $('.project-starting-panel__indicator1 .lottie-blinking-circle').hide();
                                $('.project-starting-panel__indicator1 .icon-indicator-off').hide();
                                $('.project-starting-panel__indicator1 .icon-indicator-on').show();
                            } else {
                                $('.project-starting-panel__indicator1 .lottie-blinking-circle').show();
                                $('.project-starting-panel__indicator1 .icon-indicator-off').hide();
                                $('.project-starting-panel__indicator1 .icon-indicator-on').hide();
                            }

                            if (data.indicator2 === 0) {
                                $('.project-starting-panel__indicator2 .lottie-blinking-circle').hide();
                                $('.project-starting-panel__indicator2 .icon-indicator-off').show();
                                $('.project-starting-panel__indicator2 .icon-indicator-on').hide();
                            } else if (data.indicator2 === 1) {
                                $('.project-starting-panel__indicator2 .lottie-blinking-circle').hide();
                                $('.project-starting-panel__indicator2 .icon-indicator-off').hide();
                                $('.project-starting-panel__indicator2 .icon-indicator-on').show();
                            } else {
                                $('.project-starting-panel__indicator2 .lottie-blinking-circle').show();
                                $('.project-starting-panel__indicator2 .icon-indicator-off').hide();
                                $('.project-starting-panel__indicator2 .icon-indicator-on').hide();
                            }

                            if (data.indicator3 === 0) {
                                $('.project-starting-panel__indicator3 .lottie-blinking-circle').hide();
                                $('.project-starting-panel__indicator3 .icon-indicator-off').show();
                                $('.project-starting-panel__indicator3 .icon-indicator-on').hide();
                            } else if (data.indicator3 === 1) {
                                $('.project-starting-panel__indicator3 .lottie-blinking-circle').hide();
                                $('.project-starting-panel__indicator3 .icon-indicator-off').hide();
                                $('.project-starting-panel__indicator3 .icon-indicator-on').show();
                            } else {
                                $('.project-starting-panel__indicator3 .lottie-blinking-circle').show();
                                $('.project-starting-panel__indicator3 .icon-indicator-off').hide();
                                $('.project-starting-panel__indicator3 .icon-indicator-on').hide();
                            }
                        // }
                    } else {
                        console.log(response.message);
                    }
                }
            });
        }, 5000);
    }

    $('[data-panel-id="project-starting-panel"]').show();
    startWaiting();
}

const initChart = function () {
    if (jsPageData.isDeleted) {
        return;
    }

    let projectChart = null;
    let projectChartRealtime = null;
    let $dataPickerRange = $('[data-picker-range]');
    let fromDate = $dataPickerRange.find('[name="from"]').val();
    let toDate = $dataPickerRange.find('[name="to"]').val();
    let $typeTrafficSelect = $('[name="type_traffic"]');
    let $typeTrafficRealtimeSelect = $('[name="type_traffic_realtime"]');
    let typeTraffic = $typeTrafficSelect.val();
    let typeTrafficRealtime = $typeTrafficRealtimeSelect.val();
    // let deviceType = $('[data-device-type]').data('device-type');
    // let isMobile = (deviceType === 'phone' || deviceType === 'tablet');
    let timerChartRealtimeUpdate;

    if (typeof lottie !== "undefined") {
        lottie.loadAnimation({
            container: document.querySelector('.lottie-pulsing-circle'),
            renderer: 'svg',  // svg, canvas, html
            loop: true,
            autoplay: true,
            path: '/img/lottie/pulsing-circle.json'
        });
    }

    $dataPickerRange.find('input').on('show', function () {
        $(this).closest('label').find('.icon-arrow-down').addClass('open');
    });

    $dataPickerRange.find('input').on('hide', function () {
        $(this).closest('label').find('.icon-arrow-down').removeClass('open');
    });

    $dataPickerRange.find('input').on('changeDate', function (e) {
        let selectDate = new Date(e.date);
        let dateString = ('0' + selectDate.getDate()).slice(-2) + '.' + ('0' + (selectDate.getMonth() + 1)).slice(-2) + '.' + selectDate.getFullYear();

        $(this).val(dateString);
        fromDate = $dataPickerRange.find('[name="from"]').val();
        toDate = $dataPickerRange.find('[name="to"]').val();
        typeTraffic = $typeTrafficSelect.val();

        getChartData(jsPageData.projectId, fromDate, toDate, typeTraffic);
    });

    $typeTrafficSelect.on('change', function () {
        fromDate = $dataPickerRange.find('[name="from"]').val();
        toDate = $dataPickerRange.find('[name="to"]').val();
        typeTraffic = $(this).val();

        getChartData(jsPageData.projectId, fromDate, toDate, typeTraffic, 'line');
    });

    $typeTrafficRealtimeSelect.on('change', function () {
        typeTrafficRealtime = $(this).val();

        clearTimeout(timerChartRealtimeUpdate);
        getChartRealtimeData(jsPageData.projectId, null, null, typeTrafficRealtime, 'bar');
    });

    getChartData(jsPageData.projectId, fromDate, toDate, typeTraffic, 'line');
    getChartRealtimeData(jsPageData.projectId, null, null, typeTrafficRealtime, 'bar');

    function getChartData(
        projectId,
        fromDate = null,
        toDate = null,
        typeTraffic = 'clicks',
        typeChart = 'line',
    ) {
        if (!projectId) {
            return {};
        }

        if (!fromDate) {
            fromDate = new Date();
        }

        if (!toDate) {
            toDate = new Date();
            toDate.setDate(fromDate.getDate() - 1);
        }

        if (typeChart === 'bar') {
            fromDate = '';
            toDate = '';
        }

        let params = {
            projectId: projectId,
            from: fromDate,
            to: toDate,
            type_traffic: typeTraffic,
            type_chart: typeChart,
        };

        axios.get(`/api/guaranteed/projects/${projectId}/chart-data`, {
            params: params,
        }).then(function(response) {
            if (response.data && response.data.success && response.data.data) {
                let data = response.data.data;
                let $totalCount = $('[data-id="totalHitsOrVisitsCount"]');

                data.typeTraffic = typeTraffic;
                data.typeChart = typeChart;

                let titleVisits = '';
                if (data.translations && data.translations.visits) {
                    titleVisits = (typeChart === 'bar'
                        ? _.upperFirst(data.translations.visits.toLowerCase())
                        : data.translations.visits);
                }

                let titlePageViews = '';
                if (data.translations && data.translations.pageViews) {
                    titlePageViews = (typeChart === 'bar'
                        ? _.upperFirst(data.translations.pageViews.toLowerCase())
                        : data.translations.pageViews);
                }

                if (typeTraffic === 'visits') {
                    $('.type-traffic-title').text(titleVisits);
                } else if (typeTraffic === 'hits') {
                    $('.type-traffic-title').text(titlePageViews);
                }

                let totalHits = data.totalHits ? data.totalHits : 0;
                $totalCount.text(formatNumber(totalHits));

                drawChart(data);
            }
        }).catch(function(error) {
            // console.error(error);
        });
    }

    function drawChart(chartData) {
        if (!chartData) {
            return;
        }

        let ctxEl = document.getElementById('project-chart');
        if (ctxEl) {
            let ctx = ctxEl.getContext('2d');
            let chartConfig = prepareChartData(ctx, chartData, 'normal');

            if (chartConfig) {
                if (projectChart) {
                    projectChart.data = chartConfig.data;
                    projectChart.options = chartConfig.options;
                    projectChart.update();
                } else {
                    projectChart = new Chart(ctx, chartConfig);
                }
            }
        }
    }

    function getChartRealtimeData(
        projectId,
        fromDate = null,
        toDate = null,
        typeTraffic = 'clicks',
        typeChart = 'line',
        updateEmpty = true,
    ) {
        if (!projectId) {
            return {};
        }

        if (!fromDate) {
            fromDate = new Date();
        }

        if (!toDate) {
            toDate = new Date();
            toDate.setDate(fromDate.getDate() - 1);
        }

        if (typeChart === 'bar') {
            fromDate = '';
            toDate = '';
        }

        let params = {
            projectId: projectId,
            from: fromDate,
            to: toDate,
            type_traffic: typeTraffic,
            type_chart: typeChart,
        };

        axios.get(`/api/guaranteed/projects/${projectId}/chart-data`, {
            params: params,
        }).then(function(response) {
            if (response.data && response.data.success && response.data.data) {
                let data = response.data.data;
                let $totalCount = $('[data-id="totalHitsOrVisitsRealtimeCount"]');
                let $projectChartRealtime = $('[data-panel-id="project-chart-realtime"]');

                let hitsInLast20minutes = 0;
                if (data.data && data.data.length >= 20) {
                    let count = 1;
                    for (let i = data.data.length - 1; i > 0; i--) {
                        if (count > 20) {
                            break;
                        }

                        hitsInLast20minutes += parseInt(data.data[i]);
                        count++;
                    }
                }

                if (jsPageData.projectSize.indexOf('demo') !== -1 && data.hasHits && hitsInLast20minutes === 0) {
                    $projectChartRealtime.find('.project-chart-header').hide();
                    $projectChartRealtime.find('.project-chart').hide();
                    $projectChartRealtime.find('.project-chart-realtime-total').hide();
                    $projectChartRealtime.find('.demo-is-over').show();
                } else {
                    $projectChartRealtime.find('.project-chart-header').show();
                    $projectChartRealtime.find('.project-chart').show();
                    $projectChartRealtime.find('.project-chart-realtime-total').show();
                    $projectChartRealtime.find('.demo-is-over').hide();
                }

                if (!updateEmpty && parseInt(data.totalHits) <= 0) {
                    return false;
                }

                data.typeTraffic = typeTraffic;
                data.typeChart = typeChart;

                let titleVisits = '';
                if (data.translations && data.translations.visits) {
                    titleVisits = (typeChart === 'bar'
                        ? _.upperFirst(data.translations.visits.toLowerCase())
                        : data.translations.visits);
                }

                let titlePageViews = '';
                if (data.translations && data.translations.pageViews) {
                    titlePageViews = (typeChart === 'bar'
                        ? _.upperFirst(data.translations.pageViews.toLowerCase())
                        : data.translations.pageViews);
                }

                if (typeTraffic === 'visits') {
                    $('.type-traffic-realtime-title').text(titleVisits);
                } else if (typeTraffic === 'hits') {
                    $('.type-traffic-realtime-title').text(titlePageViews);
                }

                let totalHits = data.totalHits ? data.totalHits : 0;
                $totalCount.text(formatNumber(totalHits));

                drawChartRealtime(data);
            }
        }).catch(function(error) {
            // console.error(error);
        }).finally(function() {
            if (typeChart === 'bar') {
                timerChartRealtimeUpdate = setTimeout(function() {
                    getChartRealtimeData(
                        projectId,
                        null,
                        null,
                        typeTraffic,
                        typeChart,
                        false,
                    );
                }, 10000);
            }
        });
    }

    function drawChartRealtime(chartData) {
        if (!chartData) {
            return;
        }

        let ctxEl = document.getElementById('project-chart-realtime');
        if (ctxEl) {
            let ctx = ctxEl.getContext('2d');
            let chartConfig = prepareChartData(ctx, chartData, 'normal');

            if (chartConfig) {
                if (projectChartRealtime) {
                    projectChartRealtime.data = chartConfig.data;
                    projectChartRealtime.options = chartConfig.options;
                    projectChartRealtime.update();
                } else {
                    projectChartRealtime = new Chart(ctx, chartConfig);
                }
            }
        }
    }
}

const initTrafficTypeChangeListener = function() {
    let $referrersGroup = $('#referrers-group');
    let $socialGroup = $('#social-group');
    let $keywordsGroup = $('#keywords-group');
    let $trafficType = $('#traffic_type');

    $trafficType.on('change', updateTrafficType);
    updateTrafficType();

    function updateTrafficType() {
        let type = $trafficType.val();

        $referrersGroup.hide();
        $socialGroup.hide();
        $keywordsGroup.hide();

        if (type === 'referral') $referrersGroup.show();
        if (type === 'social') $socialGroup.show();
        if (type === 'organic') $keywordsGroup.show();
    }
};

const initFormDailyHitsEstimates = function() {
    if (jsPageData.isDeleted) {
        return;
    }

    let $urlArray = $('[name="url_array"]');

    calculateDailyHitsEstimate();

    let $trafficspeedRange = $('#trafficspeed_range_slider');
    if (typeof $trafficspeedRange.slider === 'function') {
        $trafficspeedRange.slider({
            range: 'min',
            value: 0,
            min: 0,
            max: 100,
            step: 1,
            slide: function(event, ui) {
                $('[name="speed"]').val(ui.value).trigger('change');
                $('#trafficspeed').text(ui.value + '%');
                calculateDailyHitsEstimate();
            },
            change: function(event, ui) {
                $('[name="speed"]').val(ui.value).trigger('change');
                $('#trafficspeed').text(ui.value + '%');
                calculateDailyHitsEstimate();
            },
        });
        $trafficspeedRange.slider('value', $('input[name="speed"]').val());
    }

    let $bouncerateRange = $('#bouncerate_range_slider');
    if (typeof $bouncerateRange.slider === 'function') {
        $bouncerateRange.slider({
            range: 'min',
            value: 0,
            min: 0,
            max: 100,
            step: 1,
            slide: function(event, ui) {
                $('#bouncerate_range').val(ui.value).trigger('change');
                $('#bouncerate').text(ui.value + '%');
                calculateDailyHitsEstimate();
            },
        });
        $bouncerateRange.slider('value', $('#bouncerate_range').val());
    }

    let $returnRateRange = $('#return_rate_slider');
    if (typeof $returnRateRange.slider === 'function') {
        $returnRateRange.slider({
            range: 'min',
            value: 0,
            min: 0,
            max: 100,
            step: 1,
            slide: function(event, ui) {
                $('#return_rate').val(ui.value).trigger('change');
                $('#returnrate').text(ui.value + '%');
                calculateDailyHitsEstimate();
            },
        });
        $returnRateRange.slider('value', $('#return_rate').val());
    }

    let $innerUrlsCountRangeSlider = $('#inner_urls_count_range_slider');
    if (typeof $innerUrlsCountRangeSlider.slider === 'function') {
        $innerUrlsCountRangeSlider.slider({
            range: 'min',
            value: 0,
            min: 0,
            max: jsPageData.innerUrlsMaxCount,
            step: 1,
            slide: function(event, ui) {
                $('[name="inner_urls_count"]').val(ui.value).trigger('change');
                $('#inner_urls_count').text(ui.value);
                calculateDailyHitsEstimate();
            },
            change: function(event, ui) {
                $('[name="inner_urls_count"]').val(ui.value).trigger('change');
                $('#inner_urls_count').text(ui.value);
                calculateDailyHitsEstimate();
            },
        });
        $innerUrlsCountRangeSlider.slider('value', $('input[name="inner_urls_count"]').val());
    }

    $urlArray.on('input', function() {
        calculateDailyHitsEstimate();
    });

    function calculateDailyHitsEstimate() {
        let typeUrls = $urlArray.data('type');
        let exitUrls = $urlArray.val();
        let innerUrlsCount = parseInt($('[name="inner_urls_count"]').val());
        let bouncerate = parseInt($('#bouncerate_range').val());
        let returnrate = parseInt($('#return_rate').val());
        let speed = parseInt($('[name="speed"]').val());
        // let estimate = Math.round((jsPageData.dailyHits / 3 + (1 - bouncerate / 100) * (2 * jsPageData.dailyHits / 3)) * speed / 100);
        let dailyHits = Math.round(jsPageData.multiplierUpgrade * jsPageData.dailyHits * speed / 100);
        let dailyUsers = (typeUrls === 'v2' ? dailyHits : Math.round(dailyHits / jsPageData.dailyUsersKoef));
        let bounce_rate_deduction = Math.round(jsPageData.multiplierUpgrade * (((bouncerate / 100) * (2 * jsPageData.dailyHits / 3)) * speed / 100));
        let shortener_deduction = 0;

        if (typeUrls === 'v2') {
            let denominator = (exitUrls === '' ? 1 : 2) + innerUrlsCount;

            dailyUsers = Math.round(dailyHits / denominator);
        }

        $('#dailyhits').html(dailyHits);

        $('#bounce_rate_deduction').
            show().
            html('<br><small class="help-block" style="margin-left: 24px;">-' +
                bounce_rate_deduction + '/day hits For ' +
                bouncerate + '% ' + 'Bounce Rate</small>');

        $('#daily_users').html(Math.round(dailyUsers * speed / 100));

        if (jsPageData.shortener !== '') {
            let shortenerConsumption = (dailyHits / jsPageData.shortenerConsumptionKoef);
            let shortenerDeduction = Math.round(shortenerConsumption * speed / 100);
            $('#shortener_deduction').html(shortenerDeduction);
        }

        let rerurn_rate_deduction = Math.round(dailyUsers * (speed / 100) * (returnrate / 100));
        $('#return_rate_deduction').
            show().
            html('<br><small class="help-block" style="margin-left: 24px;">-' +
                rerurn_rate_deduction + '/day users For ' +
                returnrate + '% ' + 'Return Rate</small>');

        let $totalMaxHits = $('#total_max_hits');
        let totalHits = Math.round(dailyHits - bounce_rate_deduction - shortener_deduction);
        let totalUsers = Math.round((dailyUsers * speed / 100) - rerurn_rate_deduction);

        $totalMaxHits.show().
            html('<br><small class="help-block" style="margin-left: 0;">Total: ' + totalHits + '/day hits' +
                ', ' + totalUsers + '/day users</small>');
    }

    // let $btnactivate = $('#btnactivate');
    // let $btnpause = $('#btnpause');

    // function checkStatus() {
    //     $.get("/api/guaranteed/project/check?id=" + jsPageData.projectId, function (response) {
    //         let speed = response.data.speed;
    //
    //         $('#trafficspeed').text(speed + '%');
    //         $('[name="speed"]').val(speed);
    //
    //         calculateDailyHitsEstimate();
    //     });
    // }
    //
    // checkStatus();
};

const initGeoTypeChange = function () {
    if (jsPageData.isDeleted) {
        return;
    }

    let $citiesGeoCountry = $('#cities_geo_country');
    let $geoType = $("#geo_type");

    onGeoTypeChange();

    $citiesGeoCountry.on('change', function () {
        fill_in_cities();
    });

    $geoType.on('change', function () {
        onGeoTypeChange();
    });

    function onGeoTypeChange() {
        let $countries = $('#countries');
        let $cities = $('#cities');
        let $geoTypeCountries = $('.geo-type-countries');
        let $geoTypeStates = $('.geo-type-states');
        let $geoTypeCities = $('.geo-type-cities');

        if ($geoType.val() === 'cities') {
            if ($cities.find('option:selected').length <= 0) {
                let countrySelect = $.trim($countries.find('option:selected').first().text());

                if (countrySelect) {
                    $citiesGeoCountry.find('option').each(function(index, el) {
                        if ($(el).text() === countrySelect) {
                            // $(el).attr('selected', 'selected');
                            $(el).prop('selected', true);
                            $citiesGeoCountry.selectpicker('destroy').selectpicker();
                        }
                    });
                }
            }

            $geoTypeCountries.hide();
            $geoTypeStates.hide();
            $geoTypeCities.show();

            fill_in_cities();
        } else if ($geoType.val() === 'countries') {
            $geoTypeCountries.show();
            $geoTypeStates.hide();
            $geoTypeCities.hide();
        } else {
            $geoTypeCountries.hide();
            $geoTypeStates.hide();
            $geoTypeCities.hide();
        }
    }

    function fill_in_cities(remove_all) {
       let $cities = $('#cities');

        if (remove_all) {
            $cities.find('option').remove();
        } else {
            $cities.find('option:not(:selected)').remove();
        }

        let selectedCitiesIds = [];
        $cities.find('option:selected').each(function(index, el) {
            selectedCitiesIds.push(parseInt($(el).val()));
        });

        let params = {
          'id': $citiesGeoCountry.val(),
        };

        axios.get(`/api/geolocations/cities-by-country`, {
            params: params,
        }).then(function(response) {
            if (response.data && response.data.success && response.data.data) {
                let data = response.data.data;

                for (let i = 0, len = data.length; i < len; i++) {
                    let $id = data[i]['id'];

                    if (selectedCitiesIds.includes($id)) {
                        continue;
                    }

                    $cities.append($('<option></option>').
                        attr('value', $id).
                        text(data[i]['name']));
                }

                $cities.selectpicker('destroy').selectpicker();
            }
        }).catch(function(error) {
            // console.error(error);
        });
    }
}

const initScrollEventsRangeSlider = function() {
    if (jsPageData.isDeleted) {
        return;
    }

    let $scrollEventsRangeSlider = $('#scroll_events_range_slider');
    if (typeof $scrollEventsRangeSlider.slider === 'function') {
        $scrollEventsRangeSlider.slider({
            range: 'min',
            value: 0,
            min: 0,
            max: 100,
            step: 1,
            slide: function(event, ui) {
                $('[name="scroll_events"]').val(ui.value).trigger('change');
                $('#scroll_events_percent').text(ui.value + '%');
            },
            change: function(event, ui) {
                $('[name="scroll_events"]').val(ui.value).trigger('change');
                $('#scroll_events_percent').text(ui.value + '%');
            },
        });
        $scrollEventsRangeSlider.slider('value', $('input[name="scroll_events"]').val());
    }
};

const initClickOoutboundEventsRangeSlider = function() {
    if (jsPageData.isDeleted) {
        return;
    }

    let $scrollClickOoutboundRangeSlider = $('#click_outbound_events_range_slider');
    if (typeof $scrollClickOoutboundRangeSlider.slider === 'function') {
        $scrollClickOoutboundRangeSlider.slider({
            range: 'min',
            value: 0,
            min: 0,
            max: 100,
            step: 1,
            slide: function(event, ui) {
                $('[name="click_outbound_events"]').
                    val(ui.value).
                    trigger('change');
                $('#click_outbound_events_percent').text(ui.value + '%');
            },
            change: function(event, ui) {
                $('[name="click_outbound_events"]').
                    val(ui.value).
                    trigger('change');
                $('#click_outbound_events_percent').text(ui.value + '%');
            },
        });
        $scrollClickOoutboundRangeSlider.slider('value', $('input[name="click_outbound_events"]').val());
    }
};

const initFormSubmitEventsRangeSlider = function() {
    if (jsPageData.isDeleted) {
        return;
    }

    let $formSubmitEventsRangeSlider = $('#form_submit_events_range_slider');
    if (typeof $formSubmitEventsRangeSlider.slider === 'function') {
        $formSubmitEventsRangeSlider.slider({
            range: 'min',
            value: 0,
            min: 0,
            max: 100,
            step: 1,
            slide: function(event, ui) {
                $('[name="form_submit_events"]').
                    val(ui.value).
                    trigger('change');
                $('#form_submit_events_percent').text(ui.value + '%');
            },
            change: function(event, ui) {
                $('[name="form_submit_events"]').
                    val(ui.value).
                    trigger('change');
                $('#form_submit_events_percent').text(ui.value + '%');
            },
        });
        $formSubmitEventsRangeSlider.slider('value', $('input[name="form_submit_events"]').val());
    }
};

const initDesktopRateRangeSlider = function() {
    if (jsPageData.isDeleted) {
        return;
    }

    let $desktopRateBlock = $('.desktop-rate-block');

    let $formDesktopRateSlider = $('#desktop_rate_slider');
    if (typeof $formDesktopRateSlider.slider === 'function') {
        $formDesktopRateSlider.slider({
            range: 'min',
            value: 0,
            min: 0,
            max: 100,
            step: 1,
            slide: function(event, ui) {
                let percent = ui.value;
                let percentDesktop = percent;
                let percentMobile = 100 - percent;

                $('[name="desktop_rate"]').val(percent).trigger('change');
                $desktopRateBlock.find('[data-slider-percent="desktop"]').
                    text(percentDesktop + '%');
                $desktopRateBlock.find('[data-slider-percent="mobile"]').
                    text(percentMobile + '%');
            },
            change: function(event, ui) {
                let percent = ui.value;
                let percentDesktop = percent;
                let percentMobile = 100 - percent;

                $('[name="desktop_rate"]').val(percent).trigger('change');
                $desktopRateBlock.find('[data-slider-percent="desktop"]').
                    text(percentDesktop + '%');
                $desktopRateBlock.find('[data-slider-percent="mobile"]').
                    text(percentMobile + '%');
            },
        });
        $formDesktopRateSlider.slider('value', $('input[name="desktop_rate"]').val());
    }
};

const initCheckLinkStatus = function() {
    if (jsPageData.projectSize !== 'nano' || jsPageData.isDeleted) {
        return;
    }

    let $btnreciprocalcheck = $('#button_check_link_status');
    let $elLinkFoundStatus = $('#link_found_status');
    let buttonInterval;
    let secondsLeft = 0;

    const updateButton = function(countPoints = 0) {
        let points = _.reduce(_.range(0, countPoints), function(acc) {
            return acc + '.';
        }, '');

        $btnreciprocalcheck.html(
            '<i class="fal fa-redo"></i>&nbsp;&nbsp;Waiting' + points);
    };

    const startWaiting = function() {
        secondsLeft = 0;

        updateButton();
        $btnreciprocalcheck.prop('disabled', true);

        buttonInterval = setInterval(function() {
            updateButton(secondsLeft++ % 4);
            $.ajax({
                url: '/api/guaranteed/project/check-reciprocal-check',
                method: 'GET',
                data: {
                    id: jsPageData.projectId,
                },
                dataType: 'json',
                success: function(response) {
                    if (response.success) {
                        if (!response.data.isDone) {
                            return;
                        }

                        if (response.data.isFound) {
                            checkReciprocalStatus(true);
                        } else {
                            checkReciprocalStatus(false);
                        }

                        $btnreciprocalcheck.html('<i class="fal fa-redo"></i>&nbsp;&nbsp;Check');
                        $btnreciprocalcheck.prop('disabled', false);
                        clearInterval(buttonInterval);
                        buttonInterval = null;
                    } else {
                        console.log(response.message);
                    }
                },
            });
        }, 2000);
    };

    const checkReciprocalStatus = function(isFound = false) {
        if (isFound) {
            $elLinkFoundStatus.html('<span style="color: green; ">FOUND, The traffic is doubled</span>');
        } else {
            $elLinkFoundStatus.html('<span style="color: red; ">NOT FOUND</span>');
        }
    };

    $btnreciprocalcheck.click(function(e) {
        e.preventDefault();

        $.ajax({
            url: '/api/guaranteed/project/start-reciprocal-check',
            method: 'POST',
            data: {
                id: jsPageData.projectId,
            },
            dataType: 'json',
        });

        startWaiting();
    });

    if (jsPageData.force_reciprocal_check === 1) {
        startWaiting();
    }

    // $.get("/check-reciprocal.php?campaignid=" + jsPageData.projectId, function (data) {
    //     if (data === 'FOUND') {
    //         $elLinkFoundStatus.html('<font color="green">FOUND, The traffic is doubled</font>');
    //         $("#guaranteed_monthly_hits").html(formatNumber(jsPageData.monthlyHits * 2));
    //     } else {
    //         $elLinkFoundStatus.html('<font color="red">NOT FOUND</font>');
    //         $("#guaranteed_monthly_hits").html(formatNumber(jsPageData.monthlyHits));
    //     }
    // })
};

const initCheckUpdateCountersStatus = function() {
    let $btnupdatecounters = $('#btnupdatecounters');
    let isAllowedCheckCounters = true;
    let checkCountersTimeout;
    let secondsLeft = 0;

    const updateButton = function(countPoints = 0) {
        const points = _.reduce(_.range(0, countPoints), function(acc) {
            return acc + '.';
        }, '');

        $btnupdatecounters.html('<i class="fal fa-redo"></i>&nbsp;&nbsp;Updating&nbsp;Counters' + points);
    };

    const startWaiting = function() {
        updateButton(secondsLeft++ % 4);

        $.ajax({
            url: '/api/guaranteed/project/check-counters-updating',
            method: 'GET',
            data: {
                id: jsPageData.projectId,
            },
            dataType: 'json',
            success: function(response) {
                try {
                    if (typeof response.success !== 'undefined' &&
                        response.success) {
                        if (response.data.isDone) {
                            $btnupdatecounters.html('<i class="fal fa-redo"></i>&nbsp;&nbsp;Update&nbsp;Counters');
                            $btnupdatecounters.prop('disabled', false);

                            isAllowedCheckCounters = false;
                        }
                    } else {
                        console.log(response.message);
                    }
                } catch (e) {
                    console.log('Error request "check-counters-updating": ' + e.responseText);
                }
            },
            complete: function() {
                if (!isAllowedCheckCounters) {
                    clearTimeout(checkCountersTimeout);
                    checkCountersTimeout = null;

                    return;
                }

                checkCountersTimeout = setTimeout(function() {
                    startWaiting();
                }, 2000);
            },
        });
    };

    $btnupdatecounters.on('click', function(e) {
        e.preventDefault();

        secondsLeft = 0;
        $btnupdatecounters.prop('disabled', true);
        updateButton(secondsLeft++ % 4);

        $.ajax({
            url: '/api/guaranteed/project/start-counters-updating',
            method: 'POST',
            data: {
                id: jsPageData.projectId,
            },
            dataType: 'json',
            complete: function() {
                isAllowedCheckCounters = true;
                startWaiting();
            },
        });
    });

    if (jsPageData.force_update_counters === 1) {
        $btnupdatecounters.prop('disabled', true);
        startWaiting();
    }
};

const initCheckUpdateBtnRecommendationsStatus = function() {
    let hostName = window.location.hostname;
    if (hostName.indexOf('localhost') !== -1 ||
        hostName.indexOf('127.0.0.1') !== -1) {
        return;
    }

    let $buttonUpdateRecommendations = $('.button-update-recommendations');
    let checkRecommendationsInterval;
    let secondsLeft = 0;

    const updateButton = function(countPoints = 0) {
        const points = _.reduce(_.range(0, countPoints), function(acc) {
            return acc + '.';
        }, '');

        $buttonUpdateRecommendations.html('<i class="fal fa-redo"></i>&nbsp;&nbsp;Updating&nbsp;Recommendations' + points);
    };

    const startWaiting = function() {
        secondsLeft = 0;
        updateButton();
        $buttonUpdateRecommendations.prop('disabled', true);

        checkRecommendationsInterval = setInterval(function() {
            updateButton(secondsLeft++ % 4);

            $.ajax({
                url: '/api/guaranteed/project/check-recommendations-updating',
                method: 'GET',
                data: {
                    id: jsPageData.projectId,
                },
                dataType: 'json',
                success: function(response) {
                    if (response.success) {
                        if (!response.data.isDone) {
                            return;
                        }

                        $buttonUpdateRecommendations.html('<i class="fal fa-redo"></i>&nbsp;&nbsp;Update&nbsp;Recommendations');
                        $buttonUpdateRecommendations.prop('disabled', false);
                        clearInterval(checkRecommendationsInterval);
                        checkRecommendationsInterval = null;
                    } else {
                        console.log(response.message);
                    }
                },
            });
        }, 2000);
    };

    $buttonUpdateRecommendations.click(function(e) {
        e.preventDefault();

        $.ajax({
            url: '/api/guaranteed/project/start-recommendations-updating',
            method: 'POST',
            data: {
                id: jsPageData.projectId,
            },
            dataType: 'json',
        });

        startWaiting();
    });

    if (jsPageData.force_check_recommendations === 1) {
        startWaiting();
    }
};

/** Project Pause */
const initPause = function() {
    $('[data-ajax-action="pause"]').on('click', function() {
        let $button = $(this);

        $button.prev('.error-box').html('').hide();
        $button.prop('disabled', true);

        axios.patch(`${route('api.guaranteed.projects.pause', [jsPageData.projectId])}`).
            then(function(response) {
                let data = (response.data ? response.data : {});

                if (data && data.success) {
                    let $trafficspeedRange = $('#trafficspeed_range_slider');

                    $('.action-panel .action-panel__state').
                        addClass('no-active');
                    $('.action-panel .action-panel__status').
                        html('Paused').
                        addClass('no-active');

                    $('[data-ajax-action="pause"]').
                        hide().
                        next('.tooltip-icon-wrap').
                        hide();
                    $('[data-ajax-action="resume"]').
                        show().
                        next('.tooltip-icon-wrap').
                        show();

                    if (typeof $trafficspeedRange.slider === 'function') {
                        $trafficspeedRange.slider('value', 0);
                    }
                    $trafficspeedRange.addClass('disabled');
                } else if (data.message) {
                    $button.prev('.error-box').html(data.message).show();
                }

                $button.prop('disabled', false);
            }).
            catch(function() {
                $button.prop('disabled', false);
            }).
            finally(function() {
                document.dispatchEvent(new Event("updateTooltips"));
            });
    });
}

/** Project Resume */
const initResume = function() {
    $('[data-ajax-action="resume"]').on('click', function() {
        let $button = $(this);
        let $errorBox = $button.prev('.error-box');

        $errorBox.html('').hide();
        $button.prop('disabled', true);

        axios.patch(`${route('api.guaranteed.projects.resume', [jsPageData.projectId])}`).
            then(function(response) {
                let data = (response.data ? response.data : {});

                if (data && data.success) {
                    let $trafficspeedRange = $('#trafficspeed_range_slider');

                    $('.action-panel .action-panel__state').
                        removeClass('no-active');
                    $('.action-panel .action-panel__status').
                        html('Active').
                        removeClass('no-active');

                    $('[data-ajax-action="pause"]').
                        show().
                        next('.tooltip-icon-wrap').
                        show();
                    $('[data-ajax-action="resume"]').
                        hide().
                        next('.tooltip-icon-wrap').
                        hide();

                    if (typeof $trafficspeedRange.slider === 'function') {
                        $trafficspeedRange.slider('value', (data.projectSpeed ? data.projectSpeed : 0));
                    }

                    if (parseInt($trafficspeedRange.data('allow')) === 1) {
                        $trafficspeedRange.removeClass('disabled');
                    }

                    $button.prop('disabled', false);
                } else if (data.message) {
                    $errorBox.html(data.message).show();
                }
            }).
            catch(function() {
                $button.prop('disabled', false);
            }).
            finally(function() {
                document.dispatchEvent(new Event("updateTooltips"));
            });
    });
};

/** Project Renew */
const initRenew = function() {
    let buttonRenew = $('[data-ajax-action="renew"]');

    buttonRenew.on('click', function(e) {
        e.preventDefault();

        buttonRenew.prop('disabled', true);

        $.ajax({
            url: route('api.guaranteed.project.renew'),
            method: 'POST',
            data: {
                id: jsPageData.projectId,
                size: jsPageData.projectSize,
            },
            dataType: 'json',
            success: function(response) {
                if (response.success) {
                    window.location.reload();
                } else {
                    // addNotification(response.message);
                    alert(response.message);
                    buttonRenew.prop('disabled', false);
                }
            },
            error: function() {
                buttonRenew.prop('disabled', false);
            },
        });
    });

    // Animation
    let animSwipeRight = document.querySelector('.lottie-swipe-right');
    let animSwipeLeft = document.querySelector('.lottie-swipe-left');

    if (typeof lottie !== 'undefined') {
        let lottieSwipeRight = lottie.loadAnimation({
            container: animSwipeRight,
            renderer: 'svg',  // svg, canvas, html
            loop: true,
            autoplay: false,
            path: '/img/lottie/swipe-right.json',
        });

        let lottieSwipeLeft = lottie.loadAnimation({
            container: animSwipeLeft,
            renderer: 'svg',  // svg, canvas, html
            loop: true,
            autoplay: false,
            path: '/img/lottie/swipe-left.json',
        });

        lottieSwipeLeft.addEventListener('DOMLoaded', function() {
            lottieSwipeRight.play();
            lottieSwipeLeft.play();
        });
    }
};

/**  Auto-renew Activation */
const initAutoRenew = function() {
    $('[data-ajax-action="auto-renew"]').on('change', function() {
        let isAutoRenew = ($(this).prop('checked') ? 'yes' : 'no');
        let $button = $(this);

        $button.prop('disabled', true);

        $.ajax({
            url: '/api/guaranteed/project/auto-renew-activate',
            method: 'POST',
            data: {
                projectId: jsPageData.projectId,
                isAutoRenew: isAutoRenew,
            },
            dataType: 'json',
            success: function(response) {
                // nothing
            },
            error: function() {
                if (isAutoRenew === 'yes') {
                    $button.prop('checked', false);
                } else {
                    $button.prop('checked', true);
                }
            },
            complete: function() {
                $button.prop('disabled', false);
            },
        });
    });
};

const initForms = function() {
    const updateFormFields = function($form) {
        $form.find('input, select, textarea').each(function(index, el) {
            if ($(el).attr('type') === 'checkbox') {
                $(el).data('val', $(el).prop('checked'));
            } else {
                $(el).data('val', $(el).val());
            }
        });
    };

    const getFormFields = function($form) {
        let data = {};

        $form.find('input, select, textarea').each(function(index, el) {
            let fieldName = $(el).attr('name');
            if (fieldName) {
                fieldName = fieldName.replace('[]', '');

                if ($(el).attr('type') === 'checkbox') {
                    data[fieldName] = ($(el).prop('checked') ? 1 : 0);
                } else {
                    data[fieldName] = $(el).val();
                }
            }
        });

        return data;
    };

    let $forms = $('[data-role="guaranteed-project-edit"] form[data-form-id]');

    if ($forms.length === 0) {
        return;
    }

    $forms.each(function() {
        let $form = $(this);
        let $saveButton = $form.find('[data-ajax-action="save-form"]');
        let isChangesInputs = [];

        updateFormFields($form);

        $form.find('input, select, textarea').on('input change', function() {
            let isChanges = false;

            formValidationErrorClear($form);

            if ($(this).attr('type') === 'checkbox') {
                isChangesInputs[$(this).attr('name')] = $(this).data('val') !== $(this).prop('checked');
            } else {
                isChangesInputs[$(this).attr('name')] = $(this).data('val') !== $(this).val();
            }

            for (let key in isChangesInputs) {
                if (isChangesInputs.hasOwnProperty(key) && isChangesInputs[key]) {
                    isChanges = true;

                    break;
                }
            }

            if (isChanges) {
                $saveButton.find('.button-text').html('Save Changes');
                $saveButton.find('.icon-from-button').hide();
                $saveButton.prop('disabled', false);
            } else {
                $saveButton.find('.button-text').html('Saved');
                $saveButton.find('.icon-from-button').show();
                $saveButton.prop('disabled', true);
            }
        });

        $('[name="respect_time_of_day"]').on('change', function() {
            $('[name="timezone_from_gmt"]').val($(this).val());
        });

        $saveButton.on('click', function(e) {
            e.preventDefault();

            let $button = $(this);
            let $form = $button.closest('form');

            let dataRequest = {
                id: jsPageData.projectId,
                title: $('[name="title"]').data('val'),
                speed: $('[name="speed"]').data('val'),
                bouncerate: $('[name="bouncerate"]').data('val'),
                return_rate: $('[name="return_rate"]').data('val'),
                timeonpage: $('[name="timeonpage"]').data('val'),
                randomize_time: $('[name="randomize_time"]').data('val') ? 1 : 0,
                rss_feed: $('[name="rss_feed"]').data('val'),
                inner_urls_count: $('[name="inner_urls_count"]').data('val'),
                auto_crawl_entry_urls: $('[name="auto_crawl_entry_urls"]').data('val') ? 1 : 0,
                auto_crawl_inner_urls: $('[name="auto_crawl_inner_urls"]').data('val') ? 1 : 0,
                auto_crawl_exit_urls: $('[name="auto_crawl_exit_urls"]').data('val') ? 1 : 0,
                url: $('[name="url"]').data('val'),
                url_second: $('[name="url_second"]').data('val'),
                url_array: $('[name="url_array"]').data('val'),
                url_array_4: $('[name="url_array_4"]').data('val'),
                url_array_5: $('[name="url_array_5"]').data('val'),
                url_array_6: $('[name="url_array_6"]').data('val'),
                url_array_7: $('[name="url_array_7"]').data('val'),
                url_array_8: $('[name="url_array_8"]').data('val'),
                url_array_9: $('[name="url_array_9"]').data('val'),
                url_array_10: $('[name="url_array_10"]').data('val'),
                url_array_11: $('[name="url_array_11"]').data('val'),
                traffic_type: $('[name="traffic_type"]').data('val'),
                referrer: $('[name="referrer"]').data('val'),
                social_links: $('[name="social_links"]').data('val'),
                keywords: $('[name="keywords"]').data('val'),
                desktop_rate: $('[name="desktop_rate"]').data('val'),
                geo_type: $('[name="geo_type"]').data('val'),
                countries: $('[name="countries[]"]').data('val'),
                cities: $('[name="cities[]"]').data('val'),
                respect_time_of_day: $('[name="respect_time_of_day"]').data('val'),
                respect_day_of_week: $('[name="respect_day_of_week"]').data('val') ? 1 : 0,
                timezone_from_gmt: $('[name="timezone_from_gmt"]').data('val'),
                shortener: $('[name="shortener"]').data('val'),
                utm_source: $('[name="utm_source"]').data('val'),
                utm_medium: $('[name="utm_medium"]').data('val'),
                utm_campaign: $('[name="utm_campaign"]').data('val'),
                language: $('[name="language"]').data('val'),
                ga_id: $('[name="ga_id"]').data('val'),
                scroll_events: $('[name="scroll_events"]').data('val'),
                click_outbound_events: $('[name="click_outbound_events"]').data('val'),
                form_submit_events: $('[name="form_submit_events"]').data('val'),
            };

            let dataSave = getFormFields($form);
            dataRequest = {...dataRequest, ...dataSave};

            $button.find('.button-text').html('Saving...');
            $button.find('.icon-from-button').hide();
            $button.prop('disabled', true);

            $.ajax({
                url: '/api/guaranteed/project/update',
                method: 'POST',
                data: dataRequest,
                dataType: 'json',
                success: function(response) {
                    let data = (response.data ? response.data : []);

                    if (response.success) {
                        $button.find('.button-text').html('Saved');
                        $button.find('.icon-from-button').show();
                        formValidationErrorClear($form);

                        isChangesInputs = [];
                        updateFormFields($form);
                    } else {
                        $button.find('.button-text').html('Save Changes');
                        $button.find('.icon-from-button').hide();
                        $button.prop('disabled', false);

                        if (response.message === 'CREDITS') {
                            window.location.replace(route('cart', '#tab_web_traffic_credits'));
                        }

                        if (data.errorsForm) {
                            formValidationErrorHandling($form, data.errorsForm);
                        }
                    }
                },
                error: function() {
                    $button.find('.button-text').html('Save Changes');
                    $button.find('.icon-from-button').hide();
                    $button.prop('disabled', false);
                },
            });
        });
    });
};

const initStartProjectBoost = function() {
    let $buttonStartBoost = $('[data-ajax-action="start-boost"]');
    let $buttonCancelBoost = $('[data-ajax-action="cancel-boost"]');
    let $startBoostBlock = $('.start-boost-block');
    let $cancelBoostBlock = $('.cancel-boost-block');
    let $actionPanelBoostedToSize = $('.action-panel__boosted-to-size');
    let isStartingProjectBoost = jsPageData.isStartingProjectBoost;
    let checkStartingBoostTimeout;
    let $timer = $('.timer-boost-expires');

    const startWaiting = function() {
        $.ajax({
            url: '/api/user/check-starting-project-boost',
            method: 'GET',
            data: {
                project_id: jsPageData.projectId,
            },
            dataType: 'json',
            success: function(response) {
                if (response.success) {
                    let data = (response.data ? response.data : []);

                    if (data.isStarting) {
                        $startBoostBlock.hide();
                        $actionPanelBoostedToSize.show();

                        if (data.timerExpires) {
                            $timer.text(
                                formatTimeleft(
                                    data.timerExpires,
                                    true,
                                ),
                            );
                        } else {
                            $startBoostBlock.hide();
                            $cancelBoostBlock.show();
                            $actionPanelBoostedToSize.show();
                        }
                    } else {
                        $cancelBoostBlock.hide();
                        $actionPanelBoostedToSize.hide();

                        isStartingProjectBoost = false;
                    }
                }
            },
            error: function(xhr) {
                console.log('start-project-boost: ', xhr.responseText);
            },
            complete: function() {
                if (!isStartingProjectBoost) {
                    clearTimeout(checkStartingBoostTimeout);
                    checkStartingBoostTimeout = null;

                    return;
                }

                checkStartingBoostTimeout = setTimeout(function() {
                    startWaiting();
                }, 1000);
            },
        });
    };

    $buttonStartBoost.on('click', function() {
        let $button = $(this);

        $button.prop('disabled', true);

        $.ajax({
            url: '/api/user/start-project-boost',
            method: 'POST',
            data: {
                project_id: jsPageData.projectId,
                size: jsPageData.projectSize,
            },
            dataType: 'json',
            success: function(response) {
                if (response.success) {
                    $startBoostBlock.hide();
                    $cancelBoostBlock.show();
                    isStartingProjectBoost = true;

                    startWaiting();
                } else {
                    $button.prop('disabled', false);
                }
            },
            error: function(xhr) {
                console.log('start-project-boost: ', xhr.responseText);
                $button.prop('disabled', false);
            },
        });
    });

    $buttonCancelBoost.on('click', function() {
        let $button = $(this);

        $button.prop('disabled', true);

        $.ajax({
            url: '/api/user/cancel-project-boost',
            method: 'POST',
            data: {
                project_id: jsPageData.projectId,
            },
            dataType: 'json',
            success: function(response) {
                if (response.success) {
                    $startBoostBlock.hide();
                    $cancelBoostBlock.hide();
                    $actionPanelBoostedToSize.hide();

                    clearTimeout(checkStartingBoostTimeout);
                    isStartingProjectBoost = false;
                } else {
                    $button.prop('disabled', false);
                }
            },
            error: function(xhr) {
                console.log('cancel-project-boost: ', xhr.responseText);
                $button.prop('disabled', false);
            },
        });
    });

    if (isStartingProjectBoost) {
        $timer.text(
            formatTimeleft(
                $('[data-boost-timeleft]').data('boost-timeleft'),
                true,
            ),
        );
        $buttonStartBoost.prop('disabled', true);
        startWaiting();
    }
};

const initDemoIsOver = function() {
    let $demoIsOver = $('.demo-is-over');

    $demoIsOver.find('[data-button-action="yes"]').on('click', function() {
        $demoIsOver.find('.demo-is-over__title').html('Great!');
        $demoIsOver.find('.demo-is-over__subtitle').html('Now, proceed to create a permanent project');

        $demoIsOver.find('[data-button-action="yes"]').hide();
        $demoIsOver.find('[data-button-action="no"]').hide();
        $demoIsOver.find('[data-button-action="create-project"]').show();
    });

    $demoIsOver.find('[data-button-action="no"]').on('click', function() {
        $demoIsOver.find('.demo-is-over__title').html('Oh!');
        $demoIsOver.find('.demo-is-over__subtitle').html('Let us fix it');
        $demoIsOver.find('.demo-is-over__text').show();

        $demoIsOver.find('[data-button-action="yes"]').hide();
        $demoIsOver.find('[data-button-action="no"]').hide();
    });
}

const initFetchUrlsFromRSS = function() {
    let buttonFetchUrls = $('[data-button-action="fetch-urls"]');

    // if ($('[name="rss_feed"]').val() === '') {
    //     buttonFetchUrls.prop('disabled', true);
    // } else {
    //     buttonFetchUrls.prop('disabled', false);
    // }

    buttonFetchUrls.on('click', function() {
        let $button = $(this);

        $button.prop('disabled', true);
        $button.find('.text-from-button').html('Fetching');

        axios.patch(`${route('api.guaranteed.projects.update_urls_from_rss', [jsPageData.projectId])}`).
            then(function(response) {
                let dataResponse = (response.data ? response.data : {});

                if (dataResponse && dataResponse.success) {
                    let data = dataResponse.data;

                    if (data[jsPageData.projectId]) {
                        $('[name="url"]').val(data[jsPageData.projectId].url);
                        $('[name="url_second"]').val(data[jsPageData.projectId].url_second);
                        $('[name="url_array"]').val(data[jsPageData.projectId].url_array);
                    }
                }

                $button.prop('disabled', false);
                $button.find('.text-from-button').html('Fetch');
            }).
            catch(function() {
                $button.prop('disabled', false);
                $button.find('.text-from-button').html('Fetch');
            });
    });
}

export const initGuaranteedProjectEditScripts = () => {
    runIfHasSelecter('[data-role="guaranteed-project-edit"]', [
        initGaErrorMessages,
        initModals,
        initExpiration,
        initProjectStarting,
        initChart,
        initTrafficTypeChangeListener,
        initFormDailyHitsEstimates,
        initScrollEventsRangeSlider,
        initClickOoutboundEventsRangeSlider,
        initFormSubmitEventsRangeSlider,
        initDesktopRateRangeSlider,
        initGeoTypeChange,
        initCheckLinkStatus,
        initCheckUpdateCountersStatus,
        initCheckUpdateBtnRecommendationsStatus,
        initPause,
        initResume,
        initAutoRenew,
        initRenew,
        initForms,
        initStartProjectBoost,
        initDemoIsOver,
        initFetchUrlsFromRSS,
    ]);
};
