<template>
  <v-button
      class="v-button-submit button button-start"
      :class="formRequestExecuting ? 'v-button-submit--loading' : ''"
      type="submit"
  >
    <slot v-if="!formRequestExecuting"/>
    <v-loader-ship v-if="formRequestExecuting" class="v-button-submit__loader"/>
  </v-button>
</template>

<script setup>
import {inject} from "vue";
import VLoaderShip from "./VLoaderShip.vue";
import VButton from "./VButton.vue";

const formRequestExecuting = inject('formRequestExecuting');

</script>

<style lang="scss" scoped>
.v-button-submit{
  font-size: 14px;
  font-weight: 600!important;
  text-transform: none;
  position: relative;
}
.v-button-submit--loading{
  z-index:9999;
  background-color: #1C7EE0;
  cursor:initial;
}
</style>