import {
  checkOrderStatus,
  getExchangeRate,
  runIfHasSelecter, updatePaymentOrder,
} from '../../../utils/panel-lib.js';
import axios from 'axios';
import route from '../../index.js';

const initCommon = function()    {
  let $buttonPay = $('.fastspring-payment [data-ajax-action="pay-now"]');
  let $iframeWrap = $('.fastspring-iframe-wrap');
  let $iframe = $('.fastspring-iframe');
  let $cart = $('.payment-order');
  let isCheckoutTopForm = $('.fastspring-checkout.top-form').length > 0;

  // const updateFastSpringSession = () => {
  //   let params = '';
  //   if (jsPageData.isCartFromOrder && jsPageData.orderId && jsPageData.paymentMethod === 'fastspring') {
  //     params = '?orderId=' + jsPageData.orderId;
  //   }
  //
  //   fetch(route('api.fastspring.create_session') + params, {
  //     method: 'GET',
  //   }).
  //       then(sessionResponse => {
  //         if (!sessionResponse.ok) {
  //           throw new Error('HTTP error ' + sessionResponse.status);
  //         }
  //         $('body').removeClass('loading');
  //         return sessionResponse.json();
  //       }).
  //       then(session => {
  //         // console.log(JSON.stringify(session, null, 2));
  //         if (fastspring) {
  //           // if (session.taxId) {
  //              // fastspring.builder.taxId(session.taxId);
  //           // }
  //
  //           // fastspring.builder.secure({
  //           //   "taxId": session.taxId
  //           // }, session.secureKey);
  //
  //           fastspring.builder.checkout(session.id);
  //         }
  //
  //         checkOrderStatus(parseInt(session.orderId));
  //         $('[data-pay-by="fastspring"]').prop('disabled', false);
  //         $('[data-pay-by="fastspring-popup"]').prop('disabled', false);
  //         $('body').removeClass('loading');
  //       }).
  //       catch(error => {
  //         console.log('There was an error:', error);
  //         $('[data-pay-by="fastspring"]').prop('disabled', false);
  //         $('[data-pay-by="fastspring-popup"]').prop('disabled', false);
  //         $('body').removeClass('loading');
  //       });
  // };

  const updateFastSpringSession = () => {
    let isPaidSuccess = $('.checkout-block-info').hasClass('success');
    if (isPaidSuccess) {
      return;
    }

    let params = '';
    if (jsPageData.isCartFromOrder && jsPageData.orderId && jsPageData.paymentMethod === 'fastspring') {
      params = '?orderId='   + jsPageData.orderId;
    }

    if (!isCheckoutTopForm) {
      $buttonPay.prop('disabled', true);
    }

    axios.get(route('api.fastspring.get_session_data') + params).
        then(response => {
          console.log('responseId', response.id);

          let data = (response.data ? response.data : {});
          console.log('response', response);
          if (!data) {
            $('.checkout-block-payment').hide();
            return;
          }

          let orderId = parseInt(data?.orderId ?? 0);
          let sessionId = data.id ?? '';
          let sessionSecurePayload = data.securePayload ?? '';
          let sessionSecureKey = data.secureKey ?? '';

          if (!isCheckoutTopForm) {
            $buttonPay.closest('.payment-options__button').hide();
          }
          $iframeWrap.removeClass('hidden');

          if (fastspring) {
            if (isCheckoutTopForm) {
              $('.checkout-block').show();
              $('.checkout-block-payment').show();
            }

            fastspring.builder.secure(sessionSecurePayload, sessionSecureKey);
            //fastspring.builder.checkout(sessionId);

            if (orderId) {
              checkOrderStatus(orderId);
            }
          } else {
            if (isCheckoutTopForm) {
              $('.checkout-block-payment').hide();
            }
          }

          $('body').removeClass('loading');
        }).
        catch(error => {
          console.log('There was an error:', error);
          if (isCheckoutTopForm) {
            $('.checkout-block-payment').hide();
          } else {
            $buttonPay.prop('disabled', false);
          }
          $('body').removeClass('loading');
        });
  };

  $buttonPay.on('click', function() {
    // $(this).prop('disabled', true).hide();
    updateFastSpringSession();

    // if ($('.fastspring-iframe-wrap').length > 0) {
      // $('.fastspring-iframe-popup-wrap').empty();
      // $('.fastspring-iframe-wrap').empty();
      // $('.fastspring-iframe-wrap').
      //     html(`<script id="fsc-api" src="https://sbl.onfastspring.com/sbl/1.0.1/fastspring-builder.min.js" type="text/javascript" data-storefront="${jsPageData.fastspringEmbeddedStorefront}" data-access-key="${jsPageData.fastspringAccessKey}" data-debug="false"></script><div id="fsc-embedded-checkout-container" class="position-relative"></div>`);

      // updateFastSpringSession();
    // }
  });

  $cart.on('cart-updated-complete', function() {
    if (isCheckoutTopForm) {
      $iframeWrap.addClass('hidden');
      $iframe.attr('src', '');
      updateFastSpringSession();
    } else {
      if (!$iframeWrap.hasClass('hidden')) {
        updateFastSpringSession();
      } else {
        $buttonPay.closest('.payment-options__button').show();
        $buttonPay.prop('disabled', false);
      }
    }

    // if (jsPageData.isCartFromOrder && jsPageData.orderId && jsPageData.paymentMethod === 'fastspring') {
    //   $buttonPay.trigger('click');
    // } else if (jsPageData.cartId && jsPageData.paymentMethod === 'fastspring') {
    //   $buttonPay.trigger('click');
    // }
  });

  // $('[data-pay-by="fastspring-popup"]').on('click', function() {
  //   $(this).prop('disabled', true);
  //
  //   if ($('.fastspring-iframe-popup-wrap').length > 0) {
  //     $('.fastspring-iframe-wrap').empty();
  //     $('.fastspring-iframe-popup-wrap').empty();
  //     $('.fastspring-iframe-popup-wrap').
  //         html(`<script id="fsc-api" src="https://sbl.onfastspring.com/sbl/1.0.1/fastspring-builder.min.js" type="text/javascript" data-storefront="${jsPageData.fastspringPopupStorefront}" data-access-key="${jsPageData.fastspringAccessKey}" data-debug="false"></script>`);
  //
  //     $('[data-pay-by="fastspring"]').prop('disabled', false).show();
  //
  //     updateFastSpringSession();
  //   }
  // });
};

export const initCartPaymentMethodFastspringScripts = () => {
  /**
   * @property {object} jsPageData
   */

  runIfHasSelecter('[data-role="cart"]', [
    initCommon
  ]);
};
